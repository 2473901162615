import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Alert, Button } from 'reactstrap';
import config from '../../../config';
import Widget from '../../../components/Widget';
import jwt from "jsonwebtoken";
import { loginUser } from '../../../actions/auth';
import { toast } from 'react-toastify';

class Login extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    static isAuthenticated() {
        const token = localStorage.getItem('token');
        if (!config.isBackend && token) return true;
        if (!token) return;
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(token);
        if (!data) return;
        return date < data.exp;
    }

    constructor(props) {
        super(props);

        this.state = {
            b2cLoginWait: false
        };

        localStorage.setItem('app', 'en');

        this.doLogin = this.doLogin.bind(this);

        let oldState = null;
        let intervalId = window.setInterval(() => {
            let state = !!window.IS_LOGGING_IN;
            if (oldState !== state) {
                if (state) {
                    this.setState({ b2cLoginWait: true, rand: Math.random() });
                }
                else {
                    this.setState({ b2cLoginWait: false, rand: Math.random() });
                    if (oldState !== null) {
                        window.IS_LOGGING_IN = false;
                        window.clearInterval(intervalId);
                    }
                }
                oldState = state;
            }
        }, 25);
    }

    doLogin(e) {
        e.preventDefault();
        let tok = `${window.location.href}`.split("#")[0].split('/');
        tok.length = Math.min(tok.length, 3);
        const redirect_uri = tok.join('/');
        const url = `https://${config.b2cDomain}/climativetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_EA_SIGNIN&client_id=c37bab30-9b29-41a7-972b-e09ec1a0e7e6&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=openid&response_type=id_token&prompt=login`;
        window.location.href = url;
    }

    componentDidMount() {
    }

    render() {
        document.body.style.background = `#1f2631`;

        if (window.location.href.indexOf("id_token=") >= 0) {
            return (
                <div className="auth-page">
                    <div className={'loading loading-show'}><img alt={"Loading Animation"} src='/images/loading-transparent.gif'/><div className="loading-claiming-msg">{"Waiting for verification ..."}</div></div>
                </div>
            )
        }

        return (
            <div className="auth-page">
                <div className={'loading ' + (this.state.b2cLoginWait ? 'loading-show' : 'loading-hide')}><img alt={"Loading Animation"} src='/images/loading-transparent.gif'/><div className="loading-claiming-msg">{"Waiting for verification ..."}</div></div>
                <Container>
                    <center>
                        <a href="https://www.climative.ai/"><img alt="Climative" src="images/simptek-green.png" /></a><br />
                        <br />
                        <span style={{ "font-size": "18px", "color": "#cbe8f6" }}>Energy Advisor Application</span>
                    </center>
                    <br />
                    <br />
                    <br />
                    <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">Login to Advisor</h3>}>
                        <form onSubmit={this.doLogin}>
                            <div className="bg-widget auth-widget-footer">
                                <br />
                                <Button type="submit" color="danger" className="auth-btn" style={{ "background-color": "#47bcf6", "font-weight": "bold", "border": "1px solid rgba(0, 0, 0, 0.25)", "padding": "12px" }}
                                    size="sm">
                                    {this.state.b2cLoginWait ? 'Loading...' : 'Login'}
                                </Button>
                                <br />
                                <br />
                            </div>
                        </form>
                    </Widget>
                </Container>
                <footer className="auth-footer">
                    Energy Advisor Application by <a href="https://www.climative.ai/" >Climative</a>
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Login));
