import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerCard from './components/CustomerCard';
import { getENCustomers } from '../../actions/energy-navigator';
import { getBuildingsRequest } from '../../actions/buildings';
import { Dropdown, RandomKey } from '../../components/Common';
import axios from 'axios';

import './ENCustomers.scss';

class EnergyCustomers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customers: PropTypes.array,
  };

  static defaultProps = {
    isReceiving: false,
    customers: [{
      buildingId: '',
      name: "",
      building_address: '',
      projects_new: 1,
      projects_ongoing: 1,
      projects_completed: 1,
      image_uri: ""
    }]
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOrg: null
    };
  }

  componentDidMount() {
    this.props.dispatch(getENCustomers());
    //this.props.dispatch(getBuildingsRequest());
    setTimeout(() => {
      this.showPromoAlert();
    }, 100);
    this.key = RandomKey();
  }

  showPromoAlert() {
    //this.setState({ promoAlert: true });
  }

  render() {
    if (!this.props.customers || !this.props.customers.length) {
      return (<div/>);
    }
    let buildings = this.props.buildings || [];
    let bmap = {};
    for (let B of buildings) {
      bmap[B.buildingId] = B;
    }
    let customers = [];
    for (let C of this.props.customers) {
      /*let buildingsNew = [];
      for (let B of C.buildings) {
        if (B) {
          buildingsNew.push(bmap[B.buildingId]);
        }
      }
      C.buildings = buildingsNew;*/
      C.key = C.id;
      C.title = C.name;
      customers.push(C);
    }
    return (
      <div className="encustomers-container">
        <div className="page-title-white">All Customers <div className="cust-count">{this.props.customers.length}</div></div>
        <label>Select Organization:</label> <Dropdown options={customers} onChange={(val) => this.setState({selectedOrg: val, themeConfig: val.theme_config_json})} />
        {this.state.selectedOrg ? <div className='enc-selected-org'>
          <br/>
          <h4>"{this.state.selectedOrg.name}" Theme Config:</h4>
          {this.state.themeConfig?.logo_url ? <div className="ent-logo-preview">
            Current logo:<br/>
            <img src={this.state.themeConfig?.logo_url}/>
          </div> : undefined}
          <label>Upload company logo (design for a white background, should be a PNG with transparency.):</label>
          <input
            type="file"
            name="image"
            onChange={(e) => {
              e = e || window.event;
              let formData = new FormData();
              formData.append("image", e.target.files[0]);
              axios.post(
                `/attachments/upload-theme-image`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
              ).then((res) => {
                this.state.themeConfig.logo_url = res.data.imageURL;
                this.setState({ themeConfig: this.state.themeConfig });
              });
            }}
          />
          <br/>
          <br/>
          <div className='ent-save-button' onClick={() => {
            axios.put(
              `/orgs/${this.state.selectedOrg.id}`,
              {
                theme_config_json: JSON.stringify(this.state.themeConfig)
              }
            ).then((res) => {
              this.setState({ saved: true });
              setTimeout(() => {
                this.setState({ saved: false });
              }, 3000);
            });
          }}>Save Theme</div>
          {this.state.saved ? <div className='ent-saved'>Saved.</div> : undefined}
        </div> : undefined}
        {/*customers.map((obj, index) => (
          <CustomerCard data={obj} key={this.key + '-' + index}/>
        ))*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buildings: state.buildings.data,
    customers: state.energyNavigator.allENCustomers,
    isReceiving: state.products.isReceiving,
    isDeleting: state.products.isDeleting,
    idToDelete: state.products.idToDelete,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(EnergyCustomers);
