import React, { memo, useState, useEffect } from 'react';

import { Handle } from 'react-flow-renderer';
import { Input } from '../../../components/Common';
import NodeHandle from './NodeHandle';
import ColumnSelect from './ColumnSelect';

export default memo(({ model, title, onEdit, onDelete, onSave, onChangeName, onCancelEdit, children, inputs, outputs, width, maximize, data }) => {

  const [ lastModel, setLastModel ] = useState(JSON.stringify(model||{}));

  const [ editing, setEditing ] = useState(false);
  const [ name, setName ] = useState((model||{}).name || '');
  const [ previousName, setPreviousName ] = useState((model||{}).name || '');
  const [ maximized, setMaximized ] = useState(false);
  const [ myId, setId ] = useState("node-wrapper-" + Math.random());

  const wheelFn = function(e) {
    if (editing) {
      //e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  useEffect(() => {
    return function cleanup() {
      if (document.getElementById(myId)) {
        document.getElementById(myId).removeEventListener('wheel', wheelFn, {passive: false, capture: true});
      }
    };
  })

  model = model || {};

  const colInfo = (data && data.value && data.value._colInfo)||{};

  const applyPropsToChildren = (list) => {
    if (!(list instanceof Array)) {
      list = [ list ];
    }
    let ret = [];
    for (let C of list) {
      if (!C) {
        continue;
      }

      let O = null;

      if (C && C.type && C.type === ColumnSelect) {
        O = React.cloneElement(C, {__colInfo: colInfo, children: (C.props && C.props.children && applyPropsToChildren(C.props.children)) || []});
      }
      else if (C && C.props && C.props.children) {
        O = React.cloneElement(C, {children: applyPropsToChildren(C.props.children) || []});
      }
      else {
        O = C;
      }

      if (O !== undefined) {
        ret.push(O);
      }
    }
    return ret;
  };

  children = applyPropsToChildren(children);

  //
  
  if (!editing) {
    return (
      <div id={myId} className='flow-node-wrapper' style={width ? {width} : {}}>
        <img src='/images/flow-drag.svg' className='flow-drag-icon'/>
        <div className='flow-node-name'>{(name||'').trim() || title}</div>
        <img src='/images/flow-node-delete.svg' className='flow-delete-icon'
          onClick={() => {
            if (onDelete) {
              onDelete();
            }
          }} />
        <img src='/images/flow-node-edit.svg' className='flow-edit-icon'
          onClick={() => {
            setEditing(true);
            setPreviousName(name);
            if (onEdit) {
              onEdit();
            }
          } } />
        <div className='fn-right-side'></div>
        {inputs === 1 && <NodeHandle isInput={true} />}
        {inputs === 2 && <NodeHandle isDualInput={true} isMin={true} />}
        {outputs === 1 && <NodeHandle isOutput={true} />}
      </div>
    );
  }
  else {
    return (
      <div id={myId} className={'flow-node-wrapper fnw-editing ' + (maximized ? 'fnw-maximized' : '')} style={maximized ? {width:maximize.width} : ((width ? {width} : {}))} onMouseOver={(e) => document.getElementById(myId).addEventListener('wheel', wheelFn, {passive: false, capture: true})} onMouseOut={(e) => document.getElementById(myId).removeEventListener('wheel', wheelFn, {passive: false, capture: true})}>
        <div className='flow-node-title'>{title}</div>
        <div className='flow-node-name'>Node Name</div>
        <Input type="text" value={name}
          onChange={(val) => {
            setName(val);
            if (onChangeName) {
              onChangeName(val);
          } }} />
        {lastModel !== JSON.stringify(model||{}) && <img src='/images/save-flow.svg' className='flow-edit-save-icon'
          onClick={() => {
            setEditing(false);
            setPreviousName(name);
            setLastModel(JSON.stringify(model||{}));
            if (onSave) {
              onSave();
            }
          } } />}
        <img src='/images/x-icon.svg' className='flow-edit-cancel-icon'
          onClick={() => {
            setName(previousName);
            onChangeName(previousName);
            setEditing(false);
            if (onCancelEdit) {
              onCancelEdit();
            }
          } } />
        {!!maximize && !maximized && <div className='flow-edit-maximize' onClick={(e) => { setMaximized(true); maximize.callback && maximize.callback(true); }}>Maximize</div>}
        {!!maximize && !!maximized && <div className='flow-edit-minimize' onClick={(e) => { setMaximized(false); maximize.callback && maximize.callback(false); }}>Minimize</div>}
        {children}
        <div className='fn-right-side'></div>
        {inputs === 1 && <NodeHandle isInput={true} />}
        {inputs === 2 && <NodeHandle isDualInput={true} />}
        {outputs === 1 && <NodeHandle isOutput={true} />}
      </div>
    );
  }
});
