import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, CompletenessSlider, CompletenessMessage, RandomKey } from '../../../components/Common'
import './BuildingDetailsCard.scss';
import config from '../../../config';

class BuildingDetailsCard extends Component {

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  renderDetail(title, value) {
    return (
      <div className='bd-detail-half' title={value || '-'}>
        <div className='bdd-title'>{title}</div>
        <div className='bdd-value'>{value || '-'}</div>
      </div>
    );
  }

  render() {
    const { data } = this.props;

    const fmtGJ = Format.GJ,
          fmtKG = Format.KG,
          fmtPercent = Format.Percent,
          fmtDollars = Format.Dollars,
          fmtDollarsShort = Format.DollarsShort,
          fmtSQFT = Format.SQFT;

    const hasEditPermissions = true; // TODO: Hook this up

    if (window.location.href.indexOf(data.buildingId) < 0 && !this.props.notBuildingDetailsPage) {
      return <div/>;
    }

    return (
      <div key={this.key} className='building-details-card'>
        <BuildingImage
          address={data.address + ',' + data.city + ',' + data.iso3166_2_code}
          width={282} height={158}
        />

        <div className='bd-name'>{data.address}</div>

        <StatLine
          title="Energy Use Intensity"
          subtitle={data.building_comparison && data.building_comparison.building_eui ? `${fmtGJ(data.building_comparison.building_eui* 277.78 / 10.764)} kWh/ft², ` : `-- kWh/ft²`}
        >
          {data.building_comparison && data.building_comparison.building_eui && fmtPercent(data.building_comparison.eui_delta_percent)}
        </StatLine>

        <StatLine
          title="GHG Emission Intensity"
          subtitle={data.building_comparison && data.building_comparison.ghg_delta_percent ? `${fmtKG(data.building_comparison.building_ghg)} kgCO2/m², ` : `-- kgCO2/m²`}
        >
          {data.building_comparison && data.building_comparison.ghg_delta_percent && fmtPercent(data.building_comparison.ghg_delta_percent)}
        </StatLine>

        <div className="bd-actions">
          {/*<div className="en-button-primary">Share Building Profile</div>*/}
        </div>

        <div className='bd-details-title'>Building Details</div>
        {(!!data.dashboard_url && !!data.buildingId) && (<div className='bd-login-b360' onClick={() => {window.open(`${data.dashboard_url}/building/${data.buildingId}/profile`, '_blank').focus();}}>Open in Building360</div>)}

        {data.manager_name && this.renderDetail('Facility Manager', data.manager_name)}
        {data.manager_phone && this.renderDetail('Contact Number', data.manager_phone)}
        {data.electricUtilityName && this.renderDetail('Electric Utility', data.electricUtilityName)}
        {data.floorArea && this.renderDetail('Area', fmtSQFT(data.floorArea))}
        {data.propertyType && this.renderDetail('Type of Building', data.propertyType)}
        {data.yearBuilt && this.renderDetail('Built Year', data.yearBuilt)}
        {data.totalOccupants && this.renderDetail('No. of Occupants', data.totalOccupants)}
        {data.operating_hours && this.renderDetail('Operating Hours', data.operating_hours + ' hrs/week')}
        {data.primaryHeatingType && this.renderDetail('Primary Heating Type', data.primaryHeatingType)}
        {data.primaryCoolingType && this.renderDetail('Primary Cooling Type', data.primaryCoolingType)}

        {data.objective && <div className='bd-goals-objectives'>Goals & Objectives</div>}
        {data.objective && <div className='bd-goals-text'>{data.objective}</div>}

        <div className='bd-pc-title'>Profile Completeness</div>

        <CompletenessSlider size="large" percent={data.completeness_score || 0} />

        <div className='bd-pc-message'>{CompletenessMessage(data.completeness_score || 0, hasEditPermissions)}</div>

        {/* <div>
          <p className="building-details-offer-count">
            <small>Insights: {data.offersNewCount || 0}&nbsp;&nbsp;|&nbsp;&nbsp;Offers: {data.offersOngoingCount || 0}</small>
          </p>
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(BuildingDetailsCard);
