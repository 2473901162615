import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import { Input, Dropdown } from '../../../components/Common';
import Checkbox from './Checkbox';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ k2, setK2 ] = useState(["k2-" + Math.random(), "k2-" + Math.random(), "k2-" + Math.random()]);

  const setIsConstant = (col, val) => {
    model.is_constant = model.is_constant || {};
    model.is_constant[col] = !!val;
    saveModel({ ...model });
  };

  const setValue = (col, val) => {
    model[col] = val;
    saveModel({ ...model });
  }

  const processTypeOptions = [
    {
      key: "extract-fsa",
      title: "Extract FSA"
    },
    {
      key: "combine-address",
      title: "Combine Address Fields"
    }
  ];

  const ColSelectOrValue = (title, key) => {
    return (
        <div className='fcs-cs-or-val'>
            {(!model.is_constant || !model.is_constant[key]) && <ColumnSelect
                title={title + ' (Column)'} value={model[key]} onChange={(val) => setValue(key, val)}
            />}
            {(model.is_constant && model.is_constant[key]) && <div className='flow-col-select-wrapper'>
                <div className='fcs-title'>{title} (Value)</div>
                <Input type="text" title={title + ' (Column)'} defaultValue={model[key]} onChange={(val) => setValue(key, val)}/>
            </div>}
            <Checkbox title='Is Constant' value={!!model.is_constant[key]} onChange={(val) => setIsConstant(key, val)} />
        </div>
    );
  }

  return (
    <NodeWrapper
      title='Address Tools'
      onSave={() => {let newModel = {...model, is_constant: {...(model.is_constant || {})}}; data.onChange(newModel); saveModel(newModel)}}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={process === 'extract-fsa' ? "450px" : "550px"}
      data={data}
    >
      <div className='fcs-title'>Method</div>
      <Dropdown
        options={processTypeOptions}
        defaultValue={processTypeOptions.filter((e) => e.key === model.process)[0]}
        onChange={(val) => saveModel({...model, process: val.key})}
        hasArrow={true}
      />
      {model.process === 'extract-fsa' && <div key={k2[0]}>
        <div className='fcs-io-title'>INPUTS</div>
        <ColumnSelect title='Postal Code' value={model.postal_code} onChange={(val) => saveModel({...model, postal_code: val})} />
        <div className='fcs-io-title'>OUTPUT</div>
        <ColumnSelect title='Output FSA Column' value={model.fsa} noOptions={true} onChange={(val) => saveModel({...model, fsa: val})} />
      </div>}
      {model.process === 'combine-address' && <div key={k2[1]}>
        <div className='fcs-io-title'>INPUTS</div>
        {ColSelectOrValue('Street Number', 'street_number')}
        {ColSelectOrValue('Street Name', 'street_name')}
        {ColSelectOrValue('Street Suffix', 'street_suffix')}
        {ColSelectOrValue('City Name', 'city')}
        {ColSelectOrValue('Province/State', 'province')}
        {ColSelectOrValue('Postal Code', 'postal_code')}
        {ColSelectOrValue('Country', 'country')}
        <div className='fcs-io-title'>OUTPUT</div>
        <ColumnSelect title='Output Address Column' value={model.full_address} noOptions={true} onChange={(val) => saveModel({...model, full_address: val})} />
      </div>}
    </NodeWrapper>
  );
  
});