import {
    UPDATED_EN_INSIGHTS,
    UPDATED_EN_CUSTOMERS
} from '../actions/energy-navigator';

const defaultState = {
    allInsights: {},
    allENCustomers: []
};

export default function energyNavigatorReducers(state = defaultState, action) {

    const fClone = (x) => (JSON.parse(JSON.stringify(x)));

    switch (action.type) {
        case UPDATED_EN_INSIGHTS:
            return Object.assign({}, state, {
                allInsights: fClone(action.payload)
            });
        case UPDATED_EN_CUSTOMERS:
            return Object.assign({}, state, {
                allENCustomers: fClone(action.payload)
            });
        default:
            return state;
    }
}
