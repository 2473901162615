import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-nord_dark";

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ maximized, setMaximized ] = useState(false);

  return (
    <NodeWrapper
      title='Function Entry Point'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={0}
      outputs={1}
      model={model}
      width={"425px"}
      maximize={{
        width: "810px",
        callback: (flag) => setMaximized(flag)
      }}
      data={data}
    >
      <div className='fcs-title'>Mock/Input Spec JSON Editor</div>
      <div className='flow-node-js-editor-wrapper' onMouseDown={(e) => { e = e || window.event; e.preventDefault(); e.stopPropagation(); return false; }}>
        <AceEditor
          mode="javascript"
          theme="nord_dark"
          width={maximized ? "760px" : "375px"}
          height={maximized ? "369px" : "240px"}
          value={model.mockInputDraft}
          onChange={(val) => {model.mockInputDraft = val; saveModel({...model, mockInputLive: model.mockInputDraft})}}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    </NodeWrapper>
  );
});