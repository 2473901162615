import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import InsightTypeBlock from './InsightTypeBlock';
import ConfirmButton from './ConfirmButton';

import '../Common.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      opened: !!props.opened
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.opened !== prevProps.opened) {
      this.setState({ opened: this.props.opened });
    }
  }

  render() {
    return (
      <div className={'en-modal-cont ' + (this.state.opened ? 'open' : '')}>
        <div className={'en-modal'} style={{marginLeft: -Math.round(parseInt(this.props.width) / 2) + 'px', width: this.props.width, height: this.props.height}}>
          <div className={'en-modal-title'}>{this.props.title || 'Confirm'}</div>
          <ConfirmButton icon='/images/x-icon.svg' msgBottom={true} wrapperClassName="en-modal-close" className="en-button-transparent" msg={this.props.closeMsg || "Are you sure you wish to cancel? Unsaved changes will be lost."} onConfirm={() => {this.props.onClose();}} />
          <div className={'en-modal-content'} style={{height: this.props.height === '100%' ? 'calc(100vh - 387px)' : ((parseInt(this.props.height) - 144)+'px') }}>
            {this.props.children}
          </div>
          <div className={'en-modal-sep'}/>
          <div className={'en-modal-btn-cont'}>
            {(this.props.buttons || []).map((btn, idx) => (
              <div key={this.key+'b'+idx} className={(btn.className||'en-button-primary')+' en-modal-btn'} onClick={() => {btn.onClick()}}>{btn.title}</div>
            ))}
            {this.props.hasCancelButton && <ConfirmButton title="Cancel" wrapperClassName="en-modal-btn" className="en-button-secondary" msg={this.props.closeMsg || "Are you sure you wish to cancel? Unsaved changes will be lost."} onConfirm={() => {this.props.onClose();}} />}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(() => ({}))(Modal));
