import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dismissAlert } from '../../actions/alerts';
import { changeActiveSidebarItem } from '../../actions/navigation';
import { logoutUser } from '../../actions/auth';
import { RandomKey } from '../../components/Common';

import './NewSidebar.scss';

class NewSidebar extends React.Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  dismissAlert(id) {
    this.props.dispatch(dismissAlert(id));
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  render() {
    const user = this.props.currentUser;
    const rolesContain = (...args) => {
      for (let i=0; i<args.length; i++) {
        if (user && user.role && user.role.indexOf(args[i]) >= 0) {
          return true;
        }
      }
      return false;
    };

    const items = [];
    if (rolesContain('admin', 'portfoliomanager', 'portfolioowner', 'serviceprovider', 'programmanager')) {
      //items.push({ url: '/app/main/en-dashboard', icon: '/images/dashboard-icon-white.svg', iconSel: '/images/dashboard-icon-blue.svg', title: 'Dashboard' });
    }
    if (rolesContain('admin', 'serviceprovider', 'portfoliomanager', 'portfolioowner', 'programmanager')) {
      //items.push({ url: '/app/main/en-buildings', icon: '/images/buildings-icon-white.svg', iconSel: '/images/buildings-icon-blue.svg', title: 'Buildings' });
    }
    if (rolesContain('admin', 'serviceprovider', 'programmanager')) {
      items.push({ url: '/app/main/en-customers', icon: '/images/customers-icon-white.svg', iconSel: '/images/customers-icon-blue.svg', title: 'Customers' });
    }
    if (rolesContain('admin')) {
      items.push({ url: '/app/main/en-insights-map', icon: '/images/insights-map-icon.png', iconSel: '/images/insights-map-icon-sel.png', title: 'Insights Map' });
      items.push({ url: '/app/main/en-etl-flows', icon: '/images/flow-icon.png', iconSel: '/images/flow-icon-sel.png', title: 'ETL Flows' });
      items.push({ url: '/app/main/en-bg-flows', icon: '/images/bg-flow-icon.png', iconSel: '/images/bg-flow-icon-sel.png', title: 'ETL Flows - Background Jobs' });
    }
    if (rolesContain('admin')) {
      items.push({ url: '/app/main/en-user-management', icon: '/images/user-management.png', iconSel: '/images/user-management-blue.png', title: 'User Management' });
    }

    for (let i=0; i<items.length; i++) {
      items[i].selected = window.location.href.indexOf(items[i].url) >= 0;
    }
    return (
      <div className='new-sidebar'>
        <div className='burger'>
          <img src='/images/menu-icon-white.svg'/>
        </div>
        {items.map((item, index) => (
          <div key={this.key + '-' + index} className={'sidebar-item ' + (item.selected ? 'selected' : '')} onClick={() => {window.location.href = "#" + item.url}}>
            <img src={item.selected ? item.iconSel : item.icon}/>
          </div>
        ))}
      </div>
    )
  }
}

function mapStateToProps(store) {
  return {
    currentUser: store.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(NewSidebar));
