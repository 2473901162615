/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import './EstArea.scss';

class EstArea extends React.Component {

  static propTypes = {
    onClick: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
        hover: false
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }  

  render() {

    return (
      <div className={'est-area ' + (this.props.disabled ? 'ea-disabled' : '') }>
        <img className='est-area-icon' src="/images/estimate-area.png" onClick={() => { if (!this.props.disabled) { this.props.onClick() } }} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} />
        {this.state.hover && <div className='est-area-tooltip'>{this.props.disabled ? ('Error: ' + this.props.tooltip) : "Estimate this field"}</div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { };
}
export default withRouter(connect(mapStateToProps)(EstArea));
