import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../Common.scss';

class RichTextViewer extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
    
    this.state = { editorState: EditorState.createEmpty() }
    if (props.value) {
    	this.state.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.value)));
    }
  }

  componentDidUpdate(prevProps) {
  	if (this.props.value && (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) && (this.lastVal != JSON.stringify(this.props.value))) {
  		this.setState({
  			editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.value)))
  		});
      this.lastVal = JSON.stringify(this.props.value);
  	}
  }

  render() {
    return (
      <div key={this.key} className='djs-viewer-container' style={{width: (this.props.width || '645px')}}>
				<Editor
		  		editorState={this.state.editorState}
          wrapperClassName={'djs-viewer-wrapper'}
          editorClassName={'djs-viewer-editor'}
          toolbarClassName={'djs-viewer-toolbar'}
          readOnly
        />
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(RichTextViewer));

