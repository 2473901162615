import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import RadioSelect from './RadioSelect';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Sort Dataset'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      data={data}
    >
      <ColumnSelect title='Sort Order Column' value={model.column} onChange={(val) => saveModel({...model, column: val})} />
      <RadioSelect title='Sort Order' value={model.desc ? 'desc' : 'asc'} onChange={(val) => saveModel({...model, desc: val === 'desc'})} options={[{title: 'Ascending', key: 'asc'},{title: 'Descending', key: 'desc'}]} />
    </NodeWrapper>
  );
});