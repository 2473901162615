import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBuildingsRequest, getBuildingRequest } from '../../actions/buildings';
import { createENProject, editENProject } from '../../actions/energy-navigator';
import { getENInsights, getENInsightsByBuildingId } from '../../actions/energy-navigator';
import {Format, RandomKey, Dropdown, Input, MinMaxInput, TabBuilder, RichTextEditor, RichTextViewer, AttachmentUploader, ConfirmButton } from '../Common';
import BuildingDetailsCard from '../../pages/enBuildingDetails/components/BuildingDetailsCard';

import './CreateProjectModal.scss';

class CreateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      model: {
        ...(props.edit || {})
      },
      raw_files: props.edit ? (props.edit.attachments) || [] : {
        description: [],
        technical_details: [],
        financial_details: [],
        vendor: [],
        other: []
      },
      errors: {},
      desc_tab: 'description'
    };

    if (!this.state.raw_files.description) { this.state.raw_files.description = []; }
    if (!this.state.raw_files.technical_details) { this.state.raw_files.technical_details = []; }
    if (!this.state.raw_files.financial_details) { this.state.raw_files.financial_details = []; }
    if (!this.state.raw_files.vendor) { this.state.raw_files.vendor = []; }
    if (!this.state.raw_files.other) { this.state.raw_files.other = []; }

    this.descItems = [
      { 'title': 'Description', width: 107, required: true, chosen: true, key: 'description' },
      { 'title': 'Technical Details', width: 148, key: 'technical_details', chosen: props.edit && props.edit.technical_details },
      { 'title': 'Financial Details', width: 148, key: 'financial_details', chosen: props.edit && props.edit.financial_details },
      { 'title': 'Vendor', width: 87, key: 'vendor', chosen: props.edit && props.edit.vendor },
      { 'title': 'Other', width: 87, key: 'other', chosen: props.edit && props.edit.other }
    ];
  }

  componentDidUpdate(prevState, prevProps) {
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.props.dispatch(getBuildingsRequest());
    if (this.state.model.building_id) {
      this.props.dispatch(getBuildingRequest(this.state.model.building_id.key));
    }
  }

  onChange(key) {
    return (val, v2) => {
      let model = this.state.model;
      if (typeof v2 === typeof "" && !v2) {
        val = null;
      }
      model[key] = val;
      if (key === 'building_id') {
        if (val && val.key) {
          for (let i=0; i<this.props.customers.length; i++) {
            if (val.key === this.props.customers[i].buildingId) {
              model.energy_use_intensity =  Format.GJ((this.props.customers[i].building_comparison.building_eui || model.energy_use_intensity) * 277.78 / 10.764);
              model.ghg_intensity = this.props.customers[i].building_comparison.building_ghg || model.ghg_intensity;
              model.energy_star = this.props.customers[i].energyStarScore || model.energy_star;
              this.props.dispatch(getBuildingRequest(val.key));
            }
          }
        }
      }
      this.setState({ model });
      if (this.state.errors[key]) {
        this.checkErrors(key);
      }
    };
  }

  onChangeTabs() {
    return (selected, options, hasTab) => {
      this.hasTab = hasTab;
      this.setState({ desc_tab: selected.key });
      this.descItems = options;
    };
  }

  checkErrors(only) {
    let model = this.state.model;

    let error = false;

    let errors = {...(this.state.errors)};
    let doError = (field, flag, msg) => {
      if (only && field !== only) {
        return false;
      }
      errors[field] = flag ? (msg || 'This field is required') : null;
      if (flag) {
        error = true;
      }
      return flag;
    };

    doError('type', !model.type);
    doError('building_id', !model.building_id);
    doError('title', !model.title);
    /*doError('est_annual_savings', !model.est_annual_savings);
    doError('est_capital_cost', !model.est_capital_cost);
    doError('est_payback_period_years', !model.est_payback_period_years);
    doError('est_roi', !model.est_roi);*/
    doError('description', !model.description);
    doError('financial_details', (!this.hasTab.financial_details || !model.financial_details) && (model.type === 'quoted-offer'));

    if (model.est_annual_savings) {
      if (
        !doError('est_annual_savings',
          (model.est_annual_savings.max < 0 || model.est_annual_savings.max > 1000000) ||
          (model.est_annual_savings.min < 0 || model.est_annual_savings.min > 1000000)
          , 'Must be between 0 and $1,000,000.'
        )
      ) {
        doError('est_annual_savings',
          (model.est_annual_savings.max < model.est_annual_savings.min)
          , 'Minimum must be less than or equal to max.')
      }
    }

    if (model.est_capital_cost) {
      if (
        !doError('est_capital_cost',
          (model.est_capital_cost.max < 0 || model.est_capital_cost.max > 1000000) ||
          (model.est_capital_cost.min < 0 || model.est_capital_cost.min > 1000000)
          , 'Must be between 0 and $1,000,000.'
        )
      ) {
        doError('est_capital_cost',
          (model.est_capital_cost.max < model.est_capital_cost.min)
          , 'Minimum must be less than or equal to max.')
      }
    }

    if (model.est_roi) {
      if (
        !doError('est_roi',
          (model.est_roi.max < 0 || model.est_roi.max > 1000) ||
          (model.est_roi.min < 0 || model.est_roi.min > 1000)
          , 'Must be between 0% and 1,000%'
        )
      ) {
        doError('est_roi',
          (model.est_roi.max < model.est_roi.min)
          , 'Minimum must be less than or equal to max.')
      }
    }

    if (model.est_payback_period_years) {
      if (
        !doError('est_payback_period_years',
          (model.est_payback_period_years.max < 0 || model.est_payback_period_years.max > 100) ||
          (model.est_payback_period_years.min < 0 || model.est_payback_period_years.min > 100)
          , 'Must be between 0 and 100 years'
        )
      ) {
        doError('est_payback_period_years',
          (model.est_payback_period_years.max < model.est_payback_period_years.min)
          , 'Minimum must be less than or equal to max.')
      }
    }

    if (model.eui_savings) {
      doError('eui_savings',
        (model.eui_savings.max < model.eui_savings.min)
        , 'Minimum must be less than or equal to max.')
    }

    if (model.ghg_savings) {
      doError('ghg_savings',
        (model.ghg_savings.max < model.ghg_savings.min)
        , 'Minimum must be less than or equal to max.')
    }

    /*if (model.projected_energy_star) {
      if (
        !doError('projected_energy_star',
          (model.projected_energy_star.max < 0 || model.projected_energy_star.max > 100) ||
          (model.projected_energy_star.min < 0 || model.projected_energy_star.min > 100)
          , 'Must be between 0 and 100'
        )
      ) {
        doError('projected_energy_star',
          (model.projected_energy_star.max < model.projected_energy_star.min)
          , 'Minimum must be less than or equal to max.')
      }
    }

    if (model.energy_star) {
      doError('energy_star',
        (model.energy_star < 0 || model.energy_star > 100)
        , 'Must be between 0 and 100'
      )
    }*/

    this.setState({ errors: errors });

    return error;

  }

  publishProject(revoke) {

    if (this.checkErrors()) {
      return;
    }

    let model = this.state.model;

    let obj = {
      type: model.type === 'expert-insights' ? 'insight' : 'offer',
      subtype: model.type,
      property_id: model.building_id.key,
      status: 'new',
      title: model.title,
      property_name: model.building_id.title,
      category_tags: '',
      overview: model.description,
      financial_details: (this.hasTab.financial_details && model.financial_details) || null,
      technical_details: (this.hasTab.technical_details && model.technical_details) || null,
      vendor: (this.hasTab.vendor && model.vendor) || null,
      other: (this.hasTab.other && model.other) || null,
      est_annual_savings: model.est_annual_savings,
      est_capital_costs: model.est_capital_cost,
      est_payback_period: model.est_payback_period_years,
      est_roi: model.est_roi,
      parent_offer_id: model.parent_offer_id,
      active: 1
    };

    let attTypes = [ 'description', 'technical_details', 'financial_details', 'vendor', 'other' ];
    let attachments = [];
    for (let type of attTypes) {
      let rf = this.state.raw_files[type];
      for (let i=0; i<rf.length; i++) {
        let t2 = type === 'description' ? 'overview' : type;
        if (rf[i].status === 'success' && rf[i].id && obj[t2]) {
          attachments.push({
            id: rf[i].id,
            type
          });
        }
      }
    }
    obj.attachments = attachments;

    if (obj.type === 'offer') {
      obj = {
        ...obj,
        energy_use_intensity: model.energy_use_intensity,
        eui_reduction_min: model.eui_savings && model.eui_savings.min,
        eui_reduction_max: model.eui_savings && model.eui_savings.max,
        ghg_intensity: model.ghg_intensity,
        ghg_intensity_reduction_min: model.ghg_savings && model.ghg_savings.min,
        ghg_intensity_reduction_max: model.ghg_savings && model.ghg_savings.max,
        //energy_star: model.energy_star,
        //projected_energy_star_score_min: model.projected_energy_star && model.projected_energy_star.min,
        //projected_energy_star_score_max: model.projected_energy_star && model.projected_energy_star.max
      };
    }

    const after = () => {
      this.props.dispatch(getENInsights()).then(() => {
        this.setState({ loading: false });
        this.props.onClose();
      });
    };
    if (revoke) {
      obj.active = 0;
    }
    if (this.props.edit && model.offer_id) {
      obj.offer_id = model.offer_id;
      this.props.dispatch(editENProject(obj)).then(after);
    }
    else {
      this.props.dispatch(createENProject(obj)).then(after);
    }

    this.setState({ loading: true });

  }

  onChangeFiles(tab) {
    return (files) => {
      this.state.raw_files[tab] = files;
      this.setState({ raw_files: this.state.raw_files });
    };
  }

  render() {

    const fmtGJ = Format.GJ,
    fmtKG = Format.KG,
    fmtPercent = Format.Percent,
    fmtDollars = Format.Dollars,
    fmtDollarsShort = Format.DollarsShort,
    fmtSQFT = Format.SQFT;

    let buildings = [];
    let buildingData = null;
    let loadingBuildingData = false;
    for (let i=0; i<this.props.customers.length; i++) {
      buildings.push({
        key: this.props.customers[i].buildingId,
        title: this.props.customers[i].name || this.props.customers[i].address
      });
      if (this.state.model.building_id && this.props.customers[i].buildingId === this.state.model.building_id.key) {
        buildingData = this.props.customers[i];
      }
    }
    if (buildingData && this.props.customer && this.props.customer.buildingId === buildingData.buildingId) {
      buildingData = this.props.customer;
    }
    else {
      buildingData = null;
    }
    if (this.state.model.building_id && !buildingData) {
      loadingBuildingData = true;
    }


    let thisName = 'Insight or Offer';
    if (this.state.model.type === 'expert-insights') {
      thisName = 'Insight';
    }
    else if (this.state.model.type) {
      thisName = 'Offer';
    }

    return (
      <div key={this.key} className={'create-project-modal ' + (this.state.loading ? 'loading' : '')}>
        {!buildingData && (
          <div className='cpm-building-not-selected'>
            <img src="/images/building-grey.svg"/>
            <div className="cpm-bns-h1">{loadingBuildingData ? "Loading Building Data" : "Building not Selected"}</div>
            <div className="cpm-bns-h2">{loadingBuildingData ? "..." : "Select building to show information here."}</div>
          </div>
        )}
        {buildingData && (
          <div className='cpm-building-selected'>
            <BuildingDetailsCard data={buildingData} notBuildingDetailsPage={true} />
          </div>
        )}
        <div className='cpm-header'>
          <div className='cpm-title'>{this.props.edit ? 'Edit' : 'Create'} {thisName}</div>
          <div className='cpm-header-right'>
            {/*<div className='en-button-secondary'>Save</div>*/}
            {/*<div className='en-button-secondary'>Preview {thisName}</div>*/}
            <ConfirmButton icon='/images/x-icon.svg' msgBottom={true} wrapperClassName="modal-close" className="en-button-transparent" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
          </div>
        </div>
        <div className='cpm-form-cont'>
          <div className='cpm-form'>
            <Dropdown options="insight-types" error={this.state.errors.type} defaultValue={this.state.model.type} emptyText={"Select " + thisName + " Type"} onChange={this.onChange('type')} readOnly={!!this.props.edit} />
            <Input type="text" vlabel="Title" required={true} error={this.state.errors.title}  label={thisName + " Title"} large={true} width="648px" value={this.state.model.title} onChange={this.onChange('title')} maxLength={255} />
            <br/>
            <Dropdown readOnly={!!this.props.edit} options={buildings} error={this.state.errors.building_id} defaultValue={this.state.model.building_id} title={"Building for " + thisName} emptyText="Select" width="648px" onChange={this.onChange('building_id')} />
            <br/>
            <br/>
            <br/>
            <div className='cpm-form-inner'>
              <div className='cpm-form-row'>
                <MinMaxInput label="Estimated ROI" vlabel="Est. ROI %" unit="percent" decimal={true} width="316px" error={this.state.errors.est_roi} defaultValue={this.state.model.est_roi} onChange={this.onChange('est_roi')} />
                <MinMaxInput label="Estimated capital cost" vlabel="Est. Capital Cost" unit="$" width="316px" error={this.state.errors.est_capital_cost} defaultValue={this.state.model.est_capital_cost} onChange={this.onChange('est_capital_cost')} />
              </div>
              <div className='cpm-form-row'>
                <MinMaxInput label="Estimated payback period in years" decimal={true} vlabel="Est. Payback Years" unit="years" width="316px" defaultValue={this.state.model.est_payback_period_years} error={this.state.errors.est_payback_period_years} onChange={this.onChange('est_payback_period_years')} />
                <MinMaxInput label="Estimated annual savings" vlabel="Est. Yearly Savings" unit="$" width="316px" defaultValue={this.state.model.est_annual_savings} error={this.state.errors.est_annual_savings} onChange={this.onChange('est_annual_savings')} />
              </div>
            </div>
            {this.state.model.type && (this.state.model.type !== 'expert-insights') && (
              <div className='cpm-form-inner'>
                <div className='cpm-form-sep'></div>
                <div className='cpm-form-row'>
                  <Input type="number" required={false} label="Energy use intensity" vlabel="Energy use intensity" width="648px" value={this.state.model.energy_use_intensity} onChange={this.onChange('energy_use_intensity')} error={this.state.errors.energy_use_intensity} /><br/>
                  <MinMaxInput required={false} label="Projected energy use intensity reduction" vlabel="Projected energy use intensity reduction" unit="float" decimal={true} width="648px" defaultValue={this.state.model.eui_savings} error={this.state.errors.eui_savings} onChange={this.onChange('eui_savings')} /><br/><br/>
                  <Input type="number" required={false} label="GHG emmision intensity" vlabel="GHG emission intensity" width="648px" value={this.state.model.ghg_intensity} onChange={this.onChange('ghg_intensity')} error={this.state.errors.ghg_intensity} /><br/>
                  <MinMaxInput required={false} label="Projected GHG emission intensity reduction" vlabel="Projected GHG emission intensity reduction" unit="float" decimal={true} width="648px" defaultValue={this.state.model.ghg_savings} error={this.state.errors.ghg_savings} onChange={this.onChange('ghg_savings')} /><br/><br/>
                  {/*<Input type="number" required={false} label="Energy star score" vlabel={"Energy star score"} width="305px" value={this.state.model.energy_star} onChange={this.onChange('energy_star')} error={this.state.errors.energy_star} />
                  <MinMaxInput required={false} label="Projected energy star improvement" vlabel="Projected EStar Improvement" unit="estar" width="325px" defaultValue={this.state.model.projected_energy_star} error={this.state.errors.projected_energy_star} onChange={this.onChange('projected_energy_star')} />*/}
                  <br/>
                </div>
              </div>
            )}
            <TabBuilder options={this.descItems} onChange={this.onChangeTabs()} autoRequireTabWhen={{"financial_details": this.state.model.type === 'quoted-offer'}} />
            {this.state.desc_tab === 'description' && <RichTextEditor width="648px" value={this.state.model.description} error={this.state.errors.description} onChange={this.onChange('description')} />}
            {this.state.desc_tab === 'technical_details' && <RichTextEditor width="648px" value={this.state.model.technical_details} onChange={this.onChange('technical_details')}/>}
            {this.state.desc_tab === 'financial_details' && <RichTextEditor width="648px" value={this.state.model.financial_details} error={this.state.errors.financial_details} onChange={this.onChange('financial_details')}/>}
            {this.state.desc_tab === 'vendor' && <RichTextEditor width="648px" value={this.state.model.vendor} onChange={this.onChange('vendor')}/>}
            {this.state.desc_tab === 'other' && <RichTextEditor width="648px" value={this.state.model.other} onChange={this.onChange('other')}/>}
            <br/>
            <div className='cpm-att-header'>Attachments</div>
            <br/>
            {this.state.desc_tab === 'description' && <AttachmentUploader width="648px" value={this.state.raw_files['description']} onChange={this.onChangeFiles('description')} />}
            {this.state.desc_tab === 'technical_details' && <AttachmentUploader width="648px" value={this.state.raw_files['technical_details']} onChange={this.onChangeFiles('technical_details')} />}
            {this.state.desc_tab === 'financial_details' && <AttachmentUploader width="648px" value={this.state.raw_files['financial_details']} onChange={this.onChangeFiles('financial_details')} />}
            {this.state.desc_tab === 'vendor' && <AttachmentUploader width="648px" value={this.state.raw_files['vendor']} onChange={this.onChangeFiles('vendor')} />}
            {this.state.desc_tab === 'other' && <AttachmentUploader width="648px" value={this.state.raw_files['other']} onChange={this.onChangeFiles('other')} />}
            {/*<div className='cpm-form-inner'>
              <MinMaxInput required={true} label="Estimated ROI" unit="$" width="201px" defaultValue={this.state.est_roi} onChange={this.onChange('est_roi')} />
              <TabBuilder options={this.descItems} onChange={this.onChangeTabs()} />
              {this.state.desc_tab === 'description' && <RichTextEditor width="648px" value={this.state.model.description} onChange={this.onChange('description')}/>}
              {this.state.desc_tab === 'technical_details' && <RichTextEditor width="648px" value={this.state.model.technical_details} onChange={this.onChange('technical_details')} error='test error 2'/>}
              {this.state.desc_tab === 'financial_details' && <RichTextEditor width="648px" value={this.state.model.financial_details} onChange={this.onChange('financial_details')}/>}
              {this.state.desc_tab === 'vendor' && <RichTextEditor width="648px" value={this.state.model.vendor} onChange={this.onChange('vendor')}/>}
              {this.state.desc_tab === 'other' && <RichTextEditor width="648px" value={this.state.model.other} onChange={this.onChange('other')}/>}
              <RichTextViewer value={this.state.testRTValue} width="648px"/>
            </div>*/}
          </div>
        </div>
        <div className='cpm-submit-buttons'>
          <div className='en-button-primary' onClick={() => {this.publishProject();}}>Publish {thisName}</div>
          <div className='en-button-secondary' onClick={() => {this.publishProject(true);}}>Save As Draft</div>
          <ConfirmButton title="Cancel" className="en-button-secondary" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.buildings.data,
    customer: state.buildings.curBuilding.building,
    currentUser: state.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(CreateProjectModal));
