import React, { memo, useState } from 'react';

let _d1 = new Image(); _d1.src = '/images/flow-checked.svg';
let _d2 = new Image(); _d2.src = '/images/flow-unchecked.svg';

export default memo(({ title, onChange, value }) => {

  return (
    <div className='flow-checkkbox-wrapper' onClick={() => onChange(!value)}>
      <img src={value ? '/images/flow-checked.svg' : '/images/flow-unchecked.svg'} />
      <div className='fcw-title'>{title || 'Select'}</div>
    </div>
  );

});
