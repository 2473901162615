import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BuildingProject from "./components/BuildingProject";
import { getBuildingRequest } from "../../actions/buildings";
import { push } from "connected-react-router";
import { RandomKey } from "../../components/Common";

import "./EABuildingDetail.scss";

class EABuildingDetails extends Component {
	static propTypes = {
		customer: PropTypes.object,
	};

	static defaultProps = {
		customer: {
			buildingId: "",
			name: "",
			building_address: "",
			projects_new: 1,
			projects_ongoing: 1,
			projects_completed: 1,
			image_uri: "",
		},
	};

	constructor(props) {
		super(props);
		this.key = RandomKey();
	}

	componentDidMount() {
		const buildingId = this.props.match.params.buildingId;
		if (buildingId) {
			this.props.dispatch(getBuildingRequest(buildingId));
		}
		setTimeout(() => {
			this.showPromoAlert();
		}, 100);
	}

	showPromoAlert() {
		//this.setState({ promoAlert: true });
	}

	render() {
		const buildingId = this.props.match.params.buildingId;
		console.log(this.props.customer);
		console.log(buildingId);
		let tok = window.location.href.split("?cid=");
		let customerId = tok[1] || null;
		return (
			<div className="building-upload-wrapper">
				<div className="building-details-page-container">
					<div className="back-button" onClick={() => this.props.history.push("/app/main/energy-advisor")}>
						<img src="/images/back-arrow.svg" /> Back
					</div>
					<div className="building-details-container"></div>
					<div className="building-details-right-cont">
						{this.props.customer && this.props.customer.propertyAttachment && this.props.customer.buildingId === buildingId && (
							<BuildingProject buildingId={buildingId} data={this.props.customer.propertyAttachment} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		customer: state.buildings.curBuilding.building,
	};
}

export default connect(mapStateToProps)(EABuildingDetails);
