import React, { memo, useRef, useState } from 'react';
import { JSONTree } from 'react-json-tree'
import NodeWrapper from './NodeWrapper';
import { Input, Dropdown } from '../../../components/Common';

import axios from 'axios';

const fixEncoding = (str) => `${str}`.replace(/[^\x09-\x7F]/g, "");

export default memo(({ data }) => {
  const inputFile = useRef(null);
  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ uploading, setUploading ] = useState(false);
  const onChange = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setUploading(true);
    let formData = new FormData();
    formData.append("file", file);
    axios.put(
        `/api/ge360/service-areas/import-csv`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then((res) => {
        setUploading(false);
        saveModel({...model, output: res.data});
    });
  };

  const downloadFile = (filename) => {
    axios.get(
        `/api/ge360/service-areas/export-csv?category=${model.category || ''}&org_id=${model.org_id || ''}&service_area_id=${model.service_area_id || ''}`,
    ).then((res) => {
        const str = res.data;
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
          type: "application/json;charset=utf-8"
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    });
  }

  const simulateClick = function (elem) { // https://gomakethings.com/how-to-simulate-a-click-event-with-javascript/
    // Create our event (with options)
    let evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    // If cancelled, don't dispatch our event
    let canceled = !elem.dispatchEvent(evt);
  };
  
  const categoryOptions = [
    {
      key: "",
      title: "(No filter)"
    },
    {
      key: "administrative",
      title: "Administrative"
    },
    {
      key: "census",
      title: "Census"
    },
    {
      key: "utility",
      title: "Utility"
    }
  ];

  return (
    <NodeWrapper
      title='Service Area Editor'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={0}
      outputs={1}
      model={model}
      data={data}
    >
      <input className='flow-file-input' type='file' ref={inputFile} onChange={(event) => onChange(event)}/>
      <div className='flow-sa-edit-cont'>
        Step 1: Select Filters.<br/>
        Category: <Dropdown
            options={categoryOptions}
            defaultValue={categoryOptions.filter((e) => e.key === model.category)[0]}
            onChange={(val) => saveModel({...model, category: val.key})}
            hasArrow={true}
        /><br/>
        Org ID: <Input defaultValue={model.org_id} onChange={(val) => saveModel({...model, org_id: val})} /><br/>
        Service Area ID: <Input defaultValue={model.service_area_id} onChange={(val) => saveModel({...model, service_area_id: val})} /><br/>
        Step 2: <div className='flow-file-input-button' style={{width: "200px"}} onClick={(event) => downloadFile('service-area-export.csv')}>Download CSV file</div><br/>
        Step 3: Edit in excel (offline).<br/>
        <ul>
            <li> - Blank cells will not be upated</li>
            <li> - Cannot use this tool to add new service areas</li>
            <li> - Set a cell you wish to blank/null to the string "null"</li>
        </ul>
        Step 4: <div className='flow-file-input-button' style={{width: "200px"}} onClick={() => simulateClick(inputFile.current)}>
          <img src='/images/file-upload-icon.svg'/>
          Uploaded CSV file
        </div>
        {(model.output || uploading) && <div>
            Output:<br/>
            {uploading ? 'Uploading ...' : <JSONTree data={model.output} theme={{ extend: {
              base00: 'transparent',
              base01: '#383830',
              base02: '#49483e',
              base03: '#75715e',
              base04: '#a59f85',
              base05: '#f8f8f2',
              base06: '#f5f4f1',
              base07: '#f9f8f5',
              base08: '#f92672',
              base09: '#fd971f',
              base0A: '#f4bf75',
              base0B: '#a6e22e',
              base0C: '#a1efe4',
              base0D: '#66d9ef',
              base0E: '#ae81ff',
              base0F: '#cc6633'
            }}} invertTheme={false} />}
        </div>}
      </div>
    </NodeWrapper>
  );
});
