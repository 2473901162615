import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import numeral from "numeral";

const Format = {
  GJ: (gj) => {
    return Math.round(gj*100)/100;
  },
  KG: (gj) => {
    return Math.round(gj*100)/100;
  },
  Bytes: (b) => {
    if (b < 10*1024) {
      return `${Math.round((b/1024)*10)/10} KB`;
    }
    else if (b < 0.5*1024*1024) {
      return `${Math.round((b/1024))} KB`;
    }
    else if (b < 10*1024*1024) {
      return `${Math.round((b/(1024*1024))*10)/10} MB`;
    }
    else if (b < 0.5*1024*1024*1024) {
      return `${Math.round(b/(1024*1024))} MB`;
    }
    else {
      return `${Math.round((b/(1024*1024*1024))*10)/10} GB`;
    }
  },
  Percent: (p) => {
    p = Math.round(p);
    let color = p > 0 ? '#ff8b7b' : '#20c2a2';
    let msg = p > 0 ? `${p}% above average` : `${-p}% below average`;
    if (!p) {
      color = '';
      msg = 'same as average';
    }
    return (
      <span style={{color: color}}>{msg}</span>
    );
  },
  Dollars: (d) => {
    let ret = '';
    d = Math.round(d);
    if (!d) {
      return `$0`;
    }
    let cnt = 0;
    while (d > 0) {
      cnt += 1;
      ret = `${d % 10}${ret}`;
      d = Math.floor(d / 10);
      if (d > 0 && (!(cnt % 3))) {
        ret = `,${ret}`;
      }
    }
    return `$${ret}`;
  },
  DollarsShort: (d) => {
    return `$${numeral(d).format('0a')}`;
  },
  SQFT: (b) => {
    if (b < 10*1000) {
      return `${Math.round((b/1000)*100)/100} K ft²`;
    }
    else if (b < 0.5*1000*1000) {
      return `${Math.round((b/1000))} K ft²`;
    }
    else if (b < 10*1000*1000) {
      return `${Math.round((b/(1000*1000))*100)/100} Mil. ft²`;
    }
    else if (b < 0.5*1000*1000*1000) {
      return `${Math.round(b/(1000*1000))} Mil. ft²`;
    }
    else {
      return `${Math.round((b/(1000*1000*1000))*100)/100} Billion ft²`;
    }
  }
};

export default Format;
