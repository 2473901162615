import React, { memo, useState } from 'react';

export default memo(({ title, onChange, value }) => {
  
    const [val, setVal] = useState(value);
    const _onChange = (event) => {
      event = event || window.event;
      let newVal = event.target.value;
      setVal(newVal);
      onChange(newVal);
    };

  return (
    <div className='flow-color-select'>
      <div className='fcs-title'>{title || 'Select Color'}</div>
      <input type="color" value={val} onChange={(e)=>_onChange(e)} />
    </div>
  );

});
