import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Format, InsightTypeBlock, StatLine, BuildingImage, RandomKey, TableSort } from '../../../components/Common'
import './BuildingRow.scss';

class BuildingRow extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    let cols = [ 20, 15, 7.5, 7.5, 20, 10, 20 ];

    let col = (idx) => {
      return { width: `${cols[idx]}%`, display: "inline-block", verticalAlign: "top" };
    };

    if (this.props.header) {
      return (
        <div key={this.key} className='bld-card table-header'>
          <div style={col(0)}>
            <div className='bld-header-split-top'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='name'>
                Building Name
              </TableSort>
            </div>
            <div className='bld-header-split-bottom'>Address</div>
            <div className='bld-header-split-bottom-2'>Building ID</div>
          </div>
          <div style={col(1)}>
            <div className='bld-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='city'>
                City
              </TableSort>
            </div>
          </div>
          <div style={col(2)}>
            <div className='bld-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='floorArea'>
                Floor Area
              </TableSort>
            </div>
          </div>
          <div style={col(3)}>
            <div className='bld-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='yearBuilt'>
                Year Built
              </TableSort>
            </div>
          </div>
          <div style={col(4)}>
            <div className='bld-header-split-top'>Organization Name</div>
            <div className='bld-header-split-bottom'>Org. Type</div>
          </div>
          <div style={col(5)}>
            <div className='bld-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='createdAt'>
                Created On
              </TableSort>
            </div>
          </div>
          <div style={col(6)}>
            <div className='bld-header-cell'>Actions</div>
          </div>
        </div>
      )
    }

    return (
      <div key={this.key} className='bld-card'>
        <div style={col(0)}>
          <div className='bld-cell-split-top'>{data.name || data.address || ''}</div>
          <div className='bld-cell-split-bottom'>{data.address || ''}</div>
          <div className='bld-cell-split-bottom-2'>{data.id}</div>
        </div>
        <div style={col(1)}>
          <div className='bld-cell'>{data.city || ''}</div>
        </div>
        <div style={col(2)}>
          <div className='bld-cell'>{data.floorArea ? Format.SQFT(data.floorArea) : ''}</div>
        </div>
        <div style={col(3)}>
          <div className='bld-cell'>{data.yearBuilt ? data.yearBuilt : ''}</div>
        </div>
        <div style={col(4)}>
          <div className='bld-cell-split-top'>{data.org_name || ''}</div>
          <div className='bld-cell-split-bottom'>{data.org_type || ''}</div>
        </div>
        <div style={col(5)}>
          <div className='bld-cell'>{moment(data.createdAt).format('MMMM Do, YYYY')}</div>
        </div>
        <div style={col(6)}>
          {/*<div className='en-button-primary' onClick={() => {this.props.onMessage('edit-building', data);}}>Edit</div>
          <div className='en-button-secondary-red' onClick={() => {}}>Delete</div>*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(BuildingRow);
