

const usersFields = {
    id: { type: 'id', label: 'ID' },
    fullName: {
        type: 'string', label: 'Full Name',

    },
    firstName: {
        type: 'string', label: 'First Name',

    },
    lastName: {
        type: 'string', label: 'Last Name',

    },
    phoneNumber: {
        type: 'string', label: 'Phone Number',

    },
    oldPassword: {
        type: 'string', label: 'Old Password',

    },
    disabled: {
        type: 'boolean', label: 'Disabled',

    },
    role: {
        type: 'enum', label: 'Role',

        options: [

            { value: 'admin', label: 'Admin' },
            { value: 'utilityadmin', label: 'Utility Admin' },
            { value: 'remoteauditor', label: 'Remote Auditor' },
            { value: 'serviceorgmanager', label: 'Service Organization Manager' },
        ],

    },
    newPassword: {
        type: 'string', label: 'New Password',

    },
    confirmPassword: {
        type: 'string', label: 'Confirm Password',

    },
    emailVerified: {
        type: 'boolean', label: 'emailVerified', ignore: true

    },
    emailVerificationToken: {
        type: 'string', label: 'emailVerificationToken', ignore: true

    },
    emailVerificationTokenExpiresAt: {
        type: 'datetime', label: 'emailVerificationTokenExpiresAt', ignore: true

    },
    passwordResetToken: {
        type: 'string', label: 'passwordResetToken', ignore: true

    },
    passwordResetTokenExpiresAt: {
        type: 'datetime', label: 'passwordResetTokenExpiresAt', ignore: true

    },

}

export default usersFields;
