import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';
import Checkbox from './Checkbox';
import { Input, Dropdown } from '../../../components/Common';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });

  model.hidden = model.hidden || {};
  model.types = model.types || {};
  model.headerNames = model.headerNames || {};

  const colInfo = (data && data.value && data.value._colInfo)||{};
  const cols = Object.keys(colInfo||{}).filter((e) => !!e);

  const setHidden = (col, val) => {
    model.hidden[col] = !!val;
    saveModel({ ...model });
  };

  const setType = (col, val) => {
    model.types[col] = val;
    saveModel({ ...model });
  };

  const setName = (col, val) => {
    model.headerNames[col] = val;
    saveModel({ ...model });
  };

  const typeOpts = [
    { title: 'Auto', key: 'auto' },
    { title: 'String', key: 'string' },
    { title: 'Integer', key: 'int' },
    { title: 'Decimal 3', key: 'dec3' },
    { title: 'Decimal 6', key: 'dec6' }
  ];

  return (
    <NodeWrapper
      title='Table Remapper/Display'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      data={data}
      width="415px"
    >
      <div className='fcs-io-title'>COLUMN SETTINGS</div>
      <div style={{width: "100%"}} onMouseDown={(e) => { e = e || window.event; /*e.preventDefault();*/ e.stopPropagation(); return false; }}>
        <div className='flow-node-scrolling-cont' style={{height: (112*3+12*3)+'px'}}>
          {cols.map((C, idx) => (<div className='flow-trd-item' key={'ftrdi-' + idx}>
            <div className='flow-trdi-title' title={C}>{C}</div>
            <Checkbox title='Hide' value={!!model.hidden[C]} onChange={(val) => setHidden(C, val)} />
            <div className='fcs-third'>
              <div className='fcs-title'>Type</div>
              <Dropdown defaultValue={typeOpts.filter((e) => (e.key === (model.types[C] || 'auto')))[0]} options={typeOpts} onChange={(val) => setType(C, val.key)} hasArrow={true} />
            </div>
            <div className='fcs-two-thirds'>
              <div className='fcs-title'>New Name</div>
              <Input type="text" defaultValue={model.headerNames[C]} onChange={(val) => setName(C, val)} />
            </div>
          </div>))}
        </div>
      </div>
    </NodeWrapper>
  );
});