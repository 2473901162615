import React, { memo, useState } from 'react';

import { Handle } from 'react-flow-renderer';

export default memo(({ isOutput, isInput, isDualInput, isMin }) => {

  let style = {
    background: '#DDDDDD',
    border: '2px solid #55B9FC',
    width: '10px',
    height: '10px',
    'z-index': 2,
  };

  if (isOutput) {
    return (<Handle
        type="source"
        position="right"
        id="output"
        style={{ ...style, marginRight: '-2px' }}
    />);
  }
  else if (isInput) {
    return (<Handle
        type="target"
        position="left"
        id="input"
        style={{ ...style, marginRight: '-2px', 'margin-left': '-1px' }}
    />);
  }
  else if (isDualInput) {
    return (<div>
      <Handle
        type="target"
        position="left"
        id="input-left"
        style={{ ...style, 'margin-top': isMin ? '-15px' : '-45px', 'margin-left': '-1px' }}
      />
      <Handle
        type="target"
        position="left"
        id="input-right"
        style={{ ...style, 'margin-top': isMin ? '15px' : '45px', 'margin-left': '-1px' }}
      />
    </div>);
  }

});
