import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import RadioSelect from './RadioSelect';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });

  return (
    <NodeWrapper
      title='Add Buildings to Insights Map'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={0}
      model={model}
      data={data}
      width={"500px"}
    >
      <div className='fcs-io-title'>INPUTS</div>
      <ColumnSelect title='Building Address' value={model.address} onChange={(val) => saveModel({...model, address: val})} />
      <div className='fcs-half'><ColumnSelect title='Storeys' value={model.storeys} onChange={(val) => saveModel({...model, storeys: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Total Heated Floor Area (SQFT)' value={model.total_heated_floor_area_sqft} onChange={(val) => saveModel({...model, total_heated_floor_area_sqft: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Year Built' value={model.year_built} onChange={(val) => saveModel({...model, year_built: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Has Heated Basement? (Boolean)' value={model.heated_basement} onChange={(val) => saveModel({...model, heated_basement: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='House Type' value={model.house_type} onChange={(val) => saveModel({...model, house_type: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Primary Heating Type' value={model.primary_heating_type} onChange={(val) => saveModel({...model, primary_heating_type: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Primary Heating Fuel Type' value={model.primary_heating_fuel_type} onChange={(val) => saveModel({...model, primary_heating_fuel_type: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Hot Water Heater Fuel Type' value={model.hotwater_heater_fuel_type} onChange={(val) => saveModel({...model, hotwater_heater_fuel_type: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Footprint Floor Area (SQFT)' value={model.footprint_area_sqft} onChange={(val) => saveModel({...model, footprint_area_sqft: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Basement Floor Area (SQFT)' value={model.basement_floor_area_sqft} onChange={(val) => saveModel({...model, basement_floor_area_sqft: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='ID' value={model.id} onChange={(val) => saveModel({...model, id: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Data Source' value={model.data_source} onChange={(val) => saveModel({...model, data_source: val})} /></div>
    </NodeWrapper>
  );
});
