import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';

import '../Common.scss';

class MinMaxInput extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      bubbleOpened: false,
      options: props.options,
      value: props.defaultValue || null,
    };

    this.onClick = ((e) => {
      e = e || window.event;
      let div = e.target;
      while (div) {
        if (div.className && div.className.indexOf('en-dd-bubble') >= 0) {
          return;
        }
        div = div.parentNode;
      }
    }).bind(this);
  }

  closeBubble(e) {
    if (!this.state.bubbleOpened) {
      return;
    }

    if (!e) {
      return false;
    }

    let div = e.target;
    while (div) {
      if (div.className && div.className.indexOf('en-dd-bubble') >= 0) {
        return;
      }
      div = div.parentNode;
    }

    this.setState({ bubbleOpened: false });

    return false;
  }

  openBubble(e) {

    if (!e) {
      return false;
    }

    let div = e.target;
    while (div) {
      if (div.className && div.className.indexOf('en-dd-bubble') >= 0) {
        return;
      }
      div = div.parentNode;
    }

    this.setState({ bubbleOpened: !this.state.bubbleOpened });

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  componentDidMount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
    }
    this.onClick = (e) => {
      if (e.target)
      return this.closeBubble(e || window.event);
    };
    document.body.addEventListener('click', this.onClick);
  }

  componentWillUnmount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
      this.onClick = null;
    }
  }

  fmtNumber(n) {
    if (this.props.unit === '$') {
      return `$ ${(n === undefined || n === null) ? '' : Math.floor(n)}`;
    }
    else if (this.props.unit === 'years') {
      return `${(n === undefined || n === null) ? '' : (Math.floor(n*10)/10)}`;
    }
    else if (this.props.unit === 'estar') {
      return `${(n === undefined || n === null) ? '' : Math.floor(n)}`;
    }
    else if (this.props.unit === 'percent') {
      return `${(n === undefined || n === null) ? '' : (Math.floor(n*10)/10)}`;
    }
    else if (this.props.unit === 'float') {
      return `${(n === undefined || n === null) ? '' : Math.floor(n*1000)/1000}`;
    }
  }

  onChange(e, vname) {
    let val = this.state.value || {};
    let v2 = '';
    for (let i=0; i<e.target.value.length; i++) {
      let ch = e.target.value.charCodeAt(i);
      if ((ch >= "0".charCodeAt(0) && ch <= "9".charCodeAt(0)) || ((ch == ".".charCodeAt(0)) && this.props.decimal)) {
        v2 = `${v2}${String.fromCharCode(ch)}`;
      }
    }
    let v3 = undefined;
    if (v2.length) {
      v3 = this.props.decimal ? parseFloat(v2) : parseInt(v2);
    }
    val[vname+'_i'] = v3;
    val[vname] = this.fmtNumber(v3);
    if (v2 && (v2.charAt(v2.length-1) === '.')) {
      val[vname] += '.';
    }
    this.setState({ value: val });
    if (this.props.onChange) {
      this.props.onChange({min: val.min_i, max: val.max_i});
    }
  }

  render() {
    return (
      <div className={'en-min-max en-dropdown-button ' + (this.state.bubbleOpened ? 'active' : '') + (this.props.error ? ' form-error-border' : '')} onClick={(e) => (this.openBubble(e || window.event))} key={this.key} style={{...(this.props.width ? {width: this.props.width} : {}), ...(this.state.bubbleOpened ? {zIndex: 2} : {})}}>
        {this.state.value && this.state.value.min && this.state.value.max ? `${this.props.vlabel}: ${this.state.value.min} to ${this.state.value.max}` : this.props.label}
        {!(this.state.value && this.state.value.min && this.state.value.max) && this.props.required && <span className='en-input-required'>*</span>}
        {this.state.bubbleOpened && <div className={'en-dd-bubble' + (this.props.rightBubble ? ' right-bubble' : '')}>
          From
          <input type="text" value={(this.state.value && this.state.value.min)||''} className="mm-from-input" onChange={(e) => {this.onChange(e || window.event, 'min')}}/>
          To
          <input type="text" value={(this.state.value && this.state.value.max)||''} className="mm-to-input" onChange={(e) => {this.onChange(e || window.event, 'max')}}/>
        </div>}
        {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(MinMaxInput));
