import axios from "axios";

export default {
    'json': (filename, data) => {
      const str = JSON.stringify(data.data);
      const bytes = new TextEncoder().encode(str);
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename || 'untitled-export'}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    'map': (filename, data) => {
      let expGJ = null;
      if (data && data.data && data.data.geoJSON) {
        expGJ = data.data.geoJSON;
      }
      else {
        return;
      }
      const str = JSON.stringify(expGJ);
      const bytes = new TextEncoder().encode(str);
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename || 'untitled-export'}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    'insights-map': (filename, data, job_id, centroids) => {
      let el = document.createElement('div');
      el.style.position = 'fixed';
      el.style.top = '0px';
      el.style.left = '0px';
      el.style.width = el.style.height = '100%';
      el.style.zIndex = 100;
      el.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      document.body.appendChild(el);
      setTimeout(() => {
        axios.get(`/flows/insights-map-geojson-all/${job_id}/${centroids ? '?centroids=1' : ''}`).then((res) => {
          const str = JSON.stringify(res.data, null, 2);
          const bytes = new TextEncoder().encode(str);
          const blob = new Blob([bytes], {
            type: "application/json;charset=utf-8"
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${filename || 'untitled-export'}.json`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(el);
        }).catch((err) => {
          document.body.removeChild(el);
        });
      }, 10);
    },
    'table': (filename, data) => {
      let table = data.data;
      let header = table.header;
      let rows = table.rows;
  
      let outStr = [];
  
      let hStr = [];
      for (let H of header) {
        hStr.push(`"${H}"`);
      }
      hStr = hStr.join(',');
  
      outStr.push(hStr);
  
      for (let R of rows) {
        let rStr = [];
        for (let H of header) {
          let V = R[H];
          if (typeof V === 'string') {
            V = `"${V}"`;
          }
          else if (typeof parseFloat(V) === 'number' && !isNaN(parseFloat(V))) {
            V = `${Math.floor(parseFloat(V)*(1e6)) / 1e6}`;
          }
          else {
            V = ``;
          }
          rStr.push(V);
        }
        rStr = rStr.join(',');
        outStr.push(rStr);
      }
  
      const bytes = new TextEncoder().encode(outStr.join('\n'));
      const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename || 'untitled-export'}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
};