import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Function Return'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={0}
      model={model}
      width={"320px"}
      data={data}
    >
    </NodeWrapper>
  );
});