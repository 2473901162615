import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import RadioSelect from './RadioSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });

  return (
    <NodeWrapper
      title='Map Display'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={0}
      model={model}
      data={data}
    >
      <RadioSelect title='Extrude (Polygons Only)' value={model.extrude ? 'yes' : 'no'} onChange={(val) => saveModel({...model, extrude: val === 'yes'})} options={[{title: 'Extrude', key: 'yes'},{title: 'Flat', key: 'no'}]} />
    </NodeWrapper>
  );
});
