/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { listsData } from '../../selectLists';

import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import Select from 'react-select';

import './HSUpgrade.scss';

class HSUpgrade extends React.Component {

    static propTypes = {
        is_upgrade: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        is_upgrade: false,
        onChange: (model) => { }
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            model: {

            },
            visible: false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if ((this.props.building?.id !== prevProps.building?.id) || JSON.stringify(this.props.building.hs_upgrade_json || null) !== this.lasths_upgrade_json) {
            let model = {};
            try {
                model = JSON.parse(this.props.building.hs_upgrade_json ?? '{}');
                this.setState({ model, rand: Math.random(), loaded: true });
                this.lasths_upgrade_json = JSON.stringify(this.props.building.hs_upgrade_json || null);
            }
            catch (err) {
            }
        }
    }

    handleChange(e, parent) {
        parent = parent || e.target;
        parent = { name: parent.name, value: parent.value ?? e.value };
        let model = this.state.model;
        model[parent.name] = parent.value;
        this.props.building.hs_upgrade_json = this.lasths_upgrade_json = JSON.stringify(model);
        this.setState({ model, rand: Math.random() });
        this.props.onChange(model);
    }

    fieldName(V) {
        return `${V}`;
    }

    render() {

        let title = 'Base Heating System Upgrade';

        return (
            <div className='hs-upgrade-cont'>
                <div className="ac-cont panel mb-xs" key="accordion-hp-details">
                    { /* eslint-disable */}
                    <div
                        className="ac-header panel-header" role="button"
                        onClick={() => {
                            this.setState({ visible: !this.state.visible });
                        }}>
                        { /* eslint-enable */}
                        <div className="mb-0">
                            {/* eslint-disable-next-line */}
                            <a className="accordion-toggle" role="button">
                                <div className={"ac-header-text"}><div className="rvc-error-dot"></div>{title}</div>
                                <i className={`fa fa-angle-down ${this.state.visible ? 'expanded' : ''}`} />
                            </a>
                        </div>
                    </div>
                    {this.state.loaded ? <Collapse className={'panel-body'} isOpen={this.state.visible}>
                        <br/><br/>
                        <FormGroup row>
                            <Col xs={6}>
                            <Label for="v2PrimaryHeatingSystemType">Base Heating System Type</Label>
                            </Col>
                            <Col xs={6}>
                            <Label for="v2PrimaryHeatingSystemFuelType">Base Heating System Fuel Type</Label>
                            </Col>
                            <Col xs={6}>
                            <Select
                                name="base_heating_system_type"
                                classNamePrefix="react-select"
                                className="selectCustomization"
                                options={listsData.list.v2PrimaryHeatingSystemType.filter((e) => (e.value !== 'heat pump') ? e : null)}
                                value={listsData.list.v2PrimaryHeatingSystemType.filter((e) => e.value === this.state.model.base_heating_system_type)}
                                onChange={this.handleChange}
                                disabled={false}
                            />
                            </Col>
                            <Col xs={6}>
                            <Select
                                classNamePrefix="react-select"
                                className="selectCustomization"
                                options={listsData.list.v2PrimaryHeatingSystemFuelType}
                                value={listsData.list.v2PrimaryHeatingSystemFuelType.filter((e) => e.value === this.state.model.base_heating_system_fuel_type)}
                                name='base_heating_system_fuel_type'
                                onChange={this.handleChange}
                                disabled={false}
                            />
                            </Col>
                            <br/><br/>
                            <Col xs={7}>
                                <Label for="base_heating_system_efficiency"><span>Base Heating System Efficiency (%)</span></Label>
                            </Col>
                            <Col xs={5}>
                                <Label for="base_heating_system_capacity"><span>Base Heating System Capacity (BTU/hr)</span></Label>
                            </Col>
                            <Col xs={7}>
                                <Input id="base_heating_system_efficiency" type="number" defaultValue={this.state.model.base_heating_system_efficiency} disabled={false} name='base_heating_system_efficiency' onChange={this.handleChange} />
                            </Col>
                            <Col xs={5}>
                                <Input id="base_heating_system_capacity" type="number" defaultValue={this.state.model.base_heating_system_capacity} disabled={false} name='base_heating_system_capacity' onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                    </Collapse> : undefined}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let building = state.buildings.curBuilding.building || {};
    return { building };
}
export default withRouter(connect(mapStateToProps)(HSUpgrade));
