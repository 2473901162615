import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import InsightTypeBlock from './InsightTypeBlock';

import '../Common.scss';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      filterOpened: false,
      filterSelected: props.defaultValue || null
    }
  }

  closeBubble(e) {
    if (!this.state.filterOpened) {
      return;
    }

    if (!e) {
      return false;
    }

    if (e.target.className.indexOf('en-dd-option') >= 0) {
      return;
    }

    this.setState({ filterOpened: false });

    return false;
  }

  selectBubbleOption(e, val) {
    if (this.props.onNonEmpty && val) {
      this.props.onNonEmpty();
    }
    if (this.props.onChange) {
      this.props.onChange(val);
    }
    this.setState({ filterSelected: val });
    return this.closeBubble(e);
  }

  openBubble(e) {

    if (!e) {
      return false;
    }

    this.setState({ filterOpened: !this.state.filterOpened });

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  componentDidMount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
    }
    this.onClick = (e) => {
      if (e.target)
      return this.closeBubble(e || window.event);
    };
    document.body.addEventListener('click', this.onClick);
  }

  componentWillUnmount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
      this.onClick = null;
    }
  }

  render() {
    if (this.props.options === 'insight-types') {
      return (
        <div className={'en-dropdown-button types-dropdown ' + (this.props.readOnly ? "read-only " : " ") + (this.state.filterOpened ? 'active' : '') + (this.props.error ? ' form-error-border' : '')} onClick={(e) => (this.openBubble(e || window.event))} key={this.key} style={{...(this.props.width?{width:this.props.width}:{}), ...(this.state.filterOpened?{zIndex: '3'}:{})}}>
          {this.state.filterSelected && <InsightTypeBlock type={this.state.filterSelected} />}
          {!this.state.filterSelected && this.props.emptyText}
          <img src="/images/dropdown-arrow.svg" className="dropdown-arrow" />
          {this.state.filterOpened && <div className='en-dd-bubble'>
            <div key={this.key + '-opt-1'} className={'en-dd-option first ' + ('expert-insights' === this.state.filterSelected ? 'selected' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, 'expert-insights'))}>
              <InsightTypeBlock type="expert-insights" />
            </div>
            <div key={this.key + '-opt-2'} className={'en-dd-option ' + ('estimated-offer' === this.state.filterSelected ? 'selected' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, 'estimated-offer'))}>
              <InsightTypeBlock type="estimated-offer" />
            </div>
            <div key={this.key + '-opt-3'} className={'en-dd-option last ' + ('quoted-offer' === this.state.filterSelected ? 'selected' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, 'quoted-offer'))}>
              <InsightTypeBlock type="quoted-offer" />
            </div>
          </div>}
          {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
        </div>
      );
    }
    else if (this.props.etlType) {
      return (
        <div className={'en-dropdown-button en-ddb-etl-type ' + (this.props.readOnly ? "read-only " : " ") + (this.state.filterOpened ? 'active' : '') + (this.props.error ? ' form-error-border' : '')} onClick={(e) => (this.openBubble(e || window.event))} key={this.key} style={{...(this.props.width?{width:this.props.width}:{}), ...(this.state.filterOpened?{zIndex: '3'}:{})}}>
          {this.props.title ? `${this.props.title}:` : ``} {(this.state.filterSelected && this.state.filterSelected.title) || this.state.filterSelected}
          <img src='/images/dropdown-arrow.svg' className='en-dropdown-arrow' />
          {this.state.filterOpened && <div className={'en-dd-bubble ' + (this.props.openUp ? 'en-dd-open-up' : '')}>
            {this.props.options.map((val, idx) => (
              <div key={this.key + '-opt-' + idx} className={'en-dd-option ' + (val === this.state.filterSelected ? 'selected' : '') + (idx === 0 ? ' first' : idx === (this.props.options.length-1) ? ' last' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, val))}>{typeof val === 'object' ? (val.title||'-') : val}</div>
            ))}
            {this.props.emptyText && !this.props.options?.length ? <div className='en-dd-option'>{this.props.emptyText}</div>:undefined}
          </div>}
          {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
        </div>
      );
    }
    else {
      return (
        <div className={'en-dropdown-button ' + (this.props.readOnly ? "read-only " : " ") + (this.state.filterOpened ? 'active' : '') + (this.props.error ? ' form-error-border' : '')} onClick={(e) => (this.openBubble(e || window.event))} key={this.key} style={{...(this.props.width?{width:this.props.width}:{}), ...(this.state.filterOpened?{zIndex: '3'}:{})}}>
          {this.props.title ? `${this.props.title}:` : ``} {(this.state.filterSelected && this.state.filterSelected.title) || this.state.filterSelected}
          {this.props.hasArrow && <img src='/images/dropdown-arrow.svg' className='en-dropdown-arrow-icon' />}
          {this.state.filterOpened && <div className={'en-dd-bubble ' + (this.props.openUp ? 'en-dd-open-up' : '')}>
            {this.props.options.map((val, idx) => (
              <div key={this.key + '-opt-' + idx} className={'en-dd-option ' + (val === this.state.filterSelected ? 'selected' : '') + (idx === 0 ? ' first' : idx === (this.props.options.length-1) ? ' last' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, val))}>{typeof val === 'object' ? (val.title||'-') : val}</div>
            ))}
            {this.props.emptyText && !this.props.options?.length ? <div className='en-dd-option'>{this.props.emptyText}</div>:undefined}
          </div>}
          {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
        </div>
      );
    }
  }
}

export default withRouter(connect(() => ({}))(Dropdown));
