import React, { useState, useRef, useEffect } from 'react';
import Map from './Controls/Map';
import InsightsMap from './Controls/InsightsMap';
import DataTable from './Controls/DataTable';
import { Dropdown, Input, TypeSearchInput } from "../../components/Common";
import Checkbox from "./NodeTypes/Checkbox";
import { JSONTree } from 'react-json-tree'
import axios from 'axios';
import './Flow.scss';
import { withRouter } from 'react-router';

window._delayID = -1;

const GE360InsightsMap = () => {

    const [source, setSource] = useState("all");
    const [insightsOnly, setInsightsOnly] = useState("all");
    const [nothingAfterDate, setNADate] = useState("");
    const [nothingBeforeDate, setNBDate] = useState("");
    const [waitNADate, setWNADate] = useState("");
    const [waitNBDate, setWNBDate] = useState("");
    const [allJobs, setAllJobs] = useState([]);
    const [job, setJob] = useState({ key: -1, title: ' -- All Jobs -- '});
    const [jobsLoaded, setJL] = useState(false);

    const loadJobs = async () => {
        let resp = await axios('/flows/get-jobs', {
            method: 'GET',
            data: {}
        });
        let jobs = [];
        jobs.push({
            id: -1,
            title: ' -- All Jobs -- '
        });
        for (let J of resp.data) {
            jobs.push({
                id: J.id,
                title: J.title
            });
        }
        setAllJobs(jobs);
    }

    if (!jobsLoaded) {
        setJL(true);
        loadJobs();
    }

    const delay = (f) => {
        if (window._delayID != -1) {
            clearTimeout(window._delayID);
            window._delayID = -1;
        }
        window._delayID = setTimeout(() => { window._delayID = -1; f(); }, 1000);
    };

    return (
        <div className='insights-map-page-cont'>
            <div className='title'>GE360 Insights Map</div>
            <div className='filters'>
                Filter by Job: <Dropdown options={allJobs} onChange={(val) => setJob(val)} defaultValue={job} hasArrow={true} /><div style={{display: "inline-block", width: "20px"}}/>
                Hide Insights Before Date: <Input value={waitNBDate} placeholder={"YYYY-MM-DD"} onChange={(val) => {setWNBDate(val); delay(() => setNBDate(waitNBDate))}} />
                Hide Insights After Date: <Input value={waitNADate} onChange={(val) => {setWNADate(val); delay(() => setNADate(waitNADate))}} placeholder={"YYYY-MM-DD"} />
                Insights/No Insights Filter: <Dropdown options={["all", "insights", "buildings"]} onChange={(val) => {setInsightsOnly(val);}} defaultValue={insightsOnly} hasArrow={true} /><div style={{display: "inline-block", width: "20px"}}/>
                Filter by Polygon Source: <Dropdown options={["all", "obd", "osm", "ms"]} onChange={(val) => {setSource(val);}} defaultValue={source} hasArrow={true} />
            </div>
            <InsightsMap global={true} source={source} insightsOnly={insightsOnly} nothingAfterDate={nothingAfterDate} nothingBeforeDate={nothingBeforeDate} filterJobID={job.id != -1 ? job.id : null}/>
        </div>
    );
}

export default withRouter(GE360InsightsMap);
