import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Col, Row, Progress } from 'reactstrap';
import InsightCard from './components/InsightCard';
import { getENInsights, getENInsightsByBuildingId } from '../../actions/energy-navigator';
import { RandomKey, Dropdown } from '../../components/Common';
import mock from './mock';

import './ENInsightsOffers.scss';

class InsightsOffers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {
      insightFilterOptions: [ 'All', 'New', 'Ongoing', 'Completed' ],
      offerFilterOptions: [ 'All', 'New', 'Ongoing', 'Completed', 'Rejected' ],
      insightFilterSelected: 'All',
      offerFilterSelected: 'All',
      insightFilterOpened: false,
      offerFilterOpened: false,
    };

    this.filterKeys = {
      'All': 'all',
      'New': 'new',
      'Ongoing': 'in-progress',
      'Completed': 'completed',
      'Rejected': 'rejected'
    };

    this.key = RandomKey();
  }

  componentDidMount() {
    const buildingId = this.props.buildingId;
    if (buildingId) {
      this.props.dispatch(getENInsightsByBuildingId(buildingId));
    }
    else {
      this.props.dispatch(getENInsights());
    }
  }

  render() {
    let { insights } = this.props;

    const user = this.props.currentUser;
    const rolesContain = (...args) => {
      for (let i=0; i<args.length; i++) {
        if (user && user.role && user.role.indexOf(args[i]) >= 0) {
          return true;
        }
      }
      return false;
    };

    let list = [];
    if (insights) {
      for (let i = 0; insights && i < insights.length; i++) {
        const I = insights[i];
        if (I.type === 'insight') {
          const fkey = this.filterKeys[this.state.insightFilterSelected] || 'all';
          if (fkey !== 'all' && fkey !== I.status) {
            continue;
          }
        }
        else if (I.type === 'offer') {
          const fkey = this.filterKeys[this.state.offerFilterSelected] || 'all';
          if (fkey !== 'all' && fkey !== I.status) {
            continue;
          }
        }
        if (this.props.buildingId && I.property_id !== this.props.buildingId) {
          continue;
        }

        const attachments = {};
        if (I.attachments) {
          for (let i=0; i<I.attachments.length; i++) {
            let A = I.attachments[i];
            let O = {
              id: A.id,
              type: A.type,
              file_name: A.fileName,
              file_size: A.fileSize,
              link: A.link,
              status: 'success',
              progress: 100.0
            };
            (attachments[O.type] = attachments[O.type] || []).push(O);
          }
        }

        list.push({
          type: I.type,
          subtype: I.subtype,
          title: I.title,
          id: I.id,
          subtitle: '',
          building_id: I.property_id,
          building_name: I.property_name,
          building_address: '',
          building_owner_email: I.building_owner_email,
          advisor: I.author_name && I.author_company_name ? {
            name: I.author_name,
            company: I.author_company_name,
            email: I.author_email
          } : null,
          attachments: attachments,
          active: I.active,
          est_annual_savings: I.est_annual_savings,
          est_capital_cost: I.est_capital_costs,
          est_payback_period_years: I.est_payback_period,
          energy_use_intensity: I.energy_use_intensity,
          ghg_emission_intensity: I.ghg_intensity,
          roi: I.est_roi,
          overview: I.overview || null,
          financial_details: I.financial_details || null,
          vendor: I.vendor || null,
          other: I.other || null,
          technical_details: I.technical_details || null,
          projected_eui_reduction: I.projected_energy_use_reduction,
          eui_savings: I.eui_reduction_min && I.eui_reduction_max ? {
            min: I.eui_reduction_min,
            max: I.eui_reduction_max
          } : null,
          energy_star: I.energy_star_score,
          ghg_savings: I.ghg_intensity_reduction_min && I.ghg_intensity_reduction_max ? {
            min: I.ghg_intensity_reduction_min,
            max: I.ghg_intensity_reduction_max
          } : null,
          projected_energy_star: I.projected_energy_star_score_min && I.projected_energy_star_score_max ? {
            min: I.projected_energy_star_score_min,
            max: I.projected_energy_star_score_max
          } : null
        })
      }
    }

    let data = {
      combined_list: list
    };

    return (
      <div className='insights-page'>
        {!this.props.buildingId ?
          (<h1 className="page-title-white">{rolesContain("serviceprovider") ? "All Insights & Offers" : "All Insights & Offers"}</h1>) :
          (<h1 className="page-title-white">Insights and Offers</h1>)}
        {false && !this.props.buildingId && <div className='insight-filter-cont'>
          <Dropdown
            title='Insights'
            options={this.state.insightFilterOptions}
            defaultValue={this.state.insightFilterSelected}
            onChange={(val) => {this.setState({ insightFilterSelected: val })}}
          />
          <Dropdown
            title='Offers'
            options={this.state.offerFilterOptions}
            defaultValue={this.state.offerFilterSelected}
            onChange={(val) => {this.setState({ offerFilterSelected: val })}}
          />
        </div>}
        {<div className="insight-container">
          {data.combined_list.map((obj, index) => (
            <InsightCard type={obj.type} key={this.key + '-' + index} data={obj} />
          ))}
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    insights: state.energyNavigator.allInsights,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(InsightsOffers);
