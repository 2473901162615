import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import InsightTypeBlock from './InsightTypeBlock';

import '../Common.scss';
import { filter } from 'observable-fns';

class TypeSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      filterOpened: false,
      filter: props.defaultValue || '',
    }
  }

  selectBubbleOption(val) {
    if (this.props.onNonEmpty && val) {
      this.props.onNonEmpty();
    }
    if (this.props.onChange) {
      this.props.onChange(val);
    }
    this.setState({ filter: val });
  }

  openBubble(e) {

    if (!e) {
      return false;
    }

    this.setState({ filterOpened: !this.state.filterOpened });

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    let filteredOptions = [...this.props.options];
    const normStr = (val) => `${val}`.toLowerCase().replace(/\s*/g, '');
    if (this.state.filter.trim().length > 0) {
        const fVal = normStr(typeof this.state.filter === 'object' ? this.state.filter.title : this.state.filter);
        filteredOptions = filteredOptions.filter((e) => (typeof e === 'object' ? normStr(e.title).includes(fVal) : normStr(e).includes(fVal)));
    }
    return (
        <div
            className={'en-dropdown-button en-typesearch-wrapper ' + (this.props.readOnly ? "read-only " : " ") + (this.state.filterOpened ? 'active' : '') + (this.props.error ? ' form-error-border' : '')}
            key={this.key}
            style={{
                ...(this.props.width ? { width:this.props.width }:{}),
                ...(this.state.filterOpened ? { zIndex: '3' } : {})
            }} >
          <input
            type="text"
            className='en-dropdown-filter-input'
            placeholder={this.props.title ? `${this.props.title}` : ``}
            value={(this.state.filter && this.state.filter.title) || this.state.filter}
            onChange={(e) => {
                this.selectBubbleOption((e || window.event).target.value || '')
            }}
            onFocus={() =>
                setTimeout(() => this.setState({filterOpened: true, pauseNextClose: true}), 50)
            }
            onBlur={() =>
                this.setState({filterOpened: false})
            }/>
          <img src='/images/dropdown-arrow.svg' className='en-dropdown-arrow-icon' />
          {filteredOptions.length > 0 && this.state.filterOpened && <div className={'en-dd-bubble ' + (this.props.openUp ? 'en-dd-open-up' : '')}>
            {filteredOptions.map((val, idx) => (
              <div
                key={this.key + '-opt-' + idx}
                className={'en-dd-option ' + (val === this.state.filter ? 'selected' : '') + (idx === 0 ? ' first' : idx === (this.props.options.length-1) ? ' last' : '')}
                onMouseDown={() => (this.selectBubbleOption(val))}
              >{typeof val === 'object' ? (val.title||'-') : val}</div>
            ))}
          </div>}
          {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
        </div>
      );
    }
}

export default withRouter(connect(() => ({}))(TypeSearchInput));
