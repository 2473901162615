import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-nord_dark";

import { Dropdown } from '../../../components/Common/Common';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ maximized, setMaximized ] = useState(false);
  const [ editor, setEditor ] = useState("input_proc");

  const options = [
    { title: "URL/Method/Body Generator JS", key: "input_proc" },
    { title: "Mock Output JSON", key: "mock_output" },
    { title: "Output Processor JS", key: "output_proc" }
  ]

  const editorDraftVar = {
    'input_proc': 'draftInput',
    'mock_output': 'draftMockJSON',
    'output_proc': 'draftOutput'
  };

  const editorTitle = {
    'input_proc': 'Request Generator JS Editor',
    'mock_output': 'Mock Output JSON Editor',
    'output_proc': 'Output Parsing JS Editor'
  };

  return (
    <NodeWrapper
      title='API Process'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"425px"}
      maximize={{
        width: "810px",
        callback: (flag) => setMaximized(flag)
      }}
      data={data}
    >
      <div className='fcs-title'>Choose Editor</div>
      <Dropdown
        options={options}
        defaultValue={options.filter((e) => e.key === editor)[0]}
        onChange={(val) => setEditor(val.key)}
        hasArrow={true}
      />
      <div className='fcs-title'>{editorTitle[editor]}</div>
      <div className='flow-node-js-editor-wrapper' onMouseDown={(e) => { e = e || window.event; e.preventDefault(); e.stopPropagation(); return false; }}>
        <AceEditor
          mode="javascript"
          theme="nord_dark"
          width={maximized ? "760px" : "375px"}
          height={maximized ? "369px" : "240px"}
          value={model[editorDraftVar[editor]]}
          onChange={(val) => {model[editorDraftVar[editor]] = val; saveModel({...model, liveInput: model.draftInput, liveMockJSON: model.draftMockJSON, liveOutput: model.draftOutput})}}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    </NodeWrapper>
  );
});