import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';

import '../Common.scss';

class InsightTypeBlock extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
  }
  render() {
    const typeStr = this.props.type;

    if (!typeStr) {
      return (<div key={this.key}/>);
    }

    const types = {
      'automated-insights': { title: '(remove)', color: '#CF995C', icon: '/images/automated-insights-icon.svg' },
      'expert-insights': { title: 'Insight', color: '#F1C40F', icon: '/images/expert-insights-icon.svg' },
      'estimated-offer': { title: 'Estimate', color: '#B8E994', icon: '/images/estimated-offer-icon.svg' },
      'quoted-offer': { title: 'Quoted', color: '#1ABC9C', icon: '/images/quoted-offer-icon.svg' }
    };

    const type = types[typeStr];

    return (
      <div key={this.key} className='insight-type-block' style={{color: type.color, borderColor: type.color, background: type.color + '12'}}><img src={type.icon}/>{type.title}</div>
    );
  }
}

export default withRouter(connect(() => ({}))(InsightTypeBlock));
