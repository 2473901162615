/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import 'echarts/lib/chart/pie';
import { updateAuditorNotesRequest, updateBuildingRequest, getBuildingRequest, getBuildingRequestLM, downloadReportRequest, hvacTestRequest } from '../../../../actions/buildings';
import config from './config';
import './PreUpgradePanel.scss';
import SimpPie from './SimpPie';
import SimpHLChart from '../heatloss-chart/SimpHLChart';

const colors = config.chartColors;
// eslint-disable-next-line
let columnColors = [colors.blue, colors.green, colors.orange, colors.red, colors.default, colors.gray, colors.teal, colors.pink];
// eslint-disable-next-line
let lineColors = [colors.blue, colors.green, colors.orange];

window._AutoFindFields = (obj, start, pkey) => {
  let ret = [];
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      let r2 = _AutoFindFields(obj[key], `${start}.${key}`, `${pkey} - ${key}`);
      ret = [...ret, ...r2];
    }
    else {
      ret.push({
        key: `${start}.${key}`,
        title: `${pkey} - ${key}`,
        type2debug: true,
        rw: "R"
      });
    }
  }
  return ret;
};

class PreUpgradePanel extends React.Component {

  static propTypes = {
    model: PropTypes.object,
    isType2: PropTypes.bool
  };

  static defaultProps = {
    model: {},
    isType2: false
  };

  componentDidMount() {

    const buildingId = this.props.match.params.buildingId;

    this.props.dispatch(getBuildingRequest(buildingId));
    this.props.dispatch(getBuildingRequestLM(buildingId));
  }

  constructor(props) {
    super(props);

    // overview: Known
    // accuracy: Model Accuracy
    // analysis: Model Assumptions
    // recommendation: Estimated Type N Upgrades

    // bonus: scatter chart: plot actual (points) and regression line (from cofficients) - y: axis (kWh) - x: heating degree days (HDD)

    this.state = {};

    this.toggleAccordionFirst = this.toggleAccordionFirst.bind(this);

    this.lastModelStr = JSON.stringify({});

    this.handleDownloadReport = this.handleDownloadReportType1.bind(this);

    this.inotesRef = React.createRef();
  }

  handleDownloadReportType1() {
    this.props.dispatch(downloadReportRequest({buildingId: this.props.building.buildingId, downloadType1: true }));
  }

  componentDidUpdate(prevProps) {

    let newModelStr = JSON.stringify(this.props.isType2 ? this.props.model2 : this.props.model);

    if (newModelStr === this.lastModelStr) {
      return;
    }

    this.lastModelStr = newModelStr;
    let allFields = [
      { key: "P.id", title: "Building ID", rw: "R", overview: true },
      { key: "P.metrics.r_squared", title: "R²", rw: "R", accuracy: true },
      { key: "P.metrics.r_squared_cross_val", title: "R² Cross-val.", rw: "R", accuracy: true },
      { key: "P.model.coef_hdd", title: "HDD Coefficient", rw: "R", analysis: true },
      { key: "P.model.coef_cdd", title: "CDD Coefficient", rw: "R", analysis: true },
      { key: "P.model.coef_days", title: "Days Coefficient", rw: "R", analysis: true },
      { key: "P.model.base_temp_heating", title: "Heating Base Temperature", rw: "R", analysis: true },
      { key: "P.model.base_temp_cooling", title: "Cooling Base Temperature", rw: "R", analysis: true },
      { key: "P.yearly_energy_breakdown_gj.heating / 0.0036", title: "Yearly Heating Usage (kWh)", rw: "R", analysis: true },
      { key: "P.yearly_energy_breakdown_gj.cooling || 0", title: "Yearly Cooling Usage (kWh)", rw: "R", analysis: true },
      { key: "P.yearly_energy_breakdown_gj.hotwater / 0.0036", title: "Yearly Hot Water Usage (kWh)", rw: "R", analysis: true },
      { key: "P.yearly_energy_breakdown_gj.lights_appliances / 0.0036", title: "Yearly Lights & Appliances Usage (kWh)", rw: "R", analysis: true },
      { key: "P.model.annual_energy_usage_gj", title: "Yearly Total Energy Usage (GJ)", rw: "R", analysis: true },
      { key: "P.model.annual_energy_use_intensity_gj_per_sqm", title: "Yearly Total Energy Usage Intensity per SQM.", rw: "R", analysis: true },
      //{ key: "P.building.avg_yearly_hdds", title: "Yearly Average HDDs", rw: "R", analysis: true, int: true },
      //{ key: "P.building.avg_yearly_cdds", title: "Yearly Average CDDs", rw: "R", analysis: true, int: true },
      /*{ key: "P.recommendations.upgrade_walls.recommend", title: "Wall Insulation Recommended", rw: "R", type: "bool", recommendation: true, add_if: "P.recommendations.upgrade_walls" },
      { key: "P.recommendations.upgrade_walls.est_savings_gj_year", title: "Wall Insulation Upgrade Savings (GJ/year)", rw: "R", recommendation: true, add_if: "P.recommendations.upgrade_walls" },
      { key: "P.recommendations.upgrade_foundation.recommend", title: "Foundation Insulation Recommended", rw: "R", type: "bool", recommendation: true, add_if: "P.recommendations.upgrade_foundation" },
      { key: "P.recommendations.upgrade_foundation.est_savings_gj_year", title: "Foundation Insulation Upgrade Savings (GJ/year)", rw: "R", recommendation: true, add_if: "P.recommendations.upgrade_foundation" },
      { key: "P.recommendations.upgrade_ceiling.recommend", title: "Ceiling Insulation Recommended", rw: "R", type: "bool", recommendation: true, add_if: "P.recommendations.upgrade_ceiling" },
      { key: "P.recommendations.upgrade_ceiling.est_savings_gj_year", title: "Ceiling Insulation Upgrade Savings (GJ/year)", rw: "R", recommendation: true, add_if: "P.recommendations.upgrade_ceiling" },
      { key: "P.recommendations.upgrade_windows_doors", title: "Window/Door Insulation Recommended", rw: "R", type: "bool", recommendation: true, add_if: "P.recommendations.upgrade_windows_doors" },
      { key: "P.recommendations.upgrade_windows_doors.est_savings_gj_year", title: "Window/Door Insulation Upgrade Savings (GJ/year)", rw: "R", recommendation: true, add_if: "P.recommendations.upgrade_windows_doors" },
      { key: "P.recommendations.upgrade_heating", title: "Heatpump Upgrade Recommended", rw: "R", type: "bool", recommendation: true, add_if: "P.recommendations.upgrade_heating" },
      { key: "P.recommendations.upgrade_heating.est_savings_gj_year", title: "Heatpump Upgrade Upgrade Savings (GJ/year)", rw: "R", recommendation: true, add_if: "P.recommendations.upgrade_heating" },*/
      { key: "P.yearly_heatloss_breakdown_gj.walls", title: "Wall Heatloss (GJ/year)", rw: "RW", analysis: true },
      { key: "P.yearly_heatloss_breakdown_gj.foundation", title: "Foundation Heatloss (GJ/year)", rw: "RW", analysis: true },
      { key: "P.yearly_heatloss_breakdown_gj.windows_doors", title: "Window Heatloss (GJ/year)", rw: "RW", analysis: true },
      { key: "P.yearly_heatloss_breakdown_gj.ceiling", title: "Ceiling Heatloss (GJ/year)", rw: "RW", analysis: true },
      /*{ key: "P.building.seasonal_heating_btu", title: "Seasonal Heating (BTU)", rw: "RW", analysis: true },
      { key: "P.building.avg_heating_r_value", title: "Average Heating R-Value", rw: "R", analysis: true },
      { key: "P.building.avg_cooling_r_value", title: "Average Cooling R-Value", rw: "R", analysis: true },
      { key: "P.building.avg_yearly_base_usage", title: "Average Yearly Base Usage (kWh)", rw: "R", analysis: true },
      { key: "P.building.avg_yearly_heating_usage", title: "Average Yearly Heating Usage (kWh)", rw: "R", analysis: true },
      { key: "P.building.avg_yearly_cooling_usage", title: "Average Yearly Cooling Usage (kWh)", rw: "R", analysis: true },*/
      // { key: "P.building.ml_breakdown.total_kwh", title: "ML Yearly Total (kWh)", rw: "R", ml_model: true },
      // { key: "P.building.ml_breakdown.heating", title: "ML Yearly Heating (kWh)", rw: "R", ml_model: true },
      // { key: "P.building.ml_breakdown.cooling", title: "ML Yearly Cooling (kWh)", rw: "R", ml_model: true },
      // { key: "P.building.ml_breakdown.hot_water", title: "ML Yearly Hot Water (kWh)", rw: "R", ml_model: true },
      // { key: "P.building.ml_breakdown.lights_appliances", title: "ML Yearly Lights & Appliances (kWh)", rw: "R", ml_model: true },
      // { key: "P.building.ml_breakdown.invalid_baseload", title: "ML Invalid Baseload", rw: "R", type: "bool", ml_model: true },
      //{ key: "P.building.ml_breakdown.invalid_baseload", title: "ML Invalid Baseload", rw: "R", type: "bool", accuracy: true },
      { key: "P.metrics.r_squared_adjusted", title: "R² Adjusted", rw: "R", accuracy: true },
      { key: "P.metrics.multiple_r", title: "Multiple R", rw: "R", accuracy: true },
      { key: "P.metrics.pvalue_hdd", title: "HDD Coefficient P-Value", rw: "R", accuracy: true },
      { key: "P.metrics.pvalue_cdd", title: "CDD Coefficient P-Value", rw: "R", accuracy: true },
      { key: "P.metrics.pvalue_days", title: "Days Coefficient P-Value", rw: "R", accuracy: true },
      { key: "P.model.total_hdds", title: "Total HDD", rw: "R", accuracy: true, int: true },
      { key: "P.model.total_cdds", title: "Total CDD", rw: "R", accuracy: true, int: true },
      { key: "P.model.total_days", title: "Total Days", rw: "R", accuracy: true, int: true },
      { key: "P.property.storeys", title: "Number of Floors", rw: "RW", analysis: true, select: [1, 1.5, 2, 2.5, 3] },
      /*{ key: "P.building.avg_floor_area_sqft", title: "Average Floor Area (SQFT)", rw: "RW", analysis: true, int: true },
      { key: "P.building.walls_area_sqft", title: "Walls Area (SQFT)", rw: "RW", analysis: true, int: true },
      { key: "P.building.window_area_sqft", title: "Window Area (SQFT)", rw: "RW", analysis: true, int: true },
      { key: "P.building.foundation_area_sqft", title: "Foundation Area (SQFT)", rw: "RW", analysis: true, int: true },
      { key: "P.building.ceiling_area_sqft", title: "Ceiling Area (SQFT)", rw: "RW", analysis: true, int: true },
      { key: "P.building.total_area_sqft", title: "Total Area (SQFT)", rw: "RW", analysis: true, int: true },*/
      //{ key: "P.building.correction_factor.EGHHEATFconsE_MJ", title: "ML Space Heating Energy Raw (MJ)", rw: "R", ml_model: true },
      { key: "P.model.correction_factor || 1.", title: "Recommendation Impact Correction Factor", rw: "R", accuracy: true },
      { key: "P.property.year_built", title: "Year Built", rw: "R", overview: true, int: true },
      { key: "P.property.address", title: "Building Address", rw: "R", overview: true },
      { key: "P.property.total_heated_floor_area_sqft", title: "Total Floor Area (SQFT)", rw: "R", overview: true, int: true },
      { key: "P.yearly_heatloss_breakdown_gj.walls", title: "Wall Heatloss (GJ/year)", rw: "R", heatloss: true },
      { key: "P.yearly_heatloss_breakdown_gj.foundation", title: "Foundation Heatloss (GJ/year)", rw: "R", heatloss: true },
      { key: "P.yearly_heatloss_breakdown_gj.ceiling", title: "Ceiling Heatloss (GJ/year)", rw: "R", heatloss: true },
      { key: "P.yearly_heatloss_breakdown_gj.windows_doors", title: "Window Heatloss (GJ/year)", rw: "R", heatloss: true },
      { key: "P.yearly_heatloss_breakdown_gj.walls + P.yearly_heatloss_breakdown_gj.foundation + P.yearly_heatloss_breakdown_gj.ceiling + P.yearly_heatloss_breakdown_gj.windows_doors", title: "Total Heatloss (GJ/year)", rw: "R", heatloss: true },
    ];

    let P = { ...(this.props.isType2 ? this.props.model2 : this.props.model) }; // P = latest_model JSON

    if (P.type_2_model_debug) {
      //allFields = [...allFields, ...(_AutoFindFields(P.building.type_2_model_debug, 'P.building.type_2_model_debug', 'Debug'))];
    }
    if (P.type_2_breakdown_model_debug) {
      //allFields = [...allFields, ...(_AutoFindFields(P.building.type_2_breakdown_model_debug, 'P.building.type_2_breakdown_model_debug', 'Debug'))];
    }

    let t2debugCsv = { id: P.id, rec_models: P.model_debug || {}, breakdown: null };

    let initFields = [];
    for (let i = 0; i < allFields.length; i++) {
      const F = allFields[i];
      let value = undefined;
      try {
        eval(`value = ${F.key};`);
      }
      catch (erri) {
        value = undefined;
      }
      if (F.type === 'bool') {
        value = !!value;
      }
      else {
        if (value === undefined || value === null) {
          value = '';
        }
        else {
          if (typeof value === 'number' && (value !== Math.floor(value))) {
            value = `${value.toFixed(F.int ? 0 : 3)}`;
          }
          else {
            value = `${value}`;
          }
        }
      }
      let doAdd = !F.add_if;
      if (F.add_if) {
        try {
          eval(`doAdd = !!(${F.add_if})`);
        }
        catch (errif) {
          doAdd = false;
        }
      }
      if (doAdd) {
        initFields.push({ ...F, value: value, rkey: `pm-ifield-${F.key}` });
      }
    }

    let filterFields = (args, rowSize) => {
      let ret = [];
      for (let i = 0; i < initFields.length; i++) {
        const F = initFields[i];
        for (let key in args) {
          if (F[key] === args[key]) {
            ret.push(F);
            break;
          }
        }
      }
      let r2 = [];
      let bfr = [];
      for (let i = 0; i < ret.length; i++) {
        bfr.push(ret[i]);
        if (bfr.length === rowSize) {
          r2.push(bfr);
          bfr = [];
        }
      }
      if (bfr.length) {
        r2.push(bfr);
      }
      return r2;
    };

    let recs = [];
    /*for (let rec of (this.props.model?.__report_info?.recommendations || [])) {
      recs.push({ title: rec.recommendations_name, value: (-Math.round(rec.impact*1000)/1000) || 0. });
    }*/

    let recs2 = [[]];
    /*for (let i=0; i<recs.length; i++) {
      recs2[recs2.length-1].push(recs[i]);
      if (i > 0 && !((i-1)%2) && ((i+1) < recs.length)) {
        recs2.push([]);
      }
    }*/

    let aContent = [
      /*{
        title: 'Known', body: ``,
        fields: filterFields({overview: true}, 2)
      },*/
      //this.renderAssumptions(filterFields({ analysis: true }, 2)),
      ...(this.props.isType2 ? [{
        title: 'Heat Loss', body: `Heat loss figures represent the heat transferred through building assemblies and does not reflect space conditioning equipment efficiency and internal gains.`,
        fields: filterFields({ heatloss: true }, 2),
        isHLChart: true
      }] : (recs.length ? [{
        title: 'Automated Upgrade Recommendations', body: ``,
        fields: [...filterFields({ recommendation: true }, 2), ...recs2]
      }] : [])),
      {
        title: 'Regression', body: '',
        fields: filterFields({ accuracy: true }, 2)
      }
    ];

    if ((this.props.isAdmin||this.props.isUtilityAdmin) && this.props.isType2) {
      aContent.push({
        title: 'T2 Model Debug (Model + Raw ML IO)', body: '',
        fields: filterFields({ type2debug: true }, 2),
        downloadCsv: { P: this.props.model2, isPre: true },
        debugCsv: { P: t2debugCsv, isPre: true }
      })
    }

    if ((this.props.isAdmin||this.props.isUtilityAdmin) && !this.props.isType2) {
      aContent.push({
        title: 'T1 Model Debug (Model + Report Download)', body: '',
        fields: filterFields({ type2debug: true }, 2),
        downloadCsv: { P: this.props.model, isPre: true, isType1: true },
        downloadPdf: true
      })
    }

    aContent.push({
      title: 'Auditor\'s Notes (Internal)',
      fields: [],
      isNotes: true
    });

    /*if (!this.props.isType2 && !window.IS_SASK_POWER) {
      aContent.push({
        title: 'Virtual Audit Preview',
        fields: [],
        type1ReportPreview: true
      })
    }*/

    for (let i = 0; i < aContent.length; i++) {
      if (!aContent[i].fields.length && !aContent[i].downloadCsv && !aContent[i].isNotes && !aContent[i].type1ReportPreview) {
        aContent.splice(i, 1);
        i--;
        continue;
      }
    }

    let a1Array = [];
    a1Array.length = aContent.length;
    a1Array.fill(false);
    a1Array[-1] = true;

    this.setState({
      init: true,
      ld: '',
      initEchartsOptions: {
        renderer: 'canvas'
      },
      hoverData: null,
      dropdownOpen: false,
      internalNotes: this.props.building.internalNotes || '',
      accordionFirst: a1Array,
      accordionFirstContent: aContent
    });
  }

  toggleAccordionFirst(id) {
    const arr = [];
    arr.length = this.state.accordionFirst.length;
    arr.fill(false);
    arr[id] = !this.state.accordionFirst[id];
    this.setState({
      accordionFirst: arr,
    });
  }

  setHoverData = (e) => {
    this.setState({ hoverData: e.target.category });
  }

  renderPieGraph(title) {
    return (
      <SimpPie
        width={268}
        height={268}
        isType2={this.props.isType2}
      />
    );
  }

  renderHLChart(title) {
    const P = this.props.isType2 ? this.props.model2 : this.props.model;
    if (!P || !P.yearly_heatloss_breakdown_gj) {
      return (<div/>);
    }
    return (
      <SimpHLChart
        width={268}
        height={268}
        walls_hl={P.yearly_heatloss_breakdown_gj.walls}
        foundation_hl={P.yearly_heatloss_breakdown_gj.foundation}
        windows_hl={P.yearly_heatloss_breakdown_gj.windows_doors}
        ceiling_hl={P.yearly_heatloss_breakdown_gj.ceiling}
        no_found={!P.property.heated_basement}
      />
    );
  }

  renderAssumptions(filterFields) {
    const user = this.props.currentUser;

    if (user.role === 'admin' || user.role === 'utilityadmin') {
      let myheader = {
        title: 'Model Assumptions', body: ``,
        fields: filterFields
      };

      return (
        myheader
      );
    }
    else {
      return  {
        title: '',
        fields: ''
      };;
    }
  }

  changeField(field) {

  }

  notesChanged(e) {

    if (this.state.noteSaveWaiting) {
      window.clearTimeout(this.noteSaveTimeoutID);
    }

    this.noteSaveTimeoutID = window.setTimeout(() => {
      this.props.dispatch(updateAuditorNotesRequest({
        buildingId: this.props.building.buildingId,
        internalNotes: this.inotesRef.current.value
      }));
      this.setState({ noteSaveWaiting: false });
      this.noteSaveTimeoutID = null;
    }, 3000);
    this.setState({ noteSaveWaiting: true });

  }

  hvacTest() {
    this.props.dispatch(hvacTestRequest({
      buildingId: this.props.building.buildingId
    }));
  }

  render() {

    const renderField = (field) => {
      return (
        <div className='ac-field' key={field.rkey}>
          <Col xs={12}>
            <Label for="normal-field">{field.title}</Label>
          </Col>
          <Col xs={12}>
            <Input type="text" defaultValue={field.value} name={field.key} readOnly></Input>
          </Col>
        </div>
      );
    };

    if (!this.state.init) {
      return (<div />);
    }
    return (
      <div className="pre-upgrade-wrapper">
        {/* Accordion */}
        <div style={{height: "2em"}} />
        <Row className="mt-xs">
          <Col md="12" xs="12" className='mb-lg'>
            <div className="ac-cont panel mb-xs" key={`accord-one--1`}>
              { /* eslint-disable */}
              <div
                className="ac-header panel-header" role="button"
                onClick={() => {
                  this.toggleAccordionFirst(-1)
                }}>
                { /* eslint-enable */}
                <div className="mb-0">
                  {/* eslint-disable-next-line */}
                  <a className="accordion-toggle" role="button">
                    <div className="ac-header-text">Energy Breakdown Chart</div>
                    <i className={`fa fa-angle-down ${this.state.accordionFirst[-1] ? 'expanded' : ''}`} />
                  </a>
                </div>
              </div>
              <Collapse className="panel-body" isOpen={this.state.accordionFirst[-1]}>
                <div>
                  {this.renderPieGraph("Pie")}
                </div>
              </Collapse>
            </div>
            {this.state.accordionFirstContent.map((element, index) => (
              <div className="ac-cont panel mb-xs" key={`accord-one-${index.toString()}`}>
                { /* eslint-disable */}
                <div
                  className="ac-header panel-header" role="button"
                  onClick={() => {
                    this.toggleAccordionFirst(index)
                  }}>
                  { /* eslint-enable */}
                  <div className="mb-0">
                    {/* eslint-disable-next-line */}
                    <a className="accordion-toggle" role="button">
                      <div className="ac-header-text">{element.title}</div>
                      <i className={`fa fa-angle-down ${this.state.accordionFirst[index] ? 'expanded' : ''}`} />
                    </a>
                  </div>
                </div>
                <Collapse className="panel-body" isOpen={this.state.accordionFirst[index]}>
                  {!element.isHLChart && <div>
                    <div dangerouslySetInnerHTML={{ __html: element.body }} />
                    {element.downloadCsv && <div>
                      <Button className='mr-xs btn btn-primary' onClick={() => ( window.DownloadModelCSV(element.downloadCsv.P, element.downloadCsv.isPre, false, element.downloadCsv.isType1) )}>{element.downloadCsv.isType1 ? 'Download Type-1 Model CSV' : 'Download Pre-Upgrade Model CSV'}</Button><br/><br/>
                      {element.debugCsv && <Button className='mr-xs btn btn-primary' onClick={() => ( window.DownloadModelCSV(element.debugCsv.P, element.downloadCsv.isPre, true) )}>Download T2-Pre ML Debug CSV</Button>}
                      {element.downloadPdf && <Button onClick={this.handleDownloadReport} className="mr-xs btn btn-primary" type="submit">Download Type-1 Report (PDF)</Button>}
                      {element.debugCsv && <Button className='mr-xs btn btn-primary' onClick={() => { this.hvacTest(); }}>Run Type-2 HVac Test</Button>}
                      <br/></div>}
                    {element.type1ReportPreview && <center>
                      <div className='raa-report-preview-cont' id={"report-preview-cont"}>
                        {/*<CustomerReport type2={false} isPreview={true} />*/}
                      </div>
                    </center>}
                    {element.isNotes && <div>
                      <Col xs={12}>
                        <Label for="internal-notes">Internal Notes (not visible on report)<span style={this.state.noteSaveWaiting === false ? {color: '#10D010'} : this.state.noteSaveWaiting === true ? {color: '#F04035'} : {}}>{this.state.noteSaveWaiting === false ? ' (Saved)' : this.state.noteSaveWaiting === true ? ' (Unsaved)' : ''}</span>:</Label>
                      </Col>
                      <Col xs={12}>
                        <Input type="textarea" defaultValue={this.state.internalNotes} name={'internal-notes-input'} rows={15} onChange={this.notesChanged.bind(this)} innerRef={this.inotesRef}></Input>
                      </Col>
                    </div>}
                    <br />
                    <div className="ac-field-list">
                      {element.fields.map((fieldRow, rIndex) => (
                        <FormGroup row key={`_fg-${index}-${rIndex}`}>
                          {fieldRow.map((field, cIndex) => renderField(field))}
                        </FormGroup>
                      ))}
                    </div>
                    <div>{element.component}</div>
                    {/* {this.renderGraph(element.title)} */}
                  </div>}
                  {element.isHLChart && <div>
                    {this.renderHLChart('Heat Loss')}
                  </div>}
                </Collapse>
              </div>
            ))}
          </Col>
        </Row>
      </div>);
  }
}

function mapStateToProps(state) {
  let model = state.buildings.curModel || {};
  let model2 = model.LPRE || {};
  let user = state.auth.currentUser;

  return {
    building: state.buildings.curBuilding.building || {},
    model,
    model2,
    currentUser: user,
    isAdmin: user && user.role === 'admin',
    isUtilityAdmin: user && user.role === 'utilityadmin'
  };
}
export default withRouter(connect(mapStateToProps)(PreUpgradePanel));
