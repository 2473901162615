import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import InsightTypeBlock from './InsightTypeBlock';

import '../Common.scss';

class TableSort extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      current: {

      }
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.current) !== JSON.stringify(this.props.current)) {
      this.setState({ current: JSON.parse(JSON.stringify(this.props.current)) });
    }
  }

  render() {
    return (
      <div className='en-rt-sort' onClick={() => { if (this.props.onChange) this.props.onChange(this.props.name, !this.state.current.descending); }}>
        {this.props.children}
        {this.state.current.name === this.props.name && <img className='en-rt-sort-arrow' src={this.state.current.descending ? '/images/rte-dd-down-arrow.svg' : '/images/rte-dd-up-arrow.svg'} />}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(TableSort));
