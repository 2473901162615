const toTableFormat = (data, arrayOnly, headersOnly, noTruncate) => {
  if (arrayOnly) {
    const flattenJSON = (O, prev, output) => {
      for (let key in O) {
        if (Array.isArray(O[key])) {
          output[`${prev||''}${key}`] = true;
        }
        else if (typeof O[key] === 'object') {
          flattenJSON(O[key], `${prev||''}${key}/`, output);
        }
      }
    }
    let _header = {};
    for (let i=0; i<data.length; i++) {
      let row = {};
      flattenJSON(data[i], null, row);
      for (let key in row) {
        _header[key] = true;
      }
    }
    let header = [];
    for (let key in _header) {
      header.push(key);
    }
    header.sort();
    let truncated = false;
    if (header.length > 1000 && !noTruncate) {
      header.length = 1000;
      truncated = true;
    }
    return { header, rows: [], truncated };
  }
  else {
    const flattenJSON = (O, prev, output) => {
      for (let key in O) {
        if (Array.isArray(O[key])) {
          output[`${prev||''}${key}`] = `Array(${O[key].length})`;
        }
        else if (typeof O[key] === 'object') {
          flattenJSON(O[key], `${prev||''}${key}/`, output);
        }
        else {
          output[`${prev||''}${key}`] = O[key];
        }
      }
    }
    let rows = [];
    let _header = {};
    for (let i=0; i<data.length; i++) {
      let row = {};
      flattenJSON(data[i], null, row);
      for (let key in row) {
        _header[key] = true;
      }
      if (!headersOnly) {
        rows.push(row);
      }
    }
    let header = [];
    for (let key in _header) {
      header.push(key);
    }
    header.sort();
    let truncated = false;
    if (header.length > 1000 && !noTruncate) {
      header.length = 1000;
      truncated = true;
    }
    return { header, rows, truncated };
  }
}

//// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const componentToHex = (c) => {
  let hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
///

module.exports = {
  toTableFormat,
  hexToRgb,
  rgbToHex
};
