import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';

import '../Common.scss';

class BuildingImage extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
    this.imgKey = RandomKey();
    this.state = { imageShow: false }
  }

  showImage() {
    this.setState({ imageShow: true });
  }

  render() {
    return (
      <div key={this.key} className='building-details-image' style={{width: this.props.width + 'px', height: this.props.height + 'px', cursor: this.props.onClick ? 'pointer' : 'auto'}} onClick={() => {
        if (this.props.onClick) {
          this.props.onClick();
        }
      }}>
        <img key={this.imgKey} onLoad={() => (this.showImage())} src={`https://maps.googleapis.com/maps/api/streetview?size=${this.props.width}x${this.props.height+25}&location=${encodeURIComponent(this.props.address)}&fov=110&pitch=15&key=AIzaSyApXh8oEyjFlROoqd2J2Vq9n5gXAHPKuY8`} style={{opacity: this.state.imageShow ? '1' : '0'}} />
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(BuildingImage));
