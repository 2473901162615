import axios from 'axios';
import config from "../config";
import { toast } from 'react-toastify';
import { listsData } from "../pages/remoteaudits/selectLists"
export const RECEIVING_BUILDING_INSIGHTS = 'RECEIVING_BUILDING_INSIGHTS';

export const UPDATED_EN_INSIGHTS = 'UPDATED_EN_INSIGHTS';
export const UPDATED_EN_CUSTOMERS = 'UPDATED_EN_CUSTOMERS';

export function getENInsights() {
  return (dispatch) => {
    dispatch(updateENInsights([]));
    return axios.get('/insights', {}).then(res => {
      dispatch(updateENInsights([...(res.data.offers), ...(res.data.insights)]));
    })
  };
}

export function getENCustomers() {
  return (dispatch) => {
    dispatch(updateENCustomers([]));
    return axios.get('/orgs', {}).then(res => {
      dispatch(updateENCustomers([...(res.data)]));
    })
  };
}

export function uploadAttachments(file, callbacks) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post('/attachments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (ev) => {
        let total = ev.total;
        let percent = (ev.loaded * 100) / total;
        callbacks.progress(percent, total);
      }
    }).then(res => {
      callbacks.success(res);
    }).catch(err => {
      callbacks.error(err);
    });
  };
}

export function receivingBuildingInsights() {
  return {
    type: RECEIVING_BUILDING_INSIGHTS
  }
};

export function getENInsightsByBuildingId(buildingId) {
  return (dispatch) => {
    dispatch(receivingBuildingInsights());
    dispatch(updateENInsights([]));
    return axios.get('/insights/view/' + buildingId, {}).then(res => {
      dispatch(updateENInsights([...(res.data.offers), ...(res.data.insights)]));
    })
  }
};

export function updateENInsights(payload) {
  return {
    type: UPDATED_EN_INSIGHTS,
    payload
  }
};

export function updateENCustomers(payload) {
  return {
    type: UPDATED_EN_CUSTOMERS,
    payload
  }
};

export function createENProject(args) {
  return (dispatch) => {
    return axios.post('/insights/create', args);
  };
};

export function editENProject(args) {
  return (dispatch) => {
    return axios.put('/insights/edit', args);
  };
};
