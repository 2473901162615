import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Array Select'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      data={data}
    >
      <ColumnSelect title='Array Column' value={model.column} onChange={(val) => saveModel({...model, column: val})} />
    </NodeWrapper>
  );
});