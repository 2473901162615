import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';
import ColorSelect from './ColorSelect';
import GradientPreview from './GradientPreview';

import { Input } from '../../../components/Common';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Gradient Color Mapper'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"400px"}
      data={data}
    >
      <div className='fcs-io-title'>INPUTS</div>
      <ColumnSelect title='Input Column' value={model.column} onChange={(val) => saveModel({...model, column: val})} />
      <div className='fcs-half'><ColorSelect title='Start Color' value={model.start_color} onChange={(val) => saveModel({...model, start_color: val})} /></div>
      <div className='fcs-half'><ColorSelect title='End Color' value={model.end_color} onChange={(val) => saveModel({...model, end_color: val})} /></div>
      <div className='fcs-half'>
        <div className='fcs-title'>Number of Colors</div>
        <Input type="number" defaultValue={model.quant_levels} onChange={(val) => saveModel({...model, quant_levels: Math.min(256, Math.max(2, parseInt(val)||0))})} />
      </div>
      <div className='fcs-half'>
        <div className='fcs-title'>Distribution Skew Exponent</div>
        <Input type="number" defaultValue={model.skew_pow} onChange={(val) => saveModel({...model, skew_pow: Math.min(100, Math.max(0.01, parseFloat(val)||0))})} />
      </div>
      <div className='fcs-io-title'>OUTPUTS</div>
      <ColumnSelect title='Output Column' value={model.output} onChange={(val) => saveModel({...model, output: val})} />
      <GradientPreview title='Preview Color Gradient' start_color={model.start_color} end_color={model.end_color} count={model.quant_levels} skew={model.skew_pow} />
    </NodeWrapper>
  );
});