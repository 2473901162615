import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import Dropdown from './Dropdown';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../Common.scss';

class InlineBtns extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const sel = this.props.currentState;
    return (
      <div className="rte-inline-btns">
        <img src="/images/rte-bold.svg" className={'rte-button-icon ' + (sel.bold ? 'selected' : '') } onClick={() => {this.props.onChange("bold");}}/>
        <img src="/images/rte-italics.svg" className={'rte-button-icon ' + (sel.italic ? 'selected' : '') } onClick={() => {this.props.onChange("italic");}}/>
        <img src="/images/rte-underline.svg" className={'rte-button-icon ' + (sel.underline ? 'selected' : '') } onClick={() => {this.props.onChange("underline");}}/>
        <img src="/images/rte-strikethrough.svg" className={'rte-button-icon ' + (sel.strikethrough ? 'selected' : '') } onClick={() => {this.props.onChange("strikethrough");}}/>
        <img src="/images/rte-superscript.svg" className={'rte-button-icon ' + (sel.superscript ? 'selected' : '') } onClick={() => {this.props.onChange("superscript");}}/>
        <img src="/images/rte-subscript.svg" className={'rte-button-icon ' + (sel.subscript ? 'selected' : '') } onClick={() => {this.props.onChange("subscript");}}/>
      </div>
    );
  }
}

class ListBtns extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const sel = this.props.currentState;
    return (
      <div>
        <img src="/images/rte-bullets.svg" className={'rte-button-icon ' + (sel.unordered ? 'selected' : '') } onClick={() => {this.props.onChange("unordered");}}/>
        <img src="/images/rte-numbered.svg" className={'rte-button-icon ' + (sel.ordered ? 'selected' : '') } onClick={() => {this.props.onChange("ordered");}}/>
        <img src="/images/rte-indent.svg" className={'rte-button-icon ' + (sel.indent ? 'selected' : '') } onClick={() => {this.props.onChange("indent");}}/>
        <img src="/images/rte-unindent.svg" className={'rte-button-icon ' + (sel.outdent ? 'selected' : '') } onClick={() => {this.props.onChange("outdent");}}/>
      </div>
    );
  }
}

class TextAlignBtns extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const sel = this.props.currentState;
    return (
      <div className="rte-text-align-btns">
        <img src="/images/rte-left-align.svg" className={'rte-button-icon ' + (sel.left ? 'selected' : '') } onClick={() => {this.props.onChange("left");}}/>
        <img src="/images/rte-center-align.svg" className={'rte-button-icon ' + (sel.center ? 'selected' : '') } onClick={() => {this.props.onChange("center");}}/>
        <img src="/images/rte-right-align.svg" className={'rte-button-icon ' + (sel.right ? 'selected' : '') } onClick={() => {this.props.onChange("right");}}/>
      </div>
    );
  }
}

class HideBtns extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const sel = this.props.currentState;
    return (
      <div style={{"display": "none"}}>
      </div>
    );
  }
}

class BlockTypeDD extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const sel = this.props.currentState;
    const options = [{title:'Normal', key:'Normal'}, {title:'Header 1', key:'H1'}, {title:'Header 2', key:'H2'}, {title:'Header 3', key:'H3'}];
    let opt = null;
    for (let i=0; i<options.length; i++) {
      if (sel.blockType === options[i].key) {
        opt = options[i];
      }
    }
    return (
      <div>
        <Dropdown options={options} defaultValue={opt} onChange={(val) => {this.props.onChange(val.key)}} width={"70px"} />
      </div>
    );
  }
}

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
    
    this.state = { editorState: EditorState.createEmpty() }
    if (props.value) {
    	this.state.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.value)));
    }
    this.lastVal = props.value + '';
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && (this.props.value !== prevProps.value) && (this.lastVal !== this.props.value)) {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.value)))
      });
      this.lastVal = this.props.value;
    }
  }

  render() {
    return (
      <div key={this.key} className={'editor-container' + (this.props.error ? ' form-error-border' : '')} style={{width: (this.props.width || '645px')}}>
				<Editor
		  		editorState={this.state.editorState}
          wrapperClassName={'editor-wrapper'}
          editorClassName={'editor-editor'}
          toolbarClassName={'editor-toolbar'}
          onEditorStateChange={(eState) => {
          	this.setState({ editorState: eState });
            this.lastVal = JSON.stringify(convertToRaw(eState.getCurrentContent()));
          	if (this.props.onChange) {
              let plainText = eState.getCurrentContent().getPlainText();
              if (plainText.length > 0 && this.props.onNonEmpty) {
                this.props.onNonEmpty();
              }
          		this.props.onChange(
          			this.lastVal,
          			plainText
          		);
          	}
          }}
          toolbar={{
            inline: { component: InlineBtns },
            list: { component: ListBtns },
            textAlign: { component: TextAlignBtns },
            history: { component: HideBtns },
            remove: { component: HideBtns },
            image: { component: HideBtns },
            embedded: { component: HideBtns },
            emoji: { component: HideBtns },
            link: { component: HideBtns },
            colorPicker: { component: HideBtns },
            blockType: { component: BlockTypeDD }
          }}
        />
        {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(RichTextEditor));

