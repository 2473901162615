import React, { memo, useState } from 'react';

import { Input, TypeSearchInput } from '../../../components/Common';

export default memo(({ title, onChange, value, __colInfo, isLeft, isRight, noOptions }) => {

  let options = Object.keys(__colInfo||{}).filter((e) => !!e);
  if (isLeft) {
    options = Object.keys((__colInfo||{}).left || {}).filter((e) => !!e);
  }
  if (isRight) {
    options = Object.keys((__colInfo||{}).right || {}).filter((e) => !!e);
  }
  if (noOptions) {
    options = [];
  }

  return (
    <div className='flow-col-select-wrapper'>
      <div className='fcs-title'>{title || 'Select Column'}</div>
      <TypeSearchInput options={options} onChange={onChange} defaultValue={value} title={noOptions ? 'Type' : 'Search/Type'} />
    </div>
  );

});
