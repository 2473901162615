import tempConfig from './config-vars.json';

const label = tempConfig.label || 'dev';
const useGreenUrl = tempConfig.useGreenUrl || false;

const config = tempConfig[label];
if (useGreenUrl == 'true') {
    config.hostApi = config.greenBackendUrl;
    config.baseURLApi = config.greenBackendUrl;
    config.redirectUrl = config.greenUrl;
    config.remote = config.greenUrl;
}

export default config;
