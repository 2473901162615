import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Alert, Button, FormGroup, Label, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import config from '../../../config';
import Widget from '../../../components/Widget';
import { logoutUser } from '../../../actions/auth';
import jwt from "jsonwebtoken";
// import microsoft from '../../../images/microsoft.png';

class Logout extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(logoutUser());
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(mapStateToProps)(Logout));