import React, { memo, useState, useRef, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

export default memo(({ data, isBGO }) => {

  const vdtVal = (header, value) => {
    let type = (data.options.types||{})[header];
    if (value === undefined || value === null) {
      return null;
    }
    else if (!type || type === 'auto' || type === 'string') {
      if (Number.isInteger(parseFloat(value)) && !isNaN(parseInt(value)) && !isNaN(value) && parseInt(value) == value && type !== 'string') {
        return parseInt(value);
      }
      else if (!isNaN(parseFloat(value)) && !isNaN(value) && type !== 'string') {
        return (Math.round(parseFloat(value)*1000000)/1000000);
      }
      else {
        return '' + value;
      }
    }
    else if (type === 'int') {
      let nv = Math.round(parseInt(value));
      if (Number.isInteger(nv)) {
        return '' + nv;
      }
      else {
        return '';
      }
    }
    else if (type === 'dec3') {
      let nv = Math.round(parseInt(value*1000));
      if (Number.isInteger(nv)) {
        return '' + (nv/1000);
      }
      else {
        return '';
      }
    }
    else if (type === 'dec6') {
      let nv = Math.round(parseInt(value*1000000));
      if (Number.isInteger(nv)) {
        return '' + (nv/1000000);
      }
      else {
        return '';
      }
    }
  };

  const vdtHeaderVisible = (header) => {
    return !data.options.hidden || !data.options.hidden[header];
  };

  return (
    <div className='vdt-scroll'>
      <div className='vdt-header'>
        {data.data.header.map((title, idx) => (
          vdtHeaderVisible(title) && <div className='vdt-th' key={'vdth' + idx} title={title}>{title}</div>
        ))}
      </div>
      {data.data.rows.slice(0, Math.min(isBGO ? 10000 : 200, data.data.rows.length)).map((row, idx) => (
        <div className='vdt-tr' key={'vdtr' + idx}>
          {data.data.header.map((header, jdx) => (
            vdtHeaderVisible(header) && <div className='vdt-val' key={'vdtval-' + idx + '-' + jdx} title={vdtVal(header, row[header])}>{[null, undefined, ''].indexOf(vdtVal(header, row[header])) >= 0 ? (<div style={{opacity: '0'}}>{''+vdtVal(header, row[header])}</div>) : vdtVal(header, row[header])}</div>
          ))}
        </div>
      ))}
    </div>
  );
});
