import React, { memo, useRef, useState } from 'react';
import axios from 'axios';
import { Handle } from 'react-flow-renderer';

export default memo(({ data }) => {
  const [inputState, setInputState] = useState(!!data.value.internal);

  const onSelect = (event) => {
    event = event || window.event;
    const internal = data.value.internal;
    data.onChange({ ...(data.value), internal }, true);
  };

  const onChange = (event) => {
    const url = event.target.value;
    data.onChange({ ...(data.value), url }, true);
  };

  const onChangeAuth = (event) => {
    const auth_url = event.target.value;
    data.onChange({ ...(data.value), auth_url }, true);
  };

  const onChangeUser = (event) => {
    const auth_user = event.target.value;
    data.onChange({ ...(data.value), auth_user }, true);
  };

  const onChangePass = (event) => {
    const auth_pass = event.target.value;
    data.onChange({ ...(data.value), auth_pass }, true);
  };

  const onCheck = () => {
    data.value.internal = !data.value.internal;
    data.onChange({...(data.value)}, true);
    setInputState(data.value.internal);
  };

  const onCheckAuth = () => {
    data.value.auth = !data.value.auth;
    data.onChange({...(data.value)}, true);
  };

  const onClick = () => {
    data.onChange({ ...(data.value), data: data.value.data || [] }, true);
    let token = null;
    if (data.value.auth) {
      axios.post(
        '/flows/api-proxy',
        {
          method: 'POST',
          uri: data.value.auth_url,
          qs: {
            user: data.value.auth_user,
            password: data.value.auth_pass
          },
          json: true
        }
      ).then((res) => {
        const token = res.data.token;
        axios.post(
          '/flows/api-proxy',
          {
            method: 'GET',
            uri: data.value.url,
            headers: {
              Authorization: token
            },
            json: true
          }
        ).then((res) => {
          data.onChange({ ...(data.value), data: res.data });
        });
      });
      return;
    }
    if (data.value.internal) {
      axios.get(
        data.value.url,
        {
          headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    	    }
        }
      ).then((res) => {
        data.onChange({ ...(data.value), data: res.data });
      })
    }
    else {
      axios.post(
        '/flows/api-proxy',
        {
          method: 'GET',
          uri: data.value.url,
          json: true
        }
      ).then((res) => {
        data.onChange({ ...(data.value), data: res.data });
      });
    }
  };

  return (
    <div style={{width: "340px", height: data.value.auth ? "194px" : "100px", border: "1px solid #44D9E6", borderRadius: "8px", background: "rgba(0, 0, 0, 0.2)", padding: "10px", color: "#DDDDDD"}}>
      HTTP GET Endpoint{data.value.data.length ? ` (${data.value.data.length} rows)` : ``}:<br/>
      {!inputState ? (<input type='text' style={{marginRight: "8px"}} value={data.value.url} onChange={(event) => onChange(event)}/>)
      : (<select onChange={(event) => {onChange(event)}} defaultValue={data.value.url}>
        <option value=''>--Select--</option>
        <option value='/buildings/'>Buildings</option>
        <option value='/users/'>Users</option>
        <option value='/orgs/'>Organizations</option>
        <option value='/insights/'>Insights & Offers</option>
      </select>)}
      <button onClick={() => {onClick();}}>GET</button>
      <div style={{marginBottom: "10px", display: "block"}}/>
      Internal: <input type="checkbox" value={"check_internal"} defaultChecked={!!data.value.internal} style={{width: "20px", marginRight: "15px"}} onChange={() => onCheck()} />
      Auth: <input type="checkbox" value={"check_internal"} defaultChecked={!!data.value.auth} style={{width: "20px", marginRight: "15px"}} onChange={() => onCheckAuth()} />
      {data.value.auth && <div style={{"margin-top": "14px"}}>
        API Auth URL: <input type='text' style={{marginRight: "8px"}} value={data.value.auth_url} onChange={(event) => onChangeAuth(event)}/><br/>
        API Auth Username: <input name="auth_user" type='text' style={{marginRight: "8px"}} value={data.value.auth_user} onChange={(event) => onChangeUser(event)}/><br/>
        API Auth Password: <input name="auth_pass" type='password' style={{marginRight: "8px"}} value={data.value.auth_pass} onChange={(event) => onChangePass(event)}/><br/>
      </div>}
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ background: '#DDDDDD', border: '3px solid #44D9E6', width: '15px', height: '15px', marginRight: '-4px' }}
      />
    </div>
  );
});
