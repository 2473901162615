export default function CompletenessMessage (score, canEdit) {
  if (score < 75) {
    if (canEdit) {
      return "A high Profile Completeness Score for a building helps get the most out of The Energy Advisor Dashboard!";
    }
    else {
      return "A high Profile Completeness Score for a building helps get the most out of The Energy Advisor Dashboard! Ask someone with permission to Edit this building’s info to add more details.";
    }
  }
  else if (score < 100) {
    if (canEdit) {
      return "Adding a bit more info will get you a perfect Profile Completeness Score for this building!";
    }
    else {
      return "Adding a bit more info will get a perfect Profile Completeness Score for this building! Ask someone with permission to Edit this building’s info to add more details.";
    }
  }
  else {
    if (canEdit) {
      return "Your building’s profile is completely filled out - great job!";
    }
    else {
      return "This building’s profile is completely filled out!";
    }
  }
  return "";
};
