import { combineReducers } from 'redux';
import auth from './auth';
import navigation from './navigation';
import alerts from './alerts';
import users from './usersReducers';
import audits from './audits';
import buildings from './buildings';
import energyNavigator from './energy-navigator';
import { connectRouter } from 'connected-react-router';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    alerts,
    auth,
    navigation,
    users,
    audits,
    buildings,
    energyNavigator
});
