import axios from 'axios';
import {chartData} from "../pages/remoteaudits/mockSpark";
import config from "../config";

export const RECEIVED_AUDITS = 'RECEIVED_AUDITS';
export const RECEIVED_AUDITS_YBD = 'RECEIVED_AUDITS_YBD';
export const RECEIVING_AUDITS = 'RECEIVING_AUDITS';
export const RECEIVING_AUDITS_YBD = 'RECEIVING_AUDITS_YBD';
export function getAuditsRequest() {
    
  return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveAudits(chartData));
        }
  
        else {
            dispatch(receivingAudits());
            axios.get('/allaudits/154450').then(res => {
              dispatch(receiveAudits(res.data));
            })
          };
        
    };
  }
  export function getAuditsYBDRequest() {
    
    return (dispatch) => {
          // We check if app runs with backend mode
          if (!config.isBackend) {
              dispatch(receiveAudits(chartData));
          }
    
          else {
              dispatch(receivingAuditsYBD());
              axios.get('/allaudits/YBD/448872').then(res => {
                dispatch(receiveAuditsYBD(res.data));
                
              })
            };
          
      };
    }
  export function receiveAudits(payload) {
    return {
        type: RECEIVED_AUDITS,
        payload
    }
  }
  export function receiveAuditsYBD(payload) {
    return {
        type: RECEIVED_AUDITS_YBD,
        payload
    }
  }
  export function receivingAudits() {
    return {
        type: RECEIVING_AUDITS
    }
  }

  export function receivingAuditsYBD(payload) {
    return {
        type: RECEIVING_AUDITS_YBD,
        payload
    }
  }
