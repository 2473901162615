import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, CompletenessSlider, CompletenessMessage, RandomKey } from '../../../components/Common'
import './CustomerDetailsCard.scss';

class CustomerDetailsCard extends Component {

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  renderDetail(title, value, exclass) {
    return (
      <div className={'bd-detail-half ' + (exclass || '')} title={value || '-'}>
        <div className='bdd-title'>{title}</div>
        <div className='bdd-value'>{value || '-'}</div>
      </div>
    );
  }

  renderDetailFull(title, value) {
    return (
      <div className='bd-detail-full' title={value || '-'}>
        <div className='bdd-title'>{title}</div>
        <div className='bdd-value'>{value || '-'}</div>
      </div>
    );
  }

  render() {
    const { data } = this.props;

    const fmtGJ = Format.GJ,
          fmtKG = Format.KG,
          fmtPercent = Format.Percent,
          fmtDollars = Format.Dollars,
          fmtDollarsShort = Format.DollarsShort,
          fmtSQFT = Format.SQFT;

    const hasEditPermissions = true; // TODO: Hook this up

    if (window.location.href.indexOf(data.id) < 0) {
      return <div/>;
    }

    console.log(data);

    data.buildings_area = 0;
    for (let B of data.buildings) {
      data.buildings_area += B.floorArea || 0;
    }

    data.goals = data.goals || [];// || ['Reduce Avg. Building’s Operational Costs by 25% by 2025', 'Net-Zero GHG Impact by 2050', 'Test 3', 'Test 4'];;

    const anyLinks = (data.links && (data.links.facebook || data.links.linkedin || data.links.twitter || data.links_website)) || false;

    const points = [];

    if (data.industry_group && data.industry !== data.industry_group) {
      points.push({title: "Industry Group", value: data.industry_group});
    }
    if (data.industry) {
      points.push({title: "Industry", value: data.industry});
    }
    if (points.length === 1) {
      if (data.number_of_employees) {
        points.push({title: "Employees", value: data.number_of_employees});
      }
      if (data.estimated_annual_revenue) {
        points.push({title: "Estimated Annual Revenue", value: data.estimated_annual_revenue});
      }
    }
    else {
      if (data.estimated_annual_revenue) {
        points.push({title: "Estimated Annual Revenue", value: data.estimated_annual_revenue});
      }
      if (data.number_of_employees) {
        points.push({title: "Employees", value: data.number_of_employees});
      }
    }
    if (data.fiscal_year_end) {
      points.push({title: "Fiscal Year End", value: data.fiscal_year_end});
    }
    if (data.industry_group) {
      points.push({title: "Founded Year", value: data.founded_year});
    }

    return (
      <div key={this.key} className='customer-details-card'>
        <div className='customer-logo-cont'>
          <img src={data.company_logo} className='customer-logo'/>
        </div>

        <div className='bd-name'>{data.name}</div>
        <div className='company-address'>{data.office_address}</div>

        {this.renderDetailFull("Description", data.description)}

        <br/>

        {points.map((obj, index) => (
          this.renderDetail(obj.title, obj.value, !(index%2) ? 'left' : '')
        ))}

        {false && data.parent_name && this.renderDetailFull("Parent Company", data.parent_name)}

        <div className="have-access-to">You Have Access To</div>
        {this.renderDetail("Buildings", data.buildings.length, 'left')}
        {this.renderDetail("Area", fmtSQFT(data.buildings_area))}

        {data.goals.length > 0 && <div className="goals-label">Goals & Objectives</div>}
        {data.goals.map((goal) => (
          <div className='customer-goal' title={goal}><div className='cg-bullet'></div>{goal}</div>
        ))}

        {anyLinks && <div className="links-label">Links</div>}
        {anyLinks && <div className="links">
          {data.links.facebook && <a href={data.links.facebook} target="_blank">Facebook</a>}
          {data.links.linkedin && <a href={data.links.linkedin} target="_blank">LinkedIn</a>}
          {data.links.twitter && <a href={data.links.twitter} target="_blank">Twitter</a>}
          {data.links.website && <a href={data.links.website} target="_blank">Website</a>}
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(CustomerDetailsCard);
