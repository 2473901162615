import React, { memo, useState } from 'react';

export default memo(({ title, onChange, value, options }) => {
  
  const [ checked, setChecked ] = useState(value);

  return (
    <div className='flow-radio-select'>
      <div className='fcs-title'>{title || 'Select One'}</div>
      {(options||[]).map((O, idx) => (<div className={'fr-option ' + (O.key === checked ? 'fro-checked' : '')} key={'fr-option-' + idx} onClick={() => {setChecked(O.key); onChange(O.key);}}><div className='fro-circle'></div>{O.title}</div>))}
    </div>
  );

});
