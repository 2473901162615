import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import RandomKey from './RandomKey';
import Format from './Format';
import { uploadAttachments } from '../../../actions/energy-navigator';

import '../Common.scss';

class AttachmentUploader extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      value: props.defaultValue || props.value || null,
      focused: false,
      type: props.type || 'text',
      required: props.required || false,
      files: props.value || [
      ]
    };

    for (let i=0; i<this.state.files.length; i++) {
      if (!this.state.files[i].fid) {
        this.state.files[i].fid = RandomKey();
      }
    }

    this.dzRef = React.createRef();

    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    let fstate = this.state.files;
    for (let i=0; i<files.length; i++) {
      let obj = {
        file_name: files[i].name,
        file_size: files[i].size,
        status: 'pending',
        progress: 0,
        fid: RandomKey()
      };
      fstate.unshift(obj);
      this.props.dispatch(uploadAttachments(files[i], {
        progress: (progress, total) => {
          for (let j=0; j<this.state.files.length; j++) {
            if (this.state.files[j].fid === obj.fid) {
              this.state.files[j].progress = progress;
              this.state.files[j].status = 'active';
              if (this.props.onChange) {
                this.props.onChange(JSON.parse(JSON.stringify(this.state.files)));
              }
              this.setState({ files: this.state.files });
              break;
            }
          }
        },
        success: (res) => {
          for (let j=0; j<this.state.files.length; j++) {
            if (this.state.files[j].fid === obj.fid) {
              this.state.files[j].progress = 100;
              this.state.files[j].status = 'success';
              this.state.files[j].id = res.data[0].id;
              this.state.files[j].link = res.data[0].link;
              if (this.props.onChange) {
                this.props.onChange(JSON.parse(JSON.stringify(this.state.files)));
              }
              this.setState({ files: this.state.files });
              break;
            }
          }
        },
        error: (err) => {
          for (let j=0; j<this.state.files.length; j++) {
            if (this.state.files[j].fid === obj.fid) {
              this.state.files[j].progress = 100;
              this.state.files[j].status = 'error';
              if (this.props.onChange) {
                this.props.onChange(JSON.parse(JSON.stringify(this.state.files)));
              }
              this.setState({ files: this.state.files });
              break;
            }
          }
        }
      }));
    }
    if (this.props.onChange) {
      this.props.onChange(JSON.parse(JSON.stringify(this.state.files)));
    }
    this.setState({ files: fstate });
  }

  componentDidUpdate(prevState, prevProps) {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) && this.lastVal !== JSON.stringify(this.props.value)) {
      for (let i=0; this.props.value && i<this.props.value.length; i++) {
        if (!this.props.value[i].fid) {
          this.props.value[i].fid = RandomKey();
        }
      }
      this.setState({ files: this.props.value === null ? undefined : this.props.value });
      this.lastVal = JSON.stringify(this.props.value);
    }
  }

  removeFile(index) {
    let files = this.state.files;
    files.splice(index, 1);
    this.setState({ files });
    if (this.props.onChange) {
      this.props.onChange(JSON.parse(JSON.stringify(this.state.files)));
    }
  }

  render() {

    const statusMessages = {
      'success': 'Upload Complete',
      'error': 'Upload Failed',
      'active': 'Uploading ...',
      'pending': 'Pending'
    };

    return (
      <div className={'en-att-uploader'} key={this.key} style={this.props.width ? {width: this.props.width} : {}}>
      	<Dropzone ref={this.dzRef} onDrop={this.onDrop} >
		      {({getRootProps, getInputProps}) => (
      			<section>
          		<div {...getRootProps()}>
            	  <input {...getInputProps()} />
            		<div className='en-att-label'>
            			<img src='/images/att-upload-icon.svg'/>
            			<div className='en-att-label-1'>Click here or drag to upload attachment</div>
            			<div className='en-att-label-2'>Accepted formats: png, jpeg, pdf, docx, xlsx, doc, xls, ppt. Maximum of 10 MB</div>
            		</div>
          		</div>
      			</section>
          )}
      	</Dropzone>
        {this.state.files.map((file, index) => (
          <div className='en-att-file' key={this.key + '-' + index}>
            <div className='en-atf-left'><img src={'/images/upload-file-' + file.status + '.svg'} /></div>
            <div className='en-atf-right'>
              <div className='en-atf-file-name'>{file.file_name}</div>
              <div className='en-atf-back-bar' style={{width: `${(file.status === 'success' || file.status === 'error') ? 95 : 100}%`}}>
                <div className={'en-atf-bar ' + file.status} style={{width: `${(file.status === 'error' || file.status === 'success') ? 100 : (file.progress || 0)}%`}}></div>
              </div>
              {(file.status === 'success' || file.status === 'error') && <img className='en-atf-del-icon' src="/images/window-close.svg" onClick={() => {this.removeFile(index);}} />}
              <div className='en-atf-status'>
                {file.status === 'success' && <div className='en-atf-status-left'>{Format.Bytes(file.file_size)} of {Format.Bytes(file.file_size)}</div>}
                {file.status === 'active' && <div className='en-atf-status-left'>{Format.Bytes(file.file_size*file.progress/100)} of {Format.Bytes(file.file_size)}</div>}
                {file.status === 'pending' && <div className='en-atf-status-left'>{Format.Bytes(file.file_size)}</div>}
                {file.status === 'error' && <div className='en-atf-status-left'>{Format.Bytes(file.file_size)}</div>}
                <div className={'en-atf-status-right ' + file.status}>{statusMessages[file.status]}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(AttachmentUploader));
