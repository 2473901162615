const E1_STATUS_ORDER = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
};

const E1_STATUSES = {
    VA_AUTOMATED_INSIGHTS:    { main: 1, sub: 1 },
    PRE_AWAITING_SIGNUP:      { main: 2, sub: 0 },
    PRE_AWAITING_BOOKING:     { main: 2, sub: 1 },
    PRE_AWAITING_AUDIT:       { main: 2, sub: 2 },
    PRE_MODELLING_BASELINE:   { main: 2, sub: 3 },
    PRE_MODELLING_UPGRADES:   { main: 2, sub: 4 },
    PRE_PREVIEW_REPORT:       { main: 2, sub: 5 },
    PRE_PREVIEW_PUBLISHED:    { main: 2, sub: 6 },
    UPG_UPGRADES_NOT_STARTED: { main: 3, sub: 0 },
    UPG_UPGRADES_IN_PROGRESS: { main: 3, sub: 1 },
    POST_AWAITING_SIGNUP:     { main: 4, sub: 0 },
    POST_AWAITING_BOOKING:    { main: 4, sub: 1 },
    POST_AWAITING_AUDIT:      { main: 4, sub: 2 },
    POST_MODELLING_BASELINE:  { main: 4, sub: 3 },
    POST_MODELLING_UPGRADES:  { main: 4, sub: 4 },
    POST_PREVIEW_REPORT:      { main: 4, sub: 5 },
    POST_PREVIEW_PUBLISHED:   { main: 4, sub: 6 },
    INCENTIVES_RECEIVED:      { main: 5, sub: 1 }
};

const E1_BASE_STATUS = E1_STATUSES.PRE_AWAITING_SIGNUP;

const E1_STATUS_TITLES = {
    1: 'Virtual Audit',
    2: 'Pre-Upgrade Audit',
    3: 'Upgrades',
    4: 'Post-Upgrade Audit',
    5: 'Project Complete'
};

const E1_SUB_STATUS_TITLES = {
    1: {
        1: 'Automated Insights'
    },
    2: {
        0: 'Awaiting Signup',
        1: 'Awaiting Booking',
        2: 'Awaiting Audit',
        3: 'Audit Started',
        4: 'Baseline Modelled',
        5: 'Upgrades Modelled',
        6: 'Published'
    },
    3: {
        0: 'Upgrades Started',
        1: 'Upgrades Completed'
    },
    4: {
        0: 'Awaiting Signup',
        1: 'Awaiting Booking',
        2: 'Awaiting Audit',
        3: 'Audit Started',
        4: 'Baseline Modelled',
        5: 'Upgrades Modelled',
        6: 'Published'
    },
    5: {
        1: 'Incentives Received'
    }
};

const E1_SUB_STEP_TITLES = {
    1: {
        1: 'Automated Insights'
    },
    2: {
        0: 'Awaiting Signup',
        1: 'Awaiting Booking',
        2: 'Awaiting Audit',
        3: 'Baseline Modelling',
        4: 'Upgrade Modelling',
        5: 'Report Building',
        6: 'Published'
    },
    3: {
        0: 'Upgrades Started',
        1: 'Upgrades Completed'
    },
    4: {
        0: 'Awaiting Signup',
        1: 'Awaiting Booking',
        2: 'Awaiting Audit',
        3: 'Baseline Modelling',
        4: 'Upgrade Modelling',
        5: 'Report Building',
        6: 'Published'
    },
    5: {
        1: 'Incentives Received'
    }
};


const E1_SUB_STATUS_ICONS = {
    1: {
        1: 'ws-status-awaiting-audit.svg'
    },
    2: {
        0: 'ws-status-awaiting-booking.svg',
        1: 'ws-status-awaiting-booking.svg',
        2: 'ws-status-awaiting-audit.svg',
        3: 'ws-status-model-baseline.svg',
        4: 'ws-status-model-upgrades.svg',
        5: 'ws-status-preview-report.svg',
        6: 'ws-status-preview-report.svg'
    },
    3: {
        1: 'ws-status-awaiting-audit.svg'
    },
    4: {
        0: 'ws-status-awaiting-booking.svg',
        1: 'ws-status-awaiting-booking.svg',
        2: 'ws-status-awaiting-audit.svg',
        3: 'ws-status-model-baseline.svg',
        4: 'ws-status-model-upgrades.svg',
        5: 'ws-status-preview-report.svg',
        6: 'ws-status-preview-report.svg'
    },
    5: {
        1: 'ws-status-preview-report.svg'
    }
};

const GEN_STATUS_ORDER = {
    1: 1,
    10: 2,
    11: 3
};

const GEN_STATUSES = {
    VA_AUTOMATED_INSIGHTS:    { main:  1, sub: 1 },
    PLN_MAKING_RETROFIT_PLAN: { main: 10, sub: 1 },
    UGR_UPGRADES_VALIDATION:  { main: 11, sub: 1 }
};

const GEN_BASE_STATUS = GEN_STATUSES.VA_AUTOMATED_INSIGHTS;

const GEN_STATUS_TITLES = {
    1: 'Data Gathering',
    2: 'Planning',
    3: 'Upgrades'
};

const GEN_SUB_STATUS_TITLES = {
    1: {
        1: 'Improving Automated Insights'
    },
    10: {
        1: 'Making Retrofit Plan'
    },
    11: {
        1: 'Upgrades & ValidationUpgrades Completed'
    }
};

const GEN_SUB_STEP_TITLES = {
    1: {
        1: 'Improving Automated Insights'
    },
    10: {
        1: 'Making Retrofit Plan'
    },
    11: {
        1: 'Upgrades & ValidationUpgrades Completed'
    }
};


const GEN_SUB_STATUS_ICONS = {
    1: {
        1: 'ws-status-awaiting-audit.svg'
    },
    10: {
        1: 'ws-status-model-upgrades.svg'
    },
    11: {
        1: 'ws-status-preview-report.svg'
    }
};

module.exports = (theme) => {

    let STATUS_ORDER = GEN_STATUS_ORDER;
    let STATUSES = GEN_STATUSES;
    let STATUS_TITLES = GEN_STATUS_TITLES;
    let SUB_STATUS_TITLES = GEN_SUB_STATUS_TITLES;
    let SUB_STATUS_ICONS = GEN_SUB_STATUS_ICONS;
    let SUB_STEP_TITLES = GEN_SUB_STEP_TITLES;
    let BASE_STATUS = GEN_BASE_STATUS;

    if (theme.advisorExperience) {
        STATUS_ORDER = E1_STATUS_ORDER;
        STATUSES = E1_STATUSES;
        STATUS_TITLES = E1_STATUS_TITLES;
        SUB_STATUS_TITLES = E1_SUB_STATUS_TITLES;
        SUB_STATUS_ICONS = E1_SUB_STATUS_ICONS;
        SUB_STEP_TITLES = E1_SUB_STEP_TITLES;
        BASE_STATUS = E1_BASE_STATUS;
    }

    const statusEqual = (val, status, subStatus) => {
        if (typeof status == 'object') {
            return status.main == val.main && status.sub == val.sub;
        }
        else {
            return status == val.main && subStatus == val.sub;
        }
    };
    
    const statusGt = (val, status, subStatus) => {
        if (typeof status == 'object') {
            if (status.main == val.main) {
                return val.sub > status.sub;
            }
            else {
                return STATUS_ORDER[val.main] > STATUS_ORDER[status.main];
            }
        }
        else {
            if (status == val.main) {
                return val.sub > subStatus;
            }
            else {
                return STATUS_ORDER[val.main] > STATUS_ORDER[status];
            }
        }
    };
    
    const statusGtEqual = (val, status, subStatus) => {
        if (typeof status == 'object') {
            if (status.main == val.main) {
                return val.sub >= status.sub;
            }
            else {
                return STATUS_ORDER[val.main] >= STATUS_ORDER[status.main];
            }
        }
        else {
            if (status == val.main) {
                return val.sub >= subStatus;
            }
            else {
                return STATUS_ORDER[val.main] >= STATUS_ORDER[status];
            }
        }
    };
    
    const statusLtEqual = (val, status, subStatus) => (!statusGt(val, status, subStatus));
    const statusLt = (val, status, subStatus) => (!statusGtEqual(val, status, subStatus));

    const ASYNC_STATUSES = {
        NOT_SHARED:  0,
        SHARED:      1,
        OUT_OF_DATE: 2,
        RECENT:      3
    };

    const ENERGUIDE_REPORT_STATUSES = {
        USER_UPLOADED_REPORT: 0,
        PREV_USER_UPLOADED_REPORT: 1,
        NO_REPORT: 2,
    };

    return {
        STATUS_ORDER,
        STATUSES,
        STATUS_TITLES,
        SUB_STATUS_TITLES,
        SUB_STATUS_ICONS,
        SUB_STEP_TITLES,
        BASE_STATUS,
        ASYNC_STATUSES,
        ENERGUIDE_REPORT_STATUSES,
        statusEqual,
        statusGt,
        statusGtEqual,
        statusLt,
        statusLtEqual
    };

};