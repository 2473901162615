let ratings = [
  { text: 'Very Poor', color: '#B73919', colorName: "Red", textKey: 'hmp-rating-very-poor' },
  { text: 'Poor', color: '#F2675B', colorName: "Light Red", textKey: 'hmp-rating-poor' },
  { text: 'Worse than Average', color: '#F58E2E', colorName: "Orange", textKey: 'hmp-rating-worse-than-average' },
  { text: 'Average', color: '#F8AC4B', colorName: "Yellow", textKey: 'hmp-rating-average' },
  { text: 'Better than Average', color: '#A3CB38', colorName: "Light Green", textKey: 'hmp-rating-better-than-average' },
  { text: 'Good', color: '#52C55C', colorName: "Green", textKey: 'hmp-rating-good' },
  { text: 'Excellent', color: '#367B3D', colorName: "Dark Green", textKey: 'hmp-rating-excellent' }
];
ratings[-1] = { text: 'Unknown', color: '#474747' };

export const listsData = {
  findOption: (list, value) => {
    return list.filter((e) => (e == value || (e && e.value == value) || (`${e && e.value}`.toLowerCase() == `${value}`.toLowerCase())))[0];
  },
  homeRatings: ratings,
  list: {
    floorAboveCrawlspaceInsulationPresets: [
      { value: 'user-defined', label: 'User Defined' }
    ],
    ceilingInsulationPresets: [
      { value: 'user-defined', label: 'User Defined' },
      { label: 'Fr:2x4 Truss | Sp:24" | Cav:20', value: [20,18.0] },
      { label: 'Fr:2x4 Truss | Sp:24" | Cav:32', value: [32,30.0] },
      { label: 'Fr:2x4 Truss | Sp:24" | Cav:40', value: [40,38.0] },
      { label: 'Fr:2x4 Truss | Sp:24" | Cav:50', value: [50,48.0] },
      { label: 'Fr:2x6 | Sp:16" | Cav:0', value: [0,3.6] },
      { label: 'Fr:2x6 | Sp:16" | Cav:12', value: [12,11.3] },
      { label: 'Fr:2x6 | Sp:16" | Cav:20', value: [20,16.8] },
      { label: 'Fr:2x6 | Sp:16" | Cav:40', value: [40,35.0] },
      { label: 'Fr:2x6 | Sp:16" | Cav:50', value: [50,45.0] },
      { label: 'Fr:2x8 | Sp:16" | Cav:28', value: [28,23.5] },
      { label: 'Fr:2x10 | Sp:16" | Cav:31', value: [31,26.8] }
    ],
    foundationInsulationPresets: [
      { value: 'user-defined', label: 'User Defined' },
      { label: 'Fr:None | Sp:None | Cav:None | Con:None | Fnd:8" Concrete', value: [0,0.6] },
      { label: 'Fr:None | Sp:None | Cav:None | Con:None | Fnd:24" Stone', value: [0,1.6] },
      { label: 'Fr:None | Sp:None | Cav:None | Con:2" CCSF | Fnd:8" Concrete', value: [12,12.6] },
      { label: 'Fr:None | Sp:None | Cav:None | Con:2" CCSF | Fnd:24" Stone', value: [12,13.6] },
      { label: 'Fr:None | Sp:None | Cav:None | Con:4" CCSF | Fnd:8" Concrete', value: [24,24.6] },
      { label: 'Fr:None | Sp:None | Cav:None | Con:4" CCSF | Fnd:24" Stone', value: [24,25.6] },
      { label: 'Fr:2x4 | Sp:24" | Cav:12 | Con:0 | Fnd:8" Concrete', value: [12,10.8] },
      { label: 'Fr:2x4 | Sp:24" | Cav:12 | Con:5 | Fnd:8" Concrete', value: [17,15.8] },
      { label: 'Fr:2x6 | Sp:24" | Cav:20 | Con:0 | Fnd:8" Concrete', value: [20,17.0] },
      { label: 'Fr:2x6 | Sp:24" | Cav:20 | Con:5 | Fnd:8" Concrete', value: [25,22.0] }
    ],
    wallInsulationPresets: [
      { value: 'user-defined', label: 'User Defined' },
      { label: 'Fr:2x4 RC | Sp:16 | Cav:0 | Con:0 | IF:P/L | Sh:3/4" | Si:Wood', value: [0,4.6] },
      { label: 'Fr:2x4 RC | Sp:16 | Cav:Blown Cellulose (R 3.6/inch) | Con:0 | IF:P/L | Sh:3/4" | Si:Wood', value: [14.4,12.6] },
      { label: 'Fr:2x4 | Sp:16 | Cav:0 | Con:0 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [0,3.9] },
      { label: 'Fr:2x4 | Sp:16 | Cav:8 | Con:0 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [8,9.6] },
      { label: 'Fr:2x4 | Sp:16 | Cav:12 | Con:0 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [12,11.2] },
      { label: 'Fr:2x4 | Sp:16 | Cav:Blown Cellulose (R 3.6/inch) | Con:0 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [12.6,11.6] },
      { label: 'Fr:2x6 | Sp:16 | Cav:20 | Con:0 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [20,16.1] },
      { label: 'Fr:2x4 RC | Sp:16 | Cav:Blown Cellulose (R 3.6/inch) | Con:4 | IF:P/L | Sh:3/4" | Si:Wood', value: [18.4,16.7] },
      { label: 'Fr:2x4 | Sp:16 | Cav:0 | Con:4 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [4,7.9] },
      { label: 'Fr:2x4 | Sp:16 | Cav:8 | Con:4 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [12,13.6] },
      { label: 'Fr:2x4 | Sp:16 | Cav:12 | Con:4 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [16,15.2] },
      { label: 'Fr:2x4 | Sp:16 | Cav:Blown Cellulose (R 3.6/inch) | Con:4 | IF:Gyp | Sh:1/2" | Si:Vinyl', value: [16.6,15.6] }
    ],
    heatPumpUpgradeTypes: [
      { value: 'mshp-ductless', label: 'Ductless MSHP', rating: 'good' },
      { value: 'mshp', label: 'Ducted MSHP', rating: 'good' },
      { value: 'central', label: 'Central HP', rating: 'good' },
      /*{ value: 'air-water', label: 'Air-to-water HP', rating: 'good' },*/
      { value: 'geo', label: 'Geothermal HP', rating: 'good' }
    ],
    ceilingInsulationTypes: [
      { value: "A", label: "Sloped Ceiling", rating: "good" },
      { value: "F", label: "Flat Ceiling", rating: "good" },
      { value: "T", label: "Attic Above Ceiling", rating: "good" }
    ],
    foundationMainInsulationTypes: [
      { value: "B", label: "Basement Wall", rating: 'good' },
      { value: "C", label: "Crawl Space Wall", rating: 'good' },
      { value: "P", label: "Slab On Grade", rating: 'good' }
    ],
    foundationAttachmentInsulationTypes: [
      { value: "B", label: "Basement Wall", rating: 'good' },
      { value: "C", label: "Crawl Space Wall", rating: 'good' },
      { value: "F", label: "Floor Above Crawl Space", rating: 'good' }
    ],
    heatPumpSourceSupply: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'air', label: 'Air', rating: 'good' },
      { value: 'ground', label: 'Ground', rating: 'good' },
      { value: 'none', label: 'N/A {no Heat Pump}', rating: 'good' },
    ],
    utilityServiceProviderOptions: [
      { value: null, label: "- Unspecified -" },
      { value: "Nova Scotia Power", label: "Nova Scotia Power" },
      { value: "Antigonish", label: "Antigonish" },
      { value: "Berwick", label: "Berwick" },
      { value: "Canso", label: "Canso" },
      { value: "Lunenburg", label: "Lunenburg" },
      { value: "Mahone Bay", label: "Mahone Bay" },
      { value: "Riverport", label: "Riverport" },
    ],
    primaryHeatingTypes: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'baseboard', label: 'Baseboard/Hydronic/Plenum(duct) htrs.', rating: 'good' },
      { value: 'electric boiler', label: 'Electric Boiler', rating: 'good' },
      { value: 'forced air furnace', label: 'Forced Air Furnace', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'furnace', label: 'Furnace', rating: 'good' },
      { value: 'heated by stove', label: 'Heated by stove', rating: 'good' },
      { value: 'hot water furnace ', label: 'Hot water furnace ', rating: 'good' },
      { value: 'hot water tank', label: 'Hot Water Tank', rating: 'good' },
      { value: 'n/a', label: 'N/A', rating: 'good' },
      { value: 'radiation', label: 'Radiation', rating: 'good' },
      { value: 'stove top of the oven', label: 'Stove Top of the Oven', rating: 'good' },
      { value: 'wood', label: 'Wood', rating: 'good' },
      { value: 'propane', label: 'Propane', rating: 'good' },
    ],

    typesOfHouses: [
      /*{ value: null, label: "- Unspecified -", rating: 'good' },*/
      { value: "apartment" , label: "Apartment" },
      { value: "double", label: "Double/Semi Detached" },
      { value: "duplex", label: "Duplex" },
      { value: "mobile", label: "Mobile Home" },
      { value: "row house", label: "Row House" },
      { value: "row end", label: "Row House: End Unit" },
      { value: "row middle", label: "Row House: Middle Unit" },
      { value: "single detached", label: "Single Detached" },
      { value: "triplex", label: "Triplex" }
    ],

    primaryHotWaterEqType: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'heatpump', label: 'Heat Pump ', rating: 'good' },
      { value: 'condensing', label: 'Condensing', rating: 'good' },
      { value: 'conserver tank', label: 'Conserver Tank ', rating: 'good' },
      { value: 'conventional tank', label: 'Conventional Tank ', rating: 'good' },
      { value: 'conventional tank (pilot)', label: 'Conventional tank (pilot)', rating: 'good' },
      { value: 'induced draft fan', label: 'Induced draft fan', rating: 'good' },
      { value: 'instantaneous', label: 'Instantaneous', rating: 'good' },
      { value: 'instantaneous (condensing)', label: 'Instantaneous (condensing)', rating: 'good' },
      { value: 'solar collector system', label: 'Solar Collector System', rating: 'good' },
      { value: 'tankless coil', label: 'Tankless Coil', rating: 'good' },
    ],
    primaryHotWaterEqPostUpgradeType: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'heatpump', label: 'Heat Pump ', rating: 'good' },
      { value: 'solar collector system', label: 'Solar Collector System', rating: 'good' },
    ],
    primaryHotWaterEqFuelType: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'solar', label: 'Solar', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'electricity', label: 'Electricity ', rating: 'good' },
      { value: 'propane', label: 'Propane', rating: 'good' },
      { value: 'naturalgas', label: 'Natural Gas', rating: 'good' },
    ],
    houseStorys: [
      /*{ value: null, label: "- Unspecified -", rating: 'good' },*/
      { value: 1, label: '1', rating: 'good' },
      { value: 1.5, label: '1.5', rating: 'good' },
      { value: 2, label: '2', rating: 'good' },
      { value: 2.5, label: '2.5', rating: 'good' },
      { value: 3, label: '3', rating: 'good' },
      { value: 3.5, label: '3.5', rating: 'good' },
      { value: 4, label: '4', rating: 'good' },
    ],
    houseTotOccupants: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: '1', rating: 'good' },
      { value: '2', label: '2', rating: 'good' },
      { value: '3', label: '3', rating: 'good' },
      { value: '4', label: '4', rating: 'good' },
      { value: '5', label: '5', rating: 'good' },
      { value: '6', label: '6', rating: 'good' },
      { value: '7', label: '7', rating: 'good' },
    ],
    ventalationTypes: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'mechanicalventilationwithoutheatrecover', label: 'Mechanical Ventilation Without Heat Recover', rating: 'good' },
      { value: 'erv', label: 'HRV', rating: 'good' },
      { value: 'erv', label: 'erv', rating: 'good' },
    ],
    ponyWalls: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: 'Yes', rating: 'good' },
      { value: '0', label: 'No', rating: 'good' },],
    secondaryDomesticHotWaterTypes: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'instantaneous', label: 'Instantaneous', rating: 'good' },
      { value: 'stored heat', label: 'Stored heat', rating: 'good' },
      { value: 'mains pressure', label: 'Mains pressure', rating: 'good' },
      { value: 'low pressure stored water', label: 'Low Pressure Stored Water', rating: 'good' },],
    tempOfBasement: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '15', label: '15', rating: 'good' },
      { value: '20', label: '20', rating: 'good' },
      { value: '25', label: '25', rating: 'good' },
      { value: '30 ', label: '30', rating: 'good' },],
    tempOfMainFloor: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '15', label: '15', rating: 'good' },
      { value: '20', label: '20', rating: 'good' },
      { value: '25', label: '25', rating: 'good' },
      { value: '430 ', label: '30', rating: 'good' },],
    firePlaceDamper: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'chimney top', label: 'Chimney Top', rating: 'good' },
      { value: 'throat', label: 'Throat', rating: 'good' },
    ],
    typeOfACSystem: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'no cooling', label: 'No Cooling', rating: 'good' },
      { value: 'central a/c', label: 'Central Air Conditioner', rating: 'good' },
      { value: 'central heat pump', label: 'Central Heat Pump', rating: 'good' },
      { value: 'mini-split ductless', label: 'Mini-split Heat Pump (ductless)', rating: 'good' },
      { value: 'mini-split ducted', label: 'Mini-split Heat Pump (ducted)', rating: 'good' }
    ],
    suplimentaryHeating1: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'a.a. wood stove with cat. converter', label: 'Adv. Airtight Wood Stove + Cat. Conv.', rating: 'good' },
      { value: 'a.a. wood stove', label: 'Advanced Airtight Wood Stove', rating: 'good' },
      { value: 'baseboard', label: 'Baseboard/Hydronic/Plenum(duct) htrs.', rating: 'good' },
      { value: 'fan heaters', label: 'Fan Heater Units', rating: 'good' },
      { value: 'sealed pilot fireplace', label: 'Fireplace With Pilot (sealed) ', rating: 'good' },
      { value: 'unsealed pilot fireplace', label: 'Fireplace With Pilot (unsealed) ', rating: 'good' },
      { value: 'sealed spark fireplace', label: 'Fireplace With Spark Ignit.(sealed) ', rating: 'good' },
      { value: 'unsealed spark fireplace', label: 'Fireplace With Spark Ignit.(unsealed) ', rating: 'good' },
      { value: 'furnace', label: 'Furnace/Boiler With Spark Ignition ', rating: 'good' },
      { value: 'space heater', label: 'Space Heater ', rating: 'good' },
      { value: 'wood fireplace', label: 'Wood Fireplace ', rating: 'good' },
      { value: 'wood fireplace insert', label: 'Wood Fireplace Insert ', rating: 'good' },
      { value: 'wood furnace', label: 'Wood Furnace ', rating: 'good' },
      { value: 'heat pump', label: 'Heat Pump', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'wood stove', label: 'Wood Stove', rating: 'good' },
      { value: 'oil furnace', label: 'Oil Furnace', rating: 'good' },
      { value: 'propane', label: 'Propane', rating: 'good' },
    ],
    suplimentaryHeating1Fuel: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'electricity', label: 'Electricity', rating: 'good' },
      { value: 'mixed wood', label: 'Mixed Wood', rating: 'good' },
      { value: 'natural gas', label: 'Natural Gas', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'propane', label: 'Propane', rating: 'good' },
      { value: 'wood pellets', label: 'Wood Pellets', rating: 'good' },
    ],
    suplimentaryHeating2: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'base natural gas', label: 'Base Natural Gas', rating: 'good' },
      { value: 'base oil', label: 'Base Oil', rating: 'good' },
      { value: 'base propane', label: 'Base Propane', rating: 'good' },
      { value: 'base wood', label: 'Base Wood', rating: 'good' },
      { value: 'heat pump', label: 'Heat Pump', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'oil furnace', label: 'Oil Furnace', rating: 'good'},
      { value: 'propane', label: 'Propane', rating: 'good' },
      { value: 'wood furnace', label: 'Wood Furnace', rating: 'good' },
      { value: 'wood pallet', label: 'Wood Pallet', rating: 'good' },
      { value: 'wood stove', label: 'Wood Stove', rating: 'good' },
      { value: 'wood', label: 'Wood', rating: 'good' },
    ],
    suplimentaryHeating2Fuel: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: 'electricity', label: 'Electricity', rating: 'good' },
      { value: 'mixed wood', label: 'Mixed Wood', rating: 'good' },
      { value: 'natural gas', label: 'Natural Gas', rating: 'good' },
      { value: 'oil', label: 'Oil', rating: 'good' },
      { value: 'propane', label: 'Propane', rating: 'good' },
      { value: 'wood pellets', label: 'Wood Pellets', rating: 'good' },
    ],
    totalNumOfInstalledWindows: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: '1', rating: 'good' },
      { value: '2', label: '2', rating: 'good' },
      { value: '3', label: '3', rating: 'good' },
      { value: '4', label: '4', rating: 'good' },
      { value: '5', label: '5', rating: 'good' },
      { value: '6', label: '6', rating: 'good' },
      { value: '7', label: '7', rating: 'good' },
      { value: '8', label: '8', rating: 'good' },
      { value: '9', label: '9', rating: 'good' },
      { value: '10', label: '10', rating: 'good' },
      { value: '11', label: '11', rating: 'good' },
      { value: '12', label: '12', rating: 'good' },
      { value: '13', label: '13', rating: 'good' },
      { value: '14', label: '14', rating: 'good' },
      { value: '15', label: '15', rating: 'good' },
      { value: '16', label: '16', rating: 'good' },
      { value: '17', label: '17', rating: 'good' },
      { value: '18', label: '18', rating: 'good' },
      { value: '19', label: '19', rating: 'good' },
      { value: '20', label: '20', rating: 'good' },
      { value: '21', label: '21', rating: 'good' },
      { value: '22', label: '22', rating: 'good' },
      { value: '23', label: '23', rating: 'good' },
      { value: '24', label: '24', rating: 'good' },
      { value: '25', label: '25', rating: 'good' },
    ],
    totalNumOfInstalledDoors: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: '1', rating: 'good' },
      { value: '2', label: '2', rating: 'good' },
      { value: '3', label: '3', rating: 'good' },
      { value: '4', label: '4', rating: 'good' },
      { value: '5', label: '5', rating: 'good' },
      { value: '6', label: '6', rating: 'good' },
      { value: '7', label: '7', rating: 'good' },
      { value: '8', label: '8', rating: 'good' },
      { value: '9', label: '9', rating: 'good' },
      { value: '10', label: '10', rating: 'good' },
    ],
    totalNumOfInstalledEStarWindows: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: '1', rating: 'good' },
      { value: '2', label: '2', rating: 'good' },
      { value: '3', label: '3', rating: 'good' },
      { value: '4', label: '4', rating: 'good' },
      { value: '5', label: '5', rating: 'good' },
      { value: '6', label: '6', rating: 'good' },
      { value: '7', label: '7', rating: 'good' },
      { value: '8', label: '8', rating: 'good' },
      { value: '9', label: '9', rating: 'good' },
      { value: '10', label: '10', rating: 'good' },
      { value: '11', label: '11', rating: 'good' },
      { value: '12', label: '12', rating: 'good' },
      { value: '13', label: '13', rating: 'good' },
      { value: '14', label: '14', rating: 'good' },
      { value: '15', label: '15', rating: 'good' },
      { value: '16', label: '16', rating: 'good' },
      { value: '17', label: '17', rating: 'good' },
      { value: '18', label: '18', rating: 'good' },
      { value: '19', label: '19', rating: 'good' },
      { value: '20', label: '20', rating: 'good' },
      { value: '21', label: '21', rating: 'good' },
      { value: '22', label: '22', rating: 'good' },
      { value: '23', label: '23', rating: 'good' },
      { value: '24', label: '24', rating: 'good' },
      { value: '25', label: '25', rating: 'good' },
    ],
    totalNumOfInstalledEStarDoors: [
      { value: null, label: "- Unspecified -", rating: 'good' },
      { value: '1', label: '1', rating: 'good' },
      { value: '2', label: '2', rating: 'good' },
      { value: '3', label: '3', rating: 'good' },
      { value: '4', label: '4', rating: 'good' },
      { value: '5', label: '5', rating: 'good' },
      { value: '6', label: '6', rating: 'good' },
      { value: '7', label: '7', rating: 'good' },
      { value: '8', label: '8', rating: 'good' },
      { value: '9', label: '9', rating: 'good' },
      { value: '10', label: '10', rating: 'good' },
    ],
    v2Draftiness: [
      { label: '- Unspecified -', value: null },
      { label: 'Not drafty', value: 1 },
      { label: 'A little drafty', value: 2 },
      { label: 'Very drafty', value: 3 }
    ],
    v2OwnHome: [
      { label: '- Unspecified -', value: null },
      { label: 'Rent', value: false },
      { label: 'Own', value: true },
    ],
    v2PrimaryHeatingSystemFuelType: [
      { label: '- Unspecified -', value: null },
      { label: 'Electricity', value: "electricity" },
      { label: 'Wood', value: "wood" },
      { label: 'Natural Gas', value: "natural gas" },
      { label: 'Oil', value: "oil" },
      { label: 'Propane', value: "propane" },
      //{ label: 'Solar', value: "solar" },
    ],
    v2PrimaryHeatingSystemHeatpumpSource: [
      { label: '- Unspecified -', value: null },
      { label: 'Air', value: 'air' },
      { label: 'Ground', value: 'ground' },
      { label: 'Water', value: 'water' }
    ],
    v2PrimaryHeatingSystemHeatpumpType: [
      { label: '- Unspecified -', value: null },
      { label: 'Mini-Split', value: 'mini-split' },
      { label: 'Central', value: 'central' },
      { label: 'Geothermal', value: 'geothermal' },
      { label: 'Air-to-Water', value: 'air-to-water' }
    ],
    v2PrimaryHeatingSystemType: [
      { label: '- Unspecified -', value: null },
      { label: "Electric Baseboard", value: "electric baseboard" },
      { label: "Fireplace", value: "fireplace" },
      { label: "Heater", value: "heater" },
      //{ label: "Heat Pump", value: "heat pump" },
      { label: "In Floor Heat", value: "in floor heat" },
      { label: "New Boiler", value: "new boiler" },
      { label: "New Furnace", value: "new furnace" },
      { label: "Old Boiler", value: "old boiler" },
      { label: "Old Furnace", value: "old furnace" },
      { label: "Wood Stove", value: "wood stove" },
    ],
    v2SecondaryHeatingSystemFuelType: [
      { label: '- Unspecified -', value: null },
      { label: 'Electricity', value: "electricity" },
      { label: 'Wood', value: "wood" },
      { label: 'Natural Gas', value: "natural gas" },
      { label: 'Oil', value: "oil" },
      { label: 'Propane', value: "propane" }
    ],
    v2SecondaryHeatingSystemHeatpumpSource: [
      { label: '- Unspecified -', value: null },
      { label: 'Air', value: 'air' },
      { label: 'Ground', value: 'ground' },
      { label: 'Water', value: 'water' }
    ],
    v2SecondaryHeatingSystemHeatpumpType: [
      { label: '- Unspecified -', value: null },
      { label: 'Mini-Split', value: 'mini-split', set_other: { model: "secondary_hp_source", value: "air" } },
      { label: 'Central', value: 'central', set_other: { model: "secondary_hp_source", value: "air" } },
      { label: 'Geothermal', value: 'geothermal', set_other: { model: "secondary_hp_source", value: "ground" } },
      { label: 'Air-to-Water', value: 'air-to-water', set_other: { model: "secondary_hp_source", value: "water" } }
    ],
    v2SecondaryHeatingSystemType: [
      { label: '- Unspecified -', value: null },
      { value: "electric baseboard", label: "Electric Baseboard/ Space Heater" },
      { value: "fireplace", label: "Fireplace" },
      //{ value: "heat pump", label: "Heat Pump" },
      { value: "heater", label: "Heater" },
      { value: "in floor heat", label: "In Floor Heat" },
      { value: "new boiler", label: "New Boiler" },
      { value: "new furnace", label: "New Furnace" },
      { value: "old boiler", label: "Old Boiler" },
      { value: "old furnace", label: "Old Furnace" },
      { value: "wood stove", label: "Wood Stove" }
    ],
    v2WaterHeatingSystemFuelType: [
      { label: '- Unspecified -', value: null },
      { label: 'Electricity', value: "electricity" },
      { label: 'Natural Gas', value: "natural gas" },
      { label: 'Oil', value: "oil" },
      { label: 'Propane', value: "propane" },
      { label: 'Solar', value: "solar" },
      { label: 'Wood', value: "wood" }
    ],
    v2WaterHeatingSystemType: [
      { label: '- Unspecified -', value: null },
      { label: "Condensing", value: "condensing" },
      { label: "Heat Pump Water Heater", value: "heat pump" },
      { label: "Instantaneous Water Heater", value: "instantaneous" },
      { label: "New Standalone Water Heater", value: "new standalone water heater" },
      { label: "Old Standalone Water Heater", value: "old standalone water heater" },
      { label: "Solar Collector System", value: "solar" },
      { label: "Tankless Coil", value: "tankless coil" }
    ]
  }
}
