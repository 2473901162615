import React, { useState, useRef, useEffect } from 'react';
import Map from './Controls/Map';
import InsightsMap from './Controls/InsightsMap';
import DataTable from './Controls/DataTable';
import { Dropdown, Input, TypeSearchInput } from "../../components/Common";
import { JSONTree } from 'react-json-tree'
import axios from 'axios';
import LZUTF8 from 'lzutf8';
import './Flow.scss';
import moment from 'moment/moment';
import exportFunctions from './ExportFunctions';
import { withRouter } from 'react-router';
import HugeUploader from 'huge-uploader';
import config from '../../config';

window.LAST_BG_SEL_ID = null;
window.LAST_BG_SEL_STATUS = -1;
window.LAST_BG_SEL_PERCENT = -1;

const GE360BackgroundJobs = () => {

    const [allJobs, setAllJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [outputList, setOutputList] = useState([]);
    const [selectedOutput, setSelectedOutput] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [flowList, setFlowList] = useState([]);
    const [functionList, setFunctionList] = useState([]);
    const [flowTitles, setFlowTitles] = useState({});
    const [functionTitles, setFunctionTitles] = useState({});
    const [njFlow, setNJFlow] = useState(null);
    const [modalType, setModalType] = useState('');
    const [exportFileName, setExportFilename] = useState('');
    const inputFile = useRef(null);
    const [selectedFlowDatasetID, setSelectedFlowDatasetID] = useState(null);
    const [BGSDatasetInfo, setBGSDatasetInfo] = useState(null);
    const [datasetList, setDatasetList] = useState([]);
    const [uploader, setUploader] = useState(null);
    const [uploaderPercent, setUploaderPercent] = useState(0);
    const [uploaderError, setUploaderError] = useState(null);
    const [uploaderComplete, setUploaderComplete] = useState(null);

    const loadFlows = () => {
        axios.get('/flows/').then((res) => {
          res.data.sort((a, b) => {
            return -a.updatedAt.localeCompare(b.updatedAt);
          });
          setFlowList(res.data);
          let titles = {};
          for (let F of res.data) {
              titles[F.id] = F.name;
          }
          setFlowTitles(titles);
        });
    };

    const loadDatasets = () => {
        axios.get('/flows/flow-dataset/streamable').then((res) => {
          res.data.sort((a, b) => {
            return -a.updatedAt.localeCompare(b.updatedAt);
          });
          setDatasetList(res.data);
        });
    };

    const loadFunctions = () => {
        axios.get('/flows/sub-programs/').then((res) => {
          res.data.sort((a, b) => {
            return -a.updatedAt.localeCompare(b.updatedAt);
          });
          setFunctionList(res.data);
          let titles = {};
          for (let F of res.data) {
              titles[F.id] = F.name;
          }
          setFunctionTitles(titles);
        });
    };

    const newBGJob = () => {
        window.LAST_BG_SEL_ID = null;
        window.LAST_BG_SEL_STATUS = -1;
        window.LAST_BG_SEL_PERCENT = -1;
        setOutputList([]);
        setSelectedOutput(null);
        setSelectedJob({id: null, new: true, title: 'Untitled Job', output_links: [], options: {}, flow_id: null, percent_complete: 0});
        setNJFlow(null);
    };

    const loadFlow = (id) => {
        setNJFlow(null);
        if (!id || id.charAt('0') == '-') {
            return;
        }
        setIsLoading(true);
        axios.get('/flows/' + id).then(async (res) => {
            let decompressedStr = LZUTF8.decompress(res.data.flow_json, {inputEncoding: "Base64"});
            const flow = JSON.parse(decompressedStr);
            let fileInputs = [];
            for (let e of flow.elements) {
                if (e && e.type === 'fileInput' && e.data && e.data.value && e.data.value._separate_save_json) {
                  try {
                    let resp = await axios.post(
                        '/flows/api-proxy',
                        {
                            method: 'GET',
                            uri: e.data.value._separate_save_json.link
                        }
                    );
                    const size = (resp.data||[]).length || 0;
                    if (size > 0) {
                        fileInputs.push({
                            node_id: e.id,
                            name: e.data.value.file_name || 'Untitled File.json',
                            size: size,
                            job_no: 1,
                            job_size: size
                        });
                    }
                  }
                  catch (err) {
                  }
                }
            }
            if (fileInputs.length) {
                setNJFlow(fileInputs);
            }
            setIsLoading(false);
        });
    };

    const loadFunction = (id) => {
        setNJFlow(null);
        if (!id || id.charAt('0') == '-') {
            return;
        }
        setIsLoading(true);
        axios.get('/flows/sub-programs/' + id).then(async (res) => {
            let decompressedStr = LZUTF8.decompress(res.data.flow_json, {inputEncoding: "Base64"});
            const flow = JSON.parse(decompressedStr);
            let fileInputs = [];
            for (let e of flow.elements) {
                if (e && e.type === 'fileInput' && e.data && e.data.value && e.data.value._separate_save_json) {
                  try {
                    let resp = await axios.post(
                        '/flows/api-proxy',
                        {
                            method: 'GET',
                            uri: e.data.value._separate_save_json.link
                        }
                    );
                    const size = (resp.data||[]).length || 0;
                    if (size > 0) {
                        fileInputs.push({
                            node_id: e.id,
                            name: e.data.value.file_name || 'Untitled File.json',
                            size: size,
                            job_no: 1,
                            job_size: size
                        });
                    }
                  }
                  catch (err) {
                  }
                }
            }
            if (fileInputs.length) {
                setNJFlow(fileInputs);
            }
            setIsLoading(false);
        });
    };

    const loadJob = async (J) => {
        if (J.status !== window.LAST_BG_SEL_STATUS || J.id !== window.LAST_BG_SEL_ID || J.percent_complete !== window.LAST_BG_SEL_PERCENT) {
            setIsLoading(true);
            let list = [], idx = 0;
            for (let link of J.output_links) {
                idx += 1;
                try {
                    let resp = null;
                    try {
                        resp = await axios.post(
                            '/flows/api-proxy',
                            {
                                method: 'GET',
                                uri: link
                            }
                        );
                    }
                    catch (err) {
                        setIsLoading(false);
                        return;
                    }
                    const O = resp.data;
                    if (O.data.type == 'map') {
                        O.sizeStr = `${O.data.data.geoJSON.features.length} features`;
                    }
                    else if (O.data.type == 'table') {
                        O.sizeStr = `${O.data.data.header.length} columns, ${O.data.data.rows.length} rows`;
                    }
                    else if (O.data.type == 'json') {
                        let count = 0;
                        const scan = (x) => {
                            if (typeof x == 'object') {
                                for (let k in x) {
                                    scan(x[k]);
                                }
                            }
                            else {
                                count += 1;
                            }
                        }
                        O.sizeStr = `${count} values`;
                    }
                    O.id = idx;
                    list.push(O);
                }
                catch (err) {
                    console.error('Unable to load output: ' + link)
                }
            }
            let resp = null;
            try {
                resp = await axios('/flows/get-job/' + J.id, {
                    method: 'GET'
                });
            }
            catch (err) {
                setIsLoading(false);
                return;
            }
            J.stream = resp.data.stream;
            if (J.stream) {
                J.stream.percent_complete = Math.round(J.stream.complete * 100 / J.stream.total);
                list.push({
                    data: {
                        type: 'insights-map',
                        sizeStr: `${J.stream.total} buildings`,
                        id: idx+1,
                    },
                    node: {
                        data: {
                            value: {
                                name: 'Building Insights Map'
                            }    
                        }
                    }
                });
            }
            setOutputList(list);
            setSelectedOutput(list[list.length-1]);
            setIsLoading(false);
            window.LAST_BG_SEL_ID = J.id;
            window.LAST_BG_SEL_STATUS = J.status;    
            window.LAST_BG_SEL_PERCENT = J.percent_complete;
            setSelectedJob(J);
        }
    };

    const selectOutput = (O) => {
        setSelectedOutput(O);
    };

    const poll = async () => {
        let resp = await axios('/flows/get-jobs', {
            method: 'GET',
            data: {}
        });
        if (resp && resp.data && (resp.data instanceof Array)) {
            for (let J of resp.data) {
                J.output_links = JSON.parse(J.output_links || '[]');
                J.options = JSON.parse(J.options || '{}');
                J.runtime = Math.round(moment(J.updatedAt).diff(moment(J.createdAt), 'hours', true)*100)/100;
                if (J.runtime < 1) {
                    J.runtime = Math.round(moment(J.updatedAt).diff(moment(J.createdAt), 'minutes', true)*100)/100 + ' minutes';
                }
                else {
                    J.runtime += ' hours';
                }

            }
            setAllJobs(resp.data);
            for (let J of resp.data) {
                if (J.id === window.LAST_BG_SEL_ID) {
                    await loadJob(J);
                    break;
                }
            }
        }
    };

    const startBGJob = async (SO, is_stream_job, flow_dataset_id) => {
        if (!SO) {
            SO = selectedJob;
        }
        setIsLoading(true);
        let options = { ...(SO.options||{}), slicing: njFlow };
        setSelectedJob({...SO, options});
        try {
            let resp = await axios('/flows/' + (is_stream_job ? SO.function_id : SO.flow_id) + '/start-processing', {
                method: 'POST',
                data: {
                    title: SO.title,
                    options: options,
                    is_stream_job: is_stream_job || undefined,
                    flow_dataset_id: flow_dataset_id || undefined
                }
            });
            await poll();
            const J = (await axios('/flows/get-job/' + resp.data.job_id, { method: 'GET' })).data;
            await loadJob(J);
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
        setModalType(null);
    };

    const startAllJobs = async () => {

        let job_sets = [];
        for (let file of njFlow) {
            let numJobs = Math.ceil(file.size / file.job_size);
            if (numJobs > 1) {
                job_sets.push({ count: numJobs, file });
            }
        }

        if (job_sets.length > 1) {
            alert('Can only execute multi-jobs on a single input file at once.');
        }
        else if (job_sets.length < 1) {
            return await startBGJob();
        }
        else {
            setTimeout(async () => {
                let J = job_sets[0];
                let otitle = selectedJob.title;
                for (let i=1; i<=J.count; i++) {
                    J.file.job_no = i;
                    setNJFlow(njFlow);
                    selectedJob.title = `${otitle} - Job ${i} of ${J.count}`;
                    setSelectedJob(selectedJob);
                    await startBGJob(selectedJob);
                }   
            }, 50);
        }
        

    };

    const cancelJob = async () => {
        setIsLoading(true);
        try {
            let resp = await axios('/flows/cancel-processing/' + window.LAST_BG_SEL_ID, {
                method: 'POST',
                data: {
                    title: selectedJob.title,
                    options: selectedJob.options
                }
            });
            await poll();
            newBGJob();
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    const startBronzeOnboarding = async () => {
        setIsLoading(true);
        try {
            let resp = await axios('/flows/start-bronze-bucket-onboarding/' + window.LAST_BG_SEL_ID, {
                method: 'POST',
                data: {}
            });
            if (resp?.data?.job) {
                selectedJob.bronze_bucket_onboarding = resp.data.job.bronze_bucket_onboarding;
                selectedJob.bronze_bucket_last_onboarded = resp.data.job.bronze_bucket_last_onboarded;
            }
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    const deleteJob = async () => {
        setIsLoading(true);
        try {
            let resp = await axios('/flows/delete-job/' + window.LAST_BG_SEL_ID, {
                method: 'DELETE'
            });
            await poll();
            newBGJob();
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    const deleteDataset = async (flowDatasetID) => {
        setIsLoading(true);
        try {
            let resp = await axios('/flows/flow-dataset/' + flowDatasetID, {
                method: 'DELETE'
            });
            setBGSDatasetInfo(resp.data);
        }
        catch (err) {
            console.log(err);
        }
        setSelectedFlowDatasetID(null);
        setBGSDatasetInfo(null);
        loadDatasets();
        setIsLoading(false);
    };

    const checkBGSDataset = async (flowDatasetID) => {
        setIsLoading(true);
        try {
            let resp = await axios('/flows/flow-dataset/' + flowDatasetID, {
                method: 'GET'
            });
            setBGSDatasetInfo(resp.data);
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    const acceptFile = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        setIsLoading(true);
        setUploaderPercent(0);
        setUploaderError(null);
        setUploaderComplete(null);
        const token = localStorage.getItem('token');
        const uploader = new HugeUploader({
            endpoint: config.baseURLApi + '/flows/flow-dataset-upload',
            file,
            headers: {
                'Authorization': `Bearer ${token}`,
                'uploader-file-name': file.name
            },
            chunkSize: (4 * 1024 * 1024) / 1000000
        });
        uploader.on('progress', (progress) => setUploaderPercent(progress.detail));
        uploader.on('finish', (body) => { setUploaderComplete(`File "${file.name} succesfully uploaded.`); setUploader(null); loadDatasets(); setIsLoading(false); });
        uploader.on('error', (error) => { setUploaderError(`Error: ${error.detail}`); setUploader(null); setIsLoading(false); });
        setUploader(uploader);
    };

    const doDownload = (link) => {
        const a = document.createElement('a');
        a.href = link;
        a.target = "_blank";
        a.click();
    };

    useEffect(() => {
        loadFlows();
        loadFunctions();
        loadDatasets();
        poll();
        newBGJob();
        let timer = setInterval(async () => {
            poll();
        }, 10000);
        return function cleanup() {
            clearInterval(timer);
            timer = null;
            window.LAST_BG_SEL_ID = null;
            window.LAST_BG_SEL_STATUS = -1;
            window.LAST_BG_SEL_PERCENT = -1;
        }
    }, []);

    return (
        <div className={'bgj-container ' + (isLoading ? 'bgj-loading' : '')}>
            <input type="file" id="file_upload" name="file_upload" style={{display: "none"}} onChange={(e) => acceptFile(e || window.event)}></input>
            {modalType && <div className={'flow-modal-cont'} onClick={() => setModalType(null)}>
                {modalType === 'load-flow' && <div className={'flow-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
                    <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
                    <div className='fm-title-large'>{'Marketing Insights Flowcharts'}</div>
                    <div className='fm-title-2'>Saved {'Projects'}</div>
                    <div className='fm-function-library'>
                        {flowList.map((F, idx) => (<div className='fm-flow' onClick={() => {setFlowList([]); loadFlow(F.id); setModalType(null);}} key={'load-flow-' + F.id}>
                        <img src='/images/flow-file.svg'/>
                        <div className='fm-flow-right'>
                            <div className='fm-flow-title'>{F.name || 'Untitled Flow'}</div>
                            <div className='fm-flow-meta'>Created: {moment(F.createdAt).format('MMM. DD, YYYY')} &nbsp;| Modified: {moment(F.updatedAt).format('MMM. DD, YYYY')}</div>
                        </div>
                        </div>))}
                    </div>
                </div>}
                {modalType === 'export' && <div className={'flow-modal export-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
                    <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
                    <div className='fm-title-large'>Export {{'map': 'Map', 'table': 'Table', 'json': 'JSON', 'insights-map': 'Map'}[selectedOutput.data.type]} Display to filesystem.</div>
                    <div className='fm-title-2'>Filename ({{'map': '.json', 'table': '.csv', 'json': '.json', 'insights-map': '.json'}[selectedOutput.data.type]} assumed)</div>
                    <br/>
                    <Input type='text' defaultValue={exportFileName} onChange={(val) => setExportFilename(val)} />
                    <br/>
                    <div className='flow-button' onClick={() => {exportFunctions[selectedOutput.data.type](exportFileName, selectedOutput.data, selectedJob.id); setModalType(null);}}><img src='/images/save-flow-blue.svg'/> Save File</div>
                    {selectedOutput.data.type == 'insights-map' && <div className='flow-button' style={{'marginRight': '10px'}} onClick={() => {exportFunctions[selectedOutput.data.type](exportFileName, selectedOutput.data, selectedJob.id, true); setModalType(null);}}><img src='/images/save-flow-blue.svg'/> Save Centroids File</div>}
                </div>}
                {modalType === 'create-job' && <div className={'flow-modal create-job-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
                    <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
                    <div className='fm-title-large'>Start new Background Job</div>
                    <br/>
                    <div className='fm-half'>
                        <div className='fm-title'>Job Title</div>
                        <Input type='text' defaultValue={selectedJob.title} onChange={(val) => setSelectedJob({...selectedJob, title: val})} style={{width: '170px'}} /><br/>
                    </div>
                    <div className='fm-half'>
                        <div className='fm-title' style={{marginLeft: "18px"}}>Flow to Run</div>
                        <Dropdown emptyText="- Empty -" options={flowList.map((e) => ({key: e.id, title: e.name}))} onChange={(val) => {setSelectedJob({...selectedJob, flow_id: val.key}); loadFlow(`${val.key}`);}} defaultValue={null} title='' hasArrow={true} />
                    </div>
                    {njFlow && <div className='fm-title-2'>Job Slicing</div>}
                    {njFlow && <div className='fm-job-slicing'>
                        {njFlow.map((file, idx) => (
                            <div key={'job_slice_' + idx} className='bg-js-file'>
                                <div className='bg-job-title'>{file.name}</div>
                                <div className='bg-job-title'><i>Rows: {file.size}</i></div>
                                <br/>
                                <div className='bg-job-status'>Job Size (Rows)</div>
                                <Input type="number" defaultValue={file.job_size} onChange={(val) => {file.job_size = Math.max(1, Math.min(parseInt(val), file.size)); setNJFlow(njFlow);}} />
                                <br/><br/>
                                <div className='bg-job-title'><i>Num. Jobs: {Math.ceil(file.size / file.job_size)}</i></div>
                                <div className='bg-job-status'>Job No (x Job Size)</div>
                                <Input type="number" defaultValue={file.job_no} onChange={(val) => {file.job_no = Math.max(1, Math.min(parseInt(val), Math.ceil(file.size / file.job_size))); setNJFlow(njFlow);}} />
                            </div>
                        ))}
                        <br/>
                    </div>}
                    {selectedJob.flow_id && selectedJob.title && <div className='flow-button-og start-job-button' onClick={() => startBGJob()}>Start Job</div>}
                    {selectedJob.flow_id && selectedJob.title && njFlow && njFlow.length && <div className='flow-button-og start-all-jobs-button' onClick={() => startAllJobs()}>Start All Jobs</div>}
                </div>}
                {modalType === 'stream-job' && <div className={'flow-modal stream-job-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
                    <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => {
                        setModalType(null);
                        setBGSDatasetInfo(null);
                        setSelectedFlowDatasetID(null);
                        setSelectedJob({id: null, new: true, title: 'Untitled Job', output_links: [], options: {}, flow_id: null, percent_complete: 0});
                    }}/>
                    <div className='fm-title-large'>Start new Background Stream Job</div>
                    <br/>
                    <div className='fm-half'>
                        <div className='fm-title'>Job Title</div>
                        <Input type='text' defaultValue={selectedJob.title} onChange={(val) => setSelectedJob({...selectedJob, title: val})} style={{width: '170px'}} /><br/>
                    </div>
                    <div className='fm-half'>
                        <div className='fm-title' style={{marginLeft: "18px"}}>Select ETL Function</div>
                        <Dropdown emptyText="- Empty -" options={functionList.map((e) => ({key: e.id, title: e.name}))} onChange={(val) => {setSelectedJob({...selectedJob, function_id: val.key}); loadFunction(`${val.key}`);}} defaultValue={null} title='' hasArrow={true} />
                    </div>
                    <br/>
                    <div className='fm-half flow-file-upload-cont stream-upload-cont'>
                        <div className='fm-title'>Enter flow_dataset id (should represent an uploaded .CSV or .JSONL/ND file)</div>
                        <Dropdown emptyText="- Empty -" options={datasetList.map((e) => ({key: e.id, title: e.fileName}))} onChange={(val) => {setSelectedFlowDatasetID(val.key); setBGSDatasetInfo(null);}} defaultValue={null} title='' hasArrow={true} />
                        <div className='flow-button-og check-job-button' onClick={() => checkBGSDataset(selectedFlowDatasetID)}>Check Dataset</div>
                        {BGSDatasetInfo ? <div className='check-job-info'>
                            File name: <span className='value'>{BGSDatasetInfo.fileName}</span><br/>
                            File size: <span className='value'>{BGSDatasetInfo.fileSize}B ({Math.round(BGSDatasetInfo.fileSize/(1024*1024))}MB)</span><br/>
                            Row count: <span className='value'>{BGSDatasetInfo.lineCount}</span><br/>
                            Last updated: <span className='value'>{moment.utc(BGSDatasetInfo.updatedAt).format('YYYY-MM-DD HH:mm:SS')}</span><br/>
                        </div> : undefined}
                    </div>
                    <div className='flow-button-og start-job-button' onClick={() => startBGJob(undefined, true, selectedFlowDatasetID)}>Start Job</div>
                </div>}
                {modalType === 'manage-datasets' && <div className={'flow-modal manage-datasets-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
                    <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => {
                        setModalType(null);
                        setBGSDatasetInfo(null);
                        setUploader(null);
                        setUploaderComplete(null);
                        setUploaderError(null);
                        setUploaderPercent(0);
                    }}/>
                    <div className='fm-title-large'>Manage Stream Datasets</div>
                    <br/>
                    <div className='fm-half'>
                        <div className='fm-title' style={{marginLeft: "18px"}}>Select Existing Dataset to Manage</div>
                        <Dropdown emptyText="- Empty -" options={datasetList.map((e) => ({key: e.id, title: e.fileName}))} onChange={(val) => {setSelectedFlowDatasetID(val.key); setBGSDatasetInfo(null);}} defaultValue={null} title='' hasArrow={true} />
                    </div>
                    <br/>
                    {selectedFlowDatasetID && <div className='fm-half flow-file-upload-cont stream-upload-cont'>
                        <div className='fm-title'>Dataset Info</div>
                        <div className='flow-button-og check-job-button' onClick={() => checkBGSDataset(selectedFlowDatasetID)}>Check Dataset</div>
                        <div className='flow-button-og delete-dataset-button' onClick={() => deleteDataset(selectedFlowDatasetID)}>Delete Dataset</div>
                        {BGSDatasetInfo ? <div className='check-job-info'>
                            File name: <span className='value'>{BGSDatasetInfo.fileName}</span><br/>
                            File size: <span className='value'>{BGSDatasetInfo.fileSize}B ({Math.round(BGSDatasetInfo.fileSize/(1024*1024))}MB)</span><br/>
                            Row count: <span className='value'>{BGSDatasetInfo.lineCount}</span><br/>
                            Last updated: <span className='value'>{moment.utc(BGSDatasetInfo.updatedAt).format('YYYY-MM-DD HH:mm:SS')}</span><br/>
                            <a href={BGSDatasetInfo.link} target='_blank' onClick={() => doDownload(BGSDatasetInfo.link)}>Download</a>
                        </div> : undefined}
                    </div>}
                    <div className='fm-file-upload'>
                        <label>Select JSON.ND or CSV file to upload:</label>
                        <div className='flow-button-og check-job-button' onClick={() => {
                            document.getElementById('file_upload').click();
                        }}>Select File</div>
                        {uploader && <div className='fm-file-uploading'>
                            File "{uploader.file.name}" uploading ...<br/>
                            <div className='fm-uppb-cont'>
                                <div className='fm-uppb-bar' style={{width: `${uploaderPercent}%`}}>{uploaderPercent}%</div>
                            </div>
                        </div>}
                        {uploaderError && <div className='fm-file-upload-error'>
                            {uploaderError}
                        </div>}
                        {uploaderComplete && <div className='fm-file-upload-complete'>
                            {uploaderComplete}
                        </div>}
                    </div>
                </div>}
            </div>}
            <div className={'bgj-title'}>
                ETL Background Jobs
            </div>
            <div className={'bgj-header-right'}>
                <div className='flow-button-bg bgj-create' onClick={() => {newBGJob(); setModalType('create-job');}}><img src='/images/add-block.svg' /> Start new Background Job</div>
                <div className='flow-button-bg bgj-create-stream' onClick={() => {newBGJob(); loadDatasets(); setModalType('stream-job');}}><img src='/images/add-block.svg' /> Start new Background Stream Job</div>
                <div className='flow-button-bg bgj-create-stream' onClick={() => {newBGJob(); loadDatasets(); setModalType('manage-datasets');}}><img src='/images/add-block.svg' /> Upload or Manage Streamable Datasets</div>
            </div>
            <div className='bg-pane-1'>
                {/*<div style={{borderRadius: "6px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px", color: "#44d9e6", marginTop: "10px", border: "1px solid #44d9e6", cursor: "pointer", width: '170px', marginLeft: "10px"}} onClick={() => {newBGJob();}}>New Background Job</div>*/}
                {allJobs.map((job, idx)=>(
                    <div className={'bg-job-block-'+job.status + ' ' + (job.id == selectedJob.id ? 'bg-job-selected' : '')} key={'job_' + idx} onClick={() => loadJob(job)}>
                        <div className='bg-job-title'>{job.title || 'Untitled Job'}</div>
                        {job.is_stream_job ? <div className='bg-job-title bgj-is-stream'><i>Stream Job</i></div> :
                        <div className='bg-job-title'><i>{flowTitles[job.flow_id] || '-'}</i></div>}
                        <div className='bg-job-progress'>Progress: {job.percent_complete}%</div>
                        <div className='bg-job-status'>Status: {['Not Started', 'In Progress', 'Complete', 'Cancelled'][job.status || 0]}</div>
                        <div className='bg-job-status'>Date: {moment(job.createdAt).format('MMM. DD, YYYY')}</div>
                        <div className='bg-job-status'>Runtime: {job.runtime}</div>
                    </div>
                ))}
            </div>
            <div className='bg-pane-2o-wrapper'>
                <div className='bg-pane-2'>
                    {selectedJob.id && (<div className='bg-selected-job'>
                        <div className='bg-job-inline'>
                            <div className='bg-job-title'>{selectedJob.title || 'Untitled Job'}</div>
                            {selectedJob.is_stream_job ? <div className='bg-job-title bgj-is-stream'><i>Stream Job</i></div> : 
                            <div className='bg-job-title'><i>{flowTitles[selectedJob.flow_id] || '-'}</i></div>}
                        </div>
                        <div className='bg-job-inline'>
                            <div className='bg-job-progress'>Progress: {selectedJob.percent_complete}%</div>
                            <div className='bg-job-status'>Status: {['Not Started', 'In Progress', 'Complete', 'Cancelled'][selectedJob.status || 0]}</div>
                        </div>
                        <div className='bg-job-inline'>
                            <div className='bg-job-status'>Date: {moment(selectedJob.createdAt).format('MMM. DD, YYYY')}</div>
                            <div className='bg-job-status'>Runtime: {selectedJob.runtime}</div>
                        </div>
                        {selectedJob.is_stream_job ? <div className='bg-job-inline'>
                            <div className='bg-job-status'>Last Bronze Bucket Onboarding: {selectedJob.bronze_bucket_last_onboarded ? moment(selectedJob.bronze_bucket_last_onboarded).format('MMM. DD, YYYY') : 'Never'}</div>
                            <div className='bg-job-status'>Currently Onboarding to Bronze Bucket: {selectedJob.bronze_bucket_onboarding ? 'Yes' : 'No'}</div>
                        </div> : undefined}
                        {selectedJob.options.slicing && <div className='bg-job-display'>
                            {selectedJob.options.slicing.filter((e)=>(e.job_size < e.size)).length > 0 && <h2>Slicing Info:</h2>}
                            {selectedJob.options.slicing.filter((e)=>(e.job_size < e.size)).map((file, idx) => (<div key={'job_slice_' + idx} className='bg-js-file'>
                                <div className='bg-job-inline'>
                                    <div className='bg-job-title'>{file.name}</div>
                                    <div className='bg-job-title'><i>Rows: {file.size}</i></div>
                                </div>
                                <div className='bg-job-inline'>
                                    <div className='bg-job-title'>Job No: {file.job_no}</div>
                                </div>
                                <div className='bg-job-inline'>
                                <div className='bg-job-status'>Num. Jobs: {Math.ceil(file.size / file.job_size)}</div>
                                    <div className='bg-job-status'>Job Size: {file.job_size}</div>                                   
                                </div>
                            </div>))}
                        </div>}
                        {selectedJob.status !== 2 && selectedJob.status !== 3 && selectedJob.flow_id && selectedJob.title && <div className='bgj-red-button' onClick={() => cancelJob()}>Cancel Job</div>}
                        {selectedJob.status >= 2 && <div className='bgj-red-button' onClick={() => deleteJob()}>Delete Job</div>}
                    </div>)}
                </div>
                {selectedJob && selectedJob.id && <div className='bg-output-pane'>
                    {selectedJob.status === 2 && (<div className='flow-output-header'>
                        <div className='flow-output-title'>OUTPUT</div>
                        {outputList.map((O, jdx) => (<div className={'flow-output-vd-title ' + (selectedOutput && selectedOutput.id === O.id ? 'flow-output-selected' : '')} key={'bg-output-item-' + jdx} onClick={() => selectOutput(O)} title={O.node.data.value.name || ({'map': 'Map', 'table': 'Table', 'json': 'JSON'}[O.data.type] + ' Display')}>
                            {O.node.data.value.name || ({'map': 'Map', 'table': 'Table', 'json': 'JSON'}[O.data.type] + ' Display')}
                        </div>))}
                        {selectedOutput && !!selectedOutput.data && <div className='flow-output-export-button' onClick={() => {setExportFilename('untitled-export'); setModalType('export');}}><img src='/images/flow-export.svg'/> Export Output</div>}
                        {selectedJob.is_stream_job ? <div className='flow-stream-output'>
                            <div>ETL Function Name: <i>{functionList.filter((e) => e.id == selectedJob.stream_function_id)[0].name}</i></div>
                            <div>Download output & error files:</div>
                            <a href={selectedJob.output_download_link} target="_blank">Output JSON.ND file</a>
                            <a href={selectedJob.errors_download_link} target="_blank">Errors JSON.ND file</a>
                            {selectedJob.is_stream_job && !selectedJob.bronze_bucket_onboarding ? <a onClick={(e) => {
                                (e || window.event).preventDefault();
                                (e || window.event).stopPropagation();
                                startBronzeOnboarding();
                                return false;
                            }}>Start Bronze Bucket Onboarding</a> : undefined}
                        </div> : undefined}
                    </div>)}
                    <div className='flow-output-body'>
                        {(!!selectedOutput && !!selectedOutput.data && (selectedOutput.data.type === 'table')) && <div className='view-data-table'>
                            <DataTable data={selectedOutput.data} />
                        </div>}
                        {(!!selectedOutput && !!selectedOutput.data && (selectedOutput.data.type === 'insights-map')) && <InsightsMap job_id={selectedJob.id} key={"im-" + selectedJob.id}/>}
                        {(!!selectedOutput && !!selectedOutput.data && (selectedOutput.data.type === 'map')) && <Map data={selectedOutput.data} />}
                        {(!!selectedOutput && !!selectedOutput.data && (selectedOutput.data.type === 'json')) && <div className='view-data-json'>
                            <JSONTree data={selectedOutput.data.data} theme={{ extend: {
                                base00: 'transparent',
                                base01: '#383830',
                                base02: '#49483e',
                                base03: '#75715e',
                                base04: '#a59f85',
                                base05: '#f8f8f2',
                                base06: '#f5f4f1',
                                base07: '#f9f8f5',
                                base08: '#f92672',
                                base09: '#fd971f',
                                base0A: '#f4bf75',
                                base0B: '#a6e22e',
                                base0C: '#a1efe4',
                                base0D: '#66d9ef',
                                base0E: '#ae81ff',
                                base0F: '#cc6633'
                            }}} invertTheme={false} />
                        </div>}
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default withRouter(GE360BackgroundJobs);
