import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

import { toTableFormat } from './helpers';

export default memo(({ data }) => {

  const table = data.table || { rows: [], header: [] };
  table.rows = table.rows || [];

  const ellipsis = { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };

  const hiddenChecks = (data.value && data.value.hidden) || {};
  const onCheck = (title) => {
    const value = data.value || {};
    value.hidden = value.hidden || {};
    value.hidden[title] = !value.hidden[title];
    data.onChange(value);
  };

  const typeOverrides = (data.value && data.value.types) || {};
  const onType = (event, title) => {
    event = event || window.event;
    const value = data.value || {};
    value.types = value.types || {};
    value.types[title] = event.target.value;
    data.onChange(value);
  };

  const onViewData = () => {
    const value = data.value || {};
    value.viewData = new Date().getTime();
    data.onChange(value);
  };

  return (
    <div style={{width: "400px", height: "330px", border: "1px solid #44D9E6", borderRadius: "8px", background: "rgba(0, 0, 0, 0.2)"}}>
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ background: '#DDDDDD', border: '3px solid #44D9E6', width: '15px', height: '15px', marginLeft: '-4px' }}
      />
      <div style={{marginLeft: "10px", color: "#DDDDDD", marginTop: "5px", marginBottom: "9px"}}>
        Table Output ({table.rows.length} rows X {table.header.length} cols.{table.truncated ? ' - Columns truncated.' : ''}):
        <div style={{borderRadius: "6px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px", color: "#44d9e6", marginTop: "-3px", border: "1px solid #44d9e6", float: "right", cursor: "pointer", marginRight: "8px"}} onClick={() => {onViewData()}}>View Data</div>
      </div>
      <div style={{marginLeft: "10px", width: "380px", height: "280px", overflowX: "hidden", overflowY: "scroll"}}>
        {table.header.map((title) => (
          <div key={'th'+title} style={{width: "380px", color: "#DDD", background: 'rgba(0, 0, 0, 0.4)', padding: "4px", marginTop: "2px"}}>
            <div style={{width: "150px", ...ellipsis, marginRight: "5px", display: 'inline-block'}} title={title}>{title}</div>
            Hide: <input type="checkbox" value={"check_" + title} defaultChecked={!!hiddenChecks[title]} style={{width: "20px", marginRight: "15px"}} onChange={() => {onCheck(title)}} />
            Type: <select defaultValue={typeOverrides[title] || 'auto'} onChange={(event) => {onType(event, title)}} style={{width: "65px"}}>
              <option value="auto">Auto</option>
              <option value="string">String</option>
              <option value="int">Integer</option>
              <option value="dec3">Decimal 3</option>
              <option value="dec6">Decimal 6</option>
            </select>
          </div>
        ))}
      </div>
    </div>
  );
});
