import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';

import '../Common.scss';

class CompletenessSlider extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
  }
  render() {
    return (
      <div className={'completeness-slider-' + this.props.size} key={this.key}>
        <div className='slider-outer'>
          <div className='slider-inner' style={{width: `calc(${this.props.percent}% - 14px)`}}></div>
        </div>
        <div className='slider-percent'>{Math.round(this.props.percent)}%</div>
      </div>
     );
  }
}

export default withRouter(connect(() => ({}))(CompletenessSlider));
