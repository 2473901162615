import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerDetailsCard from './components/CustomerDetailsCard';
import { getENCustomers } from '../../actions/energy-navigator';
import { getBuildingsRequest } from '../../actions/buildings';
import { push } from 'connected-react-router';
import ENBuildings from '../enBuildings';
import { RandomKey } from '../../components/Common';

import './ENCustomerDetails.scss';

class EnergyCustomerDetails extends Component {
  static propTypes = {
    customer: PropTypes.object,
  };

  static defaultProps = {
    customer: {
      buildingId: '',
      name: "",
      building_address: '',
      projects_new: 1,
      projects_ongoing: 1,
      projects_completed: 1,
      image_uri: ""
    }
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
    this.buildingsLoaded = false;
  }

  componentDidMount() {
    const customerId = this.props.match.params.customerId;
    if (customerId) {
      this.props.dispatch(getBuildingsRequest());
      this.props.dispatch(getENCustomers());
    }
    setTimeout(() => {
      this.showPromoAlert();
    }, 100);
  }

  showPromoAlert() {
    //this.setState({ promoAlert: true });
  }

  render() {
    const customerId = this.props.match.params.customerId;
    let customer = null;
    for (let i=0; this.props.customers && i<this.props.customers.length; i++) {
      if (this.props.customers[i].id === customerId) {
        customer = this.props.customers[i];
        break;
      }
    }
    if (!customer || (customer.buildings.length && customer.buildings[0] === undefined)) {
      return (<div/>);
    }
    let buildingLookup = {};
    for (let B of this.props.buildings) {
      buildingLookup[B.id] = B;
      this.buildingsLoaded = true;
    }
    if (!this.buildingsLoaded) {
      return (<div/>);
    }
    for (let i=0; i<customer.buildings.length; i++) {
      let B = customer.buildings[i];
      B = buildingLookup[B.id];
      customer.buildings[i] = B;
    }
    return (
      <div className='customer-details-page-container'>
        <div className='back-button' onClick={() => this.props.dispatch(push('/app/main/en-customers'))}>
          <img src="/images/back-arrow.svg" /> Back
        </div>
        <div className='customer-details-container'>
          <CustomerDetailsCard data={customer} />
        </div>
        <ENBuildings customerId={customerId} buildings={customer.buildings}></ENBuildings>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.energyNavigator.allENCustomers,
    buildings: state.buildings.data || []
  };
}

export default connect(mapStateToProps)(EnergyCustomerDetails);
