import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, RandomKey } from '../../../components/Common'
import './CustomerCard.scss';

class CustomerCard extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    const goals = data.goals || [];//|| ['Reduce Avg. Building’s Operational Costs by 25% by 2025', 'Net-Zero GHG Impact by 2050', 'Test 3', 'Test 4'];

    let numInsights = 0;
    let numOffers = 0;
    let insightsLoaded = false;

    for (let B of data.buildings) {
      if (B && B.insights_and_offers) {
        insightsLoaded = true;
        for (let I of B.insights_and_offers) {
          if (I.type === 'insight') {
            numInsights += 1;
          }
          else {
            numOffers += 1;
          }
        }
      }
    }

    return (
      <div key={this.key} className='customer-card'>
        <div className='customer-logo-cont' onClick={() => this.props.dispatch(push('/app/main/customer-details/' + data.id))}>
          <img src={data.company_logo} className='customer-logo'/>
        </div>
        <div className='customer-name'>{data.name}</div>
        <div className='customer-goals-title' style={goals.length ? {} : {opacity: '0'}}>Goals & Objectives</div>
        {<div className='customer-goal' title={goals[0]} style={goals.length>=1 ? {} : {opacity: '0'}}><div className='cg-bullet'></div>{goals[0]}</div>}
        {<div className='customer-goal' title={goals[1]} style={goals.length>=2 ? {} : {opacity: '0'}}><div className='cg-bullet'></div>{goals[1]}</div>}
        {!goals[3] && <div className='customer-goal' title={goals[2]} style={goals.length>=3 ? {} : {opacity: '0'}}><div className='cg-bullet'></div>{goals[2]}</div>}
        {goals[3] && <div className='customer-goal link' style={goals.length>=4 ? {} : {opacity: '0'}} onClick={() => this.props.dispatch(push('/app/main/customer-details/' + data.id))}><div className='cg-bullet hidden'></div>{goals.length-2} More</div>}
        {/*<div className='bc-sep'></div>
        <div>
          <div className="customer-offer-count">
            <div>Buildings: {data.buildings.length}</div>
            {insightsLoaded ? <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div> : ""}
            {insightsLoaded && <div className='co-num-insight'>Insights: {numInsights}</div>}
            {insightsLoaded ? <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div> : ""}
            {insightsLoaded && <div className='co-num-offer'>Offers: {numOffers}</div>}
          </div>
    </div>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(CustomerCard);
