import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { loginUser } from '../../actions/auth';
import { toast } from 'react-toastify';
import config from '../../config';
import axios from 'axios';
import "./EAAcceptInvitePage.scss";

class EAAcceptInvitePage extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	state = {
        error: false
	};

	constructor() {
		super();
	}

    async acceptInvite(token) {
        try {
            let resp = await axios.post('/v3/users/invite/acknowledge', {token});
            if (resp.status === 200) {
                return resp.data;
            }
            return false;
        }
        catch (err) {
            console.error('EAAcceptInvitePage: Error sending invite');
            return false;
        }
    }

	componentDidMount() {
        if (window.location.href.indexOf('?token=') > 0) {
            (async () => {
                this.setState({ b2cLoginWait: true });

                let token = window.location.href.split('?token=')[1];
                let res;
                if (res = await this.acceptInvite(token)) {
                    let tok = `${window.location.href}`.split("#")[0].split('/');
                    tok.length = Math.min(tok.length, 3);
                    const redirect_uri = tok.join('/');
                    const url = `https://${config.b2cDomain}/climativetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_EA_SIGNUP&client_id=c37bab30-9b29-41a7-972b-e09ec1a0e7e6&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=openid&response_type=id_token&prompt=login&roleName=${res.role === 1 ? 'ra' : 'so'}&orgId=${encodeURIComponent(res.organizationId)}`;
                    window.location.href = url;
                }
                else {
                    window.location.href = '#/login';
                }
            })();
        }
	}

	componentWillUnmount() {
	}

	render() {
        document.body.style.background = 'rgb(31, 38, 49)';
		return (
			<div className="ea-accept-invite-page">
                <div className={'loading ' + (this.state.b2cLoginWait ? 'loading-show' : 'loading-hide')}><img alt={"Loading Animation"} src='/images/loading-transparent.gif'/><div className="loading-claiming-msg">{"Waiting for verification ..."}</div></div>
                {this.state.error ? <h1>Error accepting invite</h1> : undefined}
			</div >
		);
	}
}


function mapStateToProps(state) {
	return {
	};
}
export default withRouter(connect(mapStateToProps)(EAAcceptInvitePage));
