import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBuildingsRequest, getBuildingRequest } from '../../actions/buildings';
import { createENProject, editENProject } from '../../actions/energy-navigator';
import { getENInsights, getENInsightsByBuildingId } from '../../actions/energy-navigator';
import {Format, RandomKey, Dropdown, Input, MinMaxInput, TabBuilder, RichTextEditor, RichTextViewer, AttachmentUploader, ConfirmButton } from '../Common';
import BuildingDetailsCard from '../../pages/enBuildingDetails/components/BuildingDetailsCard';

import './CreateOrgModal.scss';

class CreateOrgModal extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      model: {
        ...(props.edit || {})
      },
      errors: {}
    };

  }

  componentDidUpdate(prevState, prevProps) {
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.props.dispatch(getBuildingsRequest());
  }

  onChange(key) {
    return (val, v2) => {
      let model = this.state.model;
      if (typeof v2 === typeof "" && !v2) {
        val = null;
      }
      model[key] = val;
      this.setState({ model });
      if (this.state.errors[key]) {
        this.checkErrors(key);
      }
    };
  }

  checkErrors(only) {
    let model = this.state.model;

    let error = false;

    let errors = {...(this.state.errors)};
    let doError = (field, flag, msg) => {
      if (only && field !== only) {
        return false;
      }
      errors[field] = flag ? (msg || 'This field is required') : null;
      if (flag) {
        error = true;
      }
      return flag;
    };

    doError('first_name', !model.first_name);
    doError('last_name', !model.last_name);
    doError('phone_number', !model.phone_number);
    doError('email', !model.email);
    if (!this.props.edit) {
      doError('new_password', !model.new_password);
    }

    this.setState({ errors: errors });

    return error;

  }

  saveUser() {

    if (this.checkErrors()) {
      return;
    }

    let model = this.state.model;

    let obj = {
      active: 1
    };

    const after = () => {
      /*this.props.dispatch(getENInsights()).then(() => {
        this.setState({ loading: false });
        this.props.onClose();
      });*/
    };

    if (this.props.edit && model.user_id) {
      obj.user_id = model.user_id;
      //this.props.dispatch(editENProject(obj)).then(after);
    }
    else {
      //this.props.dispatch(createENProject(obj)).then(after);
    }

    this.setState({ loading: true });

  }

  render() {

    const fmtGJ = Format.GJ,
    fmtKG = Format.KG,
    fmtPercent = Format.Percent,
    fmtDollars = Format.Dollars,
    fmtDollarsShort = Format.DollarsShort,
    fmtSQFT = Format.SQFT;

    const space = (w) => (<div style={{width: `${w}px`, height: "1px", display: "inline-block"}} />);

    return (
      <div key={this.key} className={'create-org-modal ' + (this.state.loading ? 'loading' : '')}>
        <div className='create-org-modal-header'>
          <div className='create-org-modal-title'>{this.props.edit ? 'Edit' : 'Create'} Organization</div>
          <div className='create-org-modal-header-right'>
            {/*<div className='en-button-secondary'>Save</div>*/}
            {/*<div className='en-button-secondary'>Preview {thisName}</div>*/}
            <ConfirmButton icon='/images/x-icon.svg' msgBottom={true} wrapperClassName="modal-close" className="en-button-transparent" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
          </div>
        </div>
        <div className='create-org-modal-form-cont'>
          <div className='create-org-modal-form'>
            <div className='create-um-sub-title'>Basic Information</div>
          </div>
        </div>
        <div className='create-org-modal-submit-buttons'>
          <div className='en-button-primary' onClick={() => {this.saveUser();}}>Save </div>
          <ConfirmButton title="Cancel" className="en-button-secondary" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.buildings.data,
    customer: state.buildings.curBuilding.building,
    currentUser: state.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(CreateOrgModal));
