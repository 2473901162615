import { getBuildingRequest, getBuildingRequestLM, updateBuildingRequest, updateCustomerRequest, resetBuildingType1Request, downloadReportRequest, getReportBase64, publishReportRequest, publishAndDownloadReportRequest } from '../../actions/buildings';
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PDFViewer from 'pdf-viewer-reactjs'
import moment from 'moment';
import { StreetView } from 'react-google-map-street-view';
import HPDetails from './components/heatpump-details/HPDetails';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,

} from 'reactstrap';
import './RAAnalysis.scss';
import s from './RAAnalysisMetro.module.scss';
import Select from 'react-select';
import PreUpgradePanel from './components/preupgradepanel/PreUpgradePanel'
import PostUpgradePanel from './components/postupgradepanel/PostUpgradePanel'
import UpgradeScenariosPanel from './components/upgradescenariospanel/UpgradeScenariosPanel'
import ReportInfoPanel from './components/reportinfopanel/ReportInfoPanel'
import WizardHeader from './components/wizard-header/WizardHeader';
import EstArea from './components/est-area/EstArea';
import SimpToggle from './components/toggle/Toggle';

import AuditStatus from './AuditStatus';

import { listsData } from "./selectLists"
import RValueWidget from "./components/postupgradepanel/RValueWidget";

const findOption = listsData.findOption;
const { STATUSES, STATUS_TITLES, SUB_STATUS_TITLES, SUB_STATUS_ICONS, statusEqual, statusLt, statusGt, statusLtEqual, statusGtEqual } = AuditStatus({ advisorExperience: true });

const WrappedPDF = React.memo(function WrappedPDF(args) {
  if (!args || !args.b64) {
    return (<div className='report-pdf-viewer-cont'><div className='rpdf-loading'>Loading</div></div>);
  }
  
  return (
    <PDFViewer
      document={{
        base64: args.b64,
      }}
      css={'report-pdf-viewer-cont'}
      hideRotation={true}
      minZoom={0.15}
      key={"report-pdf-" + Math.random()}
      onZoom={() => {}}
      navigation={{
        css: {
          navbarWrapper: 'rpdfv-navbarWrapper',
          zoomOutBtn: 'rpdfv-zoomOutBtn',
          resetZoomBtn: 'rpdfv-zoomResetBtn',
          zoomInBtn: 'rpdfv-zoomInBtn',
          pageIndicator: 'rpdfv-pageIndicator',
          nextPageBtn: 'rpdfv-nextPageBtn',
          previousPageBtn: 'rpdfv-previousPageBtn'
        }
      }}
    />
  );
});

window.DownloadModelCSV = (P, isPre, isT2Debug, isType1) => {

  const macros = {
      '{{id}}': [ 'P.id', 'P.building.fsa' ],
      '{{r_squared}}': [ 'P.r_squared', 'P.r_squared_cross_val' ],
      '{{coef}}': [ 'P.coef_hdd', 'P.coef_cdd', 'P.coef_days' ],
      '{{bills}}': [ 'P.bills' ],
      '{{150warnings}}': [ 'P.building.warning_billing_data_older_than_5_months' ],
      '{{base_temp}}': [ 'P.base_temp_hdd || P.base_temp_c', 'P.base_temp_cdd || P.base_temp_c' ],
      '{{breakdown}}': [ 'P.building.yearly_energy_breakdown.heating', 'P.building.yearly_energy_breakdown.cooling', 'P.building.yearly_energy_breakdown.hot_water', 'P.building.yearly_energy_breakdown.lights_appliances' ],
      '{{annual}}': [ 'P.building.annual_energy_usage_gj', 'P.building.annual_energy_use_intensity_gj_per_sqm', 'P.building.avg_yearly_hdds', 'P.building.avg_yearly_cdds' ],
      '{{recommendations}}': [ "P.building.recommendations.recommend_upgrade_wall_insulation", "P.building.recommendations.impact_upgrade_wall_insulation_gj_year", "P.building.recommendations.recommend_upgrade_foundation_insulation", "P.building.recommendations.impact_upgrade_foundation_insulation_gj_year", "P.building.recommendations.recommend_upgrade_ceiling_insulation", "P.building.recommendations.impact_upgrade_ceiling_insulation_gj_year", "P.building.recommendations.recommend_upgrade_window_door_insulation", "P.building.recommendations.impact_upgrade_window_door_insulation_gj_year", "P.building.recommendations.recommend_upgrade_heatpump", "P.building.recommendations.impact_upgrade_heatpump_gj_year" ],
  //    '{{recommendation_impacts_raw}}': [ "P.building.recommendations.impact_upgrade_wall_insulation_gj_year_raw", "P.building.recommendations.impact_upgrade_foundation_insulation_gj_year_raw", "P.building.recommendations.impact_upgrade_ceiling_insulation_gj_year_raw", "P.building.recommendations.impact_upgrade_window_door_insulation_gj_year_raw", "P.building.recommendations.impact_upgrade_heatpump_gj_year_raw" ],
      '{{heatloss}}': [ "P.building.seasonal_heating_walls_btu", "P.building.seasonal_heating_foundation_btu", "P.building.seasonal_heating_window_btu", "P.building.seasonal_heating_ceiling_btu", "P.building.walls_heatloss_gj_year", "P.building.foundation_heatloss_gj_year", "P.building.window_heatloss_gj_year", "P.building.ceiling_heatloss_gj_year", "P.building.seasonal_heating_btu", "P.building.seasonal_cooling_btu", "P.building.avg_heating_r_value", "P.building.avg_cooling_r_value", "P.building.avg_yearly_base_usage", "P.building.avg_yearly_heating_usage", "P.building.avg_yearly_cooling_usage", "P.building.avg_daily_base_usage", "P.building.avg_daily_heating_usage", "P.building.avg_daily_cooling_usage" ],
      '{{ml_heatloss}}': [ "P.building.ml_heatloss.EGHHLWalls_gj_year", "P.building.ml_heatloss.EGHHLFound_gj_year", "P.building.ml_heatloss.EGHHLCeiling_gj_year", "P.building.ml_heatloss.EGHHLWinDoor_gj_year", "P.building.ml_heatloss.EGHHLTotal_gj_year", "P.building.ml_heatloss.EGHHLAir_gj_year" ],
      '{{raw_breakdown}}': [ 'P.building.raw_breakdown.heating', 'P.building.raw_breakdown.cooling', 'P.building.raw_breakdown.base', 'P.building.yearly_energy_breakdown.raw_hot_water', 'P.building.yearly_energy_breakdown.invalid_baseload' ],
      '{{ml_breakdown}}': [ 'P.building.ml_breakdown.total_kwh', 'P.building.ml_breakdown.heating', 'P.building.ml_breakdown.cooling', 'P.building.ml_breakdown.hot_water', 'P.building.ml_breakdown.lights_appliances', 'P.building.ml_breakdown.invalid_baseload' ],
      '{{r_squared_more}}': [ 'P.r_squared_adjusted', 'P.multiple_r' ],
      '{{p_values}}': [ 'P.pvalue_hdd', 'P.pvalue_cdd', 'P.pvalue_days' ],
      '{{totals}}': [ 'P.total_hdd', 'P.total_cdd', 'P.total_days' ],
      '{{sqft}}': [ 'P.building.floors', 'P.building.avg_floor_area_sqft', 'P.building.walls_area_sqft', 'P.building.window_area_sqft', 'P.building.foundation_area_sqft', 'P.building.ceiling_area_sqft', 'P.building.total_area_sqft' ],
      '{{year_built}}': [ 'P.building.year_built' ],
      '{{correction_factor}}': [ 'P.building.correction_factor.EGHHEATFconsE_MJ', 'P.building.correction_factor.UGRHEATFconsE_MJ', 'P.building.correction_factor.factor', 'P.building.heatloss_correction_factor', 'P.building.heatpump_correction_factor' ]
  };

  let _abb = [];
  for (let key in macros) {
      if (key !== '{{bills}}') {
          _abb = [..._abb, ...(macros[key])];
      }
  }
  let all = macros['{{all_but_bills}}'] = _abb;

  if (isT2Debug) {
    let U = {};
    let fn = (O, pre) => {
      if (typeof O === 'object') {
        for (let key in O) {
          fn(O[key], isT2Debug ? `${pre}["${key}"]` : `${pre}.${key}`);
        }
      }
      else {
        U[pre] = true;
      }
    };
    fn(P, 'P');
    all = [];
    for (let key in U) {
      all.push(key);
    }
  }

  let rows = [];
  let header = [];
  for (let i=0; i<all.length; i++) {
    let k = `${all[i]}`.replaceAll("[\"", ".").replaceAll("\"]", "").replaceAll("\"", "'").replace(/\s+/g, ' ').replace(/\n/g, '');
    k = `"${k}"`;
    header.push(k);
  }
  rows.push(header.join(","));
  let row = [];
  for (let i=0; i<all.length; i++) {
    let val = null;
    try {
      val = eval(all[i]);
    }
    catch (err) {
      val = null;
    }
    if (typeof val === 'string') {
      row.push(JSON.stringify(val));
    }
    else if (typeof val === 'number') {
      if (val === Math.floor(val)) {
        row.push(`${val}`);
      }
      else {
        row.push(`${(val).toFixed(3)}`);
      }
    }
    else if (typeof val === 'boolean') {
      if (val) {
        row.push(`TRUE`);
      }
      else {
        row.push(`FALSE`);
      }
    }
    else {
      row.push(``);
    }
  }
  rows.push(row.join(','));

  let csvStr = rows.join('\n');

  let blob = new Blob([csvStr], {type: "data:application/octet-stream;base64"});
  let url  = window.URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.setAttribute("href", url);
  if (isT2Debug) {
    link.setAttribute("download", `${P.id}-type-2-model-debug-${isPre ? 'pre-upgrade' : 'post-upgrade'}.csv`);
  }
  else if (isType1) {
    link.setAttribute("download", `${P.id}-type-1-model.csv`);
  } else {
    link.setAttribute("download", `${P.id}-model-${isPre ? 'pre-upgrade' : 'post-upgrade'}.csv`);
  }
  link.style.display = 'none';
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);

};

class RAEcharts extends PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line
    const buildingInputs = RAEcharts.defaultProps.building;
    this.getSubmitData = this.getSubmitData.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.toggleResetMessage = this.toggleResetMessage.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.handleDownloadReport = this.handleDownloadReport.bind(this);
    this.handleDownloadReportPublished = this.handleDownloadReportPublished.bind(this);
    this.handlePublishReport = this.handlePublishReport.bind(this);
    this.goto3rdStep = this.goto3rdStep.bind(this);
    this.toggleUEdit = this.toggleUEdit.bind(this);
    this.saveUEdit = this.saveUEdit.bind(this);
    this.onChangeUEdit = this.onChangeUEdit.bind(this);
    this.mainRef = React.createRef();
    //Type One Inputs
    this.yearBuilt = React.createRef();
    this.preUpgradeHeatingCOP = React.createRef();
    this.preUpgradeHeatingHPCAP = React.createRef();
    this.totalNumberOfInstalledWindows = React.createRef();
    this.totalNumberOfInstalledEStarWindows = React.createRef();
    this.totalNumberOfInstalledDoors = React.createRef();
    this.totalNumberOfInstalledEStarDoors = React.createRef();
    this.service_org_file_num = React.createRef();
    this.utility_service_provider = React.createRef();
    this.utility_account_num = React.createRef();

    this.setBaseModelTab = (tabNo) => {
      return () => {
        this.setState({baseModelTab: tabNo});
      };
    };

    /*this.estimateAreaValuesClick = () => {
      //this.props.building.basementFloorArea =
      //this.props.building.footprint =
      this.props.building.foundationWallArea =
      //this.props.building.crawlSpaceFloorArea =
      this.props.building.wallsArea =
      this.props.building.ceilingArea = 0;
      this.estimateAreaValues();
    };*/

    this.changeTOs = {};

    this.footprint = React.createRef();
    this.floorArea = React.createRef();
    this.wallsArea = React.createRef();
    this.ceilingArea = React.createRef();
    this.basementFloorArea = React.createRef();
    this.crawlSpaceFloorArea = React.createRef();
    this.foundationWallArea = React.createRef();
    this.state = {
      baseModelTab: 1,
      InputsValidated: 0,
      tab: 0,
      heatPumpSourceSupply: listsData.list.heatPumpSourceSupply,
      primaryHeatingTypes: listsData.list.primaryHeatingTypes,
      typesOfHouses: listsData.list.typesOfHouses,
      primaryHotWaterEqType: listsData.list.primaryHotWaterEqType,
      primaryHotWaterEqFuelType: listsData.list.primaryHotWaterEqFuelType,
      houseStorys: listsData.list.houseStorys,
      houseTotOccupants: listsData.list.houseTotOccupants,
      ventilationTypes: listsData.list.ventalationTypes,
      ponyWalls: listsData.list.ponyWalls,
      secondaryDomesticHotWaterTypes: listsData.list.secondaryDomesticHotWaterTypes,
      tempOfBasement: listsData.list.tempOfBasement,
      tempOfMainFloor: listsData.list.tempOfMainFloor,
      firePlaceDamper: listsData.list.firePlaceDamper,
      typeOfACSystem: listsData.list.typeOfACSystem,
      suplimentaryHeating1: listsData.list.suplimentaryHeating1,
      suplimentaryHeating1Fuel: listsData.list.suplimentaryHeating1Fuel,
      suplimentaryHeating2: listsData.list.suplimentaryHeating2,
      suplimentaryHeating2Fuel: listsData.list.suplimentaryHeating2Fuel,
      utilityServiceProviderOptions: listsData.list.utilityServiceProviderOptions,
      errorWalls: false,
      errorCeiling: false,
      errorFoundation: false,
      isLoadingFirst: true
    }

    this.wheelHandler = (e) => {
      e = e || window.event;
      if (e.target?.tagName === 'INPUT') {
        e.preventDefault();
        return false;
      }
    };
  }
  static propTypes = {
    building: PropTypes.object,
    isUpdating: PropTypes.bool
  };
  static defaultProps = {
    building: {
      yearBuilt: '',
      preUpgradeHeatingCOP: 1.0,
      preUpgradeHeatingHPCAP: 0.0,
      totalNumberOfInstalledWindows:'',
      totalNumberOfInstalledDoors:'',
      totalNumberOfInstalledEStarWindows:'',
      totalNumberOfInstalledEStarDoors:'',
      floorArea: '',
      footprint: '',
      typeOfHouse: '',
      stories: '',
      basementFloorArea: ''
    }
  };

  toggleResetMessage(e) {
    this.setState({ showResetMessage: !this.state.showResetMessage });
  }

  handleChange(e, parent) {
    parent = parent || e.target;

    const delayIfNeeded = (name, cbk) => {
      if (window.event instanceof InputEvent) {
        if (this.changeTOs[name] !== undefined) {
          window.clearTimeout(this.changeTOs[name]);
        }
        this.changeTOs[name] = setTimeout(() => {
          this.changeTOs[name] = undefined;
          cbk();
        }, 750);
      }
      else {
        if (this.changeTOs[name] !== undefined) {
          window.clearTimeout(this.changeTOs[name]);
          this.changeTOs[name] = undefined;
        }
        cbk();
      }
    };

    window._RA_MADE_CHANGES = true;

    parent = { name: parent.name, value: parent.value };

    switch (parent.name) {
      case "heatPumpSourceOfSupply":
        this.setState({ heatPumpSourceOfSupply: e.value });
        var heatPumpSourceSupplyItem = listsData.list.heatPumpSourceSupply.filter(function (x) { return x.value === e.value; });
        this.props.building.heatPumpSourceSupplySelected = heatPumpSourceSupplyItem;
        if (e.value === 'air') {
          this.setState({ typeACSystem: 'mini-split ductless' });
          var typeOfACSystemItem = listsData.list.typeOfACSystem.filter(function (x) { return x.value === 'mini-split ductless'; });
          this.props.building.typeOfACSystemSelected = typeOfACSystemItem;
        }
        if (!this.state.preUpgradeHeatingCOP_val) {
          this.setState({ errorPreUpgradeHeatingCOP: true });
        }
        if (!this.state.preUpgradeHeatingHPCAP_val) {
          this.setState({ errorPreUpgradeHeatingHPCAP: true });
        }
        this.setState({ usingHeatPump: (e.value === 'air' || e.value === 'ground') });
        if (!(e.value === 'air' || e.value === 'ground')) {
          this.setState({ preUpgradeHeatingCOP_val: null, preUpgradeHeatingHPCAP_val: null });
          document.getElementById('current-hspf').value = '';
          document.getElementById('current-hpcap').value = '';
        }
        break;
      case "primaryHeatingTypes":
        this.setState({ primaryHeatingType: e.value });
        var primaryHeatingTypesItem = listsData.list.primaryHeatingTypes.filter(function (x) { return x.value === e.value; });
        this.props.building.primaryHeatingTypeSelected = primaryHeatingTypesItem;
        break;
      case "utility-service-provider":
        this.setState({ utility_service_provider: e.value });
        this.props.building.utility_service_provider = e.value;
        break;
      case "utility-account-number":
        delayIfNeeded(parent.name, () => {
          this.setState({ utility_account_num: parent.value });
          this.props.building.utility_account_num = parent.value;
        });
        break;
      case "service-org-file-number":
        delayIfNeeded(parent.name, () => {
          this.setState({ service_org_file_num: parent.value });
          this.props.building.service_org_file_num = parent.value;
        });  
        break;
      case "typeOfHouseSelected":
        this.setState({ typeOfHouse: e.value });
        var typesOfHousesItem = listsData.list.typesOfHouses.filter(function (x) { return x.value === e.value; });
        this.props.building.typeOfHouseSelected = typesOfHousesItem;
        break;
      case "primaryHotWaterEquipmentType":
        this.setState({ primaryHotWaterEquipmentType: e.value });
        var primaryHotWaterEqTypeItem = listsData.list.primaryHotWaterEqType.filter(function (x) { return x.value === e.value; });
        this.props.building.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqTypeItem;
        break;
      case "primaryHotWaterEquipmentFuelType":
        this.setState({ primaryHotWaterEquipmentFuelType: e.value });
        var primaryHotWaterEqFuelTypeItem = listsData.list.primaryHotWaterEqFuelType.filter(function (x) { return x.value === e.value; });
        this.props.building.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelTypeItem;
        break;
      case "storeys":
        this.setState({ storeys: e.value });
        var houseStorysItem = listsData.list.houseStorys.filter(function (x) { return x.value === e.value; });
        this.props.building.storiesSelected = houseStorysItem;
        this.props.building.stories = (houseStorysItem && houseStorysItem[0] ? parseFloat(houseStorysItem[0].value) : 0) || 0;
        //this.estimateAreaValues();
        break;
      case "totalOccupants":
        this.setState({ totalOccupants: e.value });
        var houseTotOccupantsItem = listsData.list.houseTotOccupants.filter(function (x) { return x.value === e.value; });
        this.props.building.totalOccupantsSelected = houseTotOccupantsItem;
        break;
      case "ventilationTypeInstalled":
        this.setState({ ventilationTypeInstalled: e.value });
        var ventilationTypeItem = listsData.list.ventilationTypes.filter(function (x) { return x.value === e.value; });
        this.props.building.ventilationTypeSelected = ventilationTypeItem;
        break;
      case "ponyWalls":
        this.setState({ presenceOfPonyWalls: e.value });
        var ponyWallsItem = listsData.list.ponyWalls.filter(function (x) { return x.value === e.value; });
        this.props.building.presenceOfPonyWallsSelected = ponyWallsItem;
        break;
      case "secondaryDomesticHotWaterType":
        this.setState({ secondaryDomesticHotWaterType: e.value });
        var secondaryDomesticHotWaterTypesItem = listsData.list.secondaryDomesticHotWaterTypes.filter(function (x) { return x.value === e.value; });
        this.props.building.secondaryDomesticHotWaterTypeSelected = secondaryDomesticHotWaterTypesItem;
        break;
      case "firePlaceDamper":
        this.setState({ fireplaceDamp: e.value });
        var firePlaceDamperItem = listsData.list.firePlaceDamper.filter(function (x) { return x.value === e.value; });
        this.props.building.firePlaceDamperSelected = firePlaceDamperItem;
        break;
      case "typeACSystem":
        this.setState({ typeACSystem: e.value });
        var typeOfACSystemItem = listsData.list.typeOfACSystem.filter(function (x) { return x.value === e.value; });
        this.props.building.typeOfACSystemSelected = typeOfACSystemItem;
        break;
      case "suplimentaryHeating1":
        this.setState({ supplementaryHeatingSystem1: e.value });
        var suplimentaryHeating1Item = listsData.list.suplimentaryHeating1.filter(function (x) { return x.value === e.value; });
        this.props.building.supplementaryHeatingSystem1Selected = suplimentaryHeating1Item;
        break;
      case "suplimentaryHeating1Fuel":
        this.setState({ supplementaryHeatingSystem1Fuel: e.value });
        var suplimentaryHeating1FuelItem = listsData.list.suplimentaryHeating1Fuel.filter(function (x) { return x.value === e.value; });
        this.props.building.supplementaryHeatingSystem1FuelSelected = suplimentaryHeating1FuelItem;
        break;
      case "supplementaryHeatingSystem2":
        this.setState({ supplementaryHeatingSystem2: e.value });
        var suplimentaryHeating2Item = listsData.list.suplimentaryHeating2.filter(function (x) { return x.value === e.value; });
        this.props.building.supplementaryHeatingSystem2Selected = suplimentaryHeating2Item;
        break;
      case "supplementaryHeatingSystem2Fuel":
        this.setState({ supplementaryHeatingSystem2Fuel: e.value });
        var suplimentaryHeating2FuelItem = listsData.list.suplimentaryHeating2Fuel.filter(function (x) { return x.value === e.value; });
        this.props.building.supplementaryHeatingSystem2FuelSelected = suplimentaryHeating2FuelItem;
        break;
      case "totalNumberOfInstalledWindows":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0.;
          }
          if (parent.value > 3000) {
            parent.value = 3000;
          }
          this.setState({ totalInstalledWindows: parseInt(parent.value)||0 });
          this.props.building.totalNumberOfInstalledWindows = parent.value;
        });
        break;
      case "totalNumberOfInstalledDoors":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0.;
          }
          if (parent.value > 1000) {
            parent.value = 1000;
          }
          this.setState({ totalInstalledDoors: parseInt(parent.value)||0 });
          this.props.building.totalNumberOfInstalledDoors = parent.value;
        });
        break;
      case "totalNumberOfInstalledEStarWindows":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0.;
          }
          if (parent.value > this.state.totalInstalledWindows) {
            parent.value = this.state.totalInstalledWindows;
          }
          this.setState({ totalInstalledEStarWindows: parseInt(parent.value)||0 });
          this.props.building.totalNumberOfInstalledEStarWindows = parent.value;
        });
        break;
      case "totalNumberOfInstalledEStarDoors":
        delayIfNeeded(parent.name, () => {
          if (parent.value < 0) {
            parent.value = 0.;
          }
          if (parent.value > this.state.totalInstalledDoors) {
            parent.value = this.state.totalInstalledDoors;
          }
          this.setState({ totalInstalledEStarDoors: parseInt(parent.value)||0 });
          this.props.building.totalNumberOfInstalledEStarDoors = parent.value;
        });
        break;
      case "preUpgradeHeatingCOP":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          if (parent.value > 100) {
            parent.value = 100;
          }
          if (!parent.value) {
            this.setState({errorPreUpgradeHeatingCOP: true, preUpgradeHeatingCOP_val: null});
          }
          else {
            this.setState({errorPreUpgradeHeatingCOP: false, preUpgradeHeatingCOP_val: parent.value});
          }
        });
        break;
      case "preUpgradeHeatingHPCAP":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          if (parent.value > 1000000) {
            parent.value = 1000000;
          }
          if (!parent.value) {
            this.setState({errorPreUpgradeHeatingHPCAP: true, preUpgradeHeatingHPCAP_val: null});
          }
          else {
            this.setState({errorPreUpgradeHeatingHPCAP: false, preUpgradeHeatingHPCAP_val: parent.value});
          }
        });
        break;
      case "basementFloorArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 0) {
            parent.value = null;
          }
          if (parent.value > 50000) {
            parent.value = 50000;
          }
          this.setState({ basementFloorArea: parent.value });
          this.props.building.basementFloorArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "crawlSpaceFloorArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 0) {
            parent.value = null;
          }
          if (parent.value > 50000) {
            parent.value = 50000;
          }
          this.setState({ crawlSpaceFloorArea: parent.value, errorFloorAbove: this.state.errorFloorAbove && parent.value });
          this.props.building.crawlSpaceFloorArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "wallsArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 200000) {
            parent.value = 200000;
          }
          this.setState({ wallsArea: parent.value });
          this.props.building.wallsArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "foundationWallArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 200000) {
            parent.value = 200000;
          }
          this.setState({ foundationWallArea: parent.value });
          this.props.building.foundationWallArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "ceilingArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 200000) {
            parent.value = 200000;
          }
          this.setState({ ceilingArea: parent.value });
          this.props.building.ceilingArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "slabFloorArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 200000) {
            parent.value = 200000;
          }
          this.setState({ slabFloorArea: parent.value });
          this.props.building.slabFloorArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "floorArea":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 50000) {
            parent.value = 50000;
          }
          this.setState({ floorArea: parent.value });
          this.props.building.floorArea = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
          if (!this.props.building.floorArea) {
            this.setState({errorFloorArea: true});
          }
          else {
            this.setState({errorFloorArea: false});
          }
        });
        break;
      case "footprint":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 50000) {
            parent.value = 50000;
          }
          this.setState({ footprint: parent.value });
          this.props.building.footprint = parent.value;
          if (!parent.value) {
            //this.estimateAreaValues();
          }
        });
        break;
      case "yearBuilt":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (!(parent.value > 0)) {
            parent.value = null;
          }
          if (parent.value > parseInt(moment().format('YYYY'))) {
            parent.value = parseInt(moment().format('YYYY'));
          }
          this.setState({ yearBuilt: parent.value });
          this.props.building.yearBuilt = parent.value;
          if (!this.props.building.yearBuilt) {
            this.setState({errorYearBuilt: true});
          }
          else {
            this.setState({errorYearBuilt: false});
          }
        });
        break;
      case "v2Draftiness":
        this.setState({ v2Draftiness: e.value });
        this.props.building.v2DraftinessSelected = findOption(listsData.list.v2Draftiness, e.value);
        this.props.building.v2Draftiness = this.props.building.v2DraftinessSelected && this.props.building.v2DraftinessSelected.value;
        break;
      case "v2PrimaryHeatingSystemFuelType":
        this.setState({ v2PrimaryHeatingSystemFuelType: e.value });
        this.props.building.v2PrimaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemFuelType, e.value);
        this.props.building.v2PrimaryHeatingSystemFuelType = this.props.building.v2PrimaryHeatingSystemFuelTypeSelected && this.props.building.v2PrimaryHeatingSystemFuelTypeSelected.value;
        this.setState({ v2PrimaryHeatingSystemHeatpumpSource: e.value });
        break;
      //case "v2PrimaryHeatingSystemHeatpumpSource":
        //this.setState({ v2PrimaryHeatingSystemHeatpumpSource: e.value });
        //this.props.building.v2PrimaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpSource, e.value);
        //this.props.building.v2PrimaryHeatingSystemHeatpumpSource = this.props.building.v2PrimaryHeatingSystemHeatpumpSourceSelected && this.props.building.v2PrimaryHeatingSystemHeatpumpSourceSelected.value;
        //break;
      case "v2PrimaryHeatingSystemHeatpumpType":
        this.setState({ v2PrimaryHeatingSystemHeatpumpType: e.value });
        this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpType, e.value);
        this.props.building.v2PrimaryHeatingSystemHeatpumpType = this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected && this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected.value;
        break;
      case "v2PrimaryHeatingSystemType":
        this.setState({ v2PrimaryHeatingSystemType: e.value });
        this.props.building.v2PrimaryHeatingSystemTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemType, e.value);
        this.props.building.v2PrimaryHeatingSystemType = this.props.building.v2PrimaryHeatingSystemTypeSelected && this.props.building.v2PrimaryHeatingSystemTypeSelected.value;
        break;
      case "v2SecondaryHeatingSystemFuelType":
        this.setState({ v2SecondaryHeatingSystemFuelType: e.value });
        this.props.building.v2SecondaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemFuelType, e.value);
        this.props.building.v2SecondaryHeatingSystemFuelType = this.props.building.v2SecondaryHeatingSystemFuelTypeSelected && this.props.building.v2SecondaryHeatingSystemFuelTypeSelected.value;
        break;
      case "v2SecondaryHeatingSystemHeatpumpSource":
        this.setState({ v2SecondaryHeatingSystemHeatpumpSource: e.value });
        this.props.building.v2SecondaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpSource, e.value);
        this.props.building.v2SecondaryHeatingSystemHeatpumpSource = this.props.building.v2SecondaryHeatingSystemHeatpumpSourceSelected && this.props.building.v2SecondaryHeatingSystemHeatpumpSourceSelected.value;
        break;
      case "v2SecondaryHeatingSystemHeatpumpType":
        this.setState({ v2SecondaryHeatingSystemHeatpumpType: e.value });
        this.props.building.v2SecondaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpType, e.value);
        this.props.building.v2SecondaryHeatingSystemHeatpumpType = this.props.building.v2SecondaryHeatingSystemHeatpumpTypeSelected && this.props.building.v2SecondaryHeatingSystemHeatpumpTypeSelected.value;
        break;
      case "v2SecondaryHeatingSystemType":
        this.setState({ v2SecondaryHeatingSystemType: e.value });
        this.props.building.v2SecondaryHeatingSystemTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemType, e.value);
        this.props.building.v2SecondaryHeatingSystemType = this.props.building.v2SecondaryHeatingSystemTypeSelected && this.props.building.v2SecondaryHeatingSystemTypeSelected.value;
        break;
      case "v2WaterHeatingSystemFuelType":
        this.setState({ v2WaterHeatingSystemFuelType: e.value });
        this.props.building.v2WaterHeatingSystemFuelTypeSelected = findOption(listsData.list.v2WaterHeatingSystemFuelType, e.value);
        this.props.building.v2WaterHeatingSystemFuelType = this.props.building.v2WaterHeatingSystemFuelTypeSelected && this.props.building.v2WaterHeatingSystemFuelTypeSelected.value;
        break;
      case "v2WaterHeatingSystemType":
        this.setState({ v2WaterHeatingSystemType: e.value });
        this.props.building.v2WaterHeatingSystemTypeSelected = findOption(listsData.list.v2WaterHeatingSystemType, e.value);
        this.props.building.v2WaterHeatingSystemType = this.props.building.v2WaterHeatingSystemTypeSelected && this.props.building.v2WaterHeatingSystemTypeSelected.value;
        break;
      case "v2OwnHome":
        this.setState({ v2OwnHome: e.value });
        this.props.building.v2OwnHomeSelected = findOption(listsData.list.v2OwnHome, e.value);
        this.props.building.v2OwnHome = this.props.building.v2OwnHomeSelected && this.props.building.v2OwnHomeSelected.value;
        break;
      case "v2PrimaryHeatingSystemAge":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2PrimaryHeatingSystemAge = parent.value;
          this.setState({ "v2PrimaryHeatingSystemAge": parent.value });
        });
        break;
      case "v2PrimaryHeatingSystemFurnaceCapacity":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2PrimaryHeatingSystemFurnaceCapacity = parent.value;
          this.setState({ "v2PrimaryHeatingSystemFurnaceCapacity": parent.value });
        });
        break;
      case "v2PrimaryHeatingSystemFurnaceEff":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2PrimaryHeatingSystemFurnaceEff = parent.value;
          this.setState({ "v2PrimaryHeatingSystemFurnaceEff": parent.value });
        });
        break;
      case "v2PrimaryHeatingSystemHeatpumpCapacity":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2PrimaryHeatingSystemHeatpumpCapacity = parent.value;
          this.setState({ "v2PrimaryHeatingSystemHeatpumpCapacity": parent.value, errorV2PrimaryHeatingSystemHeatpumpCapacity: !parent.value });
        });
        break;
      case "v2PrimaryHeatingSystemHeatpumpEff":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2PrimaryHeatingSystemHeatpumpEff = parent.value;
          this.setState({ "v2PrimaryHeatingSystemHeatpumpEff": parent.value, errorV2PrimaryHeatingSystemHeatpumpEff: !parent.value });
        });
        break;
      case "v2SecondaryHeatingSystemAge":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2SecondaryHeatingSystemAge = parent.value;
          this.setState({ "v2SecondaryHeatingSystemAge": parent.value });
        });
        break;
      case "v2SecondaryHeatingSystemFurnaceCapacity":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2SecondaryHeatingSystemFurnaceCapacity = parent.value;
          this.setState({ "v2SecondaryHeatingSystemFurnaceCapacity": parent.value });
        });
        break;
      case "v2SecondaryHeatingSystemFurnaceEff":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2SecondaryHeatingSystemFurnaceEff = parent.value;
          this.setState({ "v2SecondaryHeatingSystemFurnaceEff": parent.value });
        });
        break;
      case "v2SecondaryHeatingSystemHeatpumpCapacity":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2SecondaryHeatingSystemHeatpumpCapacity = parent.value;
          this.setState({ "v2SecondaryHeatingSystemHeatpumpCapacity": parent.value, errorV2SecondaryHeatingSystemHeatpumpCapacity: !parent.value });
        });
        break;
      case "v2SecondaryHeatingSystemHeatpumpEff":
        delayIfNeeded(parent.name, () => {
          parent.value = parseFloat(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2SecondaryHeatingSystemHeatpumpEff = parent.value;
          this.setState({ "v2SecondaryHeatingSystemHeatpumpEff": parent.value, errorV2SecondaryHeatingSystemHeatpumpEff: !parent.value });
        });
        break;
      case "v2WaterHeatingSystemAge":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value <= 0) {
            parent.value = null;
          }
          this.props.building.v2WaterHeatingSystemAge = parent.value;
          this.setState({ "v2WaterHeatingSystemAge": parent.value });
        });
        break;
      case "airConditionerSEER":
        delayIfNeeded(parent.name, () => {
          parent.value = parseInt(parent.value) || 0;
          if (parent.value < 1) {
            parent.value = null;
          }
          if (parent.value > 1000) {
            parent.value = 1000;
          }
          this.setState({ airConditionerSEER: parent.value });
          this.props.building.airConditionerSEER = parent.value;
        });
        break;
      case "primaryHotWaterHeaterEfficiency":
        parent.value = parseInt(parent.value) || 0;
        if (parent.value < 1) {
          parent.value = null;
        }
        if (parent.value > 100) {
          parent.value = 100;
        }
        this.setState({ primaryHotWaterHeaterEfficiency: parent.value });
        this.props.building.primaryHotWaterHeaterEfficiency = parent.value;
        break;
      case "primaryHeatPumpHotWaterCOP":
        parent.value = parseInt(parent.value) || 0;
        if (parent.value < 1) {
          parent.value = null;
        }
        if (parent.value > 1000) {
          parent.value = 1000;
        }
        this.setState({ primaryHeatPumpHotWaterCOP: parent.value });
        this.props.building.primaryHeatPumpHotWaterCOP = parent.value;
        break;
      default:
        this.setState({ unknowDropDownValue: e.value });
    }

    if (!this.props.building.storiesSelected || !this.props.building.storiesSelected[0] || this.props.building.storiesSelected[0].value === 'Select') {
      this.setState({errorStoreys: true});
    }
    else {
      this.setState({errorStoreys: false});
    }
    if (!this.props.building.typeOfHouseSelected || !this.props.building.typeOfHouseSelected[0] || this.props.building.typeOfHouseSelected[0].value === 'Select') {
      this.setState({errorTypeOfHouse: true});
    }
    else {
      this.setState({errorTypeOfHouse: false});
    }
  }

  handleDownloadReport(event) {

    this.props.dispatch(publishAndDownloadReportRequest({buildingId: this.props.building.buildingId }));

  }

  handleDownloadReportPublished(event) {

    this.props.dispatch(downloadReportRequest({buildingId: this.props.building.buildingId, downloadPublished: true }));

  };

  handlePublishReport(event) {

    this.props.dispatch(publishReportRequest({buildingId: this.props.building.buildingId }));

  }

  handleResetClick(event) {
    this.props.dispatch(resetBuildingType1Request({buildingId: this.props.building.buildingId}));
    this.setState({
      loaderActive: true,
      showResetMessage: false
    });
    event.preventDefault();
    this.didReset = true;
  }

  goto3rdStep(event) {
    if (window._RA_MADE_CHANGES && !window.confirm("You have unsaved changes that will be lost, continue?")) {
      return;
    }
    window._RA_MADE_CHANGES = false;
    this.props.dispatch(getReportBase64({buildingId: this.props.building.buildingId }));
    this.setState({
      InputsValidatedPost: 1,
      tab: 3
    });
  }

  getUpgradesSubmitData() {
    return JSON.parse(JSON.stringify(this.upgradeScenariosData));
  }  

  getSubmitData() {

    //this.estimateAreaValues();

    this.buildingInputs = {
      buildingId: this.props.building.buildingId,
      yearBuilt: parseInt(this.props.building.yearBuilt)||0,
      footprint: parseFloat(this.props.building.footprint)||0,
      floorArea: parseFloat(this.props.building.floorArea)||0,
      preUpgradeHeatingCOP: !this.state.usingHeatPump ? null : (parseFloat(this.state.preUpgradeHeatingCOP_val) * 1055.1 / 3600.0),
      preUpgradeHeatingHPCAP: !this.state.usingHeatPump ? null : (parseFloat(this.state.preUpgradeHeatingHPCAP_val) * 0.293071),
      basementFloorArea: this.state.heatedBasement ? parseFloat(this.props.building.basementFloorArea)||0 : 0,
      foundationWallArea: parseFloat(this.props.building.foundationWallArea)||0,
      crawlSpaceFloorArea: parseFloat(this.props.building.crawlSpaceFloorArea) || 0,
      heatPumpSourceSupply: this.state.heatPumpSourceOfSupply,
      wallsArea: parseFloat(this.props.building.wallsArea)||0,
      ceilingArea: parseFloat(this.props.building.ceilingArea)||0,
      slabFloorArea: parseFloat(this.props.building.slabFloorArea)||0,
      furnaceType: this.state.primaryHeatingType,
      typeOfHouse: this.state.typeOfHouse,
      primaryHotWaterEquipmentType: this.state.primaryHotWaterEquipmentType,
      primaryHotWaterEquipmentFuelType: this.state.primaryHotWaterEquipmentFuelType,
      stories: this.state.storeys,
      totalOccupants: this.state.totalOccupants,
      ventilationType: this.state.ventilationTypeInstalled,
      presenceOfPonyWalls: this.state.presenceOfPonyWalls,
      //Need to add to data base
      secondaryDomesticHotWaterType: this.state.secondaryDomesticHotWaterType,
      hasHeatedBasement: this.state.heatedBasement,
      hasHeatPump: this.state.hasHeatPump,

      //Type 2 Inputs
      totalNumberOfInstalledWindows: parseInt(this.props.building.totalNumberOfInstalledWindows)||0,
      totalNumberOfInstalledDoors: parseInt(this.props.building.totalNumberOfInstalledDoors)||0,
      totalNumberOfInstalledEStarWindows: parseInt(this.props.building.totalNumberOfInstalledEStarWindows)||0,
      totalNumberOfInstalledEStarDoors: parseInt(this.props.building.totalNumberOfInstalledEStarDoors)||0,

      mainWallInsulationSectionNames: this.state.wallsSectionNames,
      foundationWallInsulationSectionNames: this.state.foundationSectionNames,
      ceilingInsulationSectionNames: this.state.ceilingSectionNames,
      floorAboveCrawlspaceInsulationSectionNames: this.state.crawlspaceSectionNames,

      temperatureOfBasement: this.state.temperatureBasement,
      temperatureOfMainFloor: this.state.temperatureMainFloor,
      firePlaceDamper: this.state.fireplaceDamp,
      typeOFACSystem: this.state.typeACSystem,
      airConditionerSEER: parseFloat(this.state.airConditionerSEER)||null,
      primaryHotWaterHeaterEfficiency: parseFloat(this.state.primaryHotWaterHeaterEfficiency)||null,
      primaryHeatPumpHotWaterCOP: parseFloat(this.state.primaryHeatPumpHotWaterCOP)||null,
      supplementaryHeatingSystem1: this.state.supplementaryHeatingSystem1,
      supplementaryHeatingSystem1Fuel: this.state.supplementaryHeatingSystem1Fuel,
      supplementaryHeatingSystem2: this.state.supplementaryHeatingSystem2,
      supplementaryHeatingSystem2Fuel: this.state.supplementaryHeatingSystem2Fuel,

      mainWallInsulationEffectiveDef: this.props.building.mainWallInsulationEffectiveDef,
      ceilingInsulationEffectiveDef: this.props.building.ceilingInsulationEffectiveDef,
      foundationWallInsulationEffectiveDef: this.props.building.foundationWallInsulationEffectiveDef,
      floorAboveCrawlspaceInsulationEffectiveDef: this.props.building.floorAboveCrawlspaceInsulationEffectiveDef,

      mainWallInsulationNominalDef: this.props.building.mainWallInsulationNominalDef,
      ceilingInsulationNominalDef: this.props.building.ceilingInsulationNominalDef,
      foundationWallInsulationNominalDef: this.props.building.foundationWallInsulationNominalDef,
      floorAboveCrawlspaceInsulationNominalDef: this.props.building.floorAboveCrawlspaceInsulationNominalDef,

      ceilingInsulationTypesDef: this.props.building.ceilingInsulationTypesDef,
      foundationWallInsulationTypesDef: this.props.building.foundationWallInsulationTypesDef,

      v2Draftiness: this.props.building.v2Draftiness,
      v2PrimaryHeatingSystemFuelType: this.props.building.v2PrimaryHeatingSystemFuelType,
      //v2PrimaryHeatingSystemHeatpumpSource: this.props.building.v2PrimaryHeatingSystemHeatpumpSource,
      v2PrimaryHeatingSystemHeatpumpType: this.props.building.v2PrimaryHeatingSystemHeatpumpType,
      v2PrimaryHeatingSystemType: this.props.building.v2PrimaryHeatingSystemType,
      v2SecondaryHeatingSystemFuelType: this.props.building.v2SecondaryHeatingSystemFuelType,
      v2SecondaryHeatingSystemHeatpumpSource: this.props.building.v2SecondaryHeatingSystemHeatpumpSource,
      v2SecondaryHeatingSystemHeatpumpType: this.props.building.v2SecondaryHeatingSystemHeatpumpType,
      v2SecondaryHeatingSystemType: this.props.building.v2SecondaryHeatingSystemType,
      v2WaterHeatingSystemFuelType: this.props.building.v2WaterHeatingSystemFuelType,
      v2WaterHeatingSystemType: this.props.building.v2WaterHeatingSystemType,
      v2OwnHome: this.props.building.v2OwnHome,
      v2PrimaryHeatingSystemAge: this.props.building.v2PrimaryHeatingSystemAge,
      v2PrimaryHeatingSystemFurnaceCapacity: this.props.building.v2PrimaryHeatingSystemFurnaceCapacity,
      v2PrimaryHeatingSystemFurnaceEff: this.props.building.v2PrimaryHeatingSystemFurnaceEff,
      v2PrimaryHeatingSystemHeatpumpCapacity: this.props.building.v2PrimaryHeatingSystemHeatpumpCapacity,
      v2PrimaryHeatingSystemHeatpumpEff: this.props.building.v2PrimaryHeatingSystemHeatpumpEff,
      v2SecondaryHeatingSystemAge: this.props.building.v2SecondaryHeatingSystemAge,
      v2SecondaryHeatingSystemFurnaceCapacity: this.props.building.v2SecondaryHeatingSystemFurnaceCapacity,
      v2SecondaryHeatingSystemFurnaceEff: this.props.building.v2SecondaryHeatingSystemFurnaceEff,
      v2SecondaryHeatingSystemHeatpumpCapacity: this.props.building.v2SecondaryHeatingSystemHeatpumpCapacity,
      v2SecondaryHeatingSystemHeatpumpEff: this.props.building.v2SecondaryHeatingSystemHeatpumpEff,
      v2WaterHeatingSystemAge: this.props.building.v2WaterHeatingSystemAge,

      hp_details_json: this.props.building.hp_details_json,
      service_org_file_num: this.props.building.service_org_file_num,
      utility_account_num: this.props.building.utility_account_num,
      utility_service_provider: this.props.building.utility_service_provider
    }

    if (this.buildingInputs.v2PrimaryHeatingSystemType === 'heat pump') {
      this.buildingInputs.v2PrimaryHeatingSystemType = null;
    }

    if (this.buildingInputs.v2SecondaryHeatingSystemType === 'heat pump') {
      this.buildingInputs.v2SecondaryHeatingSystemType = null;
    }

    if (!this.props.totalNumberOfInstalledEStarWindowsPostUpgrade) {
      this.buildingInputs.totalNumberOfInstalledEStarWindowsPostUpgrade = this.buildingInputs.totalNumberOfInstalledEStarWindows;
    }

    if (!this.props.totalNumberOfInstalledEStarDoorsPostUpgrade) {
      this.buildingInputs.totalNumberOfInstalledEStarDoorsPostUpgrade = this.buildingInputs.totalNumberOfInstalledEStarDoors;
    }

    /*this.props.dispatch(updateBuildingRequest(this.buildingInputs));
    this.setState({
      loaderActive: true
    });
    event.preventDefault();*/

    return JSON.parse(JSON.stringify(this.buildingInputs));
  }

  componentDidMount() {
    const buildingId = this.props.match.params.buildingId;
    this.buildingIdToLoad = buildingId;
    this.props.dispatch(getBuildingRequest(buildingId));
    this.props.dispatch(getBuildingRequestLM(buildingId));
  }

  componentWillUnmount() {
    if (this.mainRef.current && this.attachedWheelListener) {
      this.mainRef.current.removeEventListener("wheel", this.wheelHandler);
    }
  }

  estimateAreaValues(field, checkValid) {
    if (this.props.model && this.props.building && this.props.model.id) {
      let floorArea = this.props.building.floorArea;
      if (!floorArea) {
        floorArea = 1500;
        if (checkValid) {
          return false;
        }
      }

      let storeys = Math.ceil(this.props.building.stories);

      if (checkValid && !storeys) {
        return false;
      }

      let total_floors = this.state.heatedBasement ? (1 + storeys) : storeys;
      let sqft_per_floor = floorArea / total_floors;

      if (field == 'basementFloorArea' && this.state.heatedBasement) {
        if (checkValid) {
          return !!Math.round(sqft_per_floor) ;
        }
        this.setState({ basementFloorArea: Math.round(sqft_per_floor) });
        this.props.building.basementFloorArea = Math.round(sqft_per_floor);
        if (this.basementFloorArea && this.basementFloorArea.current) {
          this.basementFloorArea.current.value = this.props.building.basementFloorArea;
        }
      }

      let basementSqft = this.props.building.basementFloorArea;

      let B = this.props.model.building;
      let perim = Math.sqrt((floorArea - basementSqft) / storeys);
      let perimBasement = Math.sqrt(basementSqft);
      let wall_height = 8;
      let header_height = 9.5/12;
      let below_wall_height = 7;
      let wall_area_per_floor = 4 * (wall_height + header_height * storeys) * perim;
      let basement_walls = 4 * (below_wall_height + header_height) * perimBasement;
      let total_wall_area = wall_area_per_floor * storeys;

      sqft_per_floor = Math.round(sqft_per_floor);
      wall_area_per_floor = Math.round(wall_area_per_floor);
      total_wall_area = Math.round(total_wall_area);
      basement_walls = Math.round(basement_walls);

      if (field == 'footprint') {
        if (checkValid) {
          return !!sqft_per_floor;
        }
        this.setState({ footprint: sqft_per_floor });
        this.props.building.footprint = sqft_per_floor;
        if (this.footprint && this.footprint.current) {
          this.footprint.current.value = this.props.building.footprint;
        }
      }

      if (field == 'foundationWallArea') {
        if (checkValid) {
          return !!basement_walls;
        }
        this.setState({ foundationWallArea: basement_walls });
        this.props.building.foundationWallArea = basement_walls;
        if (this.foundationWallArea && this.foundationWallArea.current) {
          this.foundationWallArea.current.value = this.props.building.foundationWallArea;
        }
      }

      if (field == 'crawlSpaceFloorArea') {
        if (checkValid) {
          return true;
        }
        this.setState({ crawlSpaceFloorArea: 0 });
        this.props.building.crawlSpaceFloorArea = 0;
        if (this.crawlSpaceFloorArea && this.crawlSpaceFloorArea.current) {
          this.crawlSpaceFloorArea.current.value = this.props.building.crawlSpaceFloorArea;
        }
      }

      if (field == 'wallsArea') {
        if (checkValid) {
          return !!total_wall_area;
        }
        this.setState({ wallsArea: total_wall_area });
        this.props.building.wallsArea = total_wall_area;
        if (this.wallsArea && this.wallsArea.current) {
          this.wallsArea.current.value = this.props.building.wallsArea;
        }
      }

      if (field == 'ceilingArea') {
        if (checkValid) {
          return !!sqft_per_floor;
        }
        this.setState({ ceilingArea: sqft_per_floor });
        this.props.building.ceilingArea = sqft_per_floor;
        if (this.ceilingArea && this.ceilingArea.current) {
          this.ceilingArea.current.value = this.props.building.ceilingArea;
        }
      }

      if (field == 'slabFloorArea') {
        /* TODO */
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.mainRef.current && !this.attachedWheelListener) {
      this.mainRef.current.addEventListener("wheel", this.wheelHandler);
      this.attachedWheelListener = true;
    }
    if (this.buildingIdToLoad && ((this.props.model && this.props.model && this.props.model.id) || this.props.model.no_type1) === this.buildingIdToLoad && (this.props.building && this.props.building.buildingId) === this.buildingIdToLoad) {
      this.buildingIdToLoad = null;
      this.didReset = false;
      this.setState({
        loaderActive: false,
        tab: 0,
        InputsValidated: 0,
        isLoadingFirst: false
      });
      if (this.props.building.hasHeatPump) {
        this.setState({hasHeatPump: true});
      }
      if (!this.props.building.yearBuilt) {
        this.setState({errorYearBuilt: true});
      }
      if (!this.props.building.floorArea) {
        this.setState({errorFloorArea: true});
      }
      this.setState({ storeys: this.props.building.stories });
      let houseStorysItem = listsData.list.houseStorys.filter((x) => { return x.value == this.props.building.stories; });
      this.props.building.storiesSelected = houseStorysItem;
      if (!this.props.building.storiesSelected || !this.props.building.storiesSelected[0] || this.props.building.storiesSelected[0].value === 'Select') {
        this.setState({errorStoreys: true});
      }
      if (!this.props.building.typeOfHouseSelected || !this.props.building.typeOfHouseSelected[0] || this.props.building.typeOfHouseSelected[0].value === 'Select') {
        this.setState({errorTypeOfHouse: true});
      }

      this.setState({
        usingHeatPump: this.props.building.heatPumpSourceSupplySelected && this.props.building.heatPumpSourceSupplySelected[0] && (this.props.building.heatPumpSourceSupplySelected[0].value === 'air' || this.props.building.heatPumpSourceSupplySelected[0].value === 'ground'),
        preUpgradeHeatingCOP_val: Math.round(((this.props.building.preUpgradeHeatingCOP) * 3600.) / 1055.1*1000)/1000,
        preUpgradeHeatingHPCAP_val: Math.round(this.props.building.preUpgradeHeatingHPCAP / 0.293071) || null,
        defaultsSet: true,
        floorArea: this.props.building.floorArea,
        footprint: this.props.building.footprint,
        foundationWallArea: this.props.building.foundationWallArea,
        crawlSpaceFloorArea: this.props.building.crawlSpaceFloorArea,
        wallsArea: this.props.building.wallsArea,
        ceilingArea: this.props.building.ceilingArea,
        slabFloorArea: this.props.building.slabFloorArea,
        basementFloorArea: this.props.building.basementFloorArea,
        heatedBasement: (this.props.building.basementFloorArea > 0) || (this.props.building.hasHeatedBasement),
        airConditionerSEER: this.props.building.airConditionerSEER,
        primaryHotWaterHeaterEfficiency: this.props.building.primaryHotWaterHeaterEfficiency,
        primaryHeatPumpHotWaterCOP: this.props.building.primaryHeatPumpHotWaterCOP,
        errorV2PrimaryHeatingSystemHeatpumpCapacity: !this.props.building.v2PrimaryHeatingSystemHeatpumpCapacity,
        errorV2PrimaryHeatingSystemHeatpumpEff: !this.props.building.v2PrimaryHeatingSystemHeatpumpEff,
        errorV2SecondaryHeatingSystemHeatpumpCapacity: !this.props.building.v2SecondaryHeatingSystemHeatpumpCapacity,
        errorV2SecondaryHeatingSystemHeatpumpEff: !this.props.building.v2SecondaryHeatingSystemHeatpumpEff
      });
      this.estimateAreaValues();
      this.updateBM3Error();
      return;
    }

    if (this.state.loaderActive && this.didReset) {
      this.setState({
        loaderActive: false,
        InputsValidated: 0,
        tab: 1
      });
      this.updateBM3Error();
      this.didReset = false;
      return;
    }
    if (this.state.tab === 0 && this.props.statusText) {
      let tabNo = { 0: 1, 1: 2, 2: 3, 3: 3 }[this.props.status] || 1;
      if (tabNo === 3) {
        this.props.dispatch(getReportBase64({buildingId: this.props.building.buildingId }));
      }
      this.setState({
        tab: tabNo,
        InputsValidated: tabNo >= 2 ? 1 : 0,
        InputsValidatedPost: tabNo >= 3 ? 1 : 0
      });
    }
    if (this.state.loaderActive && !this.props.isUpdating) {
      if (this.didUserEdit) {
        this.setState({
          showEdit: false,
          loaderActive: false,
          editPhoneValue: this.props.building.phoneNumber || '',
          editEmailValue: this.props.building.emailAddress || '',
          editAddressValue: this.props.building.address || '',
          editFirstNameValue: this.props.building.firstName || '',
          editLastNameValue: this.props.building.lastName || '',
          editPinValue: this.props.building.customerPin || null
        });
        this.didUserEdit = false;
      }
      else {
        this.setState({
          loaderActive: false,
          InputsValidated: 1,
          tab: 2,
        });
      }
    }
  }

  foundationWidgetChange(strREff, strRNom, strTypes) {
    this.props.building.foundationWallInsulationEffectiveDef = strREff;
    this.setState({ foundationWallInsulationEffectiveDef: strREff });
    this.props.building.foundationWallInsulationNominalDef = strRNom;
    this.setState({ foundationWallInsulationNominalDef: strRNom });
    this.props.building.foundationWallInsulationTypesDef = strTypes;
    this.setState({ foundationWallInsulationTypesDef: strTypes });
  }

  wallsSectionNameChange(str) {
    this.setState({ wallsSectionNames: str });
  }

  foundationSectionNameChange(str) {
    this.setState({ foundationSectionNames: str });
  }

  crawlspaceSectionNameChange(str) {
    this.setState({ crawlspaceSectionNames: str });
  }

  ceilingSectionNameChange(str) {
    this.setState({ ceilingSectionNames: str });
  }

  floorAboveCrawlspaceWidgetChange(strREff, strRNom) {
    this.props.building.floorAboveCrawlspaceInsulationEffectiveDef = strREff;
    this.setState({ floorAboveCrawlspaceInsulationEffectiveDef: strREff });
    this.props.building.floorAboveCrawlspaceInsulationNominalDef = strRNom;
    this.setState({ floorAboveCrawlspaceInsulationNominalDef: strRNom });
    this.updateBM3Error();
  }

  wallsWidgetChange(strREff, strRNom) {
    this.props.building.mainWallInsulationEffectiveDef = strREff;
    this.setState({ mainWallInsulationEffectiveDef: strREff });
    this.props.building.mainWallInsulationNominalDef = strRNom;
    this.setState({ mainWallInsulationNominalDef: strRNom });
    this.updateBM3Error();
  }

  ceilingWidgetChange(strREff, strRNom, strTypes) {
    this.props.building.ceilingInsulationEffectiveDef = strREff;
    this.setState({ ceilingInsulationEffectiveDef: strREff });
    this.props.building.ceilingInsulationNominalDef = strRNom;
    this.setState({ ceilingInsulationNominalDef: strRNom });
    this.props.building.ceilingInsulationTypesDef = strTypes;
    this.setState({ ceilingInsulationTypesDef: strTypes });
    this.updateBM3Error();
  }

  updateBM3Error() {
    this.setState({
      bmTabError:
        (window.CheckRVWError(
            this.state.wallsArea || this.props.building.wallsArea, 'wood',
            this.props.building.mainWallInsulationEffectiveDef,
            this.props.building.mainWallInsulationNominalDef,
            '', null
          )
        || window.CheckRVWError(
            this.state.ceilingArea || this.props.building.ceilingArea, 'wood',
            this.props.building.ceilingInsulationEffectiveDef,
            this.props.building.ceilingInsulationNominalDef,
            this.props.building.ceilingInsulationTypesDef, null
          )
        || (this.props.building.basementFloorArea > 0 && this.state.heatedBasement && window.CheckRVWError(
            this.state.foundationWallArea || this.props.building.foundationWallArea, 'concrete',
            this.props.building.foundationWallInsulationEffectiveDef,
            this.props.building.foundationWallInsulationNominalDef,
            this.props.building.foundationWallInsulationTypesDef, null
          ))
        || (this.props.building.crawlSpaceFloorArea > 0 && window.CheckRVWError(
            this.state.crawlSpaceFloorArea || this.props.building.crawlSpaceFloorArea, 'concrete',
            this.props.building.floorAboveCrawlspaceInsulationEffectiveDef,
            this.props.building.floorAboveCrawlspaceInsulationNominalDef,
            '', null, true
          )))
    });
  }

  foundationErrorChange(err) {
    this.setState({ errorFoundation: !!err });
    this.updateBM3Error();
  }

  floorAboveCrawlspaceErrorChange(err) {
    this.setState({ errorFloorAbove: !!err });
    this.updateBM3Error();
  }

  ceilingErrorChange(err) {
    this.setState({ errorCeiling: !!err });
    this.updateBM3Error();
  }

  wallsErrorChange(err) {
    this.setState({ errorWalls: !!err });
    this.updateBM3Error();
  }

  onCheckHeatedBasement(e) {
    let newHeatedBasement = !this.state.heatedBasement;
    let newBasementFloorArea = newHeatedBasement ? this.props.building.basementFloorArea : 0;
    this.setState({
      heatedBasement: newHeatedBasement,
      basementFloorArea: newBasementFloorArea,
      errorFoundation: this.state.errorFoundation && newHeatedBasement
    });
    setTimeout(() => this.updateBM3Error(), 50);
  }

  onCheckHasHeatpump(e) {
    let newHasHP = !this.state.hasHeatPump;
    this.setState({
      hasHeatPump: newHasHP,
    });
  }

  onChangeUEdit(e) {
    e = e || window.event;
    let input = e.target;
    let obj = {};
    obj[input.name] = input.value;
    this.setState(obj);
  };

  toggleUEdit() {
    this.setState({
      showEdit: !this.state.showEdit,
      editPhoneValue: this.props.building.phoneNumber || '',
      editEmailValue: this.props.building.emailAddress || '',
      editAddressValue: this.props.building.address || '',
      editFirstNameValue: this.props.building.firstName || '',
      editLastNameValue: this.props.building.lastName || '',
      editPinValue: this.props.building.customerPin || null
    });
  }

  saveUEdit(e) {

    e = e || window.event;

    let buildingInputs = {
      buildingId: this.props.building.buildingId,
      firstName: this.state.editFirstNameValue,
      lastName: this.state.editLastNameValue,
      address: this.state.editAddressValue,
      phoneNumber: this.state.editPhoneValue,
      emailAddress: this.state.editEmailValue,
      customerPin: this.state.editPinValue
    };

    this.didUserEdit = true;

    this.props.dispatch(updateCustomerRequest(buildingInputs));

    this.setState({
      loaderActive: true
    });

    e.preventDefault();

  }

  saveUnresponsive(val) {
    let buildingInputs = {
		buildingId: this.props.building.buildingId,
		isUnresponsive : val
    };

    this.props.dispatch(updateCustomerRequest(buildingInputs));

    this.setState({
      loaderActive: true
    });
  }

  upgradeSceneraiosStateChange(data, isInit) {
    this.upgradeScenariosData = JSON.parse(JSON.stringify(data || {}));
    if (isInit) {
      this.upgradeScenariosDataInitStr = JSON.stringify(data || {});
    }
    this.upgradeScenariosDataStr = JSON.stringify(this.upgradeScenariosData);
    setTimeout(() => {
      this.setState({ randomNo: Math.random() });
    }, 100);
  }

  render() {

    const status = { main: this.props.status, sub: this.props.subStatus };
    const highestStatus = { main: this.props.hStatus, sub: this.props.hSubStatus };

    const genTheme = false;//this.props.building?.themeLower != 'e1'

    const headerField = (label, data, width) => {
      return (
        <div className="raa-uih-field" style={{width}}>
          <div className="raa-uih-field-label">{label}</div>
          <div className="raa-uih-field-data">{data}</div>
        </div>
      );
    };

    let t = Math.pow(Math.max(this.props.confidenceScore, 0.75), 3.);

    const CCLR = `rgb(${Math.round((1-t)*255)}, ${Math.round(Math.min(1., t*2)*255)}, ${Math.round(0.*255)})`;

    let hasReset = this.props.status > 0;

    if (this.state.isLoadingFirst) {
      return (<div></div>);
    }

    let bmTab1Error = this.state.errorFloorArea || this.state.errorStoreys || this.state.errorYearBuilt || this.state.errorTypeOfHouse;
    let bmTab2Error = ((this.state.errorV2PrimaryHeatingSystemHeatpumpCapacity || this.state.errorV2PrimaryHeatingSystemHeatpumpEff) && this.props.building.v2PrimaryHeatingSystemType == 'heat pump') ||
                      ((this.state.errorV2SecondaryHeatingSystemHeatpumpCapacity || this.state.errorV2SecondaryHeatingSystemHeatpumpEff) && this.props.building.v2SecondaryHeatingSystemType == 'heat pump');
    let bmTab3Error = this.state.errorFoundation || this.state.errorWalls || this.state.errorCeiling || this.state.errorFloorAbove
                      || this.state.bmTabError;

    let errorTabs = [];
    if (bmTab1Error) {
      errorTabs.push(`"General Info"`);
    }
    if (bmTab2Error) {
      errorTabs.push(`"Mechanicals"`);
    }
    if (bmTab3Error) {
      errorTabs.push(`"Envelope"`);
    }
    let bmErrorMsg = null;
    if (errorTabs.length === 1) {
      bmErrorMsg = `Сorrect the values in the ${errorTabs[0]} tab to perform the update`;
    }
    else if (errorTabs.length === 2) {
      bmErrorMsg = `Сorrect the values in the ${errorTabs[0]} and ${errorTabs[1]} tabs to perform the update`;
    }
    else if (errorTabs.length === 3) {
      bmErrorMsg = `Сorrect the values in the ${errorTabs[0]}, ${errorTabs[1]} and ${errorTabs[2]} tabs to perform the update`;
    }

    const primaryHeatPump = false;//this.props.building.v2PrimaryHeatingSystemType == 'heat pump';
    const secondaryHeatPump = false;//this.props.building.v2SecondaryHeatingSystemType == 'heat pump';

    const wizGetRequestBodyCbk = () => {
      if (this.handleUpdateDraft && (statusEqual(STATUSES.PRE_PREVIEW_REPORT, status) || statusEqual(STATUSES.POST_PREVIEW_REPORT, status))) {
        this.handleUpdateDraft(window.event, true, true);
      }
      if (statusEqual(STATUSES.PRE_MODELLING_BASELINE, status) || statusEqual(STATUSES.POST_MODELLING_BASELINE, status)) {
        return this.getSubmitData();
      }
      else if (statusEqual(STATUSES.PRE_MODELLING_UPGRADES, status) || statusEqual(STATUSES.POST_MODELLING_UPGRADES, status)) {
        let data = this.getUpgradesSubmitData();
        if (this.upgradeScenariosDataInitStr == this.upgradeScenariosDataStr) {
          data = { dont_update: true };
        }
        return data;
      }
    };

    const wizNextButtonEnabled = () => {
      if (statusEqual(STATUSES.PRE_MODELLING_BASELINE, status) || statusEqual(STATUSES.POST_MODELLING_BASELINE, status)) {
        return !(this.state.errorWalls || this.state.errorFoundation || this.state.errorFloorAbove || this.state.errorCeiling || this.state.errorFloorArea || this.state.errorYearBuilt || (this.state.errorPreUpgradeHeatingCOP && this.state.usingHeatPump) || this.state.errorStoreys || this.state.errorTypeOfHouse || bmTab3Error || bmTab2Error || bmTab1Error);
      }
      else if (statusEqual(STATUSES.PRE_MODELLING_UPGRADES, status) || statusEqual(STATUSES.POST_MODELLING_UPGRADES, status)) {
        if (statusEqual(STATUSES.PRE_MODELLING_UPGRADES, highestStatus) || statusEqual(STATUSES.POST_MODELLING_UPGRADES, highestStatus)) {
          return false;
        }
        return this.upgradeSceneraiosBtnEnabled ? this.upgradeSceneraiosBtnEnabled() : false;
      }
      else {
        return true;
      }
    };

    const building = this.props.building;

    return (
      <div className='raa-main-container' ref={this.mainRef}>
        <h1 className="page-title"><span className="fw-semi-bold">{((this.props.building.firstName||'') + ' ' + (this.props.building.lastName||'')).trim() || '(Name missing)'}</span></h1>

        <div className={"raa-user-info-header " + (this.props.building.isUnresponsive ? 'unresponsive' : '')}>
          {/*headerField('Phone', this.props.building.phoneNumber || '(Phone number missing)')*/}
          {headerField('Email', this.props.building.emailAddress || '(Email missing)')}
          {headerField('Location', this.props.building.address || '(Location missing)')}
          {this.props.building.isUnresponsive ? headerField('Communication Status', (<div><div style={{color: "#F45959", display: "inline-block"}}>Marked Unresponsive</div> - Since {moment(this.props.building.isUnresponsive).format('MMM. D, YYYY')}</div>), '400px') : undefined}
          <div className="form-buttons">
            {!this.state.showEdit && this.state.tab === -1 && <button onClick={this.handleDownloadReportPublished} className="btn btn-primary fb-dl-button" type="button" >
              Download Report (PDF)
            </button>}
            {!this.state.showEdit && <button
              className="btn btn-primary"
              type="button"
              onClick={this.toggleUEdit}
            >
              {this.state.showEdit ? 'Cancel' : 'Actions'}
            </button>}
          </div>
        </div>

        {this.state.showEdit && <div className="raa-user-edit">
          <FormGroup>
            <Form>
              <FormGroup row>
                <Col xs={8}>
                  <Label for="useredit-field-7">Actions</Label>
                </Col>
                <Col xs={8}>
                 <div className='unresponsive-cont'>
                  Mark as {this.props.building.isUnresponsive ? 'Responsive' : 'Unresponsive'}? <SimpToggle
                    checked={!!this.props.building.isUnresponsive}
                    onChange={(val) => {this.saveUnresponsive(val)}}
                    checkModal={{
                      title: "Mark as Unresponsive?",
                      text: "Are you sure you want to mark this homeowner as Unresponsive? They will no longer appear in the Overview features which track # of homeowners and time in status."
                    }}
                    uncheckModal={{
                      title: "Mark as Responsive?",
                      text: "Are you sure you want to mark this homeowner as Responsive? They will remain in the same status they previously were in, and will once again appear in the Overview features which track # of homeowners and time in each status."
                    }}
                  />
                 </div>                  
                 <button
                  className="btn btn-primary"
                  style={{'marginRight': '16px', 'display': 'inline-block'}}
                  type="button"
                  onClick={this.toggleUEdit}
                 >Done</button>
                </Col>
              </FormGroup>
            </Form>
          </FormGroup>
        </div>}
        
        {!genTheme && !this.props.helpdesk && <WizardHeader nextButtonEnabledCbk={wizNextButtonEnabled} getRequestBodyCbk={wizGetRequestBodyCbk} />}

        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_AWAITING_BOOKING, status) || statusEqual(STATUSES.POST_AWAITING_BOOKING, status)) &&
          <div className="raa-widget-pane rwp-first">
            <Row>
              <div className='raa-awaiting-message-cont'>
                <img src="/images/awaiting-icon.svg"/><br/>
                <div className='raa-awaiting-message'>Awaiting Booking</div>
                <div className='raa-awaiting-message-sub'>Waiting for an audit to be booked for this customer.</div>
              </div>
            </Row>
          </div>
        }

        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_AWAITING_AUDIT, status) || statusEqual(STATUSES.POST_AWAITING_AUDIT, status)) &&
          <div className="raa-widget-pane rwp-first">
            <Row>
              <div className='raa-awaiting-message-cont'>
                <img src="/images/awaiting-icon.svg"/><br/>
                <div className='raa-awaiting-message'>Awaiting Audit</div>
                <div className='raa-awaiting-message-sub'>Waiting for the audit to begin.</div>
              </div>
            </Row>
          </div>
        }

        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_AWAITING_SIGNUP, status) || statusEqual(STATUSES.POST_AWAITING_SIGNUP, status)) &&
          <div className="raa-widget-pane rwp-first">
            <Row>
              <div className='raa-awaiting-message-cont'>
                <img src="/images/awaiting-icon.svg"/><br/>
                <div className='raa-awaiting-message'>Awaiting Signup</div>
                <div className='raa-awaiting-message-sub'>Waiting for the homeowner to sign up</div>
              </div>
            </Row>
          </div>
        }


        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_MODELLING_BASELINE, status) || statusEqual(STATUSES.POST_MODELLING_BASELINE, status)) &&
          <div className="raa-widget-pane rwp-first">
            <Row>
              {!this.props.model.no_type1 && <Col xl={4} lg={6} md={12} xs={12} style={{borderRight: "2px solid #06080C"}}>
                <h1 className="pane-title" style={{marginBottom: "8px"}}>
                  {this.props.homeownerUpdated ? 'Automated Audit Results + Survey' : 'Automated Audit Results'}<span className="fw-semi-bold"></span>
                  {this.props.homeownerUpdatedDate && <div style={{fontSize: "16px", opacity: "0.7"}}>Updated on: {this.props.homeownerUpdatedDate}</div>}
                </h1>
                <PreUpgradePanel isType2={false}></PreUpgradePanel>
              </Col>}

              <Col xl={this.props.model.no_type1 ? 12 : 8} lg={12} md={12} xs={12}>
                <div className="base-model-main-buttons">
                  <Button onClick={this.setBaseModelTab(1)} className={"general-info " + (this.state.baseModelTab === 1 ? "selected" : "")} type="submit" >
                    <img src={"/images/general-tab-icon" + (this.state.baseModelTab === 1 ? "-selected.svg" : ".svg")}/>
                    General Info
                    {bmTab1Error && <div className='raa-tab-error-dot'></div>}
                  </Button>
                  <Button onClick={this.setBaseModelTab(2)} className={"general-info " + (this.state.baseModelTab === 2 ? "selected" : "")} type="submit" >
                    <img src={"/images/home-icon-" + (this.state.baseModelTab === 2 ? "black.png" : "white.png")}/>
                    Mechanicals
                    {bmTab2Error && <div className='raa-tab-error-dot'></div>}
                  </Button>
                  <Button onClick={this.setBaseModelTab(3)} className={"general-info " + (this.state.baseModelTab === 3 ? "selected" : "")} type="submit" >
                    <img src={"/images/envelope-tab-icon" + (this.state.baseModelTab === 3 ? "-selected.svg" : ".svg")}/>
                    Envelope
                    {bmTab3Error && <div className='raa-tab-error-dot'></div>}
                  </Button>
                  {bmErrorMsg && <div className='raa-error-message'>{bmErrorMsg}</div>}
                </div>

                {this.state.baseModelTab === 1 && this.state.defaultsSet && <Row>
                  <Col xl={6} lg={12} md={12} xs={12}>
                    <div className='bmodel-tab-inner-header'>
                      <img src="/images/general-header-icon.svg"/> General
                    </div>
                    <div className='bmodel-tab-inner-fields'>
                    <FormGroup row>
                        {/*<Col xs={6}>
                          <Label for="v2OwnHome">Home Ownership</Label>
                        </Col>*/}
                        <Col xs={12}>
                          <Label for="type-of-house"><span className={this.state.errorTypeOfHouse ? "flabel-error" : ""}>* Type of House</span></Label>
                        </Col>
                        {/*<Col xs={6} className={s.select2}>
                          <Select
                            id="v2OwnHome"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2OwnHome}
                            value={this.props.building.v2OwnHomeSelected}
                            name='v2OwnHome'
                            onChange={this.handleChange}
                          />
                        </Col>*/}
                        <Col xs={12} className={s.select2}>
                          <Select
                            id="type-of-house"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={this.state.typesOfHouses}
                            value={this.props.building.typeOfHouseSelected}
                            name='typeOfHouseSelected'
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={6}>
                          <Label for="year-built"><span className={this.state.errorYearBuilt ? "flabel-error" : ""}>* Year Built</span></Label>
                        </Col>
                        <Col xs={6}>
                          <Label for="stories"><span className={this.state.errorStoreys ? "flabel-error" : ""}>* Storeys</span></Label>
                        </Col>
                        {/*<Col xs={4}>
                          <Label for="total-occupants">Total Occupants</Label>
                        </Col>*/}
                        <Col xs={6}>
                          <Input type="number" id="year-built" defaultValue={this.props.building.yearBuilt} name='yearBuilt' innerRef={this.yearBuilt} onChange={this.handleChange}></Input>
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            id="stories"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={this.state.houseStorys}
                            value={this.props.building.storiesSelected}
                            name='storeys' innerRef={this.storeys}
                            onChange={this.handleChange}
                          />
                        </Col>
                        {/*<Col xs={4} className={s.select2}>
                          <Select
                            id="total-occupants"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={this.state.houseTotOccupants}
                            value={this.props.building.totalOccupantsSelected}
                            name='totalOccupants'
                            onChange={this.handleChange}
                          />
                        </Col>*/}
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={4}>
                          <Label for="service-org-file-number"><span>Service Organization File #</span></Label>
                        </Col>
                        <Col xs={4}>
                          <Label for="utility-service-provider"><span>Utility Service Provider</span></Label>
                        </Col>
                        <Col xs={4}>
                          <Label for="utility-account-number"><span>Utility Account #</span></Label>
                        </Col>
                        <Col xs={4}>
                          <Input type="text" id="service-org-file-number" defaultValue={this.props.building.service_org_file_num} name='service-org-file-number' innerRef={this.service_org_file_num} onChange={this.handleChange}></Input>
                        </Col>
                        <Col xs={4} className={s.select2}>
                          <Select
                            id="utility-service-provider"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={this.state.utilityServiceProviderOptions}
                            value={this.state.utilityServiceProviderOptions.filter((e) => e.value === this.props.building.utility_service_provider)}
                            name='utility-service-provider' innerRef={this.utility_service_provider}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col xs={4}>
                          <Input type="text" id="utility-account-number" defaultValue={this.props.building.utility_account_num} name='utility-account-number' innerRef={this.utility_account_num} onChange={this.handleChange}></Input>
                        </Col>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xl={6} lg={12} md={12} xs={12}>
                    <div className='bmodel-tab-inner-header'>
                      <img src="/images/area-header-icon.svg"/> Area
                      {/*<Button onClick={this.estimateAreaValuesClick} color="primary" >
                        Estimate Values
                      </Button>*/}
                      <Label style={{cursor: 'pointer', marginLeft: '2.5rem'}} onClick={(e) => { this.onCheckHeatedBasement(e); }}>
                        <img src={this.state.heatedBasement ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' />
                        Heated Basement
                      </Label>
                    </div>
                    <div className='bmodel-tab-inner-fields'>
                      <FormGroup row>
                        <Col xs={8}>
                          <Label for="floor-area"><span className={this.state.errorFloorArea ? "flabel-error" : ""}>* Floor Area</span> <span className='bm-sqft'>SQFT</span>{/*<EstArea onClick={() => this.estimateAreaValues('floorArea')} />*/}</Label>
                        </Col>
                        <Col xs={4}>
                          <Label for="footprint">Footprint <span className='bm-sqft'>SQFT</span><EstArea onClick={() => this.estimateAreaValues('footprint')} tooltip="Estimate requires Floor Area, Basement Area, Storeys" disabled={!this.estimateAreaValues('footprint', true)} /></Label>
                        </Col>
                        <Col xs={8}>
                          <Input type="number" id="floor-area" defaultValue={this.state.floorArea} name='floorArea' innerRef={this.floorArea} onChange={this.handleChange} />
                        </Col>
                        <Col xs={4}>
                          <Input type="number" id="footprint" defaultValue={this.state.footprint} name='footprint' innerRef={this.footprint} onChange={this.handleChange} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={6}>
                          <Label for="basement-floor">Basement Floor <span className='bm-sqft'>SQFT</span><EstArea onClick={() => this.estimateAreaValues('basementFloorArea')} tooltip="Estimate requires Floor Area, Storeys" disabled={!this.estimateAreaValues('basementFloorArea', true)} /></Label>
                        </Col>
                        <Col xs={6}>
                          <Label for="crawlspace-floor">Crawlspace Floor <span className='bm-sqft'>SQFT</span>{/*<EstArea onClick={() => this.estimateAreaValues('crawlSpaceFloorArea')} />*/}</Label>
                        </Col>
                        <Col xs={6}>
                          <Input style={{display: this.state.heatedBasement ? undefined : 'none'}}  type="number" id="basement-floor" disabled={!this.state.heatedBasement} defaultValue={this.state.basementFloorArea} name='basementFloorArea' innerRef={this.basementFloorArea} onChange={this.handleChange} />
                          <Input style={{display: this.state.heatedBasement ? 'none' : undefined}} type="number" disabled={!this.state.heatedBasement} />
                        </Col>
                        <Col xs={6}>
                          <Input type="number" id="crawlspace-floor" name='crawlSpaceFloorArea' innerRef={this.crawlSpaceFloorArea} defaultValue={this.state.crawlSpaceFloorArea} onChange={this.handleChange} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={3}>
                          <Label for="walls">Walls <span className='bm-sqft'>SQFT</span><EstArea onClick={() => this.estimateAreaValues('wallsArea')} tooltip="Estimate requires Floor Area, Basement Area and Storeys" disabled={!this.estimateAreaValues('wallsArea', true)} /></Label>
                        </Col>
                        <Col xs={5}>
                          <Label for="fndwalls">Foundation Walls <span className='bm-sqft'>SQFT</span><EstArea onClick={() => this.estimateAreaValues('foundationWallArea')} tooltip="Estimate requires Basement Area" disabled={!this.estimateAreaValues('foundationWallArea', true)} /></Label>
                        </Col>
                        <Col xs={4}>
                          <Label for="ceiling">Ceiling <span className='bm-sqft'>SQFT</span><EstArea onClick={() => this.estimateAreaValues('ceilingArea')} tooltip="Estimate requires Floor Area, Storeys" disabled={!this.estimateAreaValues('ceilingArea', true)} /></Label>
                        </Col>
                        <Col xs={3}>
                          <Input type="number" id="walls" defaultValue={this.state.wallsArea} name='wallsArea' innerRef={this.wallsArea} onChange={this.handleChange} />
                        </Col>
                        <Col xs={5}>
                          <Input type="number" id="fndwalls" defaultValue={this.state.foundationWallArea} name='foundationWallArea' innerRef={this.foundationWallArea} onChange={this.handleChange} />
                        </Col>
                        <Col xs={4}>
                          <Input type="number" id="ceiling" name='ceilingArea' innerRef={this.ceilingArea} defaultValue={this.state.ceilingArea} onChange={this.handleChange} />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={12}>
                          <Label for="slab-floor">Slab Floor <span className='bm-sqft'>SQFT</span></Label>
                        </Col>
                        <Col xs={6}>
                          <Input type="number" id="slab-floor" defaultValue={this.state.slabFloorArea} name='slabFloorArea' innerRef={this.slabFloorArea} onChange={this.handleChange} />
                        </Col>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>}
                {false && this.state.baseModelTab === 1 && <Row>
                  <Col xl={6} lg={12} md={12} xs={12}>
                    <div className='bmodel-tab-inner-header sub-header'>
                      Gallery
                    </div>
                    <StreetView address="527 Queen Street, Fredericton, NB" APIkey="AIzaSyBNUcL1Gdz2FQpLHsrRBKslkf3AoxtW8ro" streetView zoomLevel={15} mapStyle={ {marginLeft: "2em", width: "calc(100%-4em)", background: "#1A2537", height: "22em"} }/>
                  </Col>
                </Row>}
                {this.state.baseModelTab === 2 && <Row>
                  <Col xl={6} lg={12} md={12} xs={12}>
                    <div className='bmodel-tab-inner-header'>
                      <img src="/images/hot-water-header-icon.svg"/> Hot Water
                    </div>
                    <div className='bmodel-tab-inner-fields'>
                      <FormGroup row>
                        <Col xs={6}>
                          <Label for="v2WaterHeatingSystemType">Primary Hot Water Equipment Type</Label>
                        </Col>
                        <Col xs={6}>
                          <Label for="v2WaterHeatingSystemFuelType">Primary Hot Water Equipment Fuel</Label>
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2WaterHeatingSystemType}
                            value={this.props.building.v2WaterHeatingSystemTypeSelected}
                            name='v2WaterHeatingSystemType'
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2WaterHeatingSystemFuelType}
                            value={this.props.building.v2WaterHeatingSystemFuelTypeSelected}
                            name='v2WaterHeatingSystemFuelType'
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      {(this.props.building.v2WaterHeatingSystemTypeSelected?.value && this.props.building.v2WaterHeatingSystemTypeSelected?.value !== 'heat pump') ? <FormGroup row>
                        <Col xs={12}>
                          <Label for="hot-water-heater-eff">Hot Water Heater Efficiency (%)</Label>
                        </Col>
                        <Col xs={6}>
                          <Input type="number" id="hot-water-heater-eff" value={this.state.primaryHotWaterHeaterEfficiency} name='primaryHotWaterHeaterEfficiency' innerRef={this.primaryHotWaterHeaterEfficiency} onChange={this.handleChange} />
                        </Col>
                      </FormGroup> : undefined}
                      {this.props.building.v2WaterHeatingSystemTypeSelected?.value === 'heat pump' ? <FormGroup row>
                        <Col xs={12}>
                          <Label for="heat-pump-hot-water-cop">Heat Pump Water Heater Efficiency (COP)</Label>
                        </Col>
                        <Col xs={6}>
                          <Input type="number" id="heat-pump-hot-water-cop" value={this.state.primaryHeatPumpHotWaterCOP} name='primaryHeatPumpHotWaterCOP' innerRef={this.primaryHeatPumpHotWaterCOP} onChange={this.handleChange} />
                        </Col>
                      </FormGroup> : undefined}
                    </div>
                    <div className='bmodel-tab-inner-header top-sep'>
                      <img src="/images/cooling-header-icon.svg"/> Air Cooling
                    </div>
                    <div className='bmodel-tab-inner-fields'>
                      <FormGroup row>
                        <Col xs={12}>
                          <Label for="typeACSystem">Type of A/C System </Label>
                        </Col>
                        <Col xs={12} className={s.select2}>
                          <Select
                              classNamePrefix="react-select"
                              className="selectCustomization"
                              options={this.state.typeOfACSystem}
                              value={this.props.building.typeOfACSystemSelected}
                              name='typeACSystem' innerRef={this.typeACSystem}
                              onChange={this.handleChange}
                            />
                        </Col>
                      </FormGroup>
                      {this.props.building.typeOfACSystemSelected && this.props.building.typeOfACSystemSelected[0] && this.props.building.typeOfACSystemSelected[0]?.value ? <FormGroup row>
                        <Col xs={12}>
                          <Label for="air-conditioner-seer">Cooling Efficiency (SEER)</Label>
                        </Col>
                        <Col xs={6}>
                          <Input type="number" id="air-conditioner-seer" defaultValue={this.state.airConditionerSEER} name='airConditionerSEER' innerRef={this.airConditionerSEER} onChange={this.handleChange} />
                        </Col>
                      </FormGroup> : undefined}
                    </div>
                  </Col>
                  <Col xl={6} lg={12} md={12} xs={12}>
                    <div className='bmodel-tab-inner-header'>
                      <img src="/images/heating-header-icon.svg"/> Heating
                    </div>
                    <div className='bmodel-tab-inner-fields'>
                      <FormGroup row>
                        <Col xs={6}>
                          <Label for="v2PrimaryHeatingSystemType">Primary Heating System Type</Label>
                        </Col>
                        <Col xs={6}>
                          <Label for="v2PrimaryHeatingSystemFuelType">Primary Heating System Fuel Type</Label>
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            name="v2PrimaryHeatingSystemType"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemType.filter((e) => (!secondaryHeatPump || e.value !== 'heat pump') ? e : null)}
                            value={this.props.building.v2PrimaryHeatingSystemTypeSelected}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemFuelType}
                            value={this.props.building.v2PrimaryHeatingSystemFuelTypeSelected}
                            name='v2PrimaryHeatingSystemFuelType'
                            onChange={this.handleChange}
                          />
                        </Col>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        {primaryHeatPump && <Col xs={12}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpType">Primary Heating Heat Pump Type</Label>
                        </Col>}
                        {/*primaryHeatPump && <Col xs={6}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpSource">Primary Heating System Heat Pump Source</Label>
                        </Col>*/}
                        {primaryHeatPump && <Col xs={12} className={s.select2}>
                          <Select
                            name="v2PrimaryHeatingSystemHeatpumpType"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemHeatpumpType}
                            value={this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected}
                            onChange={this.handleChange}
                          />
                        </Col>}
                        {/*primaryHeatPump && <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemHeatpumpSource}
                            value={this.props.building.v2PrimaryHeatingSystemHeatpumpSourceSelected}
                            name='v2PrimaryHeatingSystemHeatpumpSource'
                            onChange={this.handleChange}
                          />
                        </Col>*/}
                        {primaryHeatPump && <br/>}
                        {primaryHeatPump && <br/>}
                        {primaryHeatPump && <br/>}
                        {/*<Col xs={6} className={s.select2}>
                          <Label for="v2PrimaryHeatingSystemAge">Primary Heating System Age:</Label>
                        </Col>
                        <Col xs={12} className={s.select2}>
                          <Col xs={6} className={s.select2} style={{"margin-left": "-1.5em"}}>
                            <Input id="v2PrimaryHeatingSystemAge" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemAge} name='v2PrimaryHeatingSystemAge' onChange={this.handleChange} />
                          </Col>
                        </Col>*/}
                        {primaryHeatPump && <Col xs={12}><br/></Col>}
                        {primaryHeatPump && <Col xs={7}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpEff"><span className={this.state.errorV2PrimaryHeatingSystemHeatpumpEff && primaryHeatPump ? "flabel-error" : ""}>* HP Heating Efficiency (HSPF Region IV)</span></Label>
                        </Col>}
                        {primaryHeatPump && <Col xs={5}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpCapacity"><span className={this.state.errorV2PrimaryHeatingSystemHeatpumpCapacity && primaryHeatPump ? "flabel-error" : ""}>* HP Heating Capacity (BTU/hr)</span></Label>
                        </Col>}
                        {primaryHeatPump && <Col xs={7} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemHeatpumpEff" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemHeatpumpEff} name='v2PrimaryHeatingSystemHeatpumpEff' onChange={this.handleChange} />
                        </Col>}
                        {primaryHeatPump && <Col xs={5} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemHeatpumpCapacity" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemHeatpumpCapacity} name='v2PrimaryHeatingSystemHeatpumpCapacity' onChange={this.handleChange} />
                        </Col>}
                        {!primaryHeatPump && <Col xs={7}>
                          <Label for="v2PrimaryHeatingSystemFurnaceEff"><span>Heating System Efficiency (%)</span></Label>
                        </Col>}
                        {!primaryHeatPump && <Col xs={5}>
                          <Label for="v2PrimaryHeatingSystemFurnaceCapacity"><span>Heating System Capacity (BTU/hr)</span></Label>
                        </Col>}
                        {!primaryHeatPump && <Col xs={7} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemFurnaceEff" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemFurnaceEff} name='v2PrimaryHeatingSystemFurnaceEff' onChange={this.handleChange} />
                        </Col>}
                        {!primaryHeatPump && <Col xs={5} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemFurnaceCapacity" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemFurnaceCapacity} name='v2PrimaryHeatingSystemFurnaceCapacity' onChange={this.handleChange} />
                        </Col>}
                        {primaryHeatPump ? <div className='hpd-wrap'><HPDetails has_heads={this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected?.value === 'mini-split'} is_upgrade={false} is_primary={true} onChange={(model) => {this.props.building.hp_details_json = JSON.stringify(model);}} /></div> : undefined}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Col xs={6}>
                          <Label for="v2SecondaryHeatingSystemType">Secondary Heating System Type</Label>
                        </Col>
                        <Col xs={6}>
                          <Label for="v2SecondaryHeatingSystemFuelType">Secondary Heating System Fuel Type</Label>
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            name="v2SecondaryHeatingSystemType"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2SecondaryHeatingSystemType.filter((e) => (!primaryHeatPump || e.value !== 'heat pump') ? e : null)}
                            value={this.props.building.v2SecondaryHeatingSystemTypeSelected}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2SecondaryHeatingSystemFuelType}
                            value={this.props.building.v2SecondaryHeatingSystemFuelTypeSelected}
                            name='v2SecondaryHeatingSystemFuelType'
                            onChange={this.handleChange}
                          />
                        </Col>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        {secondaryHeatPump && <Col xs={6}>
                          <Label for="v2SecondaryHeatingSystemHeatpumpType">Secondary Heating Heat Pump Type</Label>
                        </Col>}
                        {secondaryHeatPump && <Col xs={6}>
                          <Label for="v2SecondaryHeatingSystemHeatpumpSource">Secondary Heating System Heat Pump Source</Label>
                        </Col>}
                        {secondaryHeatPump && <Col xs={6} className={s.select2}>
                          <Select
                            name="v2SecondaryHeatingSystemHeatpumpType"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2SecondaryHeatingSystemHeatpumpType}
                            value={this.props.building.v2SecondaryHeatingSystemHeatpumpTypeSelected}
                            onChange={this.handleChange}
                          />
                        </Col>}
                        {secondaryHeatPump && <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2SecondaryHeatingSystemHeatpumpSource}
                            value={this.props.building.v2SecondaryHeatingSystemHeatpumpSourceSelected}
                            name='v2SecondaryHeatingSystemHeatpumpSource'
                            onChange={this.handleChange}
                          />
                        </Col>}
                    	{secondaryHeatPump && <Col xs={12}><br/></Col>}
                        {/*<Col xs={12} className={s.select2}>
                          <Label for="v2SecondaryHeatingSystemAge">Secondary Heating System Age:</Label>
                        </Col>
                        <Col xs={12} className={s.select2}>
                          <Col xs={6} className={s.select2} style={{"margin-left": "-1.5em"}}>
                            <Input id="v2SecondaryHeatingSystemAge" type="number" defaultValue={this.props.building.v2SecondaryHeatingSystemAge} name='v2SecondaryHeatingSystemAge' onChange={this.handleChange} />
                          </Col>
                        </Col>*/}
                        {secondaryHeatPump && <Col xs={12}><br/></Col>}
                        {secondaryHeatPump && <Col xs={7}>
                          <Label for="v2SecondaryHeatingSystemHeatpumpEff"><span className={this.state.errorV2SecondaryHeatingSystemHeatpumpEff && secondaryHeatPump ? "flabel-error" : ""}>* Secondary Heating HP Efficiency (HSPF Region IV)</span></Label>
                        </Col>}
                        {secondaryHeatPump && <Col xs={5}>
                          <Label for="v2SecondaryHeatingSystemHeatpumpCapacity"><span className={this.state.errorV2SecondaryHeatingSystemHeatpumpCapacity && secondaryHeatPump ? "flabel-error" : ""}>* Secondary Heating HP Capacity (BTU/hr)</span></Label>
                        </Col>}
                        {secondaryHeatPump && <Col xs={7} className={s.select2}>
                          <Input id="v2SecondaryHeatingSystemHeatpumpEff" type="number" defaultValue={this.props.building.v2SecondaryHeatingSystemHeatpumpEff} name='v2SecondaryHeatingSystemHeatpumpEff' onChange={this.handleChange} />
                        </Col>}
                        {secondaryHeatPump && <Col xs={5} className={s.select2}>
                          <Input id="v2SecondaryHeatingSystemHeatpumpCapacity" type="number" defaultValue={this.props.building.v2SecondaryHeatingSystemHeatpumpCapacity} name='v2SecondaryHeatingSystemHeatpumpCapacity' onChange={this.handleChange} />
                        </Col>}
                        {primaryHeatPump && <Col xs={7}>
                          <Label for="v2PrimaryHeatingSystemFurnaceEff"><span>Heating System Efficiency (%)</span></Label>
                        </Col>}
                        {primaryHeatPump && <Col xs={5}>
                          <Label for="v2PrimaryHeatingSystemFurnaceCapacity"><span>Heating System Capacity (BTU/hr)</span></Label>
                        </Col>}
                        {primaryHeatPump && <Col xs={7} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemFurnaceEff" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemFurnaceEff} name='v2PrimaryHeatingSystemFurnaceEff' onChange={this.handleChange} />
                        </Col>}
                        {primaryHeatPump && <Col xs={5} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemFurnaceCapacity" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemFurnaceCapacity} name='v2PrimaryHeatingSystemFurnaceCapacity' onChange={this.handleChange} />
                        </Col>}
                        {secondaryHeatPump ? <div className='hpd-wrap'><HPDetails has_heads={this.props.building.v2SecondaryHeatingSystemHeatpumpTypeSelected?.value === 'mini-split'} is_upgrade={false} is_primary={false} onChange={(model) => {this.props.building.hp_details_json = JSON.stringify(model);}} /></div> : undefined}
                        
                        <Col xs={12}>
                          <Label style={{cursor: 'pointer', marginLeft: '0rem'}} onClick={(e) => { this.onCheckHasHeatpump(e); }}>
                            <img src={this.state.hasHeatPump ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' />
                            Has Heat Pump
                          </Label>
                          <br/>
                          <br/>
                        </Col>

                        {this.state.hasHeatPump && <Col xs={12}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpType">Heating Heat Pump Type</Label>
                        </Col>}
                        {/*this.state.hasHeatPump && <Col xs={6}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpSource">Heating System Heat Pump Source</Label>
                        </Col>*/}
                        {this.state.hasHeatPump && <Col xs={12} className={s.select2}>
                          <Select
                            name="v2PrimaryHeatingSystemHeatpumpType"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemHeatpumpType}
                            value={this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected}
                            onChange={this.handleChange}
                          />
                        </Col>}
                        {/*this.state.hasHeatPump && <Col xs={6} className={s.select2}>
                          <Select
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2PrimaryHeatingSystemHeatpumpSource}
                            value={this.props.building.v2PrimaryHeatingSystemHeatpumpSourceSelected}
                            name='v2PrimaryHeatingSystemHeatpumpSource'
                            onChange={this.handleChange}
                          />
                        </Col>*/}
                        {this.state.hasHeatPump && <br/>}
                        {this.state.hasHeatPump && <br/>}
                        {this.state.hasHeatPump && <br/>}
                        {this.state.hasHeatPump && <Col xs={12}><br/></Col>}
                        {this.state.hasHeatPump && <Col xs={7}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpEff"><span className={this.state.errorV2PrimaryHeatingSystemHeatpumpEff && this.state.hasHeatPump ? "flabel-error" : ""}>* HP Heating Efficiency (HSPF Region IV)</span></Label>
                        </Col>}
                        {this.state.hasHeatPump && <Col xs={5}>
                          <Label for="v2PrimaryHeatingSystemHeatpumpCapacity"><span className={this.state.errorV2PrimaryHeatingSystemHeatpumpCapacity && this.state.hasHeatPump ? "flabel-error" : ""}>* HP Heating Capacity (BTU/hr)</span></Label>
                        </Col>}
                        {this.state.hasHeatPump && <Col xs={7} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemHeatpumpEff" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemHeatpumpEff} name='v2PrimaryHeatingSystemHeatpumpEff' onChange={this.handleChange} />
                        </Col>}
                        {this.state.hasHeatPump && <Col xs={5} className={s.select2}>
                          <Input id="v2PrimaryHeatingSystemHeatpumpCapacity" type="number" defaultValue={this.props.building.v2PrimaryHeatingSystemHeatpumpCapacity} name='v2PrimaryHeatingSystemHeatpumpCapacity' onChange={this.handleChange} />
                        </Col>}
                        {this.state.hasHeatPump ? <div className='hpd-wrap'><HPDetails has_heads={this.props.building.v2PrimaryHeatingSystemHeatpumpTypeSelected?.value === 'mini-split'} is_upgrade={false} is_primary={true} onChange={(model) => {this.props.building.hp_details_json = JSON.stringify(model);}} /></div> : undefined}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>}
                {this.state.baseModelTab === 3 && <Row>
                  <Col xs={12} className={s.select2}>
                    <div className='bmodel-tab-inner-header'>
                      <img src="/images/windows-doors-header-icon.svg"/> Windows and Doors
                    </div>
                  </Col>
                  <div className='bmodel-tab-inner-fields'>
                    {/*<Row style={{marginLeft: "-0.25em"}}>
                      <Col xs={5}>
                          <Label for="v2Draftiness">Draftiness</Label>
                      </Col>
                    </Row>
                    <Row style={{marginBottom: "1.5em", marginLeft: "-0.25em"}}>
                      <Col xs={5} className={s.select2}>
                          <Select
                            name="v2Draftiness"
                            classNamePrefix="react-select"
                            className="selectCustomization"
                            options={listsData.list.v2Draftiness}
                            value={this.props.building.v2DraftinessSelected}
                            onChange={this.handleChange}
                          />
                      </Col>
                    </Row>*/}
                    <Row style={{marginBottom: "1.5em"}}>
                      <Col xl={6} lg={12} md={12} xs={12} className={s.select2} style={{marginLeft: "1em", paddingRight: "2.5em"}}>
                        <FormGroup row>
                          <Col xs={6}>
                            <Label for="totalNumberOfInstalledWindows">Total Number of Windows </Label>
                          </Col>
                          <Col xs={6}>
                            <Label for="totalNumberOfInstalledEStarWindows">Number of Energy Efficient Windows </Label>
                          </Col>
                          <Col xs={6} className={s.select2}>
                            <Input type="number" id="totalNumberOfInstalledWindows" defaultValue={this.props.building.totalNumberOfInstalledWindows} name='totalNumberOfInstalledWindows' innerRef={this.totalNumberOfInstalledWindows} onChange={this.handleChange}></Input>
                          </Col>
                          <Col xs={6} className={s.select2}>
                            <Input type="number" id="totalNumberOfInstalledEStarWindows" defaultValue={this.props.building.totalNumberOfInstalledEStarWindows} name='totalNumberOfInstalledEStarWindows' innerRef={this.totalNumberOfInstalledEStarWindows} onChange={this.handleChange}></Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xl={6} lg={12} md={12} xs={12} className={s.select2} style={{marginLeft: "-0.5em", marginRight: "-1.5em", paddingRight: "3em"}}>
                        <FormGroup row>
                          <Col xs={6}>
                            <Label for="totalNumberOfInstalledDoors">Total Number of Doors</Label>
                          </Col>
                          <Col xs={6}>
                            <Label for="totalNumberOfInstalledEStarDoors">Number of Energy Efficient Doors</Label>
                          </Col>
                          <Col xs={6} className={s.select2}>
                            <Input type="number" id="totalNumberOfInstalledDoors" defaultValue={this.props.building.totalNumberOfInstalledDoors} name='totalNumberOfInstalledDoors' innerRef={this.totalNumberOfInstalledDoors} onChange={this.handleChange}></Input>
                          </Col>
                          <Col xs={6} className={s.select2}>
                            <Input type="number" id="totalNumberOfInstalledEStarDoors" defaultValue={this.props.building.totalNumberOfInstalledEStarDoors} name='totalNumberOfInstalledEStarDoors' innerRef={this.totalNumberOfInstalledEStarDoors} onChange={this.handleChange}></Input>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Row>}
                {this.state.baseModelTab === 3 && <Row>
                  <Col xl={6} lg={12} md={12} xs={12} className={s.select2}>
                    <RValueWidget
                      titleType='Wall Insulation'
                      defaultStrREff={this.props.building.mainWallInsulationEffectiveDef}
                      defaultStrRNom={this.props.building.mainWallInsulationNominalDef}
                      allowSectionNameEdit={true}
                      sectionNames={this.props.building.mainWallInsulationSectionNames}
                      preUpgradeWarningThresholdRSI={4.5*0.8}
                      onChange={this.wallsWidgetChange.bind(this)}
                      onErrorChange={this.wallsErrorChange.bind(this)}
                      onChangeSectionNames={this.wallsSectionNameChange.bind(this)}
                      allowCollapse={true}
                      baseMaterial='wood'
                      totalArea={this.state.wallsArea || this.props.building.wallsArea}
                      error={this.state.errorWalls}
                      totalSqftDisplay='Total Wall Area'
                      compositionOptions={listsData.list.wallInsulationPresets}
                    />
                  </Col>
                  <Col xl={6} lg={12} md={12} xs={12} className={s.select2}>
                    <RValueWidget
                      titleType='Ceiling Insulation'
                      defaultStrREff={this.props.building.ceilingInsulationEffectiveDef}
                      defaultStrRNom={this.props.building.ceilingInsulationNominalDef}
                      defaultStrTypes={this.props.building.ceilingInsulationTypesDef}
                      allowSectionNameEdit={true}
                      sectionNames={this.props.building.ceilingInsulationSectionNames}
                      preUpgradeWarningThresholdRSI={10.5*0.8}
                      insulationTypes={listsData.list.ceilingInsulationTypes}
                      onChange={this.ceilingWidgetChange.bind(this)}
                      onChangeSectionNames={this.ceilingSectionNameChange.bind(this)}
                      onErrorChange={this.ceilingErrorChange.bind(this)}
                      allowCollapse={true}
                      baseMaterial='wood'
                      totalArea={this.state.ceilingArea || this.props.building.ceilingArea}
                      error={this.state.errorCeiling}
                      totalSqftDisplay='Total Ceiling Area'
                      compositionOptions={listsData.list.ceilingInsulationPresets}
                    />
                  </Col>
                  {this.props.building.basementFloorArea > 0 && this.state.heatedBasement && <Col xl={6} lg={12} md={12} xs={12} className={s.select2}>
                    <RValueWidget
                      titleType='Foundation Insulation'
                      defaultStrREff={this.props.building.foundationWallInsulationEffectiveDef}
                      defaultStrRNom={this.props.building.foundationWallInsulationNominalDef}
                      defaultStrTypes={this.props.building.foundationWallInsulationTypesDef}
                      allowSectionNameEdit={true}
                      sectionNames={this.props.building.foundationWallInsulationSectionNames}
                      preUpgradeWarningThresholdRSI={5*0.8}
                      postUpgradeWarningThresholdRSI={5}
                      onChangeSectionNames={this.foundationSectionNameChange.bind(this)}
                      onChange={this.foundationWidgetChange.bind(this)}
                      onErrorChange={this.foundationErrorChange.bind(this)}
                      insulationTypes={listsData.list.foundationMainInsulationTypes}
                      allowCollapse={true}
                      baseMaterial='concrete'
                      totalArea={this.state.foundationWallArea || this.props.building.foundationWallArea}
                      error={this.state.errorFoundation}
                      totalSqftDisplay='Total Foundation Wall Area'
                      compositionOptions={listsData.list.foundationInsulationPresets}
                    />
                  </Col>}
                  {this.props.building.crawlSpaceFloorArea > 0 && <Col xl={6} lg={12} md={12} xs={12} className={s.select2}>
                    <RValueWidget
                      titleType='Floor Above Crawl Space'
                      defaultStrREff={this.props.building.floorAboveCrawlspaceInsulationEffectiveDef}
                      defaultStrRNom={this.props.building.floorAboveCrawlspaceInsulationNominalDef}
                      allowSectionNameEdit={true}
                      sectionNames={this.props.building.floorAboveCrawlspaceInsulationSectionNames}
                      onChangeSectionNames={this.crawlspaceSectionNameChange.bind(this)}
                      preUpgradeWarningThresholdRSI={5*0.8}
                      onChange={this.floorAboveCrawlspaceWidgetChange.bind(this)}
                      onErrorChange={this.floorAboveCrawlspaceErrorChange.bind(this)}
                      allowCollapse={true}
                      baseMaterial='concrete'
                      totalArea={this.state.crawlSpaceFloorArea || this.props.building.crawlSpaceFloorArea}
                      error={this.state.errorFloorAbove}
                      totalSqftDisplay='Total Floor Above Crawlspace Area'
                      compositionOptions={listsData.list.floorAboveCrawlspaceInsulationPresets}
                      partial={true}
                    />
                  </Col>}
                </Row>}
              </Col>
            </Row>
          </div>
        }
        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_MODELLING_UPGRADES, status) || statusEqual(STATUSES.POST_MODELLING_UPGRADES, status)) &&
          <div className="raa-widget-pane">
            <Row>
              <Col xl={4} lg={6} md={12} xs={12} className='raa-widget-left'>
                <h1 className="pane-title" style={{marginBottom: "8px"}}>Baseline Model<span className="fw-semi-bold"></span></h1>
                <PreUpgradePanel isType2={true}></PreUpgradePanel>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className='raa-widget-middle'>
                <UpgradeScenariosPanel isButtonEnabledCbk={(cbk) => this.upgradeSceneraiosBtnEnabled = cbk} onStateChange={(data, isInit) => this.upgradeSceneraiosStateChange(data, isInit)}></UpgradeScenariosPanel>
              </Col>
              <Col xl={4} lg={6} md={12} xs={12} className='raa-widget-right'>
                <PostUpgradePanel></PostUpgradePanel>
              </Col>
            </Row>
          </div>}
        {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_PREVIEW_REPORT, status) || statusEqual(STATUSES.POST_PREVIEW_REPORT, status)) &&
          <div className="raa-widget-pane">
            <Row>
              <Col xl={12} lg={12} md={12} xs={12}>
                <h1 className="pane-title">Report Management<span className="fw-semi-bold"></span></h1>
                <ReportInfoPanel getHandleUpdateDraft={(fn) => {this.handleUpdateDraft = fn}}></ReportInfoPanel>
              </Col>
            </Row>
          </div>}
          {!genTheme && !this.props.helpdesk && (statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) || statusEqual(STATUSES.POST_PREVIEW_PUBLISHED, status)) &&
          <div className="raa-widget-pane">
            <div className='raa-awaiting-message-cont' style={{height: "auto"}}>
              <img style={{width: "38px", height: "38px"}} src="/images/report-published.svg"/><br/><br/>
              <div className='raa-awaiting-message'>Report Published!</div>
              <br/>
            </div>
          </div>}
      </div>
    );
  }
}
function mapStateToProps(state) {
  let building = state.buildings.curBuilding.building || {};
  let statusText = state.buildings.statusText;
  let status = state.buildings.status;
  let model = state.buildings.curModel || {};
  return {
    base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
    building,
    statusText,
    lastPublished: state.buildings.curModel.lastPublished,
    model,
    status: building.auditStatus ?? 1,
    subStatus: building.auditSubStatus ?? 1,
    hStatus: building.highestAuditStatus ?? 1,
    hSubStatus: building.highestAuditSubStatus ?? 1,
    isUpdating: state.buildings.isUpdating,
    vaStatus: state.buildings.vaStatus,
    //confidenceScore: state.buildings.curBuilding.confidenceScore || 0,
    currentUser: state.auth.currentUser,
    helpdesk: state.auth.currentUser && state.auth.currentUser.role === 'helpdesk',
    homeownerUpdated: !model?.isAutomated,
    homeownerUpdatedDate: (model?.time_stamp && !model?.isAutomated) ? moment(model?.time_stamp).format('MMM. D, YYYY - h:mm a') : null
  }
}
export default withRouter(connect(mapStateToProps)(RAEcharts));