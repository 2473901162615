import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BuildingCard from './components/BuildingCard';
import { getBuildingsRequest } from '../../actions/buildings';
import { RandomKey } from '../../components/Common';

import './ENBuildings.scss';

class EnergyBuildings extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customers: PropTypes.array,
  };

  static defaultProps = {
    isReceiving: false,
    customers: []
  };

  componentDidMount() {
    this.props.dispatch(getBuildingsRequest());
    setTimeout(() => {
      this.showPromoAlert();
    }, 100);
    this.key = RandomKey();
  }

  showPromoAlert() {
    //this.setState({ promoAlert: true });
  }

  render() {

    let customers = [];
    if (this.props.buildings) {
      for (let B of this.props.buildings) {
        customers.push(B);
      }
    }
    else {
      for (let i=0; i<this.props.customers.length; i++) {
        const C = this.props.customers[i];
        if (!this.props.customerId || C.organizationId === this.props.customerId) {
          customers.push(C);
        }
      }
    }

    return (
      <div className="enbuildings-container">
        {!this.props.customerId && <div className="page-title-white">All Buildings</div>}
        {this.props.customerId && <div className="page-title-white">Buildings</div>}
        {customers.map((obj, index) => (
          <BuildingCard data={obj} key={this.key + '-' + index} customerId={this.props.customerId} />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.buildings.data,
    isReceiving: state.products.isReceiving,
    isDeleting: state.products.isDeleting,
    idToDelete: state.products.idToDelete,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(EnergyBuildings);
