import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Left Join'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={2}
      outputs={1}
      model={model}
      width={"370px"}
      data={data}
    >
      <div className='fcs-half'><ColumnSelect title='Left Match Column' value={model.left} isLeft={true} onChange={(val) => saveModel({...model, left: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Left Output' value={model.name_left} noOptions={true} onChange={(val) => saveModel({...model, name_left: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Right Match Column' value={model.right} isRight={true} onChange={(val) => saveModel({...model, right: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='Right Output' value={model.name_right} noOptions={true} onChange={(val) => saveModel({...model, name_right: val})} /></div>
    </NodeWrapper>
  );
});