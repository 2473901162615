/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import AuditStatus from '../../AuditStatus';
import { buildingWizardRequest } from '../../../../actions/buildings';

import './WizardHeader.scss';

const { STATUSES, STATUS_TITLES, SUB_STATUS_TITLES, SUB_STATUS_ICONS, SUB_STEP_TITLES, statusEqual, statusLt, statusGt, statusLtEqual, statusGtEqual } = AuditStatus({ advisorExperience: true });

class WizardHeader extends React.Component {

    static propTypes = {
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    nextClick = () => {
        
        this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'next', this.props.getRequestBodyCbk()));
    }

    backClick = () => {

        this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'back', {}));

    }

    bookedAuditClick = () => {

        this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'next', this.props.getRequestBodyCbk()));

    }

    startAuditClick = () => {

        this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'next', this.props.getRequestBodyCbk()));

    }

    publishButtonClick = () => {

        this.props.dispatch(buildingWizardRequest({buildingId: this.props.building.buildingId }, 'next', this.props.getRequestBodyCbk()));

    }

    render() {

        const building = this.props.building;
        const status = {main: this.props.status, sub: this.props.subStatus};

        /*if (building.themeLower != 'e1') {
            return (
                <div className='wizard-header-cont'>
                    <div className='wh-status-cont'>
                        <div className='wh-status-main'></div>
                        <div className='wh-status-sub'></div>
                    </div>
                </div>
            );
        }*/

        const publishButtonVisible     = statusEqual(STATUSES.PRE_PREVIEW_REPORT, status)   || statusEqual(STATUSES.POST_PREVIEW_REPORT, status);
        const auditBookedButtonVisible = statusEqual(STATUSES.PRE_AWAITING_BOOKING, status) || statusEqual(STATUSES.POST_AWAITING_BOOKING, status);
        const startAuditButtonVisible  = statusEqual(STATUSES.PRE_AWAITING_AUDIT, status)   || statusEqual(STATUSES.POST_AWAITING_AUDIT, status);

        const noNext = statusEqual(STATUSES.PRE_AWAITING_AUDIT, status)  || statusEqual(STATUSES.PRE_AWAITING_BOOKING, status)  || statusEqual(STATUSES.PRE_PREVIEW_REPORT, status) ||
                       statusEqual(STATUSES.POST_AWAITING_AUDIT, status) || statusEqual(STATUSES.POST_AWAITING_BOOKING, status) || statusEqual(STATUSES.POST_PREVIEW_REPORT, status) ||
                       statusEqual(STATUSES.PRE_AWAITING_SIGNUP, status) || statusEqual(STATUSES.POST_AWAITING_SIGNUP, status);
        const noBack = statusEqual(STATUSES.PRE_AWAITING_AUDIT, status)  || statusEqual(STATUSES.PRE_AWAITING_BOOKING, status)  || statusEqual(STATUSES.PRE_MODELLING_BASELINE, status) ||
                       statusEqual(STATUSES.POST_AWAITING_AUDIT, status) || statusEqual(STATUSES.POST_AWAITING_BOOKING, status) || statusEqual(STATUSES.POST_MODELLING_BASELINE, status) ||
                       statusEqual(STATUSES.PRE_AWAITING_SIGNUP, status) || statusEqual(STATUSES.POST_AWAITING_SIGNUP, status);

        return (
            <div className='wizard-header-cont'>
                <div className='wh-status-cont'>
                    <div className='wh-status-main'>{STATUS_TITLES[this.props.highestStatus]}</div>
                    <div className='wh-status-sub'>Status: {SUB_STATUS_TITLES[this.props.highestStatus][this.props.highestSubStatus]}</div>
                </div>
                {!!SUB_STEP_TITLES[this.props.status][this.props.subStatus-1] && !noBack &&
                <div className='wh-np-button ' onClick={() => this.backClick()}>
                    <img className='wh-np-back' src="/images/ws-back-icon.svg"/>
                    <div className='wh-np-s'>
                        <div className='wh-np-s-main'>Back</div>
                        <div className='wh-np-s-sub'>{SUB_STEP_TITLES[this.props.status][this.props.subStatus-1]}</div>
                    </div>
                </div>}
                <div className='wh-current'>
                    <img className='wh-status-icon' src={"/images/" + SUB_STATUS_ICONS[this.props.status][this.props.subStatus]}/>
                    <div className='wh-current-s'>
                        <div className='wh-current-s-sub'>{SUB_STEP_TITLES[this.props.status][this.props.subStatus]}</div>
                    </div>
                    {publishButtonVisible && <div className='wh-current-button' onClick={() => this.publishButtonClick()}>Publish Report</div>}
                    {auditBookedButtonVisible && <div className='wh-current-button' onClick={() => this.bookedAuditClick()}>Audit Booked</div>}
                    {startAuditButtonVisible && <div className='wh-current-button' onClick={() => this.startAuditClick()}>Start Audit</div>}
                </div>
                {!!SUB_STEP_TITLES[this.props.status][this.props.subStatus+1] && !noNext &&
                <div className={'wh-np-button' + (this.props.nextButtonEnabledCbk() ? '' : ' disabled')} onClick={() => this.nextClick()}>
                    <img className='wh-np-next' src="/images/ws-next-icon.svg"/>
                    <div className='wh-np-s'>
                        <div className='wh-np-s-main'>Next</div>
                        <div className='wh-np-s-sub'>{SUB_STEP_TITLES[this.props.status][this.props.subStatus+1]}</div>
                    </div>
                </div>}
                <div className='wh-last-published'>
                    <div className="whlp-title">Last Published</div>
                    <div className="whlp-value">{this.props.lastPublished ? moment(this.props.lastPublished).format('MMM. D, YYYY - h:mm a') : '-'}</div>
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {
  let building = state?.buildings?.curBuilding?.building ?? {};
  return { 
    building,
    status: building.auditStatus ?? 1,
    subStatus: building.auditSubStatus ?? 1,
    highestStatus: building.highestAuditStatus ?? (building.auditStatus ?? 1),
    highestSubStatus: building.highestAuditSubStatus ?? (building.auditSubStatus ?? 1),
    lastPublished: state.buildings.curModel.lastPublished
  };
}
export default withRouter(connect(mapStateToProps)(WizardHeader));
