import React, { memo, useState } from 'react';
import { rgbToHex, hexToRgb } from './helpers';

export default memo(({ title, start_color, end_color, count, skew }) => {

  let palette = [];
  let ql = Math.max(1, Math.min(256, count||0));
  let spow = Math.max(0.01, Math.min(100, skew||0));
  let _start_color = hexToRgb(start_color || '#000000');
  let _end_color = hexToRgb(end_color || '#FFFFFF');
  for (let i=0; i<ql; i++) {
    let t = Math.pow(i / (ql-1), spow);
    let newR = Math.floor((_end_color.r - _start_color.r) * t + _start_color.r);
    let newG = Math.floor((_end_color.g - _start_color.g) * t + _start_color.g);
    let newB = Math.floor((_end_color.b - _start_color.b) * t + _start_color.b);
    palette.push(rgbToHex(newR, newG, newB))
  }

  return (
    <div className='flow-color-preview'>
      <div className='fcs-title'>{title || 'Palette Preview'}</div>
      <div className='fcs-pal-cont'>
        {palette.map((clr, idx) => (<div className='fcs-pal-clr' key={'fcs-pal-clr-'+idx} title={clr} style={{backgroundColor: clr, width: `${100/ql}%`}} />))}
      </div>
    </div>
  );

});
