import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-nord_dark";

import { Handle } from 'react-flow-renderer';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ maximized, setMaximized ] = useState(false);

  const onChange = (value) => {
    saveModel({...model, live: value});
  };

  const onSaveCode = () => {
    data.onChange({draft: data.value.draft, live: data.value.draft});
  };


  return (
    <NodeWrapper
      title='JavaScript Row-Filter'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"425px"}
      maximize={{
        width: "810px",
        callback: (flag) => setMaximized(flag)
      }}
      data={data}
    >
      <div className='fcs-title'>Custom Row-Filter Function</div>
      <div className='flow-node-js-editor-wrapper' onMouseDown={(e) => { e = e || window.event; e.preventDefault(); e.stopPropagation(); return false; }}>
        <AceEditor
          mode="javascript"
          theme="nord_dark"
          width={maximized ? "760px" : "375px"}
          height={maximized ? "369px" : "240px"}
          value={model.live}
          onChange={onChange}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    </NodeWrapper>
  );
});

/*export default memo(({ data }) => {

  return (
    <div style={style}>
      <Handle
        type="target"
        position="left"
        id="input"
        style={{ background: '#DDDDDD', border: '3px solid #44D9E6', width: '15px', height: '15px', marginLeft: '-4px' }}
      />
      <div style={istyle} className={expanded ? "popout" : ""}>
        <div style={{marginLeft: "10px", color: "#DDDDDD", marginTop: "5px", marginBottom: "5px"}}>
          Custom JavaScript:
          <div style={{borderRadius: "6px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px", color: "#44d9e6", marginTop: "-3px", border: "1px solid #44d9e6", float: "right", cursor: "pointer", marginRight: "8px"}} onClick={() => {onSaveCode()}}>Save Code</div>
          <div style={{borderRadius: "6px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px", color: "#44d9e6", marginTop: "-3px", border: "1px solid #44d9e6", float: "right", cursor: "pointer", marginRight: "8px"}} onClick={() => {setExpanded(!expanded)}}>{expanded ? 'Minimize' : 'Maximize'}</div>
        </div>
        <div style={{marginLeft: "10px", width: "calc(100% - 20px)", height: "calc(100% - 40px)"}} onMouseDown={(e) => {(e || window.event).stopPropagation();}}>
          <AceEditor
            mode="javascript"
            theme="github"
            width="100%"
            height="100%"
            value={data.value.draft}
            onChange={onChange}
            editorProps={{ $blockScrolling: true }}
          />
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        id="output"
        style={{ background: '#DDDDDD', border: '3px solid #44D9E6', width: '15px', height: '15px', marginRight: '-4px' }}
      />
    </div>
  );
});*/
