/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import './Toggle.scss';

class SimpToggle extends React.Component {

  static propTypes = {
    checked: PropTypes.bool
  };

  static defaultProps = {
    checked: false
  };

  constructor(props) {
    super(props);

    this.state = {
        checked: props.checked,
        uncheckModalOpen: false,
        checkModalOpen: false,
        rk: Math.random()
    };

    this.delay = (fn) => {
        return () => {
            window.setTimeout(() => fn(), 10);
        };
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.state.checked != this.props.checked && prevProps.checked != this.props.checked) {
        this.setState({ checked: this.props.checked });
    }
  }

  render() {
    return (<div className={'simp-toggle ' + (this.state.checked ? 'checked' : '')} key={"st-modal-" + this.state.rk} onMouseLeave={() => {
            if (this.mouseLeaveTimeoutId) {
                window.clearTimeout(this.mouseLeaveTimeoutId);
                this.mouseLeaveTimeoutId = null;
            }
            this.mouseLeaveTimeoutId = window.setTimeout(() => {
                this.mouseLeaveTimeoutId = null;
                this.setState({ checkModalOpen: false, uncheckModalOpen: false, rk: Math.random() });
            }, 500);            
        }} onMouseEnter={() => {
            if (this.mouseLeaveTimeoutId) {
                window.clearTimeout(this.mouseLeaveTimeoutId);
                this.mouseLeaveTimeoutId = null;
            }
        }} onClick={() => {
        let nowChecked = !this.state.checked;
        if (nowChecked && this.props.checkModal) {
            this.setState({ checkModalOpen: true, rk: Math.random() });
        }
        else if (!nowChecked && this.props.uncheckModal) {
            this.setState({ uncheckModalOpen: true, rk: Math.random() });
        }
        else {
            this.setState({ checked: nowChecked });
            if (this.props.onChange) {
                this.props.onChange(nowChecked);
            }
        }
    }}>{this.state.checkModalOpen ? <div className='st-modal'>
        <div className='stm-title'>{this.props.checkModal.title}</div>
        <img className='stm-close' src='/images/x-icon.svg' onClick={this.delay(() => { this.setState({ checkModalOpen: false, rk: Math.random() })})} />
        <div className='stm-desc'>{this.props.checkModal.text}</div>
        <div className='stm-buttons'>
            <div className='stm-yes' onClick={this.delay(() => {
                this.setState({ checked: true, checkModalOpen: false, rk: Math.random() });
                if (this.props.onChange) {
                    this.props.onChange(true);
                }
            })}>Yes</div>
            <div className='stm-no' onClick={this.delay(() => { this.setState({ checkModalOpen: false, rk: Math.random() })})}>Cancel</div>
        </div>
    </div> : undefined}
    {this.state.uncheckModalOpen ? <div className='st-modal'>
        <div className='stm-title'>{this.props.uncheckModal.title}</div>
        <img className='stm-close' src='/images/x-icon.svg' onClick={this.delay(() => { this.setState({ uncheckModalOpen: false, rk: Math.random() })})} />
        <div className='stm-desc'>{this.props.uncheckModal.text}</div>
        <div className='stm-buttons'>
            <div className='stm-yes' onClick={this.delay(() => {
                this.setState({ checked: false, uncheckModalOpen: false, rk: Math.random() });
                if (this.props.onChange) {
                    this.props.onChange(false);
                }
            })}>Yes</div>
            <div className='stm-no' onClick={this.delay(() => { this.setState({ uncheckModalOpen: false, rk: Math.random() })})}>Cancel</div>
        </div>
    </div> : undefined}<div className={'toggle-circle ' + (this.state.checked ? 'checked' : '')}></div></div>);
  }

}

function mapStateToProps(state) {
    return { };
}

export default withRouter(connect(mapStateToProps)(SimpToggle));