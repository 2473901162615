/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Select from 'react-select';
import config from './config';

import './RValueWidget.scss';

window.CheckRVWError = (outOf, baseMaterial, strREff, strRNom, strTypes, jsonUpgrades, partial) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const organizationId = currentUser.user.organizationId;
  
  outOf = outOf || 0.;

  if (outOf <= 0. && partial) {
    return false;
  }

  let tokREff = (strREff || '').split(';');
  let tokRNom = (strRNom || '').split(';');
  let tokRTypes = (strTypes || '').split(';');
  let upgrades = jsonUpgrades && JSON.parse(jsonUpgrades);

  if (partial && (tokREff.length < 2) && (tokRNom.length < 2)) {
    return false;
  }

  let totalREff = 0, totalRNom = 0;
  let totalPercent = 0;
  let typeNum = {};
  let upgradeJSON = [];

  let minRSI = { 'concrete': 0.116, 'wood': 0.417 }[baseMaterial] || 0.116;
  let estRebateTotal = 0;

  let preUpgradeEffectiveRSI = 0;
   
  let count = Math.max(tokREff.length, tokRNom.length);
  let actualCount = 0;
  for (let _i=0; _i<count; _i+=2) {
    let thisPercent = parseFloat(tokREff[_i] || tokRNom[_i]) / 100 * outOf;
    let thisREff = parseFloat(tokREff[_i+1]);
    let thisRNom = parseFloat(tokRNom[_i+1]);

    if (typeof thisPercent !== 'number' || typeof thisREff !== 'number' || typeof thisRNom !== 'number') {
      continue;
    }

    let i = _i / 2;

    if (!(thisPercent > 0) && (i === (count/2-1))) {
      continue;
    }

    actualCount += 1;

    {
      let tmp = thisREff;
      if (!(tmp >= 1)) {
        tmp = minRSI * 5.67826;
      }
      preUpgradeEffectiveRSI += (thisPercent / outOf) / tmp;
    }

    let subPercent = 0;
    let subREff = 0;
    let subRNom = 0;

    if (upgrades && upgrades.length && upgrades[i] && upgrades[i].length) {
      let jPercent = 0;
      let sREff = 0, sRNom = 0;
      for (let j=0; j<upgrades[i].length; j++) {
        const U = upgrades[i][j];
        let uSQFT = U.sqft;
        let uREff = U.rEffective;
        let uRNom = U.rNominal;
        if (typeof uSQFT !== 'number' || typeof uREff !== 'number' || typeof uRNom !== 'number') {
          continue;
        }
        let uPercent = U.percent / 100.;
        jPercent += uPercent;
        if (!(uREff >= 1)) {
          //uREff = minRSI * 5.67826;
        }
        if (!(uRNom >= 1)) {
          //uRNom = minRSI * 5.67826;
        }
        sREff += uPercent / uREff;
        sRNom += uPercent / uRNom;
      }
      subREff = 1. / sREff;
      subRNom = 1. / sRNom;
      subPercent = jPercent;
    }

    let subPercentValue = subPercent * 100;

    totalPercent += thisPercent || 0;

    if (upgrades) {
      let s2Eff = 0, s2Nom = 0;
      if (thisPercent > 0 && subPercent < 1) {
        if (!(thisREff >= 1)) {
          //thisREff = minRSI * 5.67826;
        }
        s2Eff += (1. - subPercent) / thisREff;

        if (!(thisRNom >= 1)) {
          //thisRNom = minRSI * 5.67826;
        }
        s2Nom += (1. - subPercent) / thisRNom;
      }
      if (subPercent > 0) {         
        if (!(subREff >= 1)) {
          //subREff = minRSI * 5.67826;
        }
        s2Eff += subPercent / subREff;

        if (!(subRNom >= 1)) {
          //subRNom = minRSI * 5.67826;
        }
        s2Nom += subPercent / subRNom;
      }
      thisREff = 1 / s2Eff;
      thisRNom = 1 / s2Nom;
    }

    if (thisPercent > 0/* && thisREff > 0 && thisRNom > 0*/) {       
      if (!(thisREff >= 1)) {
        //thisREff = minRSI * 5.67826;
      }
      totalREff += (thisPercent / outOf) / thisREff;

      if (!(thisRNom >= 1)) {
        //thisRNom = minRSI * 5.67826;
      }
      totalRNom += (thisPercent / outOf) / thisRNom;
    }
  }

  preUpgradeEffectiveRSI = (1. / preUpgradeEffectiveRSI) / 5.67826;

  if (totalREff > 0) {
    totalREff = (1. / totalREff);
  }
  else {
    totalREff = null;
  }
  if (totalRNom > 0) {
    totalRNom = (1. / totalRNom);
  }
  else {
    totalRNom = null;
  }

  if (partial && !actualCount) {
    return false;
  }

  let isError = !((totalREff||0) > 0) || (!window.ALLOW_NONOMINAL&& !((totalRNom||0) > 0)) || Math.abs(totalPercent - outOf) > 1e-2;
  return isError;

};

class RValueWidget extends React.Component {

  static propTypes = {
    defaultStrREff: PropTypes.string,
    defaultStrRNom: PropTypes.string,
    defaultStrTypes: PropTypes.string,
    defaultJSONUpgrades: PropTypes.string,
    calcEstRebateFn: PropTypes.func,
    onChange: PropTypes.func,
    onErrorChange: PropTypes.func,
    onChangeSectionNames: PropTypes.func,
    titleType: PropTypes.string,
    allowCollapse: PropTypes.bool,
    insulationTypes: PropTypes.array,
    totalArea: PropTypes.number,
    baseMaterial: PropTypes.string,
    isUpgrade: PropTypes.bool,
    preUpgradeWarningThresholdRSI: PropTypes.number,
    postUpgradeWarningThresholdRSI: PropTypes.number,
    totalSqftDisplay: PropTypes.string,
    compositionOptions: PropTypes.array,
    allowSectionNameEdit: PropTypes.bool,
    sectionNames: PropTypes.string,
    partial: PropTypes.bool,
    error: PropTypes.bool
  };

  static defaultProps = {
    defaultStrREff: '',
    defaultStrRNom: '',
    defaultStrTypes: '',
    defaultJSONUpgrades: '',
    onChange: (strREff, strRNom, strTypes, jsonUpgrades) => {},
    onChangeSectionNames: (strSectionNames) => {},
    calcEstRebateFn: (baseREff, baseRNom, baseType, baseSQFT, upREff, upRNom, upSQFT) => (0.),
    onErrorChange: (err) => {},
    titleType: 'Insulation',
    allowCollapse: false,
    preUpgradeWarningThresholdRSI: null,
    postUpgradeWarningThresholdRSI: null,
    insulationTypes: null,
    totalArea: null,
    baseMaterial: "concrete",
    totalSqftDisplay: null,
    compositionOptions: [{ value: 'user-defined', label: 'User Defined' }],
    isUpgrade: false,
    allowSectionNameEdit: false,
    sectionNames: '',
    error: false
  };

  calcVals(callSetState) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const organizationId = currentUser.user.organizationId;
    let tokREff = [];
    let tokRNom = [];
    let tokTypes = [];
    let totalREff = 0, totalRNom = 0;
    let totalPercent = 0;
    let typeNum = {};
    let upgradeJSON = [];

    let minRSI = { 'concrete': 0.116, 'wood': 0.417 }[this.props.baseMaterial] || 0.116;
    let estRebateTotal = 0;

    let otherError = false;

    this.preUpgradeEffectiveRSI = 0;

    let totalSQFT = 0;
   
    for (let i=0; i<this.state.rows.length; i++) {
      const R = this.state.rows[i];

      let thisPercent = parseFloat(R.valPercentInput.value);
      let thisREff = parseFloat(R.valREffInput.value);
      let thisRNom = parseFloat(R.valRNomInput.value);

      if ((i >= (this.state.rows.length-(this.props.isUpgrade ? 0 : 0))) && !(thisPercent > 0)) {
        continue;
      }

      thisPercent = thisPercent || 0.;
      thisREff = thisREff || 0.;
      thisRNom = thisRNom || 0.;

      if (!(thisPercent > 0) || !(thisREff > 0) || (!window.ALLOW_NONOMINAL&& !(thisRNom) > 0)) {
        otherError = true;
      }

      {
        let tmp = thisREff;
        if (!(tmp >= 1)) {
          //tmp = minRSI * 5.67826;
        }
        this.preUpgradeEffectiveRSI += (thisPercent / this.outOf) / tmp;
      }

      let subPercent = 0;
      let subREff = 0;
      let subRNom = 0;

      let subSQFT = 0;

      if (this.props.isUpgrade && R.upgrades && R.upgrades.length) {
        let Ji = [];
        let jPercent = 0;
        let sREff = 0, sRNom = 0;
        for (let j=0; j<R.upgrades.length; j++) {
          const U = R.upgrades[j];
          let uSQFT = parseFloat(U.valSQFTInput.value);
          let uREff = parseFloat(U.valREffInput.value);
          let uRNom = parseFloat(U.valRNomInput.value);
          if (typeof uSQFT !== 'number' || typeof uREff !== 'number' || typeof uRNom !== 'number') {
            continue;
          }
          totalSQFT += uSQFT;
          subSQFT += uSQFT;
          let uPercent = uSQFT / thisPercent;
          jPercent += uPercent;
          if (!(uREff >= 1)) {
            //uREff = minRSI * 5.67826;
          }
          if (!(uRNom >= 1)) {
            //uRNom = minRSI * 5.67826;
          }
          sREff += uPercent / uREff;
          sRNom += uPercent / uRNom;
          let estRebate = Math.max(0, this.props.calcEstRebateFn(
            thisREff, thisRNom, R.valTypesInput && `${R.valTypesInput.value}`, parseFloat(R.valPercentInput.value), uREff, uRNom, uSQFT
          ) || 0);
          estRebateTotal += estRebate
          if (callSetState) {
            let o = {};
            o['estRebate_' + U.key] = estRebate;
            this.setState(o);
          }
          else {
            this.state['estRebate_' + U.key] = estRebate;
          }
          Ji.push({sqft: uSQFT, percent: Math.round(uPercent*100), rEffective: uREff, rNominal: uRNom, estRebate: estRebate||0});
        }
        if (jPercent < 1) {
          let remainder = 1 - jPercent;
          sREff += remainder / thisREff;
          sRNom += remainder / thisRNom;
        }
        subREff = 1. / sREff;
        subRNom = 1. / sRNom;
        subPercent = 1.;
        upgradeJSON.push(Ji);
      }
      else {
        upgradeJSON.push([]);
      }

      R.areaPercentError = subSQFT > thisPercent;

      R.subPercentValue = subPercent * 100;

      if (i < (this.state.rows.length-(this.props.isUpgrade ? 0 : 0))) {
        totalPercent += thisPercent || 0;
      }

      if (this.props.isUpgrade) {
        let s2Eff = 0, s2Nom = 0;
        if (thisPercent > 0 && subPercent < 1) {
          if (!(thisREff >= 1)) {
            //thisREff = minRSI * 5.67826;
          }
          s2Eff += (1. - subPercent) / thisREff;

          if (!(thisRNom >= 1)) {
            //thisRNom = minRSI * 5.67826;
          }
          s2Nom += (1. - subPercent) / thisRNom;
        }
        if (subPercent > 0) {         
          if (!(subREff >= 1)) {
            //subREff = minRSI * 5.67826;
          }
          s2Eff += subPercent / subREff;

          if (!(subRNom >= 1)) {
            //subRNom = minRSI * 5.67826;
          }
          s2Nom += subPercent / subRNom;
        }
        thisREff = 1 / s2Eff;
        thisRNom = 1 / s2Nom;
      }

      if (thisPercent > 0/* && thisREff > 0 && thisRNom > 0*/) {
        tokREff.push(`${thisPercent / this.outOf * 100}`);
        tokREff.push(`${thisREff}`);
        tokRNom.push(`${thisPercent / this.outOf * 100}`);
        tokRNom.push(`${thisRNom}`);
        
        if (!(thisREff >= 1)) {
          //thisREff = minRSI * 5.67826;
        }
        totalREff += (thisPercent / this.outOf) / thisREff;

        if (!(thisRNom >= 1)) {
          //thisRNom = minRSI * 5.67826;
        }
        totalRNom += (thisPercent / this.outOf) / thisRNom;

        if (this.props.insulationTypes) {
          typeNum[R.valTypesInput.value] = (typeNum[R.valTypesInput.value] || 0) + 1;
          tokTypes.push(`${R.valTypesInput.value}${typeNum[R.valTypesInput.value]}`);
        }
      }
    }

    let anyAreaPercentError = false;

    let upgradeAreaErrors = [];
    if (totalSQFT > this.outOf && this.props.isUpgrade) {
      upgradeAreaErrors.push(`Error: The total area of upgrades exceeds the total area.`);
    }

    for (let i=0; i<this.state.rows.length; i++) {
      const R = this.state.rows[i];
      if (this.props.isUpgrade && R.upgrades && R.upgrades.length) {
        if (R.areaPercentError) {
          upgradeAreaErrors.push(`Error: The total area of upgrades in Section ${i+1} exceeds the total area in Section ${i+1}.`);
        }
        for (let j=0; j<R.upgrades.length; j++) {
          R.upgrades[j].areaPercentError = R.areaPercentError || (totalSQFT > this.outOf);
          anyAreaPercentError = anyAreaPercentError || R.upgrades[j].areaPercentError;
        }
      }
    }

    this.preUpgradeEffectiveRSI = (1. / this.preUpgradeEffectiveRSI) / 5.67826;

    if (!this.props.isUpgrade && this.state.rows.length > 1 && Math.abs(totalPercent - this.outOf) > 1e-2) {
      let RNm1 = this.state.rows[this.state.rows.length-1].valPercentInput;
      RNm1.value = new Number(this.outOf - (totalPercent - (parseFloat(RNm1.value)||0))).toFixed(2);
      if (RNm1.ref.current) {
        RNm1.ref.current.value = RNm1.value;
      }
      if (parseFloat(RNm1.value) > 0.) {
        totalPercent = this.outOf;
      }
      this.calcVals(callSetState);
      return;
    }

    if (totalREff > 0) {
      totalREff = (1. / totalREff);
    }
    else {
      totalREff = null;
    }
    if (totalRNom > 0) {
      totalRNom = (1. / totalRNom);
    }
    else {
      totalRNom = null;
    }

    this.strREff = tokREff.join(';');
    this.strRNom = tokRNom.join(';');
    this.strTypes = tokTypes.join(';');
    this.upgradeJSON = JSON.stringify(upgradeJSON);

    if (callSetState) {
      this.setState({
        effectiveRTotal: totalREff || undefined,
        nominalRTotal: totalRNom || undefined,
        estRebateTotal: `$${Math.round(estRebateTotal || 0)}`,
        effectiveRSI: totalREff / 5.67826,
        preUpgradeEffectiveRSI: this.preUpgradeEffectiveRSI,
        rows: this.state.rows,
        upgradeAreaErrors
      });
      if (this.props.onChange) {
        this.props.onChange(this.strREff, this.strRNom, this.strTypes, this.upgradeJSON);
      }
    }
    else {
      this.state.effectiveRTotal = totalREff || undefined;
      this.state.nominalRTotal = totalRNom || undefined;
      this.state.preUpgradeEffectiveRSI = this.preUpgradeEffectiveRSI;
      this.state.effectiveRSI = totalREff / 5.67826;
      this.state.estRebateTotal = `$${Math.round(estRebateTotal || 0)}`;
      this.state.upgradeAreaErrors = upgradeAreaErrors;
      if (this.props.onChange) {
        this.props.onChange(this.strREff, this.strRNom, this.strTypes, this.upgradeJSON);
      }
    }

    this.setState({ totalPercentError: Math.round(totalPercent) !== Math.round(this.outOf), upgradeAreaErrors });
    if (this.props.onErrorChange) {
      this.props.onErrorChange(((this.outOf >= 0. || !this.props.partial) && (!((totalREff||0) > 0) || (!window.ALLOW_NONOMINAL&& !((totalRNom||0) > 0)) || otherError || (Math.round(totalPercent) !== Math.round(this.outOf)))) || anyAreaPercentError);
    }
  }

  appendRowClick() {
    this.appendRow();
    this.calcVals(true);
  }

  appendRow() {
    let rows = this.state.rows;
    let i = `${Math.floor(Math.random() * 1e9 + 1e8)}`;
    rows.push({
      valREffInput: {
        key: `_valREffi_${this.rand}_${i}`,
        value: ``,
        ref: React.createRef()
      },
      valRNomInput: {
        key: `_valRNomi_${this.rand}_${i}`,
        value: ``,
        ref: React.createRef()
      },
      valPercentInput: {
        key: `_valPercenti_${this.rand}_${i}`,
        value: ``,
        ref: React.createRef()
      },
      valTypesInput: this.props.insulationTypes ? {
        key: `_valTypesi_${this.rand}_${i}`,
        value: ``,
        ref: React.createRef(),
        onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i))))(rows.length)
      } : undefined,
      valCompoInput: this.props.compositionOptions ? {
        key: `_valCompoi_${this.rand}_${i}`,
        value: `user-defined`,
        ref: React.createRef(),
        onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i, true))))(rows.length)
      } : undefined
    });
    this.setState({rows});
  }

  constructor(props) {
    super(props);

    this.sectionNameInput = React.createRef();

    this.outOf = 0;
    this.percentUnit = `SQFT`;
    if (props.totalArea) {
      this.outOf = Math.round(props.totalArea);
      this.percentUnit = 'SQFT';
    }

    this.addUpgradeCbk = (i) => {
      return () => {
        this.addUpgradeClick(i);
      }
    };

    this.editSectionName = (i) => {
      return () => {
        this.setState({ editingSectionName: i+1 });
      };
    }

    this.deleteUpgradeCbk = (i, j) => {
      return () => {
        this.deleteUpgradeClick(i, j);
      }
    };

    this.deleteSectionCbk = (i) => {
      return () => {
        this.deleteSectionClick(i);
      }
    };

    this.onChangeSectionName = () => {
      return () => {
        this.state.sectionNames[this.state.editingSectionName-1] = this.sectionNameInput.current.value;
        this.setState({sectionNames: this.state.sectionNames});
        this.props.onChangeSectionNames(this.state.sectionNames.join('\n'));
      }
    };

    this.onSectionChangeBlur = () => {
      return () => {
        this.setState({
          editingSectionName: 0
        });
      }
    }

    this.rand = `${Math.floor(Math.random() * 1e9 + 1e8)}`;
    let tokREff = (this.props.defaultStrREff||'').split(';');
    let tokRNom = (this.props.defaultStrRNom||'').split(';');
    let tokTypes = (this.props.defaultStrTypes||'').split(';');
    let rows = [];

    let tokUpgrades = JSON.parse(this.props.defaultJSONUpgrades||'[]');

    for (let i=0; (i+1)<tokREff.length || (i+1)<tokRNom.length; i+=2) {
      let valREff = parseFloat(tokREff[i+1]);
      let valRNom = parseFloat(tokRNom[i+1]);
      let percent = new Number(parseFloat(tokREff[i] || tokRNom[i]) / 100 * this.outOf).toFixed(2);
      let r2 = `${Math.floor(Math.random() * 1e9 + 1e8)}`;
      let compoSel = 'user-defined';
      for (let j=1; j<this.props.compositionOptions.length; j++) {
        let v = [...this.props.compositionOptions[j].value];
        let minV = ({ 'concrete': 0.116, 'wood': 0.417 }[this.props.baseMaterial] || 0.116) * 5.67826;
        if (!(v[0] > 0)) {
          v[0] = parseFloat(new Number(minV).toFixed(3));
        }
        if (!(v[1] > 0)) {
          v[1] = parseFloat(new Number(minV).toFixed(3));
        }
        if (Math.abs(v[0] - valRNom) < 0.001 && Math.abs(v[1] - valREff) < 0.001) {
          compoSel = this.props.compositionOptions[j].value;
          break;
        }
      }
      rows.push({
        valREffInput: {
          key: `_valREffi_${this.rand}_${r2}`,
          value: `${valREff}`,
          ref: React.createRef()
        },
        valRNomInput: {
          key: `_valRNomi_${this.rand}_${r2}`,
          value: `${valRNom}`,
          ref: React.createRef()
        },
        valPercentInput: {
          key: `_valPercenti_${this.rand}_${r2}`,
          value: `${percent}`,
          ref: React.createRef()
        },
        valTypesInput: this.props.insulationTypes ? {
          key: `_valTypesInputi_${this.rand}_${r2}`,
          value: `${(tokTypes[i/2]||'').charAt(0)}`,
          ref: React.createRef(),
          onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i))))(rows.length)
        } : undefined,
        valCompoInput: this.props.compositionOptions ? {
          key: `_valCompoInputi_${this.rand}_${r2}`,
          value: compoSel,
          ref: React.createRef(),
          onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i, true))))(rows.length)
        } : undefined,
        upgrades: []
      });
    };
    if (this.props.isUpgrade) {
      for (let i=0; i<rows.length; i++) {
        const R = rows[i];
        if (tokUpgrades[i]) {
          for (let j=0; j<tokUpgrades[i].length; j++) {
            const U = tokUpgrades[i][j];
            let ij = R.ij + '-' + `${Math.floor(Math.random() * 1e9 + 1e8)}`;
            //{sqft: uSQFT, percent: Math.round(uPercent*100), rEffective: uREff, rNominal: uRNom}
            R.upgrades.push({
              key: `_upgrade_${ij}`,
              valREffInput: {
                key: `_valREffi_${this.rand}_${ij}`,
                value: `${U.rEffective}`,
                ref: React.createRef()
              },
              valRNomInput: {
                key: `_valRNomi_${this.rand}_${ij}`,
                value: `${U.rNominal}`,
                ref: React.createRef()
              },
              valSQFTInput: {
                key: `_valSQFTi_${this.rand}_${ij}`,
                value: `${U.sqft}`,
                ref: React.createRef()
              },
              valCompoInput: this.props.compositionOptions ? {
                key: `_valCompoInputi_${this.rand}_${ij}`,
                value: ``,
                ref: React.createRef(),
                onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i, true))))(rows.length)
              } : undefined
            });
          }
        }
      }
    }
    if (!this.props.isUpgrade && !rows.length) {
      let ij = `${Math.floor(Math.random() * 1e9 + 1e8)}`;
      rows.push({
        ij,
        valREffInput: {
          key: `_valREffi_${this.rand}_${ij}`,
          value: ``,
          ref: React.createRef()
        },
        valRNomInput: {
          key: `_valRNomi_${this.rand}_${ij}`,
          value: ``,
          ref: React.createRef()
        },
        valPercentInput: {
          key: `_valPercenti_${this.rand}_${ij}`,
          value: rows.length ? `` : `${this.outOf}`,
          ref: React.createRef()
        },
        valTypesInput: this.props.insulationTypes ? {
          key: `_valTypesInputi_${this.rand}_${ij}`,
          value: ``,
          ref: React.createRef(),
          onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i))))(rows.length)
        } : undefined,
        valCompoInput: this.props.compositionOptions ? {
          key: `_valCompoInputi_${this.rand}_${ij}`,
          value: `user-defined`,
          ref: React.createRef(),
          onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i, true))))(rows.length)
        } : undefined
      });
    }
    this.state = {
      rows: rows,
      showInner: !this.props.allowCollapse,
      sectionNames: (this.props.sectionNames || '').split('\n'),
      editingSectionName: 0,
      upgradeAreaErrors: []
    };
    this.calcVals(false);
  }

  addUpgradeClick(index) {

    let rows = this.state.rows;
    let ij = rows[index].ij + '-' + `${Math.floor(Math.random() * 1e9 + 1e8)}`;
    rows[index].upgrades.push({
      key: `_upgrade_${ij}`,
      valREffInput: {
        key: `_valREffi_${this.rand}_${ij}`,
        value: ``,
        ref: React.createRef()
      },
      valRNomInput: {
        key: `_valRNomi_${this.rand}_${ij}`,
        value: ``,
        ref: React.createRef()
      },
      valSQFTInput: {
        key: `_valSQFTi_${this.rand}_${ij}`,
        value: ``,
        ref: React.createRef()
      },
      valCompoInput: this.props.compositionOptions ? {
        key: `_valCompoInputi_${this.rand}_${ij}`,
        value: `user-defined`,
        ref: React.createRef(),
        onChange: ((_i) => ((sel) => (this.onChangeSelect.bind(this)(sel, _i, true))))(ij)
      } : undefined
    });

    this.setState({rows});

  }

  deleteUpgradeClick(row, upgrade) {

    let rows = this.state.rows;

    rows[row].upgrades.splice(upgrade, 1);

    this.setState({rows});

    this.calcVals(false);

  }

  deleteSectionClick(row) {

    let rows = this.state.rows;

    rows.splice(row, 1);

    this.setState({rows});

    this.calcVals(false);

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  onKeyUpPercent() {
    this.onKeyUp(true)
  }

  onChangeSelect(val, index, isCompoType) {
    let rows = this.state.rows;
    if (isCompoType) {
      rows[index].valCompoInput.value = val.value;
      if (val.value !== 'user-defined') {
        let v = [...(val.value)];
        let minV = ({ 'concrete': 0.116, 'wood': 0.417 }[this.props.baseMaterial] || 0.116) * 5.67826;
        if (!(v[0] > 0)) {
          v[0] = parseFloat(new Number(minV).toFixed(3));
        }
        if (!(v[1] > 0)) {
          v[1] = parseFloat(new Number(minV).toFixed(3));
        }
        rows[index].valRNomInput.ref.current.value = rows[index].valRNomInput.value = v[0];
        rows[index].valREffInput.ref.current.value = rows[index].valREffInput.value = v[1];
      }
    }
    else {
      rows[index].valTypesInput.value = val.value;
    }
    let doAppend = false;
    /*for (let i=0; i<rows.length; i++) {
      const R = rows[i];
      if (!this.props.isUpgrade) {
        if (i === (rows.length-1) && (R.valREffInput.value)) {
          doAppend = true;
        }
      }
    }*/
    this.setState({rows});
    if (doAppend && !this.props.isUpgrade) {
      this.appendRow();
    }
    this.calcVals(true);
    window._RA_MADE_CHANGES = true;
  }

  onKeyUp(overAppend) {
    let rows = this.state.rows;
    let doAppend = false;// overAppend === true;
    for (let i=0; i<rows.length; i++) {
      const R = rows[i];
      if (!this.props.isUpgrade) {
        R.valREffInput.value = R.valREffInput.ref.current.value;
        R.valRNomInput.value = R.valRNomInput.ref.current.value;
        R.valPercentInput.value = R.valPercentInput.ref.current.value;
        if (i === (rows.length-1) && (R.valREffInput.value)) {
          //doAppend = true;
        }
      }
      else {
        for (let j=0; j<R.upgrades.length; j++) {
          const U = R.upgrades[j];
          U.valREffInput.value = U.valREffInput.ref.current.value;
          U.valRNomInput.value = U.valRNomInput.ref.current.value;
          U.valSQFTInput.value = U.valSQFTInput.ref.current.value;
        }
      }
    }
    this.setState({rows});
    if (doAppend && !this.props.isUpgrade) {
      this.appendRow();
    }
    this.calcVals(true);
    window._RA_MADE_CHANGES = true;
  }

  render() {

    const headerClick = () => {
      if (!this.props.allowCollapse) {
        return;
      }
      this.setState({showInner: !this.state.showInner});
    };

    return (
      <div className={'rvalue-widget ' + (this.props.allowCollapse ? 'rvalue-dark-bg' : '') + (this.state.showInner && this.props.allowCollapse ? ' rvalue-expanded' : '')}>
        <h2 className={'page-title ' + (this.props.allowCollapse ? 'rvalue-allow-collapse' : '') + (this.props.error ? ' rvalue-title-error' : '')} onClick={headerClick}>{(this.props.error && !this.props.noErrorDot) && <div className='rvc-error-dot'></div>}{this.props.titleType} R-Value Calculator<span className="fw-semi-bold"></span>
          {this.props.allowCollapse && (<i className={`fa ${this.state.showInner ? 'fa-angle-up' : 'fa-angle-down'}`} />)}
        </h2>
        <div className={'rvalue-inner ' + (this.state.showInner ? 'rvalue-inner-show' : '')}>
          {this.props.totalSqftDisplay && <FormGroup row>
            <Col xs={6}>
              <Col xs={12}>
                <Label for={"total-sqft-display"}>{this.props.totalSqftDisplay} <span className="rvc-sqft">SQFT</span></Label>
              </Col>
              <Col xs={12}>
                <Input readOnly={true} type="number" defaultValue={Math.round(this.props.totalArea*10)/10} name={"total-sqft-display"} />
              </Col>
            </Col>
          </FormGroup>}
          {this.state.rows.map((row, index) => (
            <div className='rv-section' key={row.valPercentInput.key} style={index === 0 ? {'marginTop': '42px'} : {}}>
              {this.state.editingSectionName !== (index+1) && <div className='rv-section-label'>{this.props.allowSectionNameEdit && (<img src="/images/rvc-section-header-icon.svg" style={{cursor: "pointer"}} onClick={this.editSectionName(index)}/>)} {(this.state.sectionNames||[])[index] || ('Section ' + (index+1))}
                {!this.props.isUpgrade && (this.state.rows.length > 1 && index === (this.state.rows.length-1)) && <img src="/images/upgrade-delete-icon.svg" className="rv-section-delete" onClick={this.deleteSectionCbk(index)}/>}
              </div>}
              {this.state.editingSectionName === (index+1) && <div className='rv-section-label'>
                <Input autoFocus type="text" id="sectionNameEdit" name="sectionNameEdit" defaultValue={this.state.sectionNames[index]} placeholder="Section Name"  innerRef={this.sectionNameInput} onChange={this.onChangeSectionName()} onBlur={this.onSectionChangeBlur()}></Input>
              </div>}
              <FormGroup row>
                <Col xs={this.props.insulationTypes ? 6 : 12}>
                  <Label for={"comp-field-" + (index+1)}>Composition</Label>
                </Col>
                {this.props.insulationTypes && <Col xs={6}>
                  <Label for={"type-field-" + (index+1)}>Type</Label>
                </Col>}
                <Col xs={this.props.insulationTypes ? 6 : 12}>
                  <Select
                    isSearchable={!this.props.isUpgrade}
                    name={"comp-field-" + (index+1)}
                    classNamePrefix="react-select"
                    value={this.props.compositionOptions.filter(function (x) { return (JSON.stringify(x.value) === JSON.stringify(row.valCompoInput.value)); })}
                    options={this.props.compositionOptions}
                    className={"selectCustomization" + ((this.props.isUpgrade || (this.outOf <= 0.)) ? ' selectReadOnly' : '')}
                    ref={row.valCompoInput.ref}
                    onChange={row.valCompoInput.onChange}
                  />
                </Col>
                {this.props.insulationTypes && <Col xs={6}>
                  <Select
                    isSearchable={!this.props.isUpgrade}
                    name={"type-field-" + (index+1)}
                    ref={row.valTypesInput.ref}
                    classNamePrefix="react-select"
                    className={"selectCustomization" + ((this.props.isUpgrade || (this.outOf <= 0.)) ? ' selectReadOnly' : '')}
                    options={this.props.insulationTypes}
                    value={this.props.insulationTypes.filter(function (x) { return x.value === row.valTypesInput.value; })}
                    onChange={row.valTypesInput.onChange}
                  />
                </Col>}
              </FormGroup>
              <FormGroup row>
                <Col xs={6}>
                  <Label title={this.percentUnit === '%' ? 'Percent of area' : this.percentUnit} for={"area-field-" + (index+1)}>{this.percentUnit === '%' ? 'Percent of area' : 'Area'}{this.percentUnit !== '%' && <span className='rvc-sqft'>SQFT</span>}</Label>
                </Col>
                <Col xs={3}>
                  <Label for={"rv-field-" + (index+1)}>Effective R</Label>
                </Col>
                <Col xs={3}>
                  <Label for={"rs-field-" + (index+1)}>Nominal R</Label>
                </Col>
                <Col xs={6}>
                  <span className={'area-percent ' + (row.valPercentInput.value < 0 ? 'area-percent-error' : '') + (!this.props.insulationTypes ? ' area-percent-shift' : '')}>{Math.round(row.valPercentInput.value / this.outOf * 100) || 0}%</span>
                  <Input readOnly={(this.outOf <= 0.) || this.props.isUpgrade} type="number" defaultValue={row.valPercentInput.value} name={"area-field-" + (index+1)} innerRef={row.valPercentInput.ref} style={{'display': 'inline-block', 'width': '49%', 'marginRight': '2%', 'backgroundColor': (parseFloat(row.valPercentInput.value) < 0 || parseFloat(row.valPercentInput.value) > this.outOf || this.state.totalPercentError) ? '#e67478' : ''}} onChange={index === (this.state.rows.length-1) ? this.onKeyUpPercent.bind(this) : this.onKeyUp.bind(this)} />
                </Col>
                <Col xs={3}>
                  <Input readOnly={(this.outOf <= 0.) || this.props.isUpgrade || (row.valCompoInput && row.valCompoInput.value != 'user-defined')} type="number" defaultValue={row.valREffInput.value} name={"rs-field-" + (index+1)} innerRef={row.valREffInput.ref} style={{'display': 'inline-block', 'width': '49%', 'backgroundColor': ((!row.valREffInput.value && (parseFloat(row.valPercentInput.value) > 0.)) ? '#e67478' : '')}} onKeyUp={this.onKeyUp.bind(this)} onMouseUp={this.onKeyUp.bind(this)}/>
                </Col>
                <Col xs={3}>
                  <Input readOnly={(this.outOf <= 0.) || this.props.isUpgrade || (row.valCompoInput && row.valCompoInput.value != 'user-defined')} type="number" defaultValue={row.valRNomInput.value} name={"rv-field-" + (index+1)} innerRef={row.valRNomInput.ref} style={{'display': 'inline-block', 'width': '32%', 'marginRight': '2%', 'backgroundColor': ((!window.ALLOW_NONOMINAL && !row.valRNomInput.value && (parseFloat(row.valPercentInput.value) > 0.)) ? '#e67478' : '')}} onKeyUp={this.onKeyUp.bind(this)} onMouseUp={this.onKeyUp.bind(this)} />
                </Col>
              </FormGroup>
              {(this.outOf > 0.) && row.upgrades && row.upgrades.map((uprow, upindex) => (
                <div className="upgrade-row" key={uprow.key}>
                  <FormGroup row>
                    <Col xs={1}>
                      <div className="upgrade-angle"></div>
                    </Col>
                    <Col xs={11}>
                      <FormGroup row>
                        <Col xs={5}>
                          <Label title={this.percentUnit === '%' ? 'Percent of area' : 'Area'} for={"area-field-" + (upindex+1)}>{this.percentUnit === '%' ? 'Percent of area' : 'Area'} {this.percentUnit !== '%' && <span className='rvc-sqft'>SQFT</span>}</Label>
                        </Col>
                        <Col xs={3}>
                          <Label for={"rv-field-" + (upindex+1)}>Effective R</Label>
                        </Col>
                        <Col xs={3}>
                          <Label for={"rs-field-" + (upindex+1)}>Nominal R</Label>
                        </Col>
                        <Col xs={1}>
                        </Col>
                        <Col xs={5}>
                          <span className={'area-percent ' + ((row.subPercentValue <= 0 || row.subPercentValue > 100 || uprow.areaPercentError) ? 'area-percent-error' : '') + (!this.props.insulationTypes ? ' area-percent-shift' : '')}>{Math.round(uprow.valSQFTInput.value / row.valPercentInput.value * 100) || 0}%</span>
                          <Input type="number" defaultValue={uprow.valSQFTInput.value} name={"area-field-" + (index+1) + '-' + (upindex+1)} innerRef={uprow.valSQFTInput.ref} style={{'display': 'inline-block', 'width': '49%', 'marginRight': '2%', 'backgroundColor': (!uprow.valSQFTInput.value || parseFloat(uprow.valSQFTInput.value) <= 0 || parseFloat(uprow.valSQFTInput.value) > this.outOf || (row.subPercentValue <= 0 || row.subPercentValue > 100 || uprow.areaPercentError)) ? '#e67478' : ''}} onKeyUp={this.onKeyUp.bind(this)} onMouseUp={this.onKeyUp.bind(this)} />
                        </Col>
                        <Col xs={3}>
                          <Input type="number" defaultValue={uprow.valREffInput.value} name={"rs-field-" + (index+1) + '-' + (upindex+1)} innerRef={uprow.valREffInput.ref} style={{'display': 'inline-block', 'width': '49%', 'backgroundColor': (!uprow.valREffInput.value ? '#e67478' : '')}} onKeyUp={this.onKeyUp.bind(this)} onMouseUp={this.onKeyUp.bind(this)} />
                        </Col>
                        <Col xs={3}>
                          <Input type="number" defaultValue={uprow.valRNomInput.value} name={"rv-field-" + (index+1) + '-' + (upindex+1)} innerRef={uprow.valRNomInput.ref} style={{'display': 'inline-block', 'width': '32%', 'marginRight': '2%', 'backgroundColor': (!window.ALLOW_NONOMINAL && !uprow.valRNomInput.value ? '#e67478' : '')}} onKeyUp={this.onKeyUp.bind(this)} onMouseUp={this.onKeyUp.bind(this)} />
                        </Col>
                        <Col xs={1}>
                          <img src="/images/upgrade-delete-icon.svg" className="rv-upgrade-delete" onClick={this.deleteUpgradeCbk(index, upindex)}/>
                        </Col>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  {!window.HIDE_AUTO_REBATES && <div className={'rc-est-rebate ' + (Math.round(this.state['estRebate_' + uprow.key] || 0) > 0 ? 'rc-est-rebate-green' : '')}>Est. Rebate: ${Math.round(this.state['estRebate_' + uprow.key] || 0)}</div>}
                </div>
              ))}
              {this.props.isUpgrade && <FormGroup row>
                <Col xs={1}>
                  <div className="upgrade-angle"></div>
                </Col>
                <Col xs={11}>
                  <Button onClick={this.addUpgradeCbk(index)} className="mr-xs pull-right mid-button slim-button" color="primary" type="submit" disabled={this.outOf <= 0.}>
                    Add Upgrade to {(this.state.sectionNames||[])[index] || ('Section ' + (index+1))}
                  </Button>
                </Col>
              </FormGroup>}
            </div>
          ))}
          {!this.props.isUpgrade && <FormGroup row>
            <br/>
            <Col xs={1}>
              <div className="upgrade-angle"></div>
            </Col>
            <Col xs={11}>
              <Button onClick={this.appendRowClick.bind(this)} className="mr-xs pull-right mid-button slim-button" color="primary" type="submit" disabled={this.outOf <= 0.}>
                Add New Section
              </Button>
            </Col>
          </FormGroup>}
          <div className='rv-section'>
            <div className='rv-section-label'>{this.props.isUpgrade ? 'Proposed Combined Totals' : 'Combined Totals'}</div>
            {this.props.isUpgrade ?
              (<FormGroup row>
                <Col xs={window.HIDE_AUTO_REBATES ? 11 : 8}>
                  <Label for="rval-total">Effective R</Label>
                </Col>
                {/*<Col xs={window.HIDE_AUTO_REBATES ? 6 : 4}>
                  <Label for="rsi-total">Nominal R</Label>
              </Col>*/}
                {!window.HIDE_AUTO_REBATES && <Col xs={4}>
                  <Label for="estrebate-total">Est. Rebate</Label>
                </Col>}
                <Col xs={window.HIDE_AUTO_REBATES ? 11 : 8}>
                  <Input type="number" value={this.state.effectiveRTotal ? Math.round(this.state.effectiveRTotal*1000)/1000 : undefined} readOnly name="rsi-total" style={{'display': 'inline-block', 'width': '99%'}} />
                </Col>
                {/*<Col xs={window.HIDE_AUTO_REBATES ? 6 : 4}>
                  <Input type="number" value={this.state.nominalRTotal ? Math.round(this.state.nominalRTotal*1000)/1000 : undefined} readOnly name="rval-total" style={{'display': 'inline-block', 'width': '49%', 'marginRight': '2%'}} />
            </Col>*/}
                {!window.HIDE_AUTO_REBATES && <Col xs={4}>
                  <Input type="text" value={this.state.estRebateTotal} readOnly name="estrebate-total" style={{'display': 'inline-block', 'width': '49%', 'marginRight': '2%'}} />
                </Col>}
              </FormGroup>)
              : (<FormGroup row>
                <Col xs={11}>
                  <Label for="rval-total">Effective R</Label>
                </Col>
                {/*<Col xs={6}>
                  <Label for="rsi-total">Nominal R</Label>
                </Col>*/}
                <Col xs={11}>
                  <Input type="number" value={this.state.effectiveRTotal ? Math.round(this.state.effectiveRTotal*1000)/1000 : undefined} readOnly name="rsi-total" style={{'display': 'inline-block', 'width': '99%'}} />
                </Col>
                {/*<Col xs={6}>
                  <Input type="number" value={this.state.nominalRTotal ? Math.round(this.state.nominalRTotal*1000)/1000 : undefined} readOnly name="rval-total" style={{'display': 'inline-block', 'width': '49%', 'marginRight': '2%'}} />
                </Col>*/}
              </FormGroup>)
            }
            {this.props.error && (<div className='rsi-thresh-warning'>Error: Please complete any missing fields, and ensure that the entire SQFT is accounted for accross all sections.</div>)}
            {(this.state.upgradeAreaErrors||[]).map((msg, idx) => (<div className='rsi-thresh-warning' key={'rsi-upgr-error-' + idx}>{msg}</div>))}
            {!this.props.error && (this.props.preUpgradeWarningThresholdRSI < this.state.preUpgradeEffectiveRSI) && (<div className='rsi-thresh-warning'>Warning: The pre-upgrade Effective R is high enough that upgrading insulation is unlikely to reduce costs significantly.</div>)}
            {!this.props.error && (this.props.isUpgrade && (this.state.effectiveRSI > this.props.postUpgradeWarningThresholdRSI)) && (<div className='rsi-thresh-warning'>Warning: The post-upgrade Effective R is above a threshold of which significant improvement is likely.</div>)}
            {!this.props.error && (this.props.isUpgrade && (this.state.preUpgradeEffectiveRSI*1.01 >= this.state.effectiveRSI)) && (<div className='rsi-thresh-warning'>Warning: The post-upgrade Effective R should be larger than the pre-upgrade Effective R for improvement to be possible.</div>)}
            {(this.outOf <= 0.) && (<div className='rsi-thresh-warning'>Warning: Total area for this insulation type cannot be zero.</div>)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { };
}
export default withRouter(connect(mapStateToProps)(RValueWidget));
