import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import { Input, Dropdown } from '../../../components/Common';
import ColumnSelect from './ColumnSelect';
import Checkbox from './Checkbox';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ eeNo, setEENo ] = useState(Math.random());

  const onSave = () => {
    setEENo(eeNo + 1);
    let newModel = JSON.parse(JSON.stringify(model));
    newModel.values = (newModel.values||[]).filter((e) => e !== null);
    for (let V of newModel.values) {
      let NM = {};
      if (V.mappings) {
        for (let IV of V.mappings) {
          if (IV !== null) {
            NM[IV] = true;
          }
        }
      }
      V.mappings = NM;
    }
    data.onChange(newModel);
    saveModel(newModel)
  };

  const onEdit = () => {
    setEENo(eeNo + 1);
    let newModel = JSON.parse(JSON.stringify(model));
    for (let V of (newModel.values||[])) {
      if (V.mappings) {
        V.mappings = Object.keys(V.mappings);
      }
    }
    saveModel(newModel);
  };

  return (
    <NodeWrapper
      title='ENUM Remapper'
      onSave={() => onSave()}
      onEdit={() => onEdit()}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"450px"}
      data={data}
    >
      <div className='fcs-half'><ColumnSelect title='Input Column' value={model.column} onChange={(val) => saveModel({...model, column: val})} /></div>
      <div className='fcs-half'><ColumnSelect title='New Column' value={model.newName} onChange={(val) => saveModel({...model, newName: val})} /></div>
      <Checkbox value={!!model.isNumber} title='Outputs are Numbers' onChange={(val) => saveModel({...model, isNumber: !!val})} />
      <br/>
      <div onMouseDown={(e) => { e = e || window.event; /*e.preventDefault();*/ e.stopPropagation(); return false; }} key={"enum-edit-no-" + eeNo}>
        <div className='fcs-add-title'>NEW ENUM VALUES<div className='fcs-add-button' onClick={() => saveModel({...model, values: [...(model.values || []), {mappings: []}]})}><img src='/images/flow-add-blue.svg'/> Add Output Value</div></div>
        <div className='fcs-enum-values'>
          {(model.values||[]).map((val, idx) => (val && <div className='fcs-enum-value' key={eeNo + "fcstp-" + idx}>
            <div className='fcs-title'>Output Value {idx+1}</div>
            <Input type={model.isNumber ? 'number' : 'text'} defaultValue={model.isNumber ? parseFloat(val.newValue) || 0 : val.newValue} onChange={(val) => {model.values[idx].newValue = val; saveModel({...model})}} key={"fcstp-" + idx + "a" + !!model.isNumber} />
            <img className='fcs-delete-property' src='/images/flow-node-delete.svg' onClick={() => {model.values[idx] = null; saveModel({...model});}} />
            <div className='fcs-add-title'>INPUT VALUES<div className='fcs-add-button' onClick={() => {model.values[idx].mappings.push(''); saveModel({...model})}}><img src='/images/flow-add-blue.svg'/> Add Input Value</div></div>
            {val.mappings.map && val.mappings.map((mval, jdx) => (mval !== null && <div className='fcs-enum-input-val' key={eeNo + "fstp-" + idx + "-e-" + jdx}>
            <div className='fcs-title'>{jdx+1}.</div><Input type="text" defaultValue={mval} onChange={(val) => {model.values[idx].mappings[jdx] = val}} />
              <img className='fcs-delete-property' src='/images/flow-node-delete.svg' onClick={() => {model.values[idx].mappings[jdx] = null; saveModel({...model});}} />
            </div>))}
          </div>))}
        </div>
      </div>
    </NodeWrapper>
  );
  
});