import React, { Component } from 'react';
import { push } from 'connected-react-router';
import UsersForm from './UsersForm';
import actions from '../../../actions/usersFormActions';
import { connect } from 'react-redux';
//import { Alert } from 'reactstrap';
//import cx from 'classnames';

//import s from '../Users.module.scss';

class UsersFormPage extends Component {
  state = {
    dispatched: false,
    promoAlert: false,
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentUserId = currentUser.user.id;
    dispatch(actions.doFind(currentUserId));
    this.setState({ dispatched: true });

  }

  showPromoAlert() {
    this.setState({promoAlert: true});
  }

  doSubmit = (id, data) => {
    const { dispatch } = this.props;
    if (this.isEditing() || this.isProfile()) {
      dispatch(actions.doUpdate(id, data, this.isProfile()));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  isEditing = () => {
    const { match } = this.props;
    return !!match.params.id;
  };

  isProfile = () => {
    const { match } = this.props;
    return match.url === '/app/main/account';
  };



  isCorrectRole = () => {
    const myCurrentUser = this.props.currentUser;
    if( (myCurrentUser.role.includes('admin') || myCurrentUser.role.includes('remoteauditor') || myCurrentUser.role.includes('serviceorgmanager')) && myCurrentUser.app === 'ra'){
      return true;
    } else {
      return false;
    }
  };

  isThirdyPartyUser = () => {
    const isThirdParty = this.props.currentUser.isThirdParty ? this.props.currentUser.isThirdParty : false;
    return isThirdParty;
  }
  render() {
    return (
      <React.Fragment>
          <div className="page-top-line">
            <h2 className="page-title">User - <span className="fw-semi-bold">{this.isProfile() ? 'Edit My Account' : this.isCorrectRole() ? 'Edit' : 'Add'}</span></h2>
          </div>

          {this.state.dispatched && (
            <UsersForm
              saveLoading={this.props.saveLoading}
              findLoading={this.props.findLoading}
              currentUser={this.props.currentUser}
              record={
                (this.isEditing() || this.isProfile()) ? this.props.record : console.log('is record empty')
              }
              isEditing={this.isEditing()}
              isProfile={this.isProfile()}
              onSubmit={this.doSubmit}
              onCancel={() => this.props.dispatch(push('/admin/users'))}
            />
          )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.users.form.findLoading,
    saveLoading: store.users.form.saveLoading,
    record: store.users.form.record,
    currentUser: store.auth.currentUser,
  };
}

export default connect(mapStateToProps)(UsersFormPage);
