import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';

import { Input } from '../../../components/Common';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
 
  return (
    <NodeWrapper
      title='Value Remapper'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"320px"}
      data={data}
    >
      <div className='fcs-io-title'>INPUTS</div>
      <ColumnSelect title='Input Column' value={model.column} onChange={(val) => saveModel({...model, column: val})} />
      <div className='fcs-half'>
        <div className='fcs-title'>Start Value</div>
        <Input type="number" defaultValue={model.start_val} onChange={(val) => saveModel({...model, start_val: parseFloat(val)||0})} />
      </div>
      <div className='fcs-half'>
        <div className='fcs-title'>End Value</div>
        <Input type="number" defaultValue={model.end_val} onChange={(val) => saveModel({...model, end_val: parseFloat(val)||0})} />
      </div>
      <div className='fcs-title'>Distribution Skew Exponent</div>
      <Input type="number" defaultValue={model.skew_pow} onChange={(val) => saveModel({...model, skew_pow: Math.min(100, Math.max(0.01, parseFloat(val)||0))})} />
      <div className='fcs-io-title'>OUTPUTS</div>
      <ColumnSelect title='Output Column' value={model.output} onChange={(val) => saveModel({...model, output: val})} />
    </NodeWrapper>
  );
});