/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';
import Select from 'react-select';

import './HPDetails.scss';

class HPDetails extends React.Component {

    static propTypes = {
        is_upgrade: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        is_upgrade: false,
        onChange: (model) => { }
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.headOptions = [{value: null, label: `- Unspecified - `}, ...([1,2,3,4,5,6,7,8,9,10].map((v) => ({ value: v, label: `${v}`})))];

        this.state = {
            model: {

            },
            visible: false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if ((this.props.building?.id !== prevProps.building?.id) || JSON.stringify(this.props.building.hp_details_json) !== this.lasthp_details_json) {
            let model = {};
            try {
                model = JSON.parse(this.props.building.hp_details_json ?? '{}');
                this.setState({ model, rand: Math.random(), loaded: true });
                this.lasthp_details_json = JSON.stringify(this.props.building.hp_details_json);
            }
            catch (err) {
            }
        }
    }

    handleChange(e, parent) {
        parent = parent || e.target;
        parent = { name: parent.name, value: parent.value ?? e.value };
        let model = this.state.model;
        model[parent.name] = parent.value;
        this.props.building.hp_details_json = this.lasthp_details_json = JSON.stringify(model);
        this.setState({ model, rand: Math.random() });
        this.props.onChange(model);
    }

    fieldName(V) {
        return `hpDetails${this.props.is_upgrade ? 'U' : 'N'}${this.props.is_primary ? 'P' : 'S'}_${V}`;
    }

    render() {

        let title = 'Heat Pump Details';
        if (this.props.is_upgrade) {
            title = `Heat Pump Upgrade Details`;
        }
        else if (this.props.is_primary) {
            title = `Baseline Heat Pump Details`;
        }
        else {
            title = `Secondary Heat Pump Details`;
        }

        return (
            <div className='hp-details-cont'>
                <div className="ac-cont panel mb-xs" key="accordion-hp-details">
                    { /* eslint-disable */}
                    <div
                        className="ac-header panel-header" role="button"
                        onClick={() => {
                            this.setState({ visible: !this.state.visible });
                        }}>
                        { /* eslint-enable */}
                        <div className="mb-0">
                            {/* eslint-disable-next-line */}
                            <a className="accordion-toggle" role="button">
                                <div className={"ac-header-text"}><div className="rvc-error-dot"></div>{title}</div>
                                <i className={`fa fa-angle-down ${this.state.visible ? 'expanded' : ''}`} />
                            </a>
                        </div>
                    </div>
                    {this.state.loaded ? <Collapse className={'panel-body'} isOpen={this.state.visible}>
                        <br/><br/>
                        <div className='hpd-input-row'>
                            <Label for={this.fieldName('AHRI')}><span>AHRI #</span></Label>
                            <Input id={this.fieldName('AHRI')} type="text" defaultValue={this.state.model[this.fieldName('AHRI')]} name={this.fieldName('AHRI')} onChange={this.handleChange} />
                        </div>
                        <div className='hpd-input-row'>
                            <Label for={this.fieldName('OutdoorModelNum')}><span>Outdoor Model #</span></Label>
                            <Input id={this.fieldName('OutdoorModelNum')} type="text" defaultValue={this.state.model[this.fieldName('OutdoorModelNum')]} name={this.fieldName('OutdoorModelNum')} onChange={this.handleChange} />
                        </div>
                        <div className='hpd-input-row'>
                            <Label for={this.fieldName('IndoorModelNum')}><span>Indoor Model #</span></Label>
                            <Input id={this.fieldName('IndoorModelNum')} type="text" defaultValue={this.state.model[this.fieldName('IndoorModelNum')]} name={this.fieldName('IndoorModelNum')} onChange={this.handleChange} />
                        </div>
                        {this.props.has_heads ? <div className='hpd-input-row'>
                            <Label for={this.fieldName('NumHeads')}><span># of Heads</span></Label>
                            <Select
                                name={this.fieldName('NumHeads')}
                                classNamePrefix="react-select"
                                className="selectCustomization"
                                options={this.headOptions}
                                value={this.state.model[this.fieldName('NumHeads')] ? this.headOptions.filter((e) => e.value == this.state.model[this.fieldName('NumHeads')]) : null}
                                onChange={this.handleChange}
                            />
                        </div> : undefined}
                    </Collapse> : undefined}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let building = state.buildings.curBuilding.building || {};
    return { building };
}
export default withRouter(connect(mapStateToProps)(HPDetails));
