import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getENCustomers } from '../../actions/energy-navigator';
import { getBuildingsRequest } from '../../actions/buildings';
import { RandomKey, Dropdown, ResponsiveTable } from '../../components/Common';
import CustomerCard from '../enCustomers/components/CustomerCard';
import CreateUserModal from '../../components/CreateUserModal';
import CreateOrgModal from '../../components/CreateOrgModal';
import CreateBuildingModal from '../../components/CreateBuildingModal';
import UserRow from './components/UserRow';
import OrgRow from './components/OrgRow';
import BuildingRow from './components/BuildingRow';

import './ENUserManagement.scss';

class ENUserManagement extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customers: PropTypes.array
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {
      editUser: null,
      addUser: false,
      editBuilding: null,
      addBuilding: false,
      editOrg: null,
      addOrg: false,
      userTableKey: 0,
      orgTableKey: 0,
      buildingTableKey: 0
    };
  }

  componentDidMount() {
    this.key = RandomKey();
    this.props.dispatch(getENCustomers());
  }

  onTableMessage(name, data) {
    switch (name) {
      case 'edit-user':
        this.setState({ editUser: data });
        break;
      case 'edit-org':
        this.setState({ editOrg: data });
        break;
      case 'enable-user':
      case 'disable-user':
        axios({
          url: `/api/users/${data.id}/set-status`,
          method: 'PUT',
          responseType: 'json',
          data: {
            status: name === 'enable-user'
          }
        }).then((res) => {
          this.setState({ userTableKey: Math.random() });
        });
        break;
      case 'edit-building':
        this.setState({ editBuilding: data });
        break;
      default:
        break;
    }
  }

  render() {
    if (!this.props.customers) {
      return (<div/>);
    }
    return (
      <div className="enusermanagement-container" key={this.key}>
        <div className="page-title-white">User Management</div>
        <div className="en-button-primary" onClick={() => {this.setState({ addUser: true })}}>Add User</div>
        <br/>
        <ResponsiveTable endpoint="/api/users" rowClass={UserRow} pagination={true} onMessage={(name, data) => {this.onTableMessage(name, data)}} refreshKey={this.state.userTableKey} />
        <br/>
        <br/>
        <div className="page-title-white">Organization Management</div>
        {/*<div className="en-button-primary" onClick={() => {this.setState({ addOrg: true })}}>Add Organization</div>*/}
        <ResponsiveTable endpoint="/api/organizations" rowClass={OrgRow} pagination={true} onMessage={(name, data) => {this.onTableMessage(name, data)}} refreshKey={this.state.orgTableKey} />
        <br/>
        <br/>
        <div className="page-title-white">Building Management</div>
        {/*<div className="en-button-primary" onClick={() => {this.setState({ addBuilding: true })}}>Add Building</div>*/}
        <ResponsiveTable endpoint="/api/buildings" rowClass={BuildingRow} pagination={true} onMessage={(name, data) => {this.onTableMessage(name, data)}} refreshKey={this.state.buildingTableKey} />
        {this.state.editUser && <CreateUserModal
          edit={this.state.editUser}
          onClose={() => { this.setState({editUser: null, userTableKey: Math.random()}) }}
        />}
        {this.state.addUser && <CreateUserModal
          onClose={() => { this.setState({addUser: false, userTableKey: Math.random() }) }}
        />}
        {this.state.editOrg && <CreateOrgModal
          edit={this.state.editOrg}
          onClose={() => { this.setState({editOrg: null, orgTableKey: Math.random()}) }}
        />}
        {this.state.addOrg && <CreateOrgModal
          onClose={() => { this.setState({addOrg: false, orgTableKey: Math.random()}) }}
        />}
        {this.state.editBuilding && <CreateBuildingModal
          edit={this.state.editBuilding}
          onClose={() => { this.setState({editBuilding: null, buildingTableKey: Math.random() }) }}
        />}
        {this.state.addBuilding && <CreateBuildingModal
          onClose={() => { this.setState({addBuilding: false, buildingTableKey: Math.random() }) }}
        />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buildings: state.buildings.data,
    customers: state.energyNavigator.allENCustomers,
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(ENUserManagement);
