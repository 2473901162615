import { getBuildingRequest, getBuildingRequestLM, updateBuildingRequest, updateCustomerRequest, resetBuildingType1Request, downloadReportRequest, getReportBase64, publishReportRequest, publishAndDownloadReportRequest } from '../../actions/buildings';
import React, { PureComponent, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import './RAHomeownerQuestions.scss';

const QuestionTitles = {
    "house_type": "What type of house do you have?",
    "year_built": "Approximately what year was your house built?",
    "do_rent": "Do you own or rent your home?",
    "customer_priorities": "Please rank your priorities when it comes to making energy efficiency decisions",
    "occupancy": "How many people normally live in your home?",
    "draft": "How drafty is your home?",
    "storeys": "How many heated floors/storeys does your house have?",
    "total_floor_area_sqft": "What is the total floor area of your home? Only include areas you heat.",
    "footprint": "What is the area of your first floor? This helps us know the size of your building\'s footprint.",
    "foundation_type": "What type of foundation do you have?",
    "heated_basement": "Is your basement heated?",
    "basement_area_percent": "Approximately how much basement area do you heat?",
    "heated_crawlspace": "Is your crawlspace heated?",
    "crawlspace_area_percent": "Approximately how much crawlspace area do you heat?",
    "foundation_insulation_nominal": "How much insulation is on your foundation walls?",
    "floor_above_crawlspace_insulation_nominal": "How much insulation is in the floor above your crawlspace?",
    "ceiling_insulation_nominal": "How much insulation is in your ceiling/attic?",
    "main_wall_insulation_nominal": "How much insulation is in your walls?",
    "exterior_doors": "How many exterior doors do you have?",
    "windows": "How many windows do you have?",
    "estar_windows_percent": "How many of your windows are ENERGY STAR (or energy efficient)?",
    "estar_doors_percent": "How many of your doors are ENERGY STAR (or energy efficient)?",
    "thermostat_types": "What type of thermostat(s) do you have? Choose all that apply.",
    "primary_heating_system": "What kind of primary heating system do you have?",
    "primary_heating_fuel_type": "What fuel type does your primary heating system use?",
    "heating_system_capacity_btu_hr": "What is the capacity of your primary heating system?",
    "primary_heating_system_efficiency": "What is the steady state efficiency of your primary heating system?",
    "primary_heating_system_seasonal_efficiency": "What is the seasonal efficiency of your primary heating system?",
    "hp_source": "What is the source of your heat pump? (Almost all are Air)",
    "hp_type": "What Type of Primary Heat Pump do you have?",
    "hp_age": "How old is your Primary Heat Pump?",
    "hp_area_percent": "How much of your home does your Primary Heat Pump heat?",
    "hp_capacity_btu_hr": "What is the capacity of your heat pump?",
    "hp_efficiency": "What is the HSPF Rating of your heat pump?",
    "has_secondary_heating_system": "Do you use a secondary heating system?",
    "secondary_heating_system": "What kind of secondary heating system do you have?",
    "secondary_heating_fuel_type": "What fuel type does your secondary heating system use?",
    "secondary_hp_source": "What is the source of your secondary heat pump? (Almost all are Air)",
    "secondary_hp_type": "What Type of Secondary Heat Pump do you have?",
    "secondary_hp_age": "How old is your Secondary Heat Pump?",
    "secondary_hp_area_percent": "How much of your home does your Secondary Heat Pump heat?",
    "secondary_hp_capacity_btu_hr": "What is the capacity of your secondary heat pump?",
    "secondary_hp_efficiency": "What is the HSPF Rating of your secondary heat pump?",
    "air_conditioner_type": "What type of cooling / air conditioner do you have?",
    "water_heating_system": "What type of water heating system do you have?",
    "water_heating_fuel_type": "What fuel type does your water heating system use?",
    "water_heating_efficiency": "What is the efficiency rating of your water heating system?",
    "hot_water_usage": "How much hot water does your household use?",
    "primary_fridge_type": "Tell us about your primary refrigerator",
    "primary_fridge_age": "How old is your primary refrigerator?",
    "secondary_fridge_type": "Do you have a second refrigerator/freezer?",
    "secondary_fridge_age": "How old is your second refrigerator/freezer?",
    "lights_type": "Tell us about your lights",
    "lights_count": "Approximately how many lights do you have?",
    "lights_hours": "On average, how long are your lights on every day?",
    "washing_machine_age": "How old is your washing machine?"
};

const DefaultSteps = [
    {
        title: 'Home Details',
        questions: [
            "house_type",
            "year_built",
           // "do_rent",
            "customer_priorities",
            "occupancy",
            "draft",
            "storeys",
            "total_floor_area_sqft",
            "footprint",
            "foundation_type",
            "heated_basement",
            "basement_area_percent",
            "heated_crawlspace",
            "crawlspace_area_percent"
        ]
    },
    {
        title: 'Insulation',
        questions: [
            "foundation_insulation_nominal",
            "floor_above_crawlspace_insulation_nominal",
            "ceiling_insulation_nominal",
            "main_wall_insulation_nominal",
            "exterior_doors",
            "windows",
            "estar_windows_percent",
            "estar_doors_percent"
        ]
    },
    {
        title: 'Heating and Cooling',
        questions: [
            "thermostat_types",
            "primary_heating_system",
            "primary_heating_fuel_type",
            "heating_system_capacity_btu_hr",
            "primary_heating_system_efficiency",
            "primary_heating_system_seasonal_efficiency",
            //"hp_source",
            "hp_type",
            "hp_age",
            "hp_area_percent",
            "hp_capacity_btu_hr",
            "hp_efficiency",
            "has_secondary_heating_system",
            "secondary_heating_system",
            "secondary_heating_fuel_type",
            //"secondary_hp_source",
            "secondary_hp_type",
            "secondary_hp_age",
            "secondary_hp_area_percent",
            "secondary_hp_capacity_btu_hr",
            "secondary_hp_efficiency",
            "air_conditioner_type",
            "water_heating_system",
            "water_heating_fuel_type",
            "water_heating_efficiency",
            "hot_water_usage"
        ]
    },
    {
        title: 'Appliances',
        questions: [
            "primary_fridge_type",
            "primary_fridge_age",
            "secondary_fridge_type",
            "secondary_fridge_age",
            "lights_type",
            "lights_count",
            "lights_hours",
            "washing_machine_age"
        ]
    }/*,
    {
        title: 'Other',
        questions: [
            
        ]
    }*/
]

const SortableItem = SortableElement(({ value }) => <li className="list-group-item bg-widget-transparent" style={{cursor: "move"}}>
  <i className="fa fa-sort" />
  &nbsp;&nbsp;&nbsp;
  {QuestionTitles[value]}
</li>);

const SortableList = SortableContainer(({ items }) => (
  <ul className="list-group list-group-sortable mt-xs">
    {items.map((value, index) => (
      <SortableItem key={`item-${index.toString()}`} index={index} value={value} />
    ))}
  </ul>
));

class RAHomeownerQuestions extends PureComponent {
    constructor(props) {
      super(props);
      
      this.state = {
        steps: JSON.parse(JSON.stringify(DefaultSteps))
      };
    }

    static propTypes = {
    };

    static defaultProps = {
    }; 

    componentDidMount() {
    }
    
    componentDidUpdate(prevProps) {
    }
  
    onSortEnd = (idx) => {
        return ({ oldIndex, newIndex }) => {
            let old = this.state.steps[idx].questions[oldIndex];
            this.state.steps[idx].questions.splice(oldIndex, 1);
            this.state.steps[idx].questions.splice(newIndex, 0, old);
            this.setState({ steps: this.state.steps, rand: Math.random() });
        };
    };

    render() {
        return (
            <div>
                <h1>Homeowner Questions Order</h1>        
                {this.state.steps.map((step, idx) => <div className='hqo-step' key={'hqo-step-' + idx}>
                    <br/>
                    <h2>{step.title}</h2>
                    <SortableList items={step.questions} onSortEnd={this.onSortEnd(idx)} />
                </div>)}
            </div>
        );
    }
}

function mapStateToProps(state) {
}

export default withRouter(connect(mapStateToProps)(RAHomeownerQuestions));
