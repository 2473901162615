import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';

import '../Common.scss';

class TabBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      options: props.options,
      selectedTab: 0,
      bubbleOpened: false,
      autoRequireTabWhen: {}
    }

    this.triggerOnChange();
  }

  newOptions() {
    let opts = [];
    for (let i=0; i<this.state.options.length; i++) {
      if (!this.state.options[i].chosen) {
        opts.push(this.state.options[i]);
      }
    }
    return opts;
  }

  triggerOnChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.options[this.state.selectedTab], this.state.options, this.getTabDict());
    }
  }

  closeBubble(e) {
    if (!this.state.bubbleOpened) {
      return;
    }

    if (!e) {
      return false;
    }

    if (e.target.className.indexOf('en-dd-option') >= 0) {
      return;
    }

    this.setState({ bubbleOpened: false });

    return false;
  }

  selectBubbleOption(e, val) {
    for (let i=0; i<this.state.options.length; i++) {
      if (this.state.options[i] === val) {
        this.state.options[i].chosen = true;
        break;
      }
    }
    this.triggerOnChange();
    this.setState({ options: this.state.options });
    if (this.state.bubbleOpened) {
      return this.closeBubble(e);
    }
  }

  openBubble(e) {

    if (!e) {
      return false;
    }

    this.setState({ bubbleOpened: !this.state.bubbleOpened });

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  componentDidMount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
    }
    this.onClick = (e) => {
      if (e.target)
      return this.closeBubble(e || window.event);
    };
    document.body.addEventListener('click', this.onClick);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.state.autoRequireTabWhen) !== JSON.stringify(this.props.autoRequireTabWhen || {})) {
      this.setState({ autoRequireTabWhen: this.props.autoRequireTabWhen || {} })
      if (this.props.autoRequireTabWhen) {
        for (let key in this.props.autoRequireTabWhen) {
          if (this.props.autoRequireTabWhen[key]) {
            for (let i=0; i<this.state.options.length; i++) {
              if (this.state.options[i].key === key) {
                this.selectBubbleOption(window.event, this.state.options[i]);
                break;
              }
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.onClick) {
      document.body.removeEventListener('click', this.onClick);
      this.onClick = null;
    }
  }

  removeTab(e, val) {
    let selectedTab = this.state.selectedTab;
    for (let i=0; i<this.state.options.length; i++) {
      if (this.state.options[i] === val) {
        this.state.options[i].chosen = false;
        if (selectedTab === i) {
          selectedTab = 0;
        }
      }
    }
    this.state.selectedTab = selectedTab;
    this.setState({ options: this.state.options, selectedTab });
    this.triggerOnChange();
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  getTabDict() {
    let dict = {};
    for (let i=0; i<this.state.options.length; i++) {
      let O = this.state.options[i];
      if (O.chosen) {
        dict[O.key] = true;
      }
    }
    return dict;
  }

  render() {
    return (
      <div key={this.key} className='en-tab-builder'>
        {this.state.options.map((opt, index) => (
          opt.chosen && (<div key={this.key + '-tab-' + index} className={'insight-tab ' + (this.state.selectedTab === index ? 'tab-selected' : '')} style={{width: opt.width + 'px'}} onClick={() => {this.setState({selectedTab: index}); this.state.selectedTab = index; this.triggerOnChange();}}>
            {opt.title}
            {(opt.required||this.state.autoRequireTabWhen[opt.key]) && (<span className='opt-required'>*</span>)}
            {!(opt.required||this.state.autoRequireTabWhen[opt.key]) && this.state.selectedTab === index && (<img src='/images/x-icon-blue.svg' onClick={(e) => {return this.removeTab(e || window.event, opt);}}/>)}
            {!(opt.required||this.state.autoRequireTabWhen[opt.key]) && this.state.selectedTab !== index && (<img src='/images/x-icon.svg' onClick={(e) => {return this.removeTab(e || window.event, opt);}}/>)}
          </div>)
        ))}
        <div className={'add-button ' + (!this.newOptions().length ? 'disabled' : '') } onClick={(e) => (this.openBubble(e || window.event))}>
          <img src='/images/plus-icon-white.svg'/>
          {this.state.bubbleOpened && <div className='en-dd-bubble'>
            {this.newOptions().map((val, idx) => (
              !val.chosen && (<div key={this.key + '-opt-' + idx} className={'en-dd-option ' + (idx === 0 ? ' first' : idx === (this.newOptions().length-1) ? ' last' : '')} onClick={(e) => (this.selectBubbleOption(e || window.event, val))}>{val.title}</div>)
            ))}
          </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(TabBuilder));
