import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, CompletenessSlider, CompletenessMessage, RandomKey, Modal, AttachmentUploader } from '../../../components/Common'
import './BuildingAttachments.scss';
import { updateBuildingAttachments, getBuildingRequest } from '../../../actions/buildings';
import config from '../../../config';

class BuildingAttachments extends Component {

  constructor(props) {
    super(props);
    this.key = RandomKey();
    this.state = {
      modalOpen: false,
      expanded: false,
      newFiles: [],
      curFiles: [],
      stateBuildingId: ''
    };
    this.onLoad(props.data, true);
  }

  onChangeFiles(tab) {
    return (files) => {
      this.setState({ newFiles: files });
    };
  }

  onSaveAttachments(files) {
    let attachments = [];
    for (let F of files) {
      attachments.push(F.id);
    }
    this.props.dispatch(updateBuildingAttachments(this.props.buildingId, attachments));
    this.props.dispatch(getBuildingRequest(this.props.buildingId));
    this.setState({ modalOpen: false });
    this.setState({ curFiles: JSON.parse(JSON.stringify(files)) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buildingId != this.props.buildingId) {
      this.onLoad(this.props.data);
    }
  }

  beforeModalOpen() {
    this.state.newFiles = JSON.parse(JSON.stringify(this.state.curFiles));
    for (let F of this.state.newFiles) {
      F.status = 'success';
      F.progress = 100;
    }
    this.setState({ newFiles: this.state.newFiles });
  }

  onLoad(attachments, isInit) {
    //If the building changes reset files
    if (attachments != undefined) {
      this.state.newFiles = [];
      this.state.curFiles = [];
      for (let F of attachments) {
        this.state.newFiles.push({
          id: F.attachment_id,
          file_name: F.fileName,
          file_size: F.size,
          status: 'success',
          progress: 100,
          date: F.created_at,
          link: F.link
        });
        this.state.curFiles.push({
          id: F.attachment_id,
          file_name: F.fileName,
          file_size: F.size,
          link: F.link,
          date: F.created_at
        });
      }
      if (!isInit) {
        this.setState({ newFiles: this.state.newFiles });
        this.setState({ curFiles: this.state.curFiles });
      }
    }
  }
  render() {
    const { buildingId, data, currentUser } = this.props;
    const role = currentUser && currentUser.role && currentUser.role[0];
    const rolesContain = (...args) => {
      for (let i=0; i<args.length; i++) {
        if (role && role.indexOf(args[i]) >= 0) {
          return true;
        }
      }
      return false;
    };
    if (window.location.href.indexOf(buildingId) < 0 || !role) {
      return <div />;
    }

    return (
      <div key={this.key} className={'building-attachments ' + (this.state.expanded ? 'expanded' : '')}>
        <div className="ba-title">Files & Photos</div>
        <Modal
          opened={this.state.modalOpen}
          title={"Attachments"}
          width="708px" height="518px"
          onClose={() => {
            this.setState({ modalOpen: false });
          }}
          hasCancelButton={true}
          buttons={[
            {
              title: 'Save Uploads',
              className: 'en-button-primary',
              onClick: () => {
                this.onSaveAttachments(this.state.newFiles || []);
              }
            }
          ]}
        >
          <AttachmentUploader width="659px" value={this.state.newFiles} onChange={this.onChangeFiles()} />
        </Modal>
        <img className='dd-arrow float-right' onClick={() => { this.setState({ expanded: !this.state.expanded }) }} src={this.state.expanded ? '/images/rte-dd-up-arrow.svg' : '/images/rte-dd-down-arrow.svg'} />
        {rolesContain('admin', 'portfolioowner') && <div className="en-button-primary float-right" onClick={() => { this.beforeModalOpen(); this.setState({ modalOpen: true }); }}>Upload Files</div>}
        {this.state.expanded && !this.state.curFiles.length && <div className='nf-cont'>
          <div className='no-files-1'>No Available Files</div>
          {rolesContain('admin', 'portfolioowner') ? <div className='no-files-2'>Click Upload Files button to get started</div> : <div className='no-files-2'></div>}
        </div>}
        {this.state.expanded && this.state.curFiles && <div className='file-cont'>
          {this.state.curFiles.map((file, idx) => (<div className='file' key={this.key + 'f' + idx}>
            <img className='file-icon' src='/images/file-icon.svg' />
            <div className='file-right'>
              <div className='file-name' title={file.file_name}>{file.file_name}</div>
              <div className='file-info'>{Format.Bytes(file.file_size)} | {(file.date ? moment(file.date) : moment()).format('MMM. D, YYYY')}</div>
            </div>
            <a href={file.link} target='_blank'><img src="/images/download-file.svg" /></a>
          </div>))}
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(BuildingAttachments);
