import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, RandomKey, TableSort } from '../../../components/Common'
import './UserRow.scss';

class UserRow extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    const roleNames = {
      "contractor": "Contractor",
      "admin": "Admin",
      "property manager": "Property Manager",
      "propertymanager": "Property Manager",
      "utilityadmin": "Utility Admin",
      "remoteauditor": "Remote Auditor",
      "organization": "Organization",
      "helpdesk": "Help Desk",
      "portfolioowner": "Portfolio Owner",
      "portfoliomanager": "Portfolio Manager",
      "serviceprovider": "Service Provider",
      "programmanager": "Program Manager",
      "energyadvisor": "Energy Advisor"
    };;

    let roles = {...(data.role || {})};
    let roles2 = [];
    let sites = [];
    for (let key in roles) {
      roles2.push(roleNames[roles[key]]);
      if (key === 'b360') {
        sites.push("Building360");
      }
      else if (key === 'ra') {
        sites.push("Energy Advisor");
      }
      else if (key === 'en') {
        sites.push("Energy Navigator");
      }
    }
    let rolesStr = roles2.join(', ');

    let cols = [ 20, 20, 30, 10, 20 ];

    let col = (idx) => {
      return { width: `${cols[idx]}%`, display: "inline-block", verticalAlign: "top" };
    };

    if (this.props.header) {
      return (
        <div key={this.key} className='user-card table-header'>
          <div style={col(0)}>
            <div className='user-header-split-top'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='first_name'>
                First & Last Name
              </TableSort>
            </div>
            <div className='user-header-split-bottom'>Email Address</div>
            <div className='user-header-split-bottom-2'>Phone Number</div>
            <div className='user-header-split-bottom-2'>User ID</div>
          </div>
          <div style={col(1)}>
            <div className='user-header-split-top'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='org_name'>
                Organization
              </TableSort>
            </div>
            <div className='user-header-split-bottom'>Org. Type</div>
          </div>
          <div style={col(2)}>
            <div className='user-header-split-top'>
              User Roles
            </div>
            <div className='user-header-split-bottom'>
              Sites
            </div>
          </div>
          <div style={col(3)}>
            <div className='user-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='status'>
                Status
              </TableSort>
            </div>
          </div>
          <div style={col(4)}>
            <div className='user-header-cell'>Actions</div>
          </div>
        </div>
      )
    }

    return (
      <div key={this.key} className='user-card'>
        <div style={col(0)}>
          <div className='user-cell-split-top'>{data.first_name} {data.last_name}</div>
          <div className='user-cell-split-bottom'>{data.email || ''}</div>
          <div className='user-cell-split-bottom-2'>{data.phone_number || ''}</div>
          <div className='user-cell-split-bottom-2'>{data.id}</div>
        </div>
        <div style={col(1)}>
          <div className='user-cell-split-top'>{data.org_name || ''}</div>
          <div className='user-cell-split-bottom'>{data.org_type || ''}</div>
        </div>
        <div style={col(2)}>
          <div className='user-cell-split-top'>{rolesStr}</div>
          <div className='user-cell-split-bottom'>{sites.join(", ")}</div>
        </div>
        <div style={col(3)}>
          <div className='user-cell'>{data.status ? 'Active' : 'Disabled'}</div>
        </div>
        <div style={col(4)}>
          <div className='en-button-primary' onClick={() => {this.props.onMessage('edit-user', data);}} style={{marginRight: "12px"}}>Edit</div>
          {data.status ? (this.props.currentUser.id != data.id && <div className='en-button-secondary-red' onClick={() => {this.props.onMessage('disable-user', data);}}>Disable Account</div>) :
                         (this.props.currentUser.id != data.id && <div className='en-button-secondary' onClick={() => {this.props.onMessage('enable-user', data);}}>Enable Account</div>)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(UserRow);
