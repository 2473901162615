import React, { memo, useState, useRef, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { toTableFormat } from '../NodeTypes/helpers';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import { rgbToHex, hexToRgb } from '../NodeTypes/helpers';
import moment from 'moment';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2ltcHRlayIsImEiOiJja3ltem9nMzMwNzhzMnZwbmNwb3hxcDFnIn0.9gGHzt9qVQHfnofHux-wtg';

window._IM_intervalId = -1;
window._IM_StatusCnt = 0;

window.MapTile = function(url, loadCbk, insightsOnly) {

    this.features = [];
    this.lineFeatures = [];
    this.url = url;
    this.loaded = false;
    this.loadCbk = loadCbk;
    this.destroyed = false;

    const CancelToken = axios.CancelToken;
    this.cancelFn = null;

    let featureCenter = (feature) => {
        let minLon = 180, maxLon = -180, minLat = 90, maxLat = -90;
        let scan = (array) => {
            for (let subArray of array) {
                if (subArray.length == 2 && typeof subArray[0] == 'number') {
                    minLon = Math.min(minLon, subArray[0]);
                    maxLon = Math.max(maxLon, subArray[0]);
                    minLat = Math.min(minLat, subArray[1]);
                    maxLat = Math.max(maxLat, subArray[1]);
                }
                else {
                    scan(subArray);
                }
            }
        }
        scan(feature.geometry.coordinates);
        return { longitude: (minLon+maxLon)*0.5, latitude: (minLat+maxLat)*0.5 };
    };

    this.bfrTimeout = setTimeout(() => {
        this.bfrTimeout = -1;
        axios.get(url, {
            cancelToken: new CancelToken((c) => {
                this.cancelFn = c;
            })
        }).then(async (res) => {
            this.cancelFn = null;
            if (this.destroyed || this.loaded) {
                return;
            }
            if (res && res.data && res.data.type === 'FeatureCollection') {
                this.features = res.data.features;
                this.loaded = true;
    
                let palette = [];
                let ql = 16;
                let spow = 1.;
                let _start_color = hexToRgb('#00FF00');
                let _end_color = hexToRgb('#FF0000');
                for (let i=0; i<ql; i++) {
                    let t = Math.pow(i / (ql-1), spow);
                    let newR = Math.floor((_end_color.r - _start_color.r) * t + _start_color.r);
                    let newG = Math.floor((_end_color.g - _start_color.g) * t + _start_color.g);
                    let newB = Math.floor((_end_color.b - _start_color.b) * t + _start_color.b);
                    palette.push(rgbToHex(newR, newG, newB))
                }
                for (let i=0; i<this.features.length; i++) {
                    let F = this.features[i];
                    if (!F.properties || !F.properties.eui) {
                        F.properties = F.properties || { "No Insights": true };
                        F.properties.__color = '#141A35';
                        F.properties.__height = 1.5;
                    }
                    else {
                        let eui = parseFloat(F.properties.eui);
                        let t = (Math.max(0, Math.min(1, (eui - 0.1) / (1.-0.1)))) || 0.;
                        F.properties.__color = palette[Math.floor(t*0.99999*palette.length)];
                        F.properties.__height = parseFloat(F.properties.floors || F.properties.storeys) * 3;
                    }
                }
                for (let feature of this.features) {
                    let center = featureCenter(feature);
                                     
                    if (!(parseFloat(feature.properties.longitude) || parseFloat(feature.properties.latitude))) {
                        continue;
                    }
                    this.lineFeatures.push({
                        type: 'Feature',
                        properties: feature.properties,
                        geometry: {
                            type: 'LineString',
                            coordinates: [
                                [ center.longitude, center.latitude ],
                                [ parseFloat(feature.properties.longitude), parseFloat(feature.properties.latitude) ]
                            ]
                        }
                    });
                }
    
                if (this.loadCbk) {
                    this.loadCbk(this);
                }
            }
        }).catch((err) => {
            console.error(err);
            setTimeout(() => {
                this.destroy();
                this.failed = true;
                this.cancelFn = null;   
            }, 10000)
        });
    }, 1000);

};

window.MapTile.prototype.destroy = function() {

    if (this.bfrTimeout >= 0) {
        clearTimeout(this.bfrTimeout);
        this.bfrTimeout = -1;
    }
    if (this.cancelFn) {
        this.cancelFn();
        this.cancelFn = null;
    }
    this.destroyed = true;
    this.features = undefined;
    this.loadCbk = undefined;

};

window.TileMap = function(map, url, tile_size, boxQuery, source) {

    this.map = map;
    this.url = url;
    this.tile_size = tile_size || [ 0.05, 0.1, 0.2 ];
    this.optTileCount = 12;
    this.maxTileCount = 32;
    this.tiles = {};
    this.source = source || 'all';
    this.insightsOnly = false;
    this.nbDate = null;
    this.naDate = null;

    this.update = this.update.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.boxQuery = !!boxQuery;

};

window.TileMap.prototype.update = function() {

    let levels = [];
    for (let level=0; level<this.tile_size.length; level++) {
        levels.push(this.getTileURLs(level));
    }

    levels.sort((a, b) => Math.abs(this.optTileCount - (a.length || -1000)) - Math.abs(this.optTileCount - (b.length || -1000)));

    let urls = levels[0];

    let deleteTiles = [];
    for (let url in this.tiles) {
        if (urls.indexOf(url) < 0 || this.tiles[url].failed) {
            deleteTiles.push(url);
        }
    }
    for (let url of deleteTiles) {
        this.tiles[url].destroy();
        this.tiles[url] = undefined;
        delete this.tiles[url];
    }

    let totalTiles = 0;
    let tilesLoaded = 0;
    for (let url of urls) {
        if (!this.tiles[url]) {
            this.tiles[url] = new window.MapTile(url, this.updateMap, this.insightsOnly);
        }
        totalTiles += 1;
        if (this.tiles[url].loaded) {
            tilesLoaded += 1;
        }
    }

    return totalTiles > 0 ? Math.round(100 * tilesLoaded / totalTiles) : 100;

};

window.TileMap.prototype.setSource = function(source) {
    this.source = source || 'all';
    this.updateMap();
};

window.TileMap.prototype.setInsightsOnly = function(flag) {
    this.insightsOnly = flag;
    this.updateMap();
}

window.TileMap.prototype.setNBDate = function(val) {
    this.nbDate = val ? moment(val, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD']) : null;
    this.updateMap();
}

window.TileMap.prototype.setNADate = function(val) {
    this.naDate = val ? moment(val, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD']) : null;
    this.updateMap();
}

window.TileMap.prototype.setLFJob = function(val) {
    this.filterJob = val;
    this.updateMap();
}

window.TileMap.prototype.updateMap = function() {

    let geoJSON = {
        type: "FeatureCollection",
        features: []
    };

    let lineGeoJSON = {
        type: "FeatureCollection",
        features: []
    };

    for (let url in this.tiles) {
        for (let F of this.tiles[url].features) {
            if (!F) {
                continue;
            }
            if (this.source != 'all' && F.properties && ((F.properties.source && F.properties.source != this.source) || (F.properties.polygon_source && F.properties.polygon_source != this.source))) {
                continue;
            }
            if (!F.properties || !F.properties.eui) {
                if (this.insightsOnly == 'insights') {
                    continue;
                }
            }
            else if (this.insightsOnly == 'buildings') {
                F = {...F};
                F.properties = {
                    __color: '#141A35',
                    __height: 1.5,
                    source: F.polygon_source
                };
            }
            if (F.properties && F.properties.created_at) {
                let date = moment(F.properties.created_at, 'YYYY-MM-DD HH:mm');
                if (this.naDate && date.isAfter(this.naDate)) {
                    continue;
                }
                if (this.nbDate && date.isBefore(this.nbDate)) {
                    continue;
                }
            }
            if (F.properties && F.properties.job_id && this.filterJob && this.filterJob != F.properties.job_id) {
                continue;
            }
            geoJSON.features.push(F);
        }
        for (let F of this.tiles[url].lineFeatures) {
            if (!F) {
                continue;
            }
            if (this.source != 'all' && F.properties && ((F.properties.source && F.properties.source != this.source) || (F.properties.polygon_source && F.properties.polygon_source != this.source))) {
                continue;
            }
            if (!F.properties || !F.properties.eui) {
                if (this.insightsOnly == 'insights') {
                    continue;
                }
            }
            else if (this.insightsOnly == 'buildings') {
                F = {...F};
                F.properties = {
                    __color: '#141A35',
                    __height: 1.5,
                    source: F.polygon_source
                };
            }
            if (F.properties && F.properties.created_at) {
                let date = moment(F.properties.created_at, 'YYYY-MM-DD HH:mm');
                if (this.naDate && date.isAfter(this.naDate)) {
                    continue;
                }
                if (this.nbDate && date.isBefore(this.nbDate)) {
                    continue;
                }
            }
            if (F.properties && F.properties.job_id && this.filterJob && this.filterJob != F.properties.job_id) {
                continue;
            }
            lineGeoJSON.features.push(F);
        }
    }

    if (this.map) {
        this.map.getSource('location-s').setData(geoJSON);
        this.map.getSource('lines-s').setData(lineGeoJSON);
    }

};

window.TileMap.prototype.getTileURLs = function(level) {

    let bounds = this.map && this.map.getBounds();
    if (bounds) {
        let minB = bounds.getSouthWest();
        let maxB = bounds.getNorthEast();
        let tSize = this.tile_size[level];
        
        let minX = Math.floor(minB.lng / tSize),
            minY = Math.floor(minB.lat / tSize);
        let maxX = Math.ceil(maxB.lng / tSize),
            maxY = Math.ceil(maxB.lat / tSize);

        if ((maxX-minX) * (maxY-minY) > this.maxTileCount) {
            return [];
        }

        let ret = [];
        for (let x=minX; x<maxX; x++) {
            for (let y=minY; y<maxY; y++) {
                let url = null;
                if (this.boxQuery) {
                    url = `${this.url}/${y*tSize}/${x*tSize}/${(y+1)*tSize}/${(x+1)*tSize}`;
                }
                else {
                    url = `${this.url}/${level+1}/${x}/${y}`;
                    /*if (this.source != 'all') {
                        url += `?source=${this.source}`;
                    }*/
                }
                ret.push(url);
            }
        }   
        return ret;
    }
    return [];

};

window.TileMap.prototype.destroy = function() {

    for (let url in this.tiles) {
        this.tiles[url].destroy();
        this.tiles[url] = undefined;
    }
    this.tiles = undefined;
    this.map = undefined;

};

export default memo(({ job_id, global, source, insightsOnly, nothingAfterDate, nothingBeforeDate, filterJobID }) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const tileMap = useRef(null);
    const [lng, setLng] = useState(-63.5752);
    const [lat, setLat] = useState(44.6488);
    const [zoom, setZoom] = useState(9);
    const [loaded, setLoaded] = useState(false);
    const [filename, setFileName] = useState("untitled-export");
    const [percentComplete, setPercentComplete] = useState("");
    const [tileLoadPercent, setTileLoadPercent] = useState(0);
    const [lSource, setLSource] = useState("all");
    const [lInsightsOnly, setLInsightsOnly] = useState(false);
    const [lNADate, setLNADate] = useState("");
    const [lNBDate, setLNBDate] = useState("");
    const [lFJob, setLFJob] = useState(null);

    useEffect(() => {
        let lastSize = [-1, -1];

        if (window._IM_intervalId < 0) {
            window._IM_LAST_URL = '';
            window._IM_intervalId = window.setInterval(() => {
                let el = document.getElementById('flow-map-output');
                if (!el) {
                    return;
                }
                let size = [el.offsetWidth, el.offsetHeight];
                if (size[0] !== lastSize[0] || size[1] !== lastSize[1] && map && map.current) {
                    map.current.resize();
                    lastSize = size;
                }
            }, Math.ceil(1000 / 30));
            window._IM_StatusCnt = 1200;
            window._IM_intervalId2 = window.setInterval(() => {
                window._IM_StatusCnt += 1;
                if (window._IM_StatusCnt >= 1200 && !global) {
                    window._IM_StatusCnt = 0;
                    axios.get('/flows/get-job/' + job_id).then(async (res) => {
                        let stream = res && res.data && res.data.stream;
                        if (stream) {
                            let runtime = '';
                            if (stream.runtime_hours < 1) {
                                runtime = `${Math.round(stream.runtime_hours * 60.)} minutes`;
                            }
                            else if (stream.runtime_hours < 24) {
                                runtime = `${Math.round(stream.runtime_hours * 10.) / 10} hours`;
                            }
                            else {
                                runtime = `${Math.round(stream.runtime_hours / 2.4) / 10} days`;
                            }
                            setPercentComplete(`Stream Processing: ${stream.complete} / ${stream.total} (${Math.round(100 * stream.complete / stream.total)}%) complete. Run-time: ${runtime}`);
                        }
                    }).catch((err) => { window._IM_StatusCnt = 0; });
                }
                if (tileMap && tileMap.current) {
                    setTileLoadPercent(tileMap.current.update());
                }
            }, 50);
        }

        if (source != lSource) {
            setLSource(source);
            if (tileMap && tileMap.current) {
                tileMap.current.setSource(source);
            }
        }

        if (insightsOnly != lInsightsOnly) {
            setLInsightsOnly(insightsOnly);
            if (tileMap && tileMap.current) {
                tileMap.current.setInsightsOnly(insightsOnly);
            }
        }

        if (nothingAfterDate != lNADate) {
            setLNADate(nothingAfterDate);
            if (tileMap && tileMap.current) {
                tileMap.current.setNADate(nothingAfterDate);
            }
        }

        if (nothingBeforeDate != lNADate) {
            setLNBDate(nothingBeforeDate);
            if (tileMap && tileMap.current) {
                tileMap.current.setNBDate(nothingBeforeDate);
            }
        }

        if (filterJobID != lFJob) {
            setLFJob(filterJobID);
            if (tileMap && tileMap.current) {
                tileMap.current.setLFJob(filterJobID);
            }
        }

        if (!map.current) { // initialize map only once

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/simptek/ckyoh7f3104th15moguf03z1m',
                center: [lng, lat],
                zoom: zoom,
                attributionControl: false,
                pitch: 45
            });
            map.current.on('load', () => {
                let geoJson = {
                    "type": "FeatureCollection",
                    "features": []
                };
                let geoJsonP = {
                    "type": "FeatureCollection",
                    "features": []
                };

                if (map.current.getSource('location-s')) {
                    map.current.getSource('location-s').setData(geoJson);
                    map.current.removeLayer('locations');
                }
                else {
                    map.current.addSource('location-s', {
                        type: 'geojson',
                        data: geoJson
                    });
                }

                if (map.current.getSource('lines-s')) {
                    map.current.getSource('lines-s').setData(geoJsonP);
                    map.current.removeLayer('lines');
                }
                else {
                    map.current.addSource('lines-s', {
                        type: 'geojson',
                        data: geoJsonP
                    });
                }

                map.current.addLayer({
                    id: 'locations',
                    source: 'location-s',
                    type: 'fill-extrusion',
                    paint: {
                        'fill-extrusion-color': [
                            'get', '__color'
                        ],
                        'fill-extrusion-height': [
                            'get', '__height'
                        ]
                    },
                });

                map.current.addLayer({
                    'id': 'lines',
                    'type': 'line',
                    'source': 'lines-s',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round',
                        'visibility': 'none'
                    },
                    'paint': {
                        'line-color': '#FFF',
                        'line-width': 2
                    }
                });

                map.current.on('click', 'locations', (e) => {
                    let props = e.features[0].properties;
                    let html = `<div style='width: 400px; word-wrap: normal; max-height: 300px; overflow-y: scroll;'>`;
                    for (let key in props) {
                        if (key.indexOf('__') === 0) {
                            continue;
                        }
                        html += `<div style='color: #dddddd; margin-bottom: 10px;'><b>${key.split('/').join(' . ')}</b>${props[key]}<br/></div>`;
                    }
                    html += `</div>`;
                    new mapboxgl.Popup()
                        .setLngLat(e.lngLat)
                        .setHTML(html)
                        .addTo(map.current);
                });

                let linesButton = document.getElementById('toggle-lines-button');
                linesButton.addEventListener('click', () => {
                    const visibility = map.current.getLayoutProperty(
                        'lines',
                        'visibility'
                    );
                    
                    if (visibility === 'visible') {
                        map.current.setLayoutProperty('lines', 'visibility', 'none');
                        linesButton.innerText = 'Show Offset Lines';
                    } else {
                        map.current.setLayoutProperty(
                            'lines',
                            'visibility',
                            'visible'
                        );
                        linesButton.innerText = 'Hide Offset Lines';
                    }
                });

                tileMap.current = new window.TileMap(map.current, global ? `/flows/insights-map-geojson-global-tile` : `/flows/insights-map-geojson-tile/${job_id}`, undefined, undefined, source);

            });

        }
        return function cleanup() {
            window.clearInterval(window._IM_intervalId);
            window.clearInterval(window._IM_intervalId2);
            window._IM_intervalId = -1;
            window._IM_intervalId2 = -1;
            window._IM_GETTING_BOUNDS = false;
            window._IM_LAST_URL = '';
        }
    });

    const onExport = () => {
    };

    return (
        <div className='flow-map-output' id='flow-map-output' style={{position: 'relative'}}>
            <div className='toggle-lines-button' id='toggle-lines-button'>Show Offset Lines</div>
            <div className='flow-map-status'>{percentComplete}<br/>Tile load: {tileLoadPercent}%</div>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
});
