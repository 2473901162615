import React, { memo, useState, useRef, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { toTableFormat } from '../NodeTypes/helpers';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = 'pk.eyJ1Ijoic2ltcHRlayIsImEiOiJja3ltem9nMzMwNzhzMnZwbmNwb3hxcDFnIn0.9gGHzt9qVQHfnofHux-wtg';

export default memo(({ data }) => {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-63.5752);
  const [lat, setLat] = useState(44.6488);
  const [zoom, setZoom] = useState(9);
  const [loaded, setLoaded] = useState(false);
  const [filename, setFileName] = useState("untitled-export");

  useEffect(() => {
    let lastSize = [-1, -1];
    const intervalId = window.setInterval(() => {
      let el = document.getElementById('flow-map-output');
      if (!el) {
        return;
      }
      let size = [el.offsetWidth, el.offsetHeight];
      if (size[0] !== lastSize[0] || size[1] !== lastSize[1] && map && map.current) {
        map.current.resize();
        lastSize = size;
      }
    }, Math.ceil(1000/30));

    if (loaded) {
      let geoJson = {
        "type": "FeatureCollection",
        "features": []
      };

      if (data && data.data && data.data.geoJSON) {
        geoJson = data.data.geoJSON;
      }
      
      if (map.current.getSource('location-s')) {
        map.current.getSource('location-s').setData(geoJson);
        map.current.removeLayer('locations');
      }
      else {
        map.current.addSource('location-s', {
          type: 'geojson',
          data: geoJson
        });
      }

      const type = ((geoJson.features || [])[0] || {geometry: {}}).geometry.type || 'Point';

      if (type == 'Point') {
        map.current.addLayer({
          id: 'locations',
          source: 'location-s',
          type: 'circle',
          paint: {
            'circle-radius': [
              'get', '__radius'
            ],
            'circle-color': [
              'get', '__color'
            ]
          },
        });
        map.current.on('click', 'locations', (e) => {
          let props = e.features[0].properties;
          let html = `<div style='width: 400px; word-wrap: normal;'>`;
          for (let key in props) {
            if (key.indexOf('__') === 0) {
              continue;
            }
            html += `<div style='color: #000; margin-bottom: 5px;'><b>${key.split('/').join(' . ')}</b>: ${props[key]}<br/></div>`;
          }
          html += `</div>`;
          new mapboxgl.Popup({ className: 'mapbox-dark-popup' })
            .setLngLat(e.lngLat)
            .setHTML(html)
            .addTo(map.current);
        });
      }
      else if (type == 'Polygon' || type == 'MultiPolygon') {
        if (data.data && data.data.extrude) {
          map.current.addLayer({
            id: 'locations',
            source: 'location-s',
            type: 'fill-extrusion',
            paint: {
              'fill-extrusion-color': [
                'get', '__color'
              ],
              'fill-extrusion-height': [
                'get', '__height'
              ]
            },
          });
        }
        else {
          map.current.addLayer({
            id: 'locations',
            source: 'location-s',
            type: 'fill',
            paint: {
              'fill-color': [
                'get', '__color'
              ]
            },
          });
        }
        map.current.on('click', 'locations', (e) => {
          let props = e.features[0].properties;
          let html = `<div style='width: 400px; word-wrap: normal;'>`;
          for (let key in props) {
            if (key.indexOf('__') === 0) {
              continue;
            }
            html += `<div style='color: #dddddd; margin-bottom: 10px;'><b>${key.split('/').join(' . ')}</b>${props[key]}<br/></div>`;
          }
          html += `</div>`;
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(html)
            .addTo(map.current);
        });
      }

      let minCoord = [1e9, 1e9];
      let maxCoord = [-1e9, -1e9];
      let count = 0;

      const updateMinMax = (coord) => {
        if (typeof(coord[0]) == 'number') {
          minCoord[0] = Math.min(minCoord[0], coord[0]);
          minCoord[1] = Math.min(minCoord[1], coord[1]);
          maxCoord[0] = Math.max(maxCoord[0], coord[0]);
          maxCoord[1] = Math.max(maxCoord[1], coord[1]);
          count += 1;
        }
        else if (coord instanceof Array) {
          for (let C of coord) {
            updateMinMax(C);
          }
        }
      };

      try {
        for (let F of geoJson.features) {
          updateMinMax(F.geometry.coordinates);
        }      

        if (count) {
          const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(minCoord[0], minCoord[1]),
            new mapboxgl.LngLat(maxCoord[0], maxCoord[1])
          );
                    
          map.current.fitBounds(bounds, {
            padding: 50
          });
        }
      }
      catch (err) {
        
      }
    }

    if (map.current) return; // initialize map only once
    
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/simptek/ckyoh7f3104th15moguf03z1m',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
      pitch: data.data.extrude ? 45 : 0
    });
    map.current.on('load', () => {
      setLoaded(true);
    });
    return function cleanup() {
      window.clearInterval(intervalId);
    }
  });

  const onExport = () => {
    let expGJ = null;
    if (data && data.data && data.data.geoJSON) {
      expGJ = data.data.geoJSON;
    }
    else {
      return;
    }
    const str = JSON.stringify(expGJ);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8"
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${filename || 'untitled-export'}.json`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className='flow-map-output' id='flow-map-output'>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
});
