import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dismissAlert } from '../../actions/alerts';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup';

import { changeActiveSidebarItem } from '../../actions/navigation';
import { logoutUser } from '../../actions/auth';

class Sidebar extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    activeItem: '',
  };

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    this.element.addEventListener('transitionend', () => {
      if (this.props.sidebarOpened) {
        this.element.classList.add(s.sidebarOpen);
      }
    }, false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sidebarOpened !== this.props.sidebarOpened) {
      if (nextProps.sidebarOpened) {
        this.element.style.height = `${this.element.scrollHeight}px`;
      } else {
        this.element.classList.remove(s.sidebarOpen);
        setTimeout(() => {
          this.element.style.height = '';
        }, 0);
      }
    }
  }

  dismissAlert(id) {
    this.props.dispatch(dismissAlert(id));
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  render() {

    const user = this.props.currentUser;
    let dashboard, projectmanagement, ramenu, usermenu, homeownerquestions, account, invite, faqLink;

    const rolesContain = (...args) => {
      for (let i=0; i<args.length; i++) {
        if (user && user.role && user.role.indexOf(args[i]) >= 0) {
          return true;
        }
      }
      return false;
    };

    /*if (rolesContain('admin')) {
      dashboard = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Dashboard"
        isHeader
        iconName="flaticon-home"
        index="main"
        link="/app/main/flow"
        childrenLinks={[
          {
            header: 'ETL Flows', link: '/app/main/flow',
          },
          {
            header: 'ETL Sub-Flows', link: '/app/main/sub-flow',
          },
          {
            header: 'Background Jobs', link: '/app/main/bg-flows',
          }
        ]}
      />);
    }*/

    if (rolesContain('customer')) {
      projectmanagement = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="View Report"
        isHeader
        labelColor="danger"
        iconName="flaticon-file"
        link="/app/customer/dashboard"
        index="main"
        label=""
        exact={false}
        childrenLinks={[
          {
            header: 'View Report', link: '/app/customer/dashboard'
          }
        ]}
     />);
    }

    if (rolesContain('remoteauditor', 'serviceorgmanager', 'utilityadmin', 'admin', 'helpdesk')) {
      projectmanagement = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Building List"
        labelColor="danger"
        iconName="flaticon-controls"
        link="/app/main/energy-advisor"
        index="main"
        label=""
        exact={false}
        />);
      account = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Account"
        labelColor="danger"
        iconName="flaticon-user"
        link="/app/main/account"
        index="main"
        label=""
        exact={false}
        />);
      faqLink = (<div className='ea-faq-link'>
        <a href="https://climative.atlassian.net/wiki/spaces/CPKB/pages/2123038721/Energy+Advisor+FAQs" target="_blank">Energy Advisor FAQs</a>
      </div>)
    }
    if (rolesContain('utilityadmin', 'admin', 'serviceorgmanager')) {
      invite = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Invite Advisors"
        labelColor="danger"
        iconName="flaticon-user-2"
        link="/app/main/invites"
        index="main"
        label=""
        exact={false}
        />);
    }
    if (false && rolesContain('utilityadmin', 'admin')) {
      homeownerquestions = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Homeowner Survey"
        labelColor="danger"
        iconName="flaticon-controls"
        link="/app/main/homeowner-questions"
        index="main"
        label=""
        exact={false}
        />);
      }
    if (!rolesContain('customer')) {
      usermenu = (<LinksGroup
        onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
        activeItem={this.props.activeItem}
        header="Account"
        isHeader
        labelColor="danger"
        iconName="flaticon-user"
        link="/admin"
        index="admin"
        label=""
        exact={false}
        childrenLinks={[
          {
            header: 'Edit Profile', link: '/app/edit_profile',
          }/*,
          {
            header: 'Change Password', link: '/app/password',
          },*/
        ]}
      />);
    }

    return (
      <div>
        <div className={s.logoMobile}>
          <a href="https://www.climative.ai/"><img alt="Climative" src="/images/simptek-green.png" /></a>
        </div>
        <nav
          className={cx(s.root)}
          ref={(nav) => { this.element = nav; }}
        >
          <header className={s.logo}>
            <a href="https://www.climative.ai/"><img alt="Climative" src="/images/simptek-green.png" /></a>
          </header>
          <ul className={s.nav}>
            {dashboard}
            {projectmanagement}
            {account}
            {invite}
            {homeownerquestions}
            {ramenu}
            {faqLink}
            {/*usermenu*/}
          </ul>
        </nav >
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    activeItem: store.navigation.activeItem,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
