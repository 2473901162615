import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import GE360Flow from './Flow';

const GE360SubProgram = () => {
  return (
    <GE360Flow subProgram={true} />
  );
}

export default withRouter(GE360SubProgram);
