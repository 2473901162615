import React, { memo, useRef, useState } from 'react';

import readXlsxFile from 'read-excel-file'

import CSVToArray from './CSVToArray';
import NodeWrapper from './NodeWrapper';
import RadioSelect from './RadioSelect';
import { Input } from '../../../components/Common';

const fixEncoding = (str) => `${str}`.replace(/[^\x09-\x7F]/g, "");

export default memo(({ data }) => {
  const inputFile = useRef(null);
  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const onContents = (contents, name) => {
    saveModel({
      _separate_save_json: contents,
      file_name: name
    });
  };
  const onChange = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = function(e) {
      let contents = [];
      try {
        try {
          contents = JSON.parse(e.target.result);
          onContents(contents, file.name);
        } catch (err0) {
          if (file.name.indexOf('json') >= 0) {
            let lines = e.target.result.split('\n');
            for (let line of lines) {
              try { contents.push(JSON.parse(line)); } catch (err01) { }
            }
            if (!contents.length) {
              throw "next";
            }
            onContents(contents, file.name);
          }
          else {
            throw "next";
          }
        }
      }
      catch (err) {
        try {
          readXlsxFile(file).then((rows) => {
            contents = [];
            for (let i=1; i<rows.length; i++) {
              const NR = {};
              for (let j=0; j<rows[0].length; j++) {
                if (typeof rows[i][j] === 'string') {
                  NR[fixEncoding(rows[0][j])] = fixEncoding(rows[i][j]);
                }
                else {
                  NR[fixEncoding(rows[0][j])] = rows[i][j];
                }
              }
              contents.push(NR);
            }
            onContents(contents, file.name);
          }).catch((err3) => {
            let rows = CSVToArray(e.target.result);
            for (let i=1; i<rows.length; i++) {
              const NR = {};
              for (let j=0; j<rows[0].length; j++) {
                NR[fixEncoding(rows[0][j])] = fixEncoding(rows[i][j]);
              }
              contents.push(NR);
            }
            onContents(contents, file.name);
          });
        }
        catch (err2) {
          console.error(err2);
        }
      }
    };
    reader.readAsText(file);
  }

  const downloadFile = (data, filename) => {
    const str = JSON.stringify(data, null, 2);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8"
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    let fileNameToks = filename.split('.');
    fileNameToks[fileNameToks.length-1] = 'json';
    a.download = fileNameToks.join('.');
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const simulateClick = function (elem) { // https://gomakethings.com/how-to-simulate-a-click-event-with-javascript/
    // Create our event (with options)
    let evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    // If cancelled, don't dispatch our event
    let canceled = !elem.dispatchEvent(evt);
  };
  
  return (
    <NodeWrapper
      title='File Input'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={0}
      outputs={1}
      model={model}
      data={data}
    >
      <input className='flow-file-input' type='file' ref={inputFile} onChange={(event) => onChange(event)}/>
      <div className='flow-file-upload-cont'>
        <div className='flow-file-input-button' onClick={() => simulateClick(inputFile.current)}>
          <img src='/images/file-upload-icon.svg'/>
          Select File
        </div>
        <div className='flow-file-types'>csv, xlsx, json, geojson</div>
        {model._separate_save_json && <div className='flow-file-label' onClick={(event) => downloadFile(model._separate_save_json, model.file_name)}>
          <img src='/images/file-icon-grey.svg'/>
          {model.file_name ? `${model.file_name}` : `uploaded file`} {(model && model._separate_save_json && model._separate_save_json.features) ? ` (${(model._separate_save_json.features instanceof Array) ? model._separate_save_json.features.length : 1} features)` : (model && model._separate_save_json) ? ` (${(model._separate_save_json instanceof Array) ? model._separate_save_json.length : 1} rows)` : ``}
        </div>}
        {model._separate_save_json && <div className='fcs-io-title' style={{'marginTop': '30px'}}>SLICING</div>}
        {model._separate_save_json && <RadioSelect title='Slice Dataset' value={model.slice ? 'yes' : 'no'} onChange={(val) => saveModel({...model, slice: val === 'yes'})} options={[{title: 'Slice Dataset', key: 'yes'},{title: 'All Data', key: 'no'}]} />}
        {model.slice && model._separate_save_json && <div>
          <div className='fcs-title'>Window Size</div>
          <Input type="number" defaultValue={model.window_size || 100} onChange={(val) => saveModel({...model, window_size: parseInt(val) || 0})} />
          <div className='fcs-title'>Window Number</div>
          <Input type="number" defaultValue={model.window_no || 1} onChange={(val) => saveModel({...model, window_no: parseInt(val) || 1})} />
        </div>}
      </div>
    </NodeWrapper>
  );
});
