import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import { Input, Dropdown } from '../../../components/Common';
import ColumnSelect from './ColumnSelect';
import RadioSelect from './RadioSelect';
import Checkbox from './Checkbox';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ k2, setK2 ] = useState(["k2-" + Math.random(), "k2-" + Math.random(), "k2-" + Math.random(), "k2-" + Math.random()]);

  const onSelect = (v) => {
    const value = model || {};
    value.process = v;
    saveModel(value);
  };

  const onSelectMFS = (v, name) => {
    const value = model || {};
    value[name] = v;
    saveModel(value);
  };

  const onChange = (v, name) => {
    const value = model || {};
    value[name] = v;
    saveModel(value);
  };

  const processTypeOptions = [
    {
      key: "geocode",
      title: "GeoCode"
    },
    {
      key: "va-type1",
      title: "Type-1 Insights"
    },
    {
      key: "closest-building",
      title: "Get Building Polygon"
    },
    {
      key: "insights-v1",
      title: "Insights + Bundles V1"
    }
  ];

  return (
    <NodeWrapper
      title='Pre-Built API Process'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"425px"}
      data={data}
    >
      <div className='fcs-title'>Process</div>
      <Dropdown
        options={processTypeOptions}
        defaultValue={processTypeOptions.filter((e) => e.key === model.process)[0]}
        onChange={(val) => saveModel({...model, process: val.key})}
        hasArrow={true}
      />
      {model.process === 'geocode' && <div key={k2[0]}>
        <div className='fcs-io-title'>INPUTS</div>
        <RadioSelect title='Get Building Height from MapBox?' value={model.tile_query ? 'yes' : 'no'} onChange={(val) => saveModel({...model, tile_query: val === 'yes'})} options={[{title: 'Yes', key: 'yes'},{title: 'No', key: 'no'}]} />
        <ColumnSelect title='Full Address' value={model.full_address} onChange={(val) => saveModel({...model, full_address: val})} />
        <div className='fcs-sep'>OR</div>
        <div className='fcs-half'><ColumnSelect title='Street Number' value={model.street_address} onChange={(val) => saveModel({...model, street_address: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Street' value={model.street} onChange={(val) => saveModel({...model, street: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='City' value={model.city} onChange={(val) => saveModel({...model, city: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Province' value={model.province} onChange={(val) => saveModel({...model, province: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Country' value={model.country} onChange={(val) => saveModel({...model, country: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Postal Code' value={model.postal_code} onChange={(val) => saveModel({...model, postal_code: val})} /></div>
        <div className='fcs-io-title'>OUTPUTS</div>
        <ColumnSelect title='Output Column' value={model.output_prefix} onChange={(val) => saveModel({...model, output_prefix: val})} />
      </div>}
      {model.process === 'closest-building' && <div key={k2[1]}>
        <div className='fcs-io-title'>INPUTS</div>
        <div className='fcs-half'><ColumnSelect title='Longitude' value={model.longitude} onChange={(val) => saveModel({...model, longitude: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Latitude' value={model.latitude} onChange={(val) => saveModel({...model, latitude: val})} /></div>
        <div className='fcs-title'>Max Distance (Meters)</div>
        <Input type="number" defaultValue={model.max_distance} onChange={(val) => saveModel({...model, max_distance: parseFloat(val) || 0})} />
        <div className='fcs-io-title'>OUTPUTS</div>
        <ColumnSelect title='Output Column' value={model.output_prefix} onChange={(val) => saveModel({...model, output_prefix: val})} />
      </div>}
      {model.process === 'va-type1' && <div key={k2[2]}>
        <div className='fcs-io-title'>INPUTS</div>
        <ColumnSelect title='Building Address' value={model.address} onChange={(val) => saveModel({...model, address: val})} />
        <div className='fcs-half'><ColumnSelect title='Storeys' value={model.storeys} onChange={(val) => saveModel({...model, storeys: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Total Heated Floor Area (SQFT)' value={model.total_heated_floor_area_sqft} onChange={(val) => saveModel({...model, total_heated_floor_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Year Built' value={model.year_built} onChange={(val) => saveModel({...model, year_built: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Has Heated Basement? (Boolean)' value={model.heated_basement} onChange={(val) => saveModel({...model, heated_basement: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='House Type' value={model.house_type} onChange={(val) => saveModel({...model, house_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Primary Heating Type' value={model.primary_heating_type} onChange={(val) => saveModel({...model, primary_heating_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Primary Heating Fuel Type' value={model.primary_heating_fuel_type} onChange={(val) => saveModel({...model, primary_heating_fuel_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Hot Water Heater Fuel Type' value={model.hotwater_heater_fuel_type} onChange={(val) => saveModel({...model, hotwater_heater_fuel_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Footprint Floor Area (SQFT)' value={model.footprint_area_sqft} onChange={(val) => saveModel({...model, footprint_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Basement Floor Area (SQFT)' value={model.basement_floor_area_sqft} onChange={(val) => saveModel({...model, basement_floor_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='ID' value={model.id} onChange={(val) => saveModel({...model, id: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Organization ID' value={model.organization_id} onChange={(val) => saveModel({...model, organization_id: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Data Source' value={model.data_source} onChange={(val) => saveModel({...model, data_source: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Geocode Object' value={model.geocode_object} onChange={(val) => saveModel({...model, geocode_object: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Has Ducting' value={model.has_ducting} onChange={(val) => saveModel({...model, has_ducting: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Has Air Conditioning' value={model.has_air_conditioning} onChange={(val) => saveModel({...model, has_air_conditioning: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Secondary Heating Type' value={model.secondary_heating_type} onChange={(val) => saveModel({...model, secondary_heating_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Secondary Heating Fuel Type' value={model.secondary_heating_fuel_type} onChange={(val) => saveModel({...model, secondary_heating_fuel_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Heat Pump COP' value={model.heatpump_cop} onChange={(val) => saveModel({...model, heatpump_cop: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Heat Pump Capacity' value={model.heatpump_capacity_system_capacity_watts} onChange={(val) => saveModel({...model, heatpump_capacity_system_capacity_watts: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Assumptions (Object)' value={model.assumptions} onChange={(val) => saveModel({...model, assumptions: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Baseline Heatpump Coverage Targets (Object)' value={model.baseline_heatpump_coverage_targets} onChange={(val) => saveModel({...model, baseline_heatpump_coverage_targets: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Install Heatpump Coverage Targets (Object)' value={model.install_heatpump_coverage_targets} onChange={(val) => saveModel({...model, install_heatpump_coverage_targets: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Override City' value={model.override_city} onChange={(val) => saveModel({...model, override_city: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Override Postal Code' value={model.override_postal_code} onChange={(val) => saveModel({...model, override_postal_code: val})} /></div>
        <br/>
        <Checkbox title='Generate Bundles' value={!!model.generate_bundles} onChange={(val) => saveModel({...model, generate_bundles: !!val})} />
        <Checkbox title='Return Model Debug Info.' value={!!model.model_debug} onChange={(val) => saveModel({...model, model_debug: !!val})} />
        <Checkbox title='Find Closest Building Polygon & Centroid.' value={!!model.find_building_polygon} onChange={(val) => saveModel({...model, find_building_polygon: !!val})} />
        <div className='fcs-io-title'>OUTPUTS</div>
        <ColumnSelect title='Output Column' value={model.output_prefix} onChange={(val) => saveModel({...model, output_prefix: val})} />
      </div>}
      {model.process === 'insights-v1' && <div key={k2[3]}>
        <div className='fcs-io-title'>INPUTS</div>
        <div className='fcs-half'><ColumnSelect title='Customer Building ID' value={model.customer_building_id} onChange={(val) => saveModel({...model, customer_building_id: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Year Built' value={model.year_built} onChange={(val) => saveModel({...model, year_built: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='House Type' value={model.house_type} onChange={(val) => saveModel({...model, house_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Storeys' value={model.storeys} onChange={(val) => saveModel({...model, storeys: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Total Heated Floor Area (SQFT)' value={model.total_heated_floor_area_sqft} onChange={(val) => saveModel({...model, total_heated_floor_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Heated Basement Floor Area (SQFT)' value={model.heated_basement_floor_area_sqft} onChange={(val) => saveModel({...model, heated_basement_floor_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Footprint Floor Area (SQFT)' value={model.footprint_area_sqft} onChange={(val) => saveModel({...model, footprint_area_sqft: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Primary Heating Fuel Type' value={model.primary_heating_fuel_type} onChange={(val) => saveModel({...model, primary_heating_fuel_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Primary Heating Type' value={model.primary_heating_system_type} onChange={(val) => saveModel({...model, primary_heating_system_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Has Air Conditioning? (Boolean)' value={model.has_air_conditioning} onChange={(val) => saveModel({...model, has_air_conditioning: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Air Conditioning System Type' value={model.air_conditioning_system_type} onChange={(val) => saveModel({...model, air_conditioning_system_type: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Has Heated Basement? (Boolean)' value={model.heated_basement} onChange={(val) => saveModel({...model, heated_basement: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Unit Number' value={model.unit_number} onChange={(val) => saveModel({...model, unit_number: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Street Number' value={model.street_number} onChange={(val) => saveModel({...model, street_number: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Street Name' value={model.street_name} onChange={(val) => saveModel({...model, street_name: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Street Suffix' value={model.street_suffix} onChange={(val) => saveModel({...model, street_suffix: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Municipality' value={model.municipality} onChange={(val) => saveModel({...model, municipality: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Postal Code' value={model.postal_code} onChange={(val) => saveModel({...model, postal_code: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Province' value={model.province} onChange={(val) => saveModel({...model, province: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Country' value={model.country} onChange={(val) => saveModel({...model, country: val})} /></div>
        <br/>
        <Checkbox title='Save to Temporary Insights Table' value={!!model.temp_insights_table} onChange={(val) => saveModel({...model, temp_insights_table: !!val})} />
        <div className='fcs-io-title'>OUTPUTS</div>
        <ColumnSelect title='Output Column' value={model.output_prefix} onChange={(val) => saveModel({...model, output_prefix: val})} />
      </div>}
    </NodeWrapper>
  );


});