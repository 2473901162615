import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RandomKey from './RandomKey';
import '../Common.scss';

class StatLine extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
  }
  render() {
    const {title, subtitle, icon} = this.props;

    return (
      <div key={this.key} className='insight-stat-cont'>
        {subtitle && icon ? (<img src={icon}/>) : (<div className='insight-stat-line' style={subtitle?{}:{opacity:0.0}}></div>)}
        {subtitle && <div className='insight-stat-inner-cont'>
          <div className='insight-stat-title'>{title}</div>
          <div className='insight-stat-subtitle'>{subtitle}{this.props.children}</div>
        </div>}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(StatLine));
