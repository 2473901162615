import axios from 'axios';
import config from '../config';
import jwt from "jsonwebtoken";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Errors from '../components/FormItems/error/errors';
import { mockUser } from './mock';
import { getBuildingRequest, getBuildingRequestLM, receiveBuilding, receiveBuildings } from './buildings';

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_CUSTOMER_BUILDINGS = 'GET_CUSTOMER_BUILDINGS';

async function findMe() {
  // window.HIDE_REBATES = !(false/*response.data.org.options.show_rebates*/);
  // window.HIDE_DOLLARS = !(false/*response.data.org.options.show_dollars*/);
  if (config.isBackend) {
    const response = await axios.get('/user/me');
    //  Sask Power Oganizations in Patch DB and Prod Simptek DB respectivley
    window.ALLOW_NONOMINAL = response.data.organizationId == "01E7B2CA-2081-EB11-9889-0004FF9D6381" || response.data.organizationId == "6DDB0E6E-4F78-EB11-85AA-DC9840138F01";
    window.IS_SASK_POWER = response.data.organizationId == "01E7B2CA-2081-EB11-9889-0004FF9D6381" || response.data.organizationId == "6DDB0E6E-4F78-EB11-85AA-DC9840138F01";
    window.IS_E1_ORG = response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51";

    // E1 Org in Patch DB
    window.HIDE_REBATES = false;//!(response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51");
    window.HIDE_AUTO_REBATES = true;
    window.HIDE_DOLLARS = false;//!(response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51");

    window.IS_ON_DEMO_ORG = response.data.organizationId == "69AF673A-619B-ED11-994A-000D3AE8B695";
    window.IS_BC_DEMO_ORG = response.data.organizationId == "DD9E8140-2D76-ED11-9D79-6045BD5CD0CD";

    return response.data;
  } else {
    return mockUser;
  }
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function acceptTermsAndConditions() {
  return (dispatch) => {
    axios.post('/user/me/accept-terms-conditions').then(res => {
      dispatch(doInit());
    });
  };
};

export function doInit() {
  return async (dispatch) => {

    /*if (window.location.href.indexOf("/public/") > 0) {
      return;
    }*/

    let currentUser = null;
    window._HAS_BOTH_SITES = false;
    if (!config.isBackend) {
      currentUser = mockUser;
      if (window._SITE_OVERRIDE) {
        currentUser.app = window._SITE_OVERRIDE;
      }
      if (localStorage.getItem('app') !== currentUser.app) {
        currentUser.app = localStorage.getItem('app');
      }
      if (currentUser.role.en && currentUser.role.ra) {
        window._HAS_BOTH_SITES = true;
      }
      currentUser.role = [ currentUser.role[currentUser.app] ];
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } else {
      try {
        let token = localStorage.getItem('token');
        if (token) {
          currentUser = await findMe();
          if (window._SITE_OVERRIDE) {
            currentUser.app = window._SITE_OVERRIDE;
          }
          if (localStorage.getItem('app') !== currentUser.app) {
            currentUser.app = localStorage.getItem('app');
          }
          if (currentUser.role.en && currentUser.role.ra) {
            window._HAS_BOTH_SITES = true;
          }
          currentUser.role = [ currentUser.role[currentUser.app] ];
        }
        dispatch({
          type: AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: AUTH_INIT_ERROR,
          payload: error,
        });
      }
    }
  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    let app = localStorage.getItem('app') || 'ra';
    localStorage.removeItem('app');
    axios.defaults.headers.common['Authorization'] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    window.location.href = '#' + {'en': '/login', 'ra': '/login', 'b360': '/login'}[app];
  };
}

export function receiveToken(token) {
    return (dispatch) => {
        let user;
        if (config.isBackend) {
          user = jwt.decode(token)
        } else {
          user = {
            email: config.auth.email,
            user: {
              id: 'default_no_connection_id_444',
            }
          }
        }
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        dispatch({
          type: LOGIN_SUCCESS
        });
        dispatch(push('/app'));
    }
}

export function switchAdminSite(site) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(receiveToken('token'));
      dispatch(doInit());
      dispatch(push('/app'));
    } else {
      dispatch({
        type: LOGIN_REQUEST,
      });
      axios.put("/users/switch-sites", { app_name: site }).then(res => {
        const token = res.data.token;
        localStorage.setItem('app', site);
        window._SITE_OVERRIDE = site;
        dispatch(receiveToken(token));
        dispatch(doInit());
        if (site === 'en') {
          if (res.data.roles.en === 'serviceprovider') {
            //dispatch(push('/app/main/en-buildings'));
            dispatch(push('/app/main/en-dashboard'));
          }
          else {
            dispatch(push('/app/main/en-dashboard'));
          }
        }
        else {
          dispatch(push('/app/main/energy-advisor'));
        }
      }).catch(err => {
        dispatch(authError(err.response.data));
      });
    }
  };
}

export function loginUser(creds,app) {
    return (dispatch) => {
      if (!config.isBackend) {
        dispatch(receiveToken('token'));
        dispatch(doInit());
        dispatch(push('/app'));
      } else {
        dispatch({
          type: LOGIN_REQUEST,
        });
        if (creds.social) {
          window.location.href = config.baseURLApi + "/user/signin/" + creds.social + '?app=' + config.redirectUrl;
        }
        else if (creds.token || (creds.email?.length > 0 && creds.password?.length > 0)) {
          return axios.post("/user/signin/local", creds).then(res => {
            const token = res.data.token;
            localStorage.setItem('app', res.data.app_name);
            dispatch(receiveToken(token));
            dispatch(doInit());
            if (res.data.app_name === 'en') {
              if (res.data.roles.en === 'serviceprovider') {
                dispatch(push('/app/main/en-dashboard'));
              }
              else {
                dispatch(push('/app/main/en-dashboard'));
              }
            }
            else {
              dispatch(push('/app/main/energy-advisor'));
            }
          }).catch(err => {
            dispatch(authError(err?.response?.data ?? 'Something was wrong. Try again'));
            toast.error("Unable to sign in");
          });
        } else {
          dispatch(authError('Something was wrong. Try again'));
        }
      }
    };
}

export function verifyEmail(token) {
  return(dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      axios.put("/user/verify-email", {token}).then(verified => {
        if (verified) {
          toast.success("Your email was verified");
        }
      }).catch(err => {
        toast.error(err.response.data);
      }).finally(() => {
        dispatch(push('/login'));
      })
    }
  }
}

export function resetPassword(token, password) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: RESET_REQUEST,
      });
      axios.put("/user/password-reset", {token, password}).then(res => {
          dispatch({
            type: RESET_SUCCESS,
          });
          toast.success("Password has been updated");
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: PASSWORD_RESET_EMAIL_REQUEST,
      });
      axios.post("/user/send-password-reset-email", {email}).then(res => {
        dispatch({
          type: PASSWORD_RESET_EMAIL_SUCCESS,
        });
        toast.success("Email with resetting instructions has been sent");
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios.post("/user/signup", creds).then(res => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success("You've been registered successfully. Please check your email for verification link");
          dispatch(push('/login'));
        }).catch(err => {
          dispatch(authError(err.response.data));
        })

      } else {
        dispatch(authError('Something was wrong. Try again'));
      }
    }
  };
}
