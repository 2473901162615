import { acceptTermsAndConditions } from '../../../actions/auth';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row, Col, Button, Form, FormGroup, Label, Input, Collapse } from 'reactstrap';

import './TermsAndConditions.scss';

const CUSTOMER_TERMS_HTML = `
<style>
    [data-custom-class='body'],
    [data-custom-class='body'] * {
        background: transparent !important;
    }

    [data-custom-class='title'],
    [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
    }

    [data-custom-class='subtitle'],
    [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
    }

    [data-custom-class='heading_1'],
    [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
    }

    [data-custom-class='heading_2'],
    [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
    }

    [data-custom-class='body_text'],
    [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
    }

    [data-custom-class='link'],
    [data-custom-class='link'] * {
        color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
    }
</style>

<div data-custom-class="body">
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div align="center" class="MsoNormal" data-custom-class="title" style="text-align: left; line-height: 1.5;"><a
                name="_gm5sejt4p02f"></a><strong><span style="line-height: 150%; font-family: Arial; font-size: 26px;">
                    <bdt class="block-component"></bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>
                </span></strong></div>
        <div align="center" class="MsoNormal" style="text-align: center; line-height: 1.5;"><a name="_7m5b3xg56u7y"></a>
        </div>
        <div align="center" class="MsoNormal" data-custom-class="subtitle" style="text-align: left; line-height: 1.5;">
            <br></div>
        <div align="center" class="MsoNormal" data-custom-class="subtitle" style="text-align: left; line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:150%;font-family:Arial;color:#A6A6A6;
mso-themecolor:background1;mso-themeshade:166;"><span
                    style="color: rgb(127, 127, 127); font-size: 15px; text-align: justify;"><strong>Last
                        updated</strong></span><strong><span
                        style="color: rgb(127,127,127); font-size: 14.6667px; text-align: justify;"> </span></strong><span
                    style="color: rgb(127, 127, 127); font-size: 15px; text-align: justify;">
                    <bdt class="block-container question question-in-editor"
                        data-id="0d5ae8ae-7749-9afb-1fed-6556cb563dc0" data-type="question"><strong>August 23,
                            2022</strong></bdt>
                </span></span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><strong><span data-custom-class="heading_1">TABLE OF
                CONTENTS</span></strong></div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#agreement"><span
                style="font-size: 15px;"><span data-custom-class="body_text">1. AGREEMENT TO TERMS</span></span></a>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#ip"><span
                data-custom-class="body_text">2. INTELLECTUAL PROPERTY RIGHTS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#userreps"><span
                data-custom-class="body_text">3. USER REPRESENTATIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#cancel"><span
                data-custom-class="body_text">4. CANCELLATION</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#prohibited"><span
                data-custom-class="body_text">5. PROHIBITED ACTIVITIES</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#ugc"><span
                data-custom-class="body_text">6. USER GENERATED CONTRIBUTIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#license"><span
                data-custom-class="body_text">7. CONTRIBUTION LICENSE</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#submissions"><span
                data-custom-class="body_text">8. SUBMISSIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt><a data-custom-class="link"
            href="#usrights"><span data-custom-class="body_text">9. U.S. GOVERNMENT RIGHTS</span></a>
        <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#sitemanage"><span
                data-custom-class="body_text">10. SITE MANAGEMENT</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt><span
            data-custom-class="body_text">
            <bdt class="block-component"></bdt>
            <bdt class="block-component">
        </span></bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#terms"><span data-custom-class="body_text">11.
                TERM AND TERMINATION</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#modifications"><span
                data-custom-class="body_text">12. MODIFICATIONS AND INTERRUPTIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#law"><span data-custom-class="body_text">13.
                GOVERNING LAW</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#disputes"><span data-custom-class="body_text">14.
                DISPUTE RESOLUTION</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#corrections"><span
                data-custom-class="body_text">15. CORRECTIONS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#disclaimer"><span
                data-custom-class="body_text">16. DISCLAIMER</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#liability"><span data-custom-class="body_text">17.
                LIMITATIONS OF LIABILITY</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#indemnification"><span
                data-custom-class="body_text">18. INDEMNIFICATION</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#userdata"><span data-custom-class="body_text">19.
                USER DATA</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#electronic"><span
                data-custom-class="body_text">20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></a>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#misc"><span data-custom-class="body_text">21.
                MISCELLANEOUS</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><a data-custom-class="link" href="#contact"><span data-custom-class="body_text">22.
                CONTACT US</span></a></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="heading_1" id="agreement" style="line-height: 1.5;"><a
                name="_6aa3gkhykvst"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">1. AGREEMENT TO
                    TERMS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">These <bdt
                    class="block-component"></bdt>
                <bdt class="question">Terms of Service</bdt>
                <bdt class="statement-end-if-in-editor"></bdt> constitute a legally binding agreement made between you,
                whether personally or on behalf of an entity (“you”) and <bdt
                    class="block-container question question-in-editor" data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                    data-type="question">Climative</bdt>
            </span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-component"></bdt> 
            </span><span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">("<bdt
                    class="block-component"></bdt><strong>Company</strong>
                <bdt class="statement-end-if-in-editor"></bdt>", “<strong>we</strong>”, “<strong>us</strong>”, or
                “<strong>our</strong>”), concerning your access to and use of the <bdt
                    class="block-container question question-in-editor" data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd"
                    data-type="question"><a href="https://audit.climative.ai" target="_blank"
                        data-custom-class="link">https://audit.climative.ai</a></bdt> website as well as any other
                media form, media channel, mobile website or mobile application related, linked, or otherwise connected
                thereto (collectively, the “Site”).<span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                            <bdt class="question">
                                <bdt class="block-component"></bdt>
                            </bdt><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                <bdt class="block-component"></bdt>
                            </span> We are registered in <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt> <span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                    <bdt class="question">Canada</bdt>
                                    <bdt class="statement-end-if-in-editor"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span></bdt>
                                </span></span> and have our registered office at <bdt class="question">527 Queen Street
                            </bdt>
                            <bdt class="block-component"></bdt>
                        </span></span>, <bdt class="question">Fredericton</bdt></span>
            </span><span style="font-size: 15px;"><span data-custom-class="body_text"><span
                        style="font-size: 15px;"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);">
                                <bdt class="block-component"></bdt>, <bdt class="question">New Brunswick</bdt>
                                <bdt class="block-component"></bdt>
                                <bdt class="block-component"></bdt>
                                <bdt class="question">E3B 1B8</bdt>
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span></span></span></span><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                    <bdt class="statement-end-if-in-editor">.</bdt>
                    <bdt class="else-block"></bdt>
                    </bdt>
                </span></bdt></span>
            <bdt class="statement-end-if-in-editor"></bdt></span>
            <bdt class="block-component"></bdt></span></span></span></span></span> You agree that by accessing the Site,
            you have read, understood, and agree to be bound by all of these <bdt class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="separate_agreement_option"
                        data-type="statement"></bdt>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt data-type="conditional-block">
                    <bdt data-type="body">,</bdt>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt data-type="conditional-block"> <bdt data-type="body">including the <bdt
                            class="block-container question question-in-editor"
                            data-id="854b2e15-26e1-3b27-3764-4c2e1562ed00" data-type="question">User Agreement</bdt>
                    </bdt>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt data-type="conditional-block">
                    <bdt data-type="body"> posted on the Site, which are incorporated into these <bdt
                            class="block-component"></bdt>
                        <bdt class="question">Terms of Service</bdt>
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </bdt>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="c4a4c609-d962-97d8-9b96-d3492402adad">
                <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
            </bdt>. IF YOU DO NOT AGREE WITH ALL OF THESE <bdt class="block-component"></bdt>
            <bdt class="question">TERMS OF SERVICE</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND
            YOU MUST DISCONTINUE USE IMMEDIATELY.</span>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Supplemental terms and
                    conditions or documents that may be posted on the Site from time to time are hereby expressly
                    incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
                    modifications to these <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>at any time and for any reason<bdt
                        class="statement-end-if-in-editor"></bdt>. We will alert you about any changes by updating the
                    “Last updated” date of these <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>, and you waive any right to receive specific notice
                    of each such change. Please ensure that you check the applicable Terms every time you use our Site
                    so that you understand which Terms apply. You will be subject to, and will be deemed to have been
                    made aware of and to have accepted, the changes in any revised <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt> by your continued use of the Site after the date such
                    revised <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt> are posted.
                </span></span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">The information
                provided on the Site is not intended for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be contrary to law or regulation or which
                would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
                persons who choose to access the Site from other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent local laws are applicable.</span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
            </span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">The Site is not tailored to comply with
                industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal
                Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such
                laws, you may not use this Site. You may not use the Site in a way that would violate the
                Gramm-Leach-Bliley Act (GLBA).<bdt class="block-component"></bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
            </span></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1;"><br></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <bdt class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
                <bdt data-type="conditional-block"><span style="font-size: 15px;">
                        <bdt class="block-component" data-record-question-key="user_o18_option" data-type="statement">
                        </bdt>
                    </span>
                    <bdt data-type="body"><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">The
                            Site is intended for users who are at least 18 years old. Persons under the age
                            of 18 are not permitted to use or</span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"> </span><span
                            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">register
                            for the Site.</span></bdt>
                </bdt>
                <bdt data-type="conditional-block">
                    <bdt data-type="body"><span style="color: rgb(89, 89, 89); font-size: 15px;">
                            <bdt class="block-component">
                        </span></bdt>
                </bdt>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="ip" style="line-height: 1.5;"><a
                name="_b6y29mp52qvx"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">2. INTELLECTUAL PROPERTY
                    RIGHTS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Unless otherwise
                indicated, the Site is our proprietary property and all source code, databases, functionality, software,
                website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”)
                and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by
                us or licensed to us, and are protected by copyright and trademark laws and various other intellectual
                property rights and unfair competition laws of the United States, international copyright laws, and
                international conventions. The Content and the Marks are provided on the Site “AS IS” for your
                information and personal use only. Except as expressly provided in these <bdt class="block-component">
                </bdt>
                <bdt class="question">Terms of Service</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>, no part of the Site and no Content or Marks may be
                copied, reproduced,
                aggregated, republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or otherwise exploited
                for any commercial purpose whatsoever, without our express prior written
                permission.
            </span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Provided that
                you
                are eligible to use the Site, you are granted a limited license to access and
                use the Site and to download or print a copy of any portion of the Content to
                which you have properly gained access solely for your personal, non-commercial
                use. We reserve all rights not expressly granted to you in and to the Site, the Content and the
                Marks.</span></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" style="line-height: 1.5;"><br></div>
        <div class="MsoNormal" data-custom-class="heading_1" id="userreps" style="line-height: 1.5;"><a
                name="_5hg7kgyv9l8z"></a><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">3. USER
                    REPRESENTATIONS</span></strong></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">By using the
                Site, you represent and warrant that:</span>
            <bdt class="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement">
                        <span style="font-size: 15px;"></span></bdt> 
                </bdt><span style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span
                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">1</span></span><span
                    style="color: rgb(89, 89, 89); font-size: 15px;">) you have the legal capacity and you agree to
                    comply with these <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>; <bdt class="block-component">
                </span>
            </bdt>
            </bdt><span style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">2</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you are not a
                minor in the jurisdiction in which you reside<bdt class="block-container if" data-type="if"
                    id="76948fab-ec9e-266a-bb91-948929c050c9">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="user_o18_option" data-type="statement">
                        </bdt>
                    </bdt>;</span><span style="color: rgb(89, 89, 89); font-size: 11pt;"> </span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">3</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you will not access the Site
                through automated or non-human means, whether through a bot, script or
                otherwise;</span><span style="color: rgb(89, 89, 89); font-size: 11pt;"> </span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">(</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">4</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) you will not use the Site for any illegal or
                unauthorized
                purpose; and (</span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);">5</span></span><span
                style="color: rgb(89, 89, 89); font-size: 15px;">) your use of the Site
                will not violate any applicable law or regulation.</span>
        </div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">If you provide
                any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future use of the Site (or any portion
                thereof).</span></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;">
            <bdt class="block-container if" data-type="if" id="d13abc62-cc6f-e059-7fd6-cb6208085aa7">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement">
                    </bdt>
                </bdt>
        </div>

        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;">
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><a name="_drzjqilz2ujm"></a></div>
            <bdt class="block-container if" data-type="if" id="4380167d-5abe-b98f-f389-f707429e6c52">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="free_trial_option" data-type="statement">
                        </span></bdt>
                </bdt>
                <div class="MsoNormal" data-custom-class="heading_1" id="cancel"
                    style="text-align: left; line-height: 1.5;"><a name="_e993diqrk0qx"></a><strong><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;">4.
                            CANCELLATION</span></strong></div>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="cef55cf6-3a65-8031-d0c3-cffe36e64f10">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="return_option" data-type="statement">
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="cef55cf6-3a65-8031-d0c3-cffe36e64f10">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">All purchases are non-refundable.</bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="cef55cf6-3a65-8031-d0c3-cffe36e64f10">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"> </bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="cef55cf6-3a65-8031-d0c3-cffe36e64f10">
                        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>You can cancel your subscription at any time
                </span><span style="font-size:11.0pt;line-height:115%;
Arial;color:#595959;mso-themecolor:text1;mso-themetint:166;"> </span><span
                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="cancel_how" data-type="statement">
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">by contacting us using the contact</bdt>
                        </bdt>
                    </bdt>
                </span><span style="font-size:11.0pt;line-height:115%;
Arial;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"> </bdt>
                        </bdt>
                    </bdt>
                </span><span style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">information provided below</bdt>
                        </bdt>
                    </bdt>
                    <bdt class="block-container if" data-type="if" id="1a40a488-252c-f087-02cc-5e0de451aa88">
                        <bdt data-type="conditional-block">
                            <bdt class="block-component" data-record-question-key="cancel_how" data-type="statement">
                            </bdt>
                        </bdt>. Your cancellation will take effect at the end of the current paid term.
                </span></div>
        </div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">If you are unsatisfied with our
                    services, please email us at <bdt class="block-container question question-in-editor"
                        data-id="7c40698b-5cc8-bb58-245d-2fbd1b57dfba" data-type="question">info@climative.ai</bdt>
                    <bdt class="block-component"></bdt>
                </span></span></span>.</span></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="line-height: 1;">
                <bdt class="block-container if" data-type="if" id="b671a946-ab46-9fff-9cee-e88149335e8e">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="software_option" data-type="statement">
                        </bdt>
                    </bdt>
            </div>
            <div class="MsoNormal" data-custom-class="heading_1" id="prohibited"
                style="line-height: 1.5; text-align: left;"><a name="_h284p8mrs3r7"></a><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">5. PROHIBITED
                        ACTIVITIES</span></strong></div>
        </div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">You may not
                access or use the Site for any purpose other than that for which we make the Site available. The Site
                may not be used in connection with any commercial endeavors except those that are specifically endorsed
                or approved by us.</span></div>
        <div class="MsoNormal" style="line-height: 1;"><br></div>
        <div class="MsoNormal" style="text-align: justify; line-height: 115%;">
            <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                    style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">As a user of the Site, you
                    agree not to:</span></div>
            <ul>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                            style="font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Systematically
                            retrieve data or other content from the Site to create or compile, directly or indirectly, a
                            collection, compilation, database, or directory without written permission from
                            us.</span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Trick,
                                        defraud, or mislead us and other users, especially in any attempt to learn
                                        sensitive account information such as user
                                        passwords.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Circumvent,
                                        disable, or otherwise interfere with security-related features of the Site,
                                        including features that prevent or restrict the use or copying of any Content or
                                        enforce limitations on the use of the Site and/or the Content contained
                                        therein.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Disparage,
                                        tarnish, or otherwise harm, in our opinion, us and/or the
                                        Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        any information obtained from the Site in order to harass, abuse, or harm
                                        another person.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                                        improper use of our support services or submit false reports of abuse or
                                        misconduct.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        the Site in a manner inconsistent with any applicable laws or
                                        regulations.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                                        in unauthorized framing of or linking to the
                                        Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                                        or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
                                        other material, including excessive use of capital letters and spamming
                                        (continuous posting of repetitive text), that interferes with any party’s
                                        uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts,
                                        alters, or interferes with the use, features, functions, operation, or
                                        maintenance of the Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                                        in any automated use of the system, such as using scripts to send comments or
                                        messages, or using any data mining, robots, or similar data gathering and
                                        extraction tools.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Delete
                                        the copyright or other proprietary rights notice from any
                                        Content.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                                        to impersonate another user or person or use the username of another
                                        user.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                                        or transmit (or attempt to upload or to transmit) any material that acts as a
                                        passive or active information collection or transmission mechanism, including
                                        without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web
                                        bugs, cookies, or other similar devices (sometimes referred to as “spyware” or
                                        “passive collection mechanisms” or “pcms”).</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Interfere
                                        with, disrupt, or create an undue burden on the Site or the networks or services
                                        connected to the Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Harass,
                                        annoy, intimidate, or threaten any of our employees or agents engaged in
                                        providing any portion of the Site to you.</span></span></span></span></span>
                </li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                                        to bypass any measures of the Site designed to prevent or restrict access to the
                                        Site, or any portion of the Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Copy
                                        or adapt the Site’s software, including but not limited to Flash, PHP, HTML,
                                        JavaScript, or other code.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                                        as permitted by applicable law, decipher, decompile, disassemble, or reverse
                                        engineer any of the software comprising or in any way making up a part of the
                                        Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                                        as may be the result of standard search engine or Internet browser usage, use,
                                        launch, develop, or distribute any automated system, including without
                                        limitation, any spider, robot, cheat utility, scraper, or offline reader that
                                        accesses the Site, or using or launching any unauthorized script or other
                                        software.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        a buying agent or purchasing agent to make purchases on the
                                        Site.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                                        any unauthorized use of the Site, including collecting usernames and/or email
                                        addresses of users by electronic or other means for the purpose of sending
                                        unsolicited email, or creating user accounts by automated means or under false
                                        pretenses.</span></span></span></span></span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                                        style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                                        the Site as part of any effort to compete with us or otherwise use the Site
                                        and/or the Content for any revenue-generating endeavor or commercial
                                        enterprise.</span></span></span></span></span><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="forloop-component"></bdt>
                    </span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="question">Sell or otherwise transfer your profile.</bdt>
                    </span><span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="forloop-component"></bdt>
                    </span></li>
                <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="question">Use the Site to advertise or offer to sell goods and services.</bdt>
                    </span><span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                        <bdt class="forloop-component"></bdt>
                    </span></li>
            </ul>
        </div>
        <div class="MsoNormal" style="line-height: 1.5;"><a name="_zbbv9tgty199"></a></div>
    </div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1.5;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" data-custom-class="heading_1" id="ugc" style="line-height: 1.5;">
                        <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">6. USER GENERATED
                                CONTRIBUTIONS</span></strong></div>
                </bdt>
            </bdt>
        </bdt>
    </div>
    <div align="center" style="text-align: left; line-height: 1;"><br></div>
    <div align="center" style="text-align: left; line-height: 1;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">
                    <div class="MsoNormal" style="text-align:justify;line-height:115%;">
                        <div class="MsoNormal" data-custom-class="body_text"
                            style="line-height: 1.5; text-align: left;"><span
                                style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">
                                <bdt class="block-container if" data-type="if"
                                    id="24327c5d-a34f-f7e7-88f1-65a2f788484f">
                                    <bdt data-type="conditional-block">
                                        <bdt class="block-component" data-record-question-key="user_post_content_option"
                                            data-type="statement"><span style="font-size: 15px;"></span></bdt>
                                    </bdt>
                                </bdt>The Site may invite you to chat, contribute to, or participate in blogs, message
                                boards, online forums, and other functionality, and may provide you with the opportunity
                                to create, submit, post, display, transmit, perform, publish, distribute, or broadcast
                                content and materials to us or on the Site, including but not limited to text, writings,
                                video, audio, photographs, graphics, comments, suggestions, or personal information or
                                other material (collectively, "Contributions"). Contributions may be viewable by other
                                users of the Site and through third-party websites. As such, any Contributions you
                                transmit may be treated as non-confidential and non-proprietary. When you create or make
                                available any Contributions, you thereby represent and warrant that:<bdt
                                    class="else-block"></bdt>
                </bdt></span>
    </div>
    <ul>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">The creation, distribution, transmission,
                    public display, or performance, and the accessing, downloading, or copying of your Contributions do
                    not and will not infringe the proprietary rights, including but not limited to the copyright,
                    patent, trademark, trade secret, or moral rights of any third party.</span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">You are the creator and owner of or have
                    the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the
                    Site, and other users of the Site to use your Contributions in any manner contemplated by the Site
                    and these <bdt class="block-component"></bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>.
                </span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">You have the written consent, release,
                    and/or permission of each and every identifiable individual person in your Contributions to use the
                    name or likeness of each and every such identifiable individual person to enable inclusion and use
                    of your Contributions in any manner contemplated by the Site and these <bdt class="block-component">
                    </bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>.
                </span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions are not false,
                    inaccurate, or misleading.</span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions are not unsolicited or
                    unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass
                    mailings, or other forms of solicitation.</span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions are not obscene, lewd,
                    lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as
                    determined by us).</span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not ridicule, mock,
                    disparage, intimidate, or abuse anyone.</span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions are not used to harass
                    or threaten (in the legal sense of those terms) any other person and to promote violence against a
                    specific person or class of people.<br></span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not violate any
                    applicable law, regulation, or rule.<br></span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not violate the
                    privacy or publicity rights of any third party.<br></span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not violate any
                    applicable law concerning child pornography, or otherwise intended to protect the health or
                    well-being of minors.<br></span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not include any
                    offensive comments that are connected to race, national origin, gender, sexual preference, or
                    physical handicap.<br></span></span></li>
        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="color: rgb(89, 89, 89);"><span style="font-size: 14px;">Your Contributions do not otherwise
                    violate, or link to material that violates, any provision of these <bdt class="block-component">
                    </bdt>
                    <bdt class="question">Terms of Service</bdt>
                    <bdt class="statement-end-if-in-editor"></bdt>, or any applicable law or regulation.
                </span></span></li>
    </ul>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
            style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">Any use of the Site in violation of
            the foregoing violates these <bdt class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt> and may result in, among other things, termination or
            suspension of your rights to use the Site.
        </span></div>
</div>
</bdt>
</bdt>
</bdt>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="heading_1" id="license" style="line-height: 1.5;">
                    <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">7. CONTRIBUTION
                            LICENSE</span></strong></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                        style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                        <bdt class="block-container if" data-type="if" id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="user_post_content_option"
                                    data-type="statement"></bdt>
                            </bdt>
                        </bdt>By posting your
                        Contributions to any part of the Site<bdt class="block-container if" data-type="if"
                            id="19652acc-9a2a-5ffe-6189-9474402fa6cc">
                            <bdt data-type="conditional-block">
                                <bdt class="block-component" data-record-question-key="socialnetwork_link_option"
                                    data-type="statement"></bdt>
                            </bdt>, you automatically grant, and you represent and warrant that you have the right to
                            grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
                            transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy,
                            reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache,
                            publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in
                            part), and distribute such Contributions (including, without limitation, your image and
                            voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative
                            works of, or incorporate into other works, such Contributions, and grant and authorize
                            sublicenses of the foregoing. The use and distribution may occur in any media formats and
                            through any media channels.
                    </span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                        style="font-size: 15px;"><span
                            style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">This license will
                            apply to any form, media, or technology now known or hereafter developed, and includes our
                            use of your name, company name, and franchise name, as applicable, and any of the
                            trademarks, service marks, trade names, logos, and personal and commercial images you
                            provide. You waive all moral rights in your Contributions, and you warrant that moral rights
                            have not otherwise been asserted in your Contributions. </span></span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                        style="font-size: 15px;"><span
                            style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We do not assert any
                            ownership over your Contributions. You retain full ownership of all of your Contributions
                            and any intellectual property rights or other proprietary rights associated with your
                            Contributions. We are not liable for any statements or representations in your Contributions
                            provided by you in any area on the Site. You are solely responsible for your Contributions
                            to the Site and you expressly agree to exonerate us from any and all responsibility and to
                            refrain from any legal action against us regarding your Contributions.</span></span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                        style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We have
                        the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
                        Contributions; (2) to re-categorize any Contributions to place them in more appropriate
                        locations on the Site; and (3) to pre-screen or delete any Contributions at any time and for any
                        reason, without notice. We have no obligation to monitor your Contributions.</span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <bdt class="else-block"></span></bdt>
    </bdt>
    <bdt class="block-container if" data-type="if" id="a378120a-96b1-6fa3-279f-63d5b96341d3">
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="review_option" data-type="statement"></span></bdt>
        </bdt>
        <bdt class="block-container if" data-type="if" id="c954892f-02b9-c743-d1e8-faf0d59a4b70">
            <bdt data-type="conditional-block">
                <bdt class="block-component" data-record-question-key="mobile_app_option" data-type="statement"></span>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="e9981d4e-3a93-85dd-654b-7ecdf4bfe7d2">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="socialnetwork_link_option"
                        data-type="statement"></span></bdt>
                </bdt>
                <div class="MsoNormal" data-custom-class="heading_1" id="submissions" style="line-height: 1.5;"><a
                        name="_wfq2hvrw11j4"></a><strong><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;">8.
                            SUBMISSIONS</span></strong></div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">You acknowledge and
            agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own
            exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use
            and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you
            hereby warrant that any such Submissions are original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or
            misappropriation of any proprietary right in your Submissions.</span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" style="line-height: 1;">
        <bdt class="block-container if" data-type="if" id="36ce5a69-4560-4947-dc72-46e53e2d562a">
            <bdt data-type="conditional-block">
                <bdt class="block-component" data-record-question-key="3rd_party_option" data-type="statement"></span>
                </bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" id="14038561-dad7-be9d-370f-f8aa487b2570">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="advertiser_option" data-type="statement">
                        <bdt class="block-component"></bdt></span>
                    </bdt>
                </bdt>
    </div>
    <div class="MsoNormal" style="text-align:justify;line-height:115%;">
        <div class="MsoNormal" data-custom-class="heading_1" id="usrights" style="text-align: left; line-height: 1.5;">
            <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">9. U.S. GOVERNMENT
                    RIGHTS</span></strong></div>
    </div>
    <div class="MsoNormal" style="text-align: justify; line-height: 1;"><br></div>
    <div class="MsoNormal" style="text-align:justify;line-height:115%;">
        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Our
                services are “commercial items” as defined in Federal Acquisition Regulation (“FAR”) 2.101. If our
                services are acquired by or on behalf of any agency not within the Department of Defense (“DOD”), our
                services are subject to the terms of these <bdt class="block-component"></bdt>
                <bdt class="question">Terms of Service</bdt>
                <bdt class="statement-end-if-in-editor"></bdt> in accordance with FAR 12.212 (for computer software) and
                FAR 12.211 (for technical data). If our services are acquired by or on behalf of any agency within the
                Department of Defense, our services are subject to the terms of these <bdt class="block-component">
                </bdt>
                <bdt class="question">Terms of Service</bdt>
                <bdt class="statement-end-if-in-editor"></bdt> in accordance with Defense Federal Acquisition Regulation
                (“DFARS”) 227.7202-
            </span><span style="font-size: 15px;"><span
                    style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">3.
                    In addition, DFARS 252.227-</span></span><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89); background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">7015
                applies to technical data acquired by the DOD. This U.S. Government Rights clause is in lieu of, and
                supersedes, any other FAR, DFARS, or other clause or provision that addresses government rights in
                computer software or technical data under these <bdt class="block-component"></bdt>
                <bdt class="question">Terms of Service</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>.
            </span></div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
        <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;
Arial;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-container if" data-type="if" id="14038561-dad7-be9d-370f-f8aa487b2570">
                    <bdt class="statement-end-if-in-editor" data-type="close"><span style="font-size: 15px;"></span>
                    </bdt>
                </bdt>
            </span></div>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="sitemanage" style="line-height: 1.5;"><a
            name="_wj13r09u8u3u"></a><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">10.
                SITE MANAGEMENT</span></strong></div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We reserve the
            right, but not the obligation, to: (1) monitor the Site for violations of these <bdt
                class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>; (2) take appropriate legal action against anyone who, in our
            sole discretion, violates the law or these <bdt class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>, including without
            limitation, reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit the
            availability of, or disable (to the extent technologically feasible) any of
            your Contributions or any portion thereof; (4) in our sole discretion and
            without limitation, notice, or liability, to remove from the Site or otherwise
            disable all files and content that are excessive in size or are in any way
            burdensome to our systems; and (5) otherwise manage the Site in a manner
            designed to protect our rights and property and to facilitate the proper
            functioning of the Site.
        </span></div>
    <div class="MsoNormal" style="line-height: 1.5;"><a name="_jugvcvcw0oj9"></a></div>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="privacy_policy_option" data-type="statement"></span>
            </bdt>
            <bdt class="block-container if" data-type="if" id="7371467a-f2b5-2aff-cd0f-3379e970551e">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="privacy_policy_followup"
                        data-type="statement" style="font-size: 14.6667px;">
                        <bdt class="block-component"></bdt></span>
                    </bdt>
                </bdt>
                <bdt class="block-container if" data-type="if" id="923fc4bc-b171-82ba-b6eb-0a13c12d1b6b">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="copyright_agent_option"
                            data-type="statement"></bdt></span></span>
                        <bdt class="statement-end-if-in-editor" data-type="close"><span style="font-size: 15px;">
                                <bdt class="block-component"></bdt>
                            </span></bdt>
                    </bdt>
                </bdt>
                <bdt class="block-container if" data-type="if" id="95e88984-ac54-be9d-35de-f10fd010af14">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="no_agent_clause_option"
                            data-type="statement"></span></bdt>
                    </bdt>
                    <bdt class="block-component"></span></bdt>
                </bdt></span>
            </bdt>
        </bdt>
</div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="heading_1" id="terms" style="line-height: 1.5;"><a
            name="_k3mndam4w6w1"></a><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">11.
                TERM AND
                TERMINATION</span></strong></div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">These <bdt
                class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt> shall remain in full force and effect while you use the Site.
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE <bdt class="block-component"></bdt>
            <bdt class="question">TERMS OF SERVICE</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
            PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
            WARRANTY, OR COVENANT CONTAINED IN THESE <bdt class="block-component"></bdt>
            <bdt class="question">TERMS OF SERVICE</bdt>
            <bdt class="statement-end-if-in-editor"></bdt> OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE <bdt class="block-container if" data-type="if"
                id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement">
                    </bdt>
                </bdt>ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        </span></div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">If we terminate
                or suspend your account for any reason, you are prohibited from registering and
                creating a new account under your name, a fake or borrowed name, or the name of
                any third party, even if you may be acting on behalf of the third party. In
                addition to terminating or suspending your account, we reserve the right to
                take appropriate legal action, including without limitation pursuing civil,
                criminal, and injunctive redress.</span></span></div>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="modifications" style="line-height: 1.5;"><a
            name="_e2dep1hfgltt"></a><strong><span style="line-height: 115%; font-family: Arial;"><span
                    style="font-size: 19px;">12. MODIFICATIONS AND INTERRUPTIONS</span></span></strong></div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We reserve the right
            to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any information on our Site. We also reserve the
            right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to
            you or any third party for any modification, price change, suspension, or discontinuance of the Site.</span>
    </div>
</div>
<div align="center" style="text-align: left; line-height: 1;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">We cannot guarantee
            the Site will be available at all times. We may experience hardware, software, or other problems or need to
            perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right
            to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason
            without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
            caused by your inability to access or use the Site during any downtime or discontinuance of the Site.
            Nothing in these <bdt class="block-component"></bdt>
            <bdt class="question">Terms of Service</bdt>
            <bdt class="statement-end-if-in-editor"></bdt> will be construed to obligate us to maintain and support the
            Site or to supply any corrections, updates, or releases in connection therewith.
        </span></div>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="law" style="line-height: 1.5;"><a
            name="_p6vbf8atcwhs"></a><strong><span style="line-height: 115%; font-family: Arial;"><span
                    style="font-size: 19px;">13. GOVERNING LAW</span></span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
            <bdt class="block-component"></bdt>
        </span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">These
            Terms shall be governed by and defined following the laws of <bdt class="block-component"></bdt>
            <bdt class="question">Canada</bdt>
            <bdt class="statement-end-if-in-editor"></bdt>. <bdt class="question">Climative</bdt> and yourself
            irrevocably consent that the courts of <span style="font-size: 15px;">
                <bdt class="block-component"></bdt>
                <bdt class="question">Canada</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
            </span> shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these
            terms.<span style="font-size: 15px;"><span style="font-size: 15px;">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </span></span>
        </span></div>
</div>
<div align="center" style="text-align: left; line-height: 1.5;"><br></div>
<div align="center" style="text-align: left; line-height: 1.5;">
    <div class="MsoNormal" style="line-height: 1.5;"><br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="disputes" style="line-height: 1.5;"><a
            name="_v5i5tjw62cyw"></a><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">14.
                DISPUTE RESOLUTION</span></strong></div>
    <div class="MsoNormal" style="line-height: 1;"><br></div>
    <div class="MsoNormal" style="line-height: 1.5;">
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt class="block-component"></bdt>
    </div>
    </bdt>
    </bdt>
    </bdt>
</div>
<div class="MsoNormal" style="line-height: 1.5;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" style="line-height: 1.5;">
                    <bdt class="block-component"></bdt>
                </div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" style="line-height: 1.5;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Informal
                        Negotiations</strong></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                        style="font-size: 15px;">To expedite r</span><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);">esolution and control
                        the cost of any dispute, controversy, or claim related to these <bdt class="block-component">
                        </bdt>
                        <bdt class="question">Terms of Service</bdt>
                        <bdt class="statement-end-if-in-editor"></bdt> (each "Dispute" and collectively, the “Disputes”)
                        brought by either you or us (individually, a “Party” and collectively, the “Parties”), the
                        Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly
                        provided below) informally for at least <bdt class="block-container question question-in-editor"
                            data-id="33108d82-92fb-fbe9-6ef5-01cac7097351" data-type="question">thirty (30)</bdt> days
                        before initiating arbitration. Such informal negotiations commence upon written notice from one
                        Party to the other Party.
                    </span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" style="line-height: 1;">
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" style="line-height: 1.5;"></div>
            </bdt>
        </bdt>
    </bdt>
    <bdt class="block-container if" data-type="if">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><span
                        style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);"><strong>Binding
                            Arbitration</strong></span></div>
            </bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
</div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">Any
        dispute arising out of or in connection with this contract, including any question regarding its existence,
        validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration
        Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146)  according to the Rules of
        this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of
        arbitrators shall be <bdt class="question">three (3)</bdt>. The seat, or legal place, of arbitration shall be
        <bdt class="block-component"></bdt>
        <bdt class="question">Fredericton</bdt>, <bdt class="block-component"></bdt>
        <bdt class="question">Canada</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>
        <bdt class="else-block"></bdt>
        </bdt>. The language to be used in the arbitral proceedings shall be <bdt class="question">English</bdt>. The
        governing law of the contract shall be the substantive law of <bdt class="block-component"></bdt>
        <bdt class="block-component"></bdt>
        <bdt class="question">Canada</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>
        <bdt class="statement-end-if-in-editor"></bdt>.
    </span>
    <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"></span></bdt>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Restrictions</strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">The
        Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full
        extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or
        authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and
        (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on
        behalf of the general public or any other persons.</span></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;">
    <bdt class="block-component"><span style="font-size: 15px;"><strong></bdt>Exceptions to Informal Negotiations and
    Arbitration</strong>
    <bdt class="statement-end-if-in-editor"><strong></strong></bdt></span>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">
        <bdt class="block-component"></bdt>The Parties agree that the following Disputes are not subject to the above
        provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or
        protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute
        related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any
        claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will
        elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed
        for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.<span
            style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
            <bdt class="statement-end-if-in-editor"></bdt>
        </span>
    </span></span></div>
<div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span
            style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                            style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; line-height: 115%; color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span></span></bdt>
            </span></span></span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="corrections" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">15. CORRECTIONS</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">There may
        be information on the Site that contains typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
        inaccuracies, or omissions and to change or update the information on the Site at any time, without prior
        notice.</span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="disclaimer" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">16. DISCLAIMER</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">THE SITE
        IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT
        YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
        CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
        TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
        CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
        ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
        OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
        MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
        MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
        WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="liability" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">17. LIMITATIONS OF LIABILITY</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">IN <span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">NO EVENT WILL WE OR
            OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
            LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. <span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                    <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="limitations_liability_option"
                            data-type="statement"></bdt>
                    </bdt>
                </bdt>
                <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">NOTWITHSTANDING</bdt>
                    </bdt>
                </bdt>
            </span><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
                <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body"> </bdt>
                    </bdt>
                </bdt>
            </span><span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                    <bdt data-type="conditional-block">
                        <bdt data-type="body">ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                            CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                            <span
                                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                <bdt class="block-container if" data-type="if"
                                    id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <bdt class="block-container if" data-type="if"
                                                id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                <bdt data-type="conditional-block">
                                                    <bdt class="block-component"
                                                        data-record-question-key="limitations_liability_option"
                                                        data-type="statement"></bdt>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                            <bdt data-type="if">
                                <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                        <bdt data-type="if">
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">THE AMOUNT PAID,</bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                            <bdt data-type="if"> <bdt data-type="conditional-block">
                                    <bdt data-type="body">
                                        <bdt data-type="if">
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">IF ANY, BY YOU TO US<span
                                                        style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                                        <bdt class="block-container if" data-type="if"
                                                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <bdt class="block-container if" data-type="if"
                                                                        id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body">
                                                                                <bdt class="block-container if"
                                                                                    data-type="if"
                                                                                    id="19e172cb-4ccf-1904-7c06-4251800ba748">
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt class="block-component"
                                                                                            data-record-question-key="limilation_liability_time_option"
                                                                                            data-type="statement"></bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span> DURING THE <bdt class="question">ONE (1)</bdt> MONTH PERIOD
                                                    PRIOR TO ANY CAUSE OF ACTION ARISING<span
                                                        style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                                        <bdt class="block-container if" data-type="if"
                                                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <bdt class="block-container if" data-type="if"
                                                                        id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body">
                                                                                <bdt class="block-container if"
                                                                                    data-type="if"
                                                                                    id="19e172cb-4ccf-1904-7c06-4251800ba748">
                                                                                    <bdt class="statement-end-if-in-editor"
                                                                                        data-type="close"></bdt>
                                                                                </bdt>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                        <bdt class="block-container if" data-type="if"
                                                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <bdt class="block-container if" data-type="if"
                                                                        id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt class="block-component"
                                                                                data-record-question-key="limitations_liability_option"
                                                                                data-type="statement"></bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </bdt>
                                                            </bdt>
                                                    </span>. <span
                                                        style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
                                                        <bdt class="block-container if" data-type="if"
                                                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">CERTAIN US STATE LAWS AND
                                                                    INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                                                                    IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                                                                    CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
                                                                    ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT
                                                                    APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                        <bdt class="block-container if" data-type="if"
                                                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                                            <bdt class="statement-end-if-in-editor" data-type="close">
                                                            </bdt>
                                                        </bdt>
                                                    </span></bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                </bdt>
            </span></span></span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="indemnification" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">18. INDEMNIFICATION</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">You agree
        to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective
        officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand,
        including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: <span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
            <bdt class="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
                <bdt data-type="conditional-block">
                    <bdt class="block-component" data-record-question-key="user_post_content_option"
                        data-type="statement"></bdt>
                </bdt>
            </bdt>
        </span>
        <bdt data-type="if">
            <bdt data-type="conditional-block">
                <bdt data-type="body">(1) your Contributions;</bdt>
            </bdt>
        </bdt>
        <bdt data-type="if"> </bdt><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
            <bdt class="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
                <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
            </bdt>
        </span>(2) use of the Site; (3) breach of these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>; (4) any breach of your representations and warranties set forth
        in these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>; (5) your violation of the rights of a third party, including but
        not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Site with
        whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume
        the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to
        cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any
        such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
    </span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="userdata" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">19. USER DATA</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">We will
        maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as
        well as data relating to your use of the Site. Although we perform regular routine backups of data, you are
        solely responsible for all data that you transmit or that relates to any activity you have undertaken using the
        Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you
        hereby waive any right of action against us arising from any such loss or corruption of such data.</span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="electronic" style="line-height: 1.5;"><span
        style="font-size: 19px;"><strong>20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></span>
</div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">Visiting
        <span style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">the Site, sending us emails, and
            completing online forms constitute electronic communications. You consent to receive electronic
            communications, and you agree that all agreements, notices, disclosures, and other communications we provide
            to you electronically, via email and on the Site, satisfy any legal requirement that such communication be
            in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND
            TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
            THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
            other laws in any jurisdiction which require an original signature or delivery or retention of
            non-electronic records, or to payments or the granting of credits by any means other than electronic
            means.</span></span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;">
    <bdt class="block-component"></bdt>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="misc" style="line-height: 1.5;"><strong><span
            style="font-size: 19px;">21. MISCELLANEOUS</span></strong></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">These <bdt
            class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> and any policies or operating rules posted by us on the Site or
        in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to
        exercise or enforce any right or provision of these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> shall not operate as a waiver of such right or provision. These
        <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> operate to the fullest extent permissible by law. We may assign
        any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any
        loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> is determined to be unlawful, void, or unenforceable, that
        provision or part of the provision is deemed severable from these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> and does not affect the validity and enforceability of any
        remaining provisions. There is no joint venture, partnership, employment or agency relationship created between
        you and us as a result of these <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> or use of the Site. You agree that these <bdt
            class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> will not be construed against us by virtue of having drafted
        them. You hereby waive any and all defenses you may have based on the electronic form of these <bdt
            class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt> and the lack of signing by the parties hereto to execute these
        <bdt class="block-component"></bdt>
        <bdt class="question">Terms of Service</bdt>
        <bdt class="statement-end-if-in-editor"></bdt>.<bdt class="block-component"></bdt>
    </span></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" style="line-height: 1.5;"><br></div>
<div class="MsoNormal" data-custom-class="heading_1" id="contact" style="line-height: 1.5;"><span
        style="font-size: 19px;"><strong>22. CONTACT US</strong></span></div>
<div class="MsoNormal" style="line-height: 1.1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">In o<span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">rder to resolve a
            complaint regarding the Site or to receive further information regarding use of the Site, please contact us
            at:</span></span></div>
<div class="MsoNormal" style="line-height: 1;"><br></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
            <bdt class="question"><strong>Climative</strong></bdt>
        </span></span></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
            style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"><strong><span
                    style="color: rgb(89, 89, 89);">
                    <bdt class="question">527 Queen Street</bdt><strong><span style="color: rgb(89, 89, 89);"><span
                                style="font-size: 15px;">
                                <bdt class="block-component"></strong></bdt>
                </span></span></strong></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span
            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                <bdt class="question">Fredericton</bdt><strong><span style="color: rgb(89, 89, 89);"><span
                            style="font-size: 15px;">
                            <bdt class="block-component"></bdt>, <bdt class="question">New Brunswick</bdt>
                            <bdt class="block-component"></bdt>
                        </span></span></strong><span style="font-size: 15px;"><strong><span
                            style="color: rgb(89, 89, 89);">
                            <bdt class="block-component"></bdt>
                            <bdt class="question">E3B 1B8</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                        </span></strong><strong><span style="color: rgb(89, 89, 89);">
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </span></strong></span>
            </span></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <bdt class="block-component"><strong><span style="font-size: 15px;"></bdt>
    <bdt class="question">Canada</bdt></strong>
    <bdt class="statement-end-if-in-editor"><strong><span style="font-size: 15px;">
                <bdt class="statement-end-if-in-editor"><strong></strong></bdt>
                <bdt class="statement-end-if-in-editor"><strong></strong></bdt>
            </span></strong></bdt></span></span></strong>
</div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span style="font-size: 15px;">
            <bdt class="block-component"></bdt>
        </span></strong></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><strong><span style="font-size: 15px;">
            <bdt class="block-component"></bdt>
        </span></strong></div>
<div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <bdt class="question"><strong><span style="font-size: 15px;">info@climative.ai</span></strong></bdt>
</div>
</div>
<style>
    ul {
        list-style-type: square;
    }

    ul>li>ul {
        list-style-type: circle;
    }

    ul>li>ul>li>ul {
        list-style-type: square;
    }

    ol li {
        font-family: Arial;
    }
</style>
</div>
`;

class TermsAndConditions extends PureComponent {

  static propTypes = {
    isCustomer: PropTypes.bool
  };

  static defaultProps = {
    isCustomer: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      termsChecked: false
    };
  }

  renderCustomerTerms() {
    return (
      <div className='tc-terms-copy tc-terms-customer-copy' dangerouslySetInnerHTML={{__html: CUSTOMER_TERMS_HTML}} onClick={(e) => {
        e = e || window.event;
        let aTag = e.target?.parentNode;
        if (aTag.tagName != 'A') {
          aTag = aTag?.parentNode;
        }
        if (e.target.tagName == 'SPAN' && aTag?.tagName == 'A') {
          if (window.location.href.split("#")[0] == aTag.href.split("#")[0] && aTag.href.indexOf("#") >= 0)  {
            let section = aTag.href.split("#")[1];
            let element = document.getElementById(section);
            element.scrollIntoView();
            e.preventDefault();
            e.stopPropagation();   
            return false;
          }
        }
      }}>

      </div>
    );
  }

  renderAuditorTerms() {
    return (
      <div className='tc-terms-copy'>
        <i>Last modified: January 21, 2020</i><br/>
        <br/>
        <b>Climative Technologies Inc.</b><br/>
        <br/>
        APPLICATION SERVICES AGREEMENT<br/>
        <br/>
        PLEASE READ THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING ANY PORTION OF THE SERVICE, OR BY PAYING FOR THE SERVICE BY ANY MEANS OFFERED BY Climative, YOU ACCEPT ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. YOU AGREE THAT THIS AGREEMENT IS ENFORCEABLE LIKE ANY OTHER WRITTEN NEGOTIATED CONTRACT SIGNED BY YOU.<br/>
        <br/>
        THE TERMS AND CONDITIONS OF THIS AGREEMENT APPLY TO ANY AND ALL USE OF THE SERVICE BY YOU OR OTHERS IN YOUR ORGANIZATION, AND YOU AGREE TO BE BOUND BY THIS AGREEMENT REGARDLESS OF THE TYPE OF USE OF THE SERVICE BY YOU OR YOUR ORGANIZATION.<br/>
        <br/>
        This Agreement provides that all disputes between you and Climative will be resolved by BINDING ARBITRATION. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under this contract, except for matters that may be taken to small claims court. Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury, and your claims cannot be brought as a class action. Please review Exhibit A (“Dispute Resolution and Arbitration”) for the details regarding your agreement to arbitrate any disputes with Climative.<br/>
        <br/>
        This agreement, as amended from time to time (the "Agreement") is between you ("you", "your", "Client") and Climative Technologies Inc.<br/>
        <br/>
        1. DEFINITIONS. As used in this Agreement:<br/>
        <br/>
        “You”, “Subscriber”, and “Your” means your organization and anyone in your organization that uses Our software or Our services, including the person signing this Agreement.<br/>
        <br/>
        "Climative", "the Company", and "Our" means Climative Technologies Inc. and its assigns.<br/>
        <br/>
        "Licensed Software" means the web-based software programs and any modified, updated or enhanced versions of such programs that Climative in its sole discretion may provide to You pursuant to this Agreement or under a separate support agreement. Licensed Software specifically excludes Subscriber’s Materials.<br/>
        <br/>
        “Service” and “Services” means the Licensed Software, Virtual Energy Management (VEM)  and any and all of Climative’s automated information collection services licensed to You that operates continuously over the internet to access data from Your facilities, store this information over time on server(s) owned and/or managed by Climative, and enable data access over the internet through websites or other types of displays.<br/>
        <br/>
        “Effective Date” means the date that the Climative order form and/or contract was created, and it remains in effect until the Expiration Date.<br/>
        <br/>
        “Expiration Date” means the date that the Climative order form and/or contract expires, after which its terms are no longer valid.<br/>
        <br/>
        "Documentation" means the description of software features and capabilities as described on Climative’s website, www.climative.ai, as of the Effective Date.<br/>
        <br/>
        "Intellectual Property Rights" means all Canadian and United States copyrights, trademarks, service marks, trade secrets, patents, patent applications, and all other proprietary rights and equivalent rights throughout the world.<br/>
        <br/>
        “Service Period” means the license term (“Term” or “term”) purchased by Subscriber as referenced in a valid price quotation from Climative.<br/>
        <br/>
        Service Start Date means the date on which service begins.<br/>
        <br/>
        Service End Date means the last day of the service term.<br/>
        <br/>
        "Subscriber’s Materials" (or simply “Materials”) means the images, artwork, architectural renderings, or other electronic material of a visual nature provided by You to Climative for display in connection with the Licensed Software.<br/>
        <br/>
        <br/>
        2. PROPRIETARY RIGHTS.<br/>
        <br/>
        2.1 License Grant. Subject to the terms and conditions of this Agreement, including the payment of fees as set forth in Section 4, Climative will provide to You access to, and use of, the Licensed Software and the Service on a non-exclusive, non transferable (except as permitted in Section 11.5), revocable, non-sublicensable basis throughout the Service Period. Promptly following the Service Start Date, Climative will deliver to You user account details and/or URLs to access the Service.<br/>
        <br/>
        2.2 Additional Functionality. During the Service Period, Climative may offer additional functionality and/or the ability to use the Service with other third-party applications (collectively the “Applications”). If you elect to subscribe to such Applications, your license grant will be hereby amended to include such Applications in the license grant in Section 2.1 above. Applications may allow the use or disclosure of your information, as further set forth in each application description.<br/>
        <br/>
        2.3 Use of Data. Subscriber hereby grants Climative a non-exclusive, perpetual, irrevocable, transferable, worldwide, royalty-free license to Subscriber’s data gathered by the Service, including the right to sublicense data on an anonymized basis.<br/>
        <br/>
        2.4 Subscriber’s Materials. You hereby grant a non-exclusive, worldwide, fully sublicensable license to Climative to copy, display and modify (as necessary for compatibility with the Service) Your Materials. You warrant that Your Materials are owned by You or are properly licensed from the Materials’ rightful owner(s) for use in association with the Licensed Software and Service. By providing such Materials to Climative, you warrant that all Materials do not infringe any third party’s proprietary rights (including Intellectual Property Rights) and will not cause Climative to violate any law or regulation.<br/>
        <br/>
        2.5 Proprietary Rights. The Service and Licensed Software, including its underlying software and structure, and all worldwide Intellectual Property Rights therein are the exclusive property of Climative. Except as may be expressly provided in this Agreement, nothing in this Agreement will be deemed to grant, by implication, estoppel or otherwise, a license under any of Climative’s existing or future intellectual property, including patents. Subscriber will not remove, alter, or obscure any proprietary notices (including copyright notices) of Climative.<br/>
        <br/>
        2.6 Publicity. Unless otherwise specified and mutually agreed, Climative may use Subscriber’s name, organization logo, marks (including marks on Subscriber’s properties) to identify Subscriber as a Climative customer on Climative’s website and in other marketing materials.<br/>
        <br/>
        3. UPDATES, UPGRADES AND NEW PRODUCTS.<br/>
        <br/>
        Climative may update the Services and the hardware and software used to provide the Service from time to time. Climative shall include in the Services provided hereunder any such updates that Climative generally provides to its customers free of charge; however, nothing in this Agreement will obligate Climative to provide Services that include any upgrade (i.e. revisions to the Services that include new features or substantial increases in functionality), unless such upgrade is described in a new purchase order and You have paid the applicable license fees for such upgrade.<br/>
        <br/>
        4. FEES AND PAYMENT.<br/>
        <br/>
        You will pay, within 30 days of receipt of an invoice from Climative, all fees or charges accruing to Your account in accordance with the fees, charges and billing terms in effect at the time a fee or charge is due and payable. You may only use the Service and Licensed Software if you have paid all license fees due to Climative (“Fees”). Unless otherwise specified, all payments must be made in Canadian dollars (Canadian based companies) or in American dollars (American based companies) and are non-refundable. The Fees exclude all applicable sales, use and other taxes and you are responsible for payment of all such taxes (other than taxes based on Climative’s income), fees, duties and charges, and any related penalties and interest, arising from the payment of the Fees.<br/>
        <br/>
        Climative reserves the right to modify its fees and charges and to introduce new charges upon renewal of the Agreement. Fees for other services will be charged on an as quoted basis. All pricing terms are confidential, and You agree not to disclose them to any third party.<br/>
        <br/>
        Any portion of the fees that is not paid when due will accrue interest at twelve percent (12%) per annum or the maximum rate permitted by applicable law, whichever is less, from the due date until paid. IF ANY PAYMENT IS GREATER THAN 30 DAYS PAST DUE, YOUR SERVICE AND THIS AGREEMENT MAY BE TERMINATED AT Climative’S DISCRETION.<br/>
        <br/>
        <br/>
        <br/>
        5. INDEPENDENT CONTRACTOR STATUS.<br/>
        <br/>
        This Agreement will not be construed to create a relationship between the parties of agent, servant, employee, partnership, joint venture or association. Climative will perform the services hereunder as an independent contractor, retaining complete control over its personnel and operation. Neither Climative nor its employees will be, in any sense, Your employees or agents. You will be responsible for paying all required provincial and/or state and federal taxes, including applicable sales and use taxes. If applicable, You will pay all shipping charges (billed at cost) and agree that all hardware will be shipped FOB shipping point. Additional insurance for shipping is available to You at additional cost. Any shipping or other terms listed on a purchase order will be of no force and effect unless expressly agreed to in writing by Climative.<br/>
        <br/>
        6. YOUR OBLIGATIONS.<br/>
        <br/>
        6.1 Non-transfer. The Services are for use by You and Your authorized end users only, and only for Your internal business purposes, and not for resale, distribution, assignment, transfer, rent or loan to any third party, including any of Your parent or subsidiary entities unless expressly authorized by Climative.<br/>
        <br/>
        6.2 Other Restrictions on Use. BY USING THE SERVICES YOU AGREE NOT TO:<br/>
        (a) use the Services for any illegal purpose or in violation of any local, provincial and/or state, national, or international law;<br/>
        <br/>
        (b) violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third party Intellectual Property Right;<br/>
        <br/>
        (c) post, upload, or distribute any Subscriber Materials or other content that is unlawful, defamatory, libellous, inaccurate, or that a reasonable person could deem to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate;<br/>
        <br/>
        (d) interfere with security-related features of the Services, including by: (a) disabling or circumventing features that prevent or limit use or copying of any content; or (b) reverse engineering or otherwise attempting to discover the source code of any portion of the Services except to the extent that the activity is expressly permitted by applicable law;<br/>
        <br/>
        (e) interfere with the operation of the Services or any user’s enjoyment of the Services, including by uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code or interfering with or disrupting any network, equipment, or server connected to or used to provide the Services, or violating any regulation, policy, or procedure of any such network, equipment, or server; or<br/>
        <br/>
        (f) attempt to do any of the acts described in this Section 6.2 or assist or permit any person in engaging in any of the acts described in this Section 6.2.<br/>
        <br/>
        <br/>
        <br/>
        7. WARRANTIES.<br/>
        <br/>
        7.1 Performance. For the period of time described below, Climative warrants that the Service and Licensed Software, when used as permitted under this Agreement and in accordance with the Documentation, will operate substantially as described in the Documentation. Climative, on behalf of itself and its licensors, does not warrant that Your use of the Service or Licensed Software will be error-free or uninterrupted. Climative is not liable whatsoever for interruptions in the Service stemming from Your computers, network hardware, building automation software, internet connectivity, or other elements owned or controlled by You or others that are reasonably required for the Service or Licensed Software to operate. Climative will, at its own expense and as its sole obligation and as Your sole and exclusive remedy for any breach of this warranty, use commercially reasonable efforts to maintain maximum uptime throughout the term of this Agreement.<br/>
        <br/>
        7.2 Disclaimers. EXCEPT AS TO THE EXPRESS WARRANTIES IN THIS SECTION 7, THE SERVICES AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. Climative DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICES AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICES, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.<br/>
        <br/>
        8. LIABILITY LIMITATION.<br/>
        <br/>
        8.1 IN NO EVENT WILL Climative BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICES OR ANY MATERIALS OR CONTENT ON THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Climative HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.<br/>
        <br/>
        8.2 THE AGGREGATE LIABILITY OF Climative TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICES OR OTHERWISE UNDER THIS AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE AMOUNT YOU HAVE PAID FOR THE LICENSED SOFTWARE IN THE 12 MONTHS PRIOR TO THE EVENT(S) OR CIRCUMSTANCES GIVING RISE TO CLAIM.<br/>
        <br/>
        8.3 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.<br/>
        <br/>
        <br/>
        <br/>
        <br/>
        8.4 EACH PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THIS AGREEMENT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS AGREEMENT. THE LIMITATIONS IN THIS SECTION 8 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.<br/>
        <br/>
        9. INDEMNITY.<br/>
        <br/>
        You are responsible for Your use of the Service, and You will defend and indemnify Climative and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the “Climative Entities”) from and against every claim, liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with: (a) Your access to, use of, or alleged use of, the Service; (b) Your violation of any portion of this Agreement, any representation, warranty, or agreement referenced in this Agreement, or any applicable law or regulation; (c) Your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between You and any third party. Climative reserves the right, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by You (without limiting Your indemnification obligations with respect to that matter), and in that case, You agree to cooperate with Climative’s defence of that claim.<br/>
        <br/>
        10. TERM AND TERMINATION.<br/>
        <br/>
        10.1 Term. The term of this Agreement will begin on the Service Start Date in the Climative order form and/or contract and will continue in force for the Service Period (the “Term”). Thereafter this Agreement will renew for a period of one year or for such other term as otherwise mutually agreed upon in writing by the parties (“Renewal Term”), unless either party gives the other party not fewer than thirty (30) days notice of its intent not to renew, or unless terminated under the terms contained within this Agreement.<br/>
        <br/>
        10.2 Termination. Subscriber may terminate this Agreement at any time, with or without cause, upon written notice to Climative. Climative may terminate this Agreement, effective immediately upon written notice to Subscriber, if: (a) Subscriber fails to pay any portion of the Fees when due within thirty (30) days after receiving written notice from Climative that payment is due; or (b) Subscriber breaches any provision of this Agreement and does not cure the breach within thirty (30) days after receiving written notice thereof from Climative. Climative may also immediately terminate this Agreement for Subscriber’s breach of Section 6, upon written notice.<br/>
        <br/>
        10.3 Effects of Termination. Upon termination of this Agreement for any reason, any amounts owed to Climative under this Agreement before such termination will be immediately due and payable, all rights granted to Subscriber in this Agreement will immediately cease to exist, and all Services and Licensed Software provided by Climative may cease functioning, the precise timing of which will be determined at Climative’s sole discretion. If Subscriber elects to terminate this Agreement, no portion of the Fees will be refunded or excused.<br/>
        <br/>
        10.4 Survival. Sections 1 ("Definitions"), Section 2 ("Proprietary Rights"), Section 6 (“Your Obligations”), 7.2 ("Disclaimers"), 8 ("Liability Limitation"), 9 (“Indemnity”), 10.3 ("Effects of Termination"), 11 ("General") and Exhibit A will survive termination of this Agreement for any reason.<br/>
        <br/>
        11. GENERAL.<br/>
        <br/>
        11.1 Support. Climative is not required to provide any support services with respect to Subscriber’s property, including without limitation computers, building automation systems, network connections, routers, firewalls, or software products that operate on Subscriber’s computers.<br/>
        <br/>
        11.2 Compliance with Laws. Subscriber will comply with all applicable laws and regulations in its use of the Service and Licensed Software.<br/>
        <br/>
        11.3 Notices. All notices, consents, and approvals under this Agreement must be delivered in writing via email and will be effective one (1) business day after sending by email. Climative will use the email address provided by Subscriber upon entering into this Agreement. It is Subscriber’s duty to promptly notify Climative of any change in Subscriber’s email at the following email address sales@climative.ai.<br/>
        <br/>
        11.4 Governing Law. This Agreement will be governed by the laws of the Province of New Brunswick, Canada without giving effect to its rules regarding conflicts of law. This Agreement will not be governed by and will be construed without regard to, the United Nations Convention on the International Sales of Goods. Each party hereby irrevocably submits to the personal and exclusive jurisdiction of the provincial courts and federal courts located within New Brunswick, Canada for the purpose of litigating any dispute, but subject in all cases to the provisions in Exhibit A.<br/>
        <br/>
        11.5 Assignment. Neither party may subcontract its duties or obligations in this Agreement to a third party without the other party’s prior written consent; however, Climative may assign this Agreement in connection with a merger, reincorporation, reorganization or sale of all or substantially all of the assets and/or shares of Climative.<br/>
        <br/>
        11.6 Feedback. If You choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Services (“Feedback”), then you hereby grant Climative an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose, including to improve the Service and create other products and services.<br/>
        <br/>
        11.7 Waiver. All waivers must be in writing and signed by the party to be charged. Any waiver or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.<br/>
        <br/>
        11.8 Severability. If any provision of this Agreement is held to be unenforceable or invalid by any court of competent jurisdiction, the remaining provisions will not be affected and will continue in full force and effect.<br/>
        <br/>
        11.9 Construction. The headings of Sections of this Agreement are for convenience and are not to be used in interpreting this Agreement. As used in this Agreement, the word "including" means "including but not limited to".<br/>
        <br/>
        11.10 Force Majeure. Except for Subscriber’s obligations to pay Climative hereunder, neither party will be liable to the other party for any failure or delay in performance caused by reasons beyond its reasonable control.<br/>
        <br/>
        11.11 Entire Agreement. This Agreement constitutes the final, complete and exclusive statement of the terms of the agreement between the parties regarding the Service and Licensed Software and supersedes all prior or contemporaneous agreements, understandings, and communication between the parties, whether written or oral. The terms and conditions listed on any purchase order that conflict with this Agreement are null and void and will have no binding effect. This Agreement may be amended only by a written document signed by both parties.<br/>
        <br/>
        <br/>
        EXHIBIT A.<br/>
        <br/>
        Generally. In the interest of resolving disputes between you and Climative in the most expedient and cost-effective manner, you and Climative agree that every dispute arising in connection with this Agreement will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of this Agreement, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory and regardless of whether a claim arises during or after the termination of this Agreement. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND Climative ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.<br/>
        <br/>
        Exceptions. Despite the provisions of Section 1 of this Exhibit A, nothing in this Agreement will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law; or (d) to file suit in a court of law to address an intellectual property infringement claim.<br/>
        <br/>
        Arbitrator. Any dispute, controversy or claim arising out of or relating to this contract including any question regarding its existence, interpretation, validity, breach or termination or the business relationship created by it shall be referred to and finally resolved by arbitration under the Canadian Arbitration Association Arbitration Rules. The place of the arbitration shall be Fredericton, New Brunswick, Canada.<br/>
        <br/>
        Notice; Process. A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified mail or by Federal Express (signature required) or, only if such other party has not provided a current physical address, then by electronic mail (“Notice”). Climative’s address for Notice is: 527 Queen Street, Suite B160, Fredericton, NB. The Notice must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 60 days after the Notice is received, you or Climative may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or Climative must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If you are an individual (and not a corporate entity) and the dispute is finally resolved through arbitration in your favor, Climative will pay you the highest of the following: (i) the amount awarded by the arbitrator, if any; (ii) the last written settlement amount offered by Climative in settlement of the dispute prior to the arbitrator’s award; or (iii) $1,000.<br/>
        <br/>
        No Class Actions. YOU AND Climative AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Climative agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.<br/>
        <br/>
        Modifications to this Arbitration Provision. If Climative makes any future change to this arbitration provision, other than a change to Climative’s address for Notice, you may reject the change by sending us written notice within 30 days of the change to Climative’s address for Notice, in which case your account with Climative will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.<br/>
        <br/>
        Enforceability. If any section of this Exhibit A is found to be unenforceable, then the entirety of this Exhibit A will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section 11.4 will govern any action arising out of or related to this Agreement.<br/>
      </div>
    );
  }

  termsToggle() {
    this.setState({termsChecked: !this.state.termsChecked});
  }

  handleSubmit() {
    this.props.dispatch(acceptTermsAndConditions());
  }

  render() {

    if (!this.props.termsAccepted) {

      document.body.style.overflow = "hidden";

      return (
        <div className={'terms-and-conditions-cont ' + (!this.props.isCustomer ? 'tc-black' : '')} >
          {this.props.currentUser && <div className='tc-inner-cont'>
            <div className='tc-title'>TERMS OF SERVICE</div>
            <div className='tc-sep' style={{marginBottom: '0rem'}}></div>
            {this.props.isCustomer ? this.renderCustomerTerms() : this.renderAuditorTerms()}
            <div className='tc-sep' style={{marginTop: '1rem'}}></div>
            <br/><br/>
            <div className='tc-terms-toggle' onClick={this.termsToggle.bind(this)}>
              <img src={this.state.termsChecked ? '/images/checkbox-white-checked.png' : '/images/checkbox-white-unchecked.png'} className='accordion-checkbox' />
              I agree to the Terms of Service stated above.
            </div>
            <FormGroup className="form-action bg-widget-transparent tc-button-cont">
              <Button onClick={this.handleSubmit.bind(this)} className="mr-xs pull-right mid-button" color="primary" type="submit" disabled={!this.state.termsChecked} >
                Save & Continue
              </Button>      
            </FormGroup>
          </div>}
        </div>
      );

    }
    else {
      document.body.style.overflow = "auto";
      return (<div style={{'display': 'onone'}}/>);
    }

  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}
export default withRouter(connect(mapStateToProps)(TermsAndConditions));

