/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import './HoverIcon.scss';

class SimpHoverIcon extends React.Component {

  static propTypes = {
    icon: PropTypes.string
  };

  static defaultProps = {
    icon: ''
  };

  constructor(props) {
    super(props);

    this.iconRef = React.createRef();
    this.bubbleRef = React.createRef();

    this.state = {
        hoverOpen: false,
        rk: Math.random()
    };

    this.delay = (fn, time) => {
        return () => {
            window.setTimeout(() => fn(), time || 10);
        };
    };

    this.updateBubblePos = this.updateBubblePos.bind(this);
  }

  updateBubblePos() {
    if (!this.iconRef.current || !this.bubbleRef.current) {
        return {};
    }
    const el = this.iconRef.current;
    const bubbleEl = this.bubbleRef.current;
    const iconPos = el.getBoundingClientRect();
    const bubblePos = bubbleEl.getBoundingClientRect();
    bubbleEl.style.top = `${Math.round(iconPos.top - bubblePos.height)}px`;
    bubbleEl.style.left = `${Math.round(iconPos.left - bubblePos.width * 0.5 + iconPos.width * 0.5)}px`;
    if (!this.stopped) {
        window.requestAnimationFrame(this.updateBubblePos);
    }
  }

  componentWillUnmount() {
    if (!this.stopped) {
        this.stopped = true;
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    return (
        <div className='simp-hover-icon' onMouseLeave={() => {
            if (this.mouseLeaveTimeoutId) {
                window.clearTimeout(this.mouseLeaveTimeoutId);
                this.mouseLeaveTimeoutId = null;
            }
            this.mouseLeaveTimeoutId = window.setTimeout(() => {
                this.mouseLeaveTimeoutId = null;
                this.setState({ hoverOpen: false });
            }, 500);            
        }} onMouseEnter={() => {
            if (this.mouseLeaveTimeoutId) {
                window.clearTimeout(this.mouseLeaveTimeoutId);
                this.mouseLeaveTimeoutId = null;
            }
        }}>
            <div style={this.state.hoverOpen ? {'display': 'block'} : {'display': 'none'}} className='shi-bubble' ref={this.bubbleRef}><div className='shi-bubble-inner'>{this.props.children}</div><div className='shi-bubble-arrow'></div></div>
            <img className='shi-icon' ref={this.iconRef} src={this.props.icon} onMouseEnter={this.delay(() => {
                this.setState({hoverOpen: true});
                this.updateBubblePos();
            }, 250)}/>
        </div>
    );
  }

}

function mapStateToProps(state) {
    return { };
}

export default withRouter(connect(mapStateToProps)(SimpHoverIcon));