import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBuildingsRequest, getBuildingRequest } from '../../actions/buildings';
import { createENProject, editENProject } from '../../actions/energy-navigator';
import { getENInsights, getENInsightsByBuildingId } from '../../actions/energy-navigator';
import {Format, RandomKey, Dropdown, Input, MinMaxInput, TabBuilder, RichTextEditor, RichTextViewer, AttachmentUploader, ConfirmButton, ResponsiveTable } from '../Common';
import OrgSelRow from './OrgSelRow';
import ByOrgSelRow from './ByOrgSelRow';
import BuildingSelRow from './BuildingSelRow';

import './CreateUserModal.scss';

class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      model: {
        ...(props.edit || {})
      },
      errors: {},
      roles: null
    };

    if (!props.edit) {
      this.state.model.role = {};
    }
    else {
      this.initRoleB360Admin = false;
      if (this.state.model.role.b360 === 'admin') {
        this.initRoleB360Admin = true;
      }
      this.editingSelf = this.props.edit && this.props.currentUser.id == this.props.edit.id;
    }

  }

  componentDidUpdate(prevState, prevProps) {
  }

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  componentDidMount() {
    document.body.style.overflow = "hidden";

    this.props.dispatch(getBuildingsRequest());
    if (this.state.model.org_id) {
      this.props.dispatch(getBuildingRequest(this.state.model.org_id.key));
    }

    if (this.state.model.id) {
      axios({
        url: `/api/users/${this.state.model.id}/buildings`,
        method: 'GET',
        responseType: 'json',
      }).then((res) => {
        this.state.model.buildings = [];
        for (let B of res.data) {
          this.state.model.buildings.push({
            id: B.buildingId,
            name: B.name || B.address,
            org_name: B.org_name
          });
        }
        this.setState({ model: { ...(this.state.model), buildings: this.state.model.buildings }, buildingsTableRefreshKey: Math.random()*1e9 });
      });
    }

    axios({
      url: '/api/roles',
      method: 'GET',
      responseType: 'json',
    }).then((res) => {
      let roles = {
        b360: [],
        en: [],
        ra: []
      };
      let displayNames = {
        "contractor": "Contractor",
        "admin": "Admin",
        "property manager": "Property Manager",
        "propertymanager": "Property Manager",
        "utilityadmin": "Utility Admin",
        "remoteauditor": "Remote Auditor",
        "helpdesk": "Help Desk",
        "owner": "Owner",
        "organization": "Organization",
        "portfolioowner": "Portfolio Owner",
        "portfoliomanager": "Portfolio Manager",
        "serviceprovider": "Service Provider",
        "programmanager": "Program Manager",
        "energyadvisor": "Energy Advisor"
      };
      for (let R of res.data) {
        if (["b360", "en", "ra"].indexOf(R.app) >= 0) {
          R.title = displayNames[R.name] || "Unknown";
          if (R.name === 'property manager') {
            R.name = "propertymanager";
          }
          roles[R.app].push(R);
        }
      }
      this.setState({ roles });
    })
  }

  onChange(key) {
    return (val, v2) => {
      if (this.editingSelf) {
        return false;
      }
      let model = this.state.model;
      if (typeof v2 === typeof "" && !v2) {
        val = null;
      }
      model[key] = val;
      if (key === 'org_id') {
        if (val && val.key) {
          // look up something based on org id?
        }
      }
      this.setState({ model });
      if (this.state.errors[key]) {
        this.checkErrors(key);
      }
    };
  }

  checkErrors(only) {
    let model = this.state.model;

    let error = false;

    let errors = {...(this.state.errors)};
    let doError = (field, flag, msg) => {
      if (only && field !== only) {
        return false;
      }
      errors[field] = flag ? (msg || 'This field is required') : null;
      if (flag) {
        error = true;
      }
      return flag;
    };

    doError('first_name', !model.first_name);
    doError('last_name', !model.last_name);
    doError('phone_number', !model.phone_number);
    doError('email', !model.email);
    if (!this.props.edit) {
      doError('new_password', !model.new_password);
    }
    if (!errors.new_password) {
      doError('new_password', model.new_password && `${model.new_password}`.trim().length < 8, 'Password must be at least 8 characters in length');
    }
    doError('org_id', !model.org_id);

    this.setState({ errors: errors });

    return error;

  }

  saveUser() {

    if (this.editingSelf) {
      return;
    }

    if (this.checkErrors()) {
      return;
    }

    let model = this.state.model;

    let obj = {
      active: 1
    };

    const after = (res) => {
      let user_id = model.id ? model.id : res.data.user_id;
      let buildingIds = [];
      for (let B of (model.buildings || [])) {
        buildingIds.push(B.id);
      }
      axios({
        url: `/api/users/${user_id}/buildings`,
        method: 'PUT',
        responseType: 'json',
        data: {
          building_ids: buildingIds
        }
      }).then(() => {
        if (this.props.edit) {
          axios({
            url: `/api/users/${user_id}/roles`,
            method: 'PUT',
            responseType: 'json',
            data: {
              roles: {...(model.role)}
            }
          }).then(() => { this.props.onClose(); });
        }
        else {
          this.props.onClose();
        }
      });
    };

    const catchErr = (error) => {
      let res = error.response;
      if (res.data.error && res.data.error.email) {
        this.setState({ errors: { ...this.state.errors, email: res.data.error.email }, loading: false });
      }
    };

    if (this.props.edit && model.id) {
      axios({
        url: `/api/users/${model.id}`,
        method: 'PUT',
        responseType: 'json',
        data: {
          ...(this.state.model),
          new_password: `${this.state.model.new_password || ''}`.trim() || null
        }
      }).then(after).catch(catchErr);
    }
    else {
      axios({
        url: '/api/users',
        method: 'POST',
        responseType: 'json',
        data: {
          ...(this.state.model),
          new_password: `${this.state.model.new_password || ''}`.trim()
        }
      }).then(after).catch(catchErr);
    }

    this.setState({ loading: true });

  }

  setRole(app, name) {
    if (this.editingSelf) {
      return false;
    }
    if (this.state.model.role[app] === name) {
      this.state.model.role[app] = null;
    }
    else {
      this.state.model.role[app] = name;
    }
    this.setState({
      model: this.state.model
    });
  }

  onTableMessage(name, data) {

    if (this.editingSelf) {
      return false;
    }

    let bmap = {};
    for (let i = 0; this.state.model.buildings && i < this.state.model.buildings.length; i ++) {
      bmap[this.state.model.buildings[i].id] = i;
    }

    switch (name) {
      case 'select-org':
        this.setState({ model: { ...(this.state.model), org_id: data.id, org_name: data.name}, orgTableRefreshKey: Math.random()*1e9, errors: { ...this.state.errors, org_id: null  } });
        break;
      case 'toggle-building':
        this.state.model.buildings = this.state.model.buildings || [];
        if (bmap[data.id] >= 0) {
          this.state.model.buildings.splice(bmap[data.id], 1);
        }
        else {
          this.state.model.buildings.push({id: data.id, name: data.name, org_name: data.org_name, selected: true});
        }
        this.setState({ model: { ...(this.state.model), buildings: this.state.model.buildings }, buildingsTableRefreshKey: Math.random()*1e9 });
        break;
      case 'select-by-org':
      case 'remove-by-org':
        axios({
          url: `/api/organizations/${data.id}/buildings`,
          method: 'GET',
          responseType: 'json',
        }).then((res) => {
          this.state.model.buildings = this.state.model.buildings || [];
          for (let B of res.data) {
            let idx = -1;
            for (let i=0; i<this.state.model.buildings.length && idx < 0; i++) {
              if (this.state.model.buildings[i].id === B.id) {
                idx = i;
              }
            }
            if (idx >= 0 && name === 'select-by-org') {
              continue;
            }
            else if (idx >= 0 && name === 'remove-by-org') {
              this.state.model.buildings.splice(idx, 1);
            }
            else if (name === 'select-by-org') {
              this.state.model.buildings.push({
                id: B.buildingId,
                name: B.name || B.address,
                org_name: B.org_name
              });
            }
          }
          this.setState({ model: { ...(this.state.model), buildings: this.state.model.buildings }, buildingsTableRefreshKey: Math.random()*1e9, showByOrg: false });
        });
        break;
      default: break;
    }
  }

  render() {

    const fmtGJ = Format.GJ,
    fmtKG = Format.KG,
    fmtPercent = Format.Percent,
    fmtDollars = Format.Dollars,
    fmtDollarsShort = Format.DollarsShort,
    fmtSQFT = Format.SQFT;

    const space = (w) => (<div style={{width: `${w}px`, height: "1px", display: "inline-block"}} />);

    return (
      <div key={this.key} className={'create-user-modal ' + (this.state.loading ? 'loading' : '')}>
        <div className='create-user-modal-header'>
          <div className='create-user-modal-title'>{this.props.edit ? 'Edit' : 'Create'} User</div>
          <div className='create-user-modal-header-right'>
            {/*<div className='en-button-secondary'>Save</div>*/}
            {/*<div className='en-button-secondary'>Preview {thisName}</div>*/}
            <ConfirmButton icon='/images/x-icon.svg' msgBottom={true} wrapperClassName="modal-close" className="en-button-transparent" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
          </div>
        </div>
        <div className='create-user-modal-form-cont'>
          <div className='create-user-modal-form'>
            <div className='create-um-sub-title'>Basic Information</div>
            <Input type="text" vlabel="First Name" required={true} error={this.state.errors.first_name}  label={"First Name"} width="322px" value={this.state.model.first_name} onChange={this.onChange('first_name')} maxLength={255} readOnly={this.editingSelf}/>{space(16)}
            <Input type="text" vlabel="Last Name" required={true} error={this.state.errors.last_name}  label={"Last Name"} width="322px" value={this.state.model.last_name} onChange={this.onChange('last_name')} maxLength={255} readOnly={this.editingSelf}/><br/>
            <Input type="text" vlabel="Email Address" required={true} error={this.state.errors.email}  label={"Email Address"} width="322px" value={this.state.model.email} onChange={this.onChange('email')} maxLength={255} />{space(16)}
            <Input type="text" vlabel="Phone Number" required={true} error={this.state.errors.phone_number}  label={"Phone Number"} width="322px" value={this.state.model.phone_number} onChange={this.onChange('phone_number')} maxLength={255} readOnly={this.editingSelf}/><br/>
            <div className='create-um-sub-title'>Set Password</div>
            <Input type="text" vlabel="New Password" required={!this.props.edit} error={this.state.errors.new_password}  label={"New Password"} width="658px" value={this.state.model.new_password} onChange={this.onChange('new_password')} maxLength={255} readOnly={this.editingSelf}/><br/>
            <div className='create-um-sub-title'>Organization</div>
            {!this.state.model.id && <ResponsiveTable
              endpoint="/api/organizations"
              rowClass={OrgSelRow}
              pagination={false}
              error={this.state.errors.org_id}
              hideDefaultIfSearchEmpty={true}
              isSearchSelect={true}
              onMessage={(name, data) => {this.onTableMessage(name, data)}}
              filterData={(rows) => {
                if (this.state.model.org_id) {
                  for (let i=0; i<rows.length; i++) {
                    if (rows[i].id === this.state.model.org_id) {
                      rows.splice(i, 1);
                      break;
                    }
                  }
                  rows.unshift({
                    id: this.state.model.org_id,
                    name: this.state.model.org_name
                  });
                }
                for (let O of rows) {
                  O.selected = O.id === this.state.model.org_id;
                }
                return rows;
              }}
              refreshKey={this.state.orgTableRefreshKey}
            />}
            {this.state.model.id && <ResponsiveTable
              rowClass={OrgSelRow}
              pagination={false}
              pagSize={1}
              noSearch={true}
              rows={[ { id: this.state.model.org_id, name: this.state.model.org_name, selected: true } ]}
            />}
            <br/>
            <div className='create-um-sub-title'>Buildings</div>
            <div className={this.state.showByOrg ? 'en-button-secondary-red by-org' : 'en-button-primary by-org'} onClick={() => {this.setState({ showByOrg: !this.state.showByOrg})}}>{this.state.showByOrg ? 'Close' : 'By Organization'}</div>
            {this.state.showByOrg && <div className='by-org-popup'>
              <div className='create-um-sub-title-2'>Set access by organization</div><br/>
              <ResponsiveTable
                endpoint="/api/organizations"
                rowClass={ByOrgSelRow}
                pagination={false}
                hideDefaultIfSearchEmpty={true}
                isSearchSelect={true}
                onMessage={(name, data) => {this.onTableMessage(name, data)}}
                refreshKey={this.state.byOrgTableRefreshKey}
              />
            </div>}
            <ResponsiveTable
              endpoint="/api/buildings"
              rowClass={BuildingSelRow}
              pagination={false}
              isSearchSelect={true}
              pageSize={(this.state.model.buildings||[]).length + 5}
              onMessage={(name, data) => {this.onTableMessage(name, data)}}
              hideDefaultIfSearchEmpty={true}
              filterData={(rows) => {
                for (let R of rows) {
                  R.id = R.buildingId;
                  R.name = R.name || R.address;
                  R.org_name = R.org_name;
                }
                if (this.state.model.buildings) {
                  for (let i=0; i<rows.length; i++) {
                    rows[i].selected = false;
                    let any = false;
                    for (let B of this.state.model.buildings) {
                      any = B.id === rows[i].id;
                      if (any) {
                        break;
                      }
                    }
                    if (any) {
                      rows.splice(i, 1);
                      i --;
                      continue;
                    }
                  }
                  for (let B of this.state.model.buildings) {
                    rows.unshift({
                      id: B.id,
                      name: B.name,
                      org_name: B.org_name,
                      selected: true
                    });
                  }
                  if (rows.length > (this.state.model.buildings.length+5)) {
                    rows.length = (this.state.model.buildings.length+5);
                  }
                }
                return rows;
              }}
              refreshKey={this.state.buildingsTableRefreshKey}
            />
            <br/>
            <div className='create-um-sub-title'>Roles / Site Access</div>
            {this.state.roles && this.state.roles.en && <div className='role-sel'>
              <div className='create-um-sub-title-2'>Energy Navigator</div><br/>
              {this.state.roles.en.map((role, idx) => (
                <div className={'role-list-item ' + (this.state.model.role.en === role.name ? 'selected' : '')} key={"role-sel-" + role.name} onClick={() => this.setRole(role.app, role.name)}>{role.title}</div>
              ))}
            </div>}
            {this.state.roles && this.state.roles.ra && <div className='role-sel'>
              <div className='create-um-sub-title-2'>Energy Advisor</div><br/>
              {this.state.roles.ra.map((role, idx) => (
                <div className={'role-list-item ' + (this.state.model.role.ra === role.name ? 'selected' : '')} key={"role-sel-" + role.name} onClick={() => this.setRole(role.app, role.name)}>{role.title}</div>
              ))}
            </div>}
            {this.state.roles && this.state.roles.b360 && <div className='role-sel'>
              <div className='create-um-sub-title-2'>Building 360</div><br/>
              {this.state.roles.b360.map((role, idx) => (
                <div className={'role-list-item ' + (this.state.model.role.b360 === role.name ? 'selected' : '') + ' ' + ((this.initRoleB360Admin || role.name === 'admin') ? 'disabled' : '')} key={"role-sel-" + role.name} onClick={() => this.setRole(role.app, role.name)}>{role.title}</div>
              ))}
            </div>}
            <br/>
            {this.props.edit && <div className='create-um-sub-title'>Activity Log</div>}
          </div>
        </div>
        <div className='create-user-modal-submit-buttons'>
          {this.editingSelf && <div className='form-error-message-rel large'>Can't update your own user account, use Edit Profile instead.</div>}
          <div className={'en-button-primary ' + (this.editingSelf ? 'disabled' : '')} onClick={() => {this.saveUser();}}>Save</div>
          <ConfirmButton title="Cancel" className="en-button-secondary" msg="Are you sure you wish to cancel? Unsaved changes will be lost." onConfirm={() => {this.props.onClose();}} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.buildings.data,
    customer: state.buildings.curBuilding.building,
    currentUser: state.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(CreateUserModal));
