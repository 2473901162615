import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Format, InsightTypeBlock, StatLine, BuildingImage, RandomKey, TableSort } from '../../../components/Common'
import './OrgRow.scss';

class OrgRow extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    let cols = [ 60, 20, 20 ];

    let col = (idx) => {
      return { width: `${cols[idx]}%`, display: "inline-block", verticalAlign: "top" };
    };

    if (this.props.header) {
      return (
        <div key={this.key} className='org-card table-header'>
          <div style={col(0)}>
            <div className='org-header-split-top'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='name'>
                Organization Name
              </TableSort>
            </div>
            <div className='org-header-split-bottom'>Org. Type</div>
            <div className='org-header-split-bottom-2'>Org. ID</div>
          </div>
          <div style={col(1)}>
            <div className='org-header-cell'>
              <TableSort current={this.props.currentSort} onChange={this.props.onSortChange} name='createdAt'>
                Created On
              </TableSort>
            </div>
          </div>
          <div style={col(2)}>
            <div className='org-header-cell'>Actions</div>
          </div>
        </div>
      )
    }

    return (
      <div key={this.key} className='org-card'>
        <div style={col(0)}>
          <div className='org-cell-split-top'>{data.name}</div>
          <div className='org-cell-split-bottom'>{data.type || ''}</div>
          <div className='org-cell-split-bottom-2'>{data.id}</div>
        </div>
        <div style={col(2)}>
          <div className='org-cell'>{moment(data.createdAt).format('MMMM Do, YYYY')}</div>
        </div>
        <div style={col(2)}>
          {/*<div className='en-button-primary' onClick={() => {this.props.onMessage('edit-org', data);}}>Edit</div>
          <div className='en-button-secondary-red' onClick={() => {}}>Delete</div>*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(OrgRow);
