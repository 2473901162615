import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import Input from './Input';
import Dropdown from './Dropdown';
import InsightTypeBlock from './InsightTypeBlock';

import '../Common.scss';

class ResponsiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.rowClass = props.rowClass;

    this.latestGetID = 0;

    this.state = {
      search: "",
      currentSort: {
        name: null,
        descending: false
      },
      pageSize: props.pageSize || 10,
      pageSizes: [ 5, 10, 25, 50 ],
      pageNo: 0,
      loadedRows: []
    }

    if (props.isSearchSelect && !props.pageSize) {
      this.state.pageSize = 5;
    }
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    this.loadEndpoint();
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshKey !== prevProps.refreshKey) {
      this.loadEndpoint(1);
    }
    if (this.props.pageSize !== prevProps.pageSize) {
      this.setState({ pageSize: this.props.pageSize || 5 });
    }
    if (this.props.error != prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  loadEndpoint(delay) {
    this.latestGetID += 1;
    let getID = this.latestGetID;
    setTimeout(() => {
      if (getID !== this.latestGetID) {
        return;
      }
      if (!this.props.endpoint) {
        return;
      }
      axios({
          url: this.props.endpoint,
          method: 'GET',
          responseType: 'json',
          params: {
            limit: this.state.pageSize,
            offset: this.state.pageNo * this.state.pageSize,
            searchtext: this.state.search || '',
            sort: this.state.currentSort.name || undefined,
            order: this.state.currentSort.descending ? 'desc' : 'asc'
          }
      }).then(res => {
        if (getID !== this.latestGetID) {
          return;
        }
        if (this.props.hideDefaultIfSearchEmpty && !`${this.state.search}`.trim().length) {
          res.data.rows = [];
        }
        if (this.props.filterData) {
          res.data.rows = this.props.filterData(res.data.rows || []);
        }
        this.setState({ loadedRows: res.data.rows, totalRows: res.data.count, pageCount: Math.max(1, Math.ceil(res.data.count / this.state.pageSize)) });
      });
    }, delay||1);
  }

  render() {
    const Row = this.rowClass;
    let rows = [...(this.props.rows || [])];

    if (this.props.endpoint) {
      rows = [...(this.state.loadedRows || [])];
    }

    if (this.state.currentSort.name && !this.props.endpoint) {
      rows = [...rows];
      rows = rows.sort((a, b) => ((this.state.currentSort.descending ? -1 : 1) * JSON.stringify(a[this.state.currentSort.name] || "").localeCompare(JSON.stringify(b[this.state.currentSort.name] || ""))));
    }

    if (this.state.search && !this.props.endpoint) {
      rows = rows.filter((r) => {
        let vals = [];
        for (let key in r) {
          vals.push(r[key]);
        }
        return JSON.stringify(vals).toLowerCase().indexOf(`${this.state.search}`.toLowerCase()) >= 0;
      });
    }

    let numPages = this.state.pageCount || 1;
    let pageNo = this.state.pageNo;

    if (this.props.pagination && !this.props.endpoint) {
      let offset = this.state.pageNo * this.state.pageSize;
      if (offset >= rows.length) {
        offset = Math.floor(rows.length / this.state.pageSize) * this.state.pageSize;
      }
      pageNo = Math.floor(offset / this.state.pageSize);
      let orows = rows;
      rows = [];
      for (let i=offset; i<(offset+this.state.pageSize) && i<orows.length; i++) {
        rows.push(orows[i]);
      }
      numPages = Math.max(1, Math.ceil(orows.length / this.state.pageSize));
    }

    return (
      <div className='en-resp-table-container' key={this.key}>
        <div>
          {this.state.error && <div className='form-error-message-rel'>{this.state.error}</div>}
        </div>
        {!this.props.noSearch && <div className={'en-rt-search ' + (this.props.isSearchSelect ? 'is-ss' : '')}>
          <Input type="text" vlabel="Search" label={"Search"} width="280px" value={this.state.search} onChange={(v) => { this.setState({ search: v });  this.loadEndpoint(500); }} maxLength={255} />
        </div>}
        <div className='en-rt-header'>
          <Row header={true} currentSort={this.state.currentSort} onSortChange={(name, desc) => { this.setState({currentSort: {name, descending: desc}}); this.loadEndpoint(1); }} onMessage={this.props.onMessage} />
        </div>
        <div className='en-rt-body'>
          {rows.map((row, idx) => (
            <Row data={row} key={this.key + '-row-' + idx} onMessage={this.props.onMessage} />
          ))}
        </div>
        {this.props.pagination && <div className='en-rt-pagination'>
          <Dropdown title="Page Size" openUp={true} options={this.state.pageSizes} onChange={(v) => {this.setState({pageSize: parseInt(v)}); this.loadEndpoint(1);}} defaultValue={this.state.pageSize} />
          <div className={'en-button-primary ' + (pageNo < 1 ? 'disabled' : '')} onClick={() => {this.setState({pageNo: pageNo-1}); this.loadEndpoint(1); return false;}}>Previous</div>
          <div className='page-label'>Page {pageNo + 1} of {numPages}</div>
          <div className={'en-button-primary ' + ((pageNo+1) >= numPages ? 'disabled' : '')} onClick={() => {this.setState({pageNo: pageNo+1}); this.loadEndpoint(1); return false;}}>Next</div>
        </div>}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(ResponsiveTable));
