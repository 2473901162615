export default {
  'javascript': {
    draft: `function(row) {\n   // processing here, return null to delete row.\n   return row;\n}\n`,
    live: `function(row) {\n   // processing here, return null to delete row.\n   return row;\n}\n`
  },
  'jsonOutput': {
    draft: `function(rows) {\n   // processing here.\n   return { rows };\n}\n`,
    live: `function(rows) {\n   // processing here.\n   return { rows };\n}\n`,
    viewData: -1
  },
  'httpInput': {
    data: [],
    url: ''
  },
  'subEnter': {
    mockInputDraft: `[ {} ]`,
    mockInputLive: `[ {} ]`
  },
  'fileInput': [],
  'sort': {

  },
  'arraySelect': {

  },
  'enumNode': {

  },
  'joinNode': {

  },
  'addressTools': {
    full_address: 'full_address',
    fsa: 'fsa',
    process: 'combine-address',
    is_constant: {

    }
  },
  'insightsMap': {
    geocode: true
  },
  'tableRemapperNode': {
    viewData: -1
  },
  'pbApiProcess': {
    process: 'geocode',
    output_prefix: 'geocode',
    max_distance: 10,
    full_address: '',
    street_address: '',
    street: '',
    city: '',
    province: '',
    country: '',
    postal_code: '',
    floors: '',
    total_floor_area_sqft: '',
    year_built: '',
    air_conditioner_type: '',
    basement_area_sqft: '',
    crawlspace_floor_area_sqft: '',
    house_type: '',
    fsa: '',
    id: ''
  },
  'geoJson': {
    process: 'generate-points',
    properties: []
  },
  'gradientNode': {
    skew_pow: 1.0,
    quant_levels: 16,
    start_color: '#00FF00',
    end_color: '#FF0000',
    palette: ["#00ff00","#11ee00","#22dd00","#33cc00","#44bb00","#55aa00","#669900","#778800","#887700","#996600","#aa5500","#bb4400","#cc3300","#dd2200","#ee1100","#ff0000"],
    output: 'color'
  },
  'remapperNode': {
    skew_pow: 1.0,
    start_val: 0,
    end_val: 100,
    output: 'value'
  },
  'tableOutput': {
    viewData: -1
  },
  'mapOutput': {
    viewData: -1
  },
  'apiProcess': {
    draftInput: `function(rows) {\n    return {\n        method: 'GET',\n        url: '/',\n        data: {\n            rows\n        }\n    }\n}`,
    liveInput: `function(rows) {\n    return {\n        method: 'GET',\n        url: '/',\n        data: {\n\n        }\n    }\n}`,
    draftMockJSON: `{\n    "status": 200,\n    "data": {\n        "rows": [\n            { "test": 1 },\n            { "test": 2 }\n        ]\n    }\n}`,
    liveMockJSON: `{\n    "status": 200,\n    "data": {\n        "rows": [\n            { "test": 1 },\n            { "test": 2 }\n        ]\n    }\n}`,
    draftOutput: `function(data, status) {\n    return data.rows; // array\n}`,
    liveOutput: `function(data, status) {\n    return data.rows; // array\n}`,
  }
};
