import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Format, InsightTypeBlock, StatLine, RichTextViewer, ConfirmButton } from '../../../components/Common'
import CreateProjectModal from '../../../components/CreateProjectModal';

import './InsightCard.scss';

class InsightCard extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      showPullout: false,
      selectedTab: 'overview'
    }
  }

  showPullout(flag) {
    this.setState({ showPullout: !!flag });
  }

  setTab(key) {
    this.setState({ selectedTab: key });
  }

  componentDidMount() {

  }

  makeNewVersion() {
    this.editInsight(true);
  }

  editInsight(newVersion) {
    const { type, data } = this.props;

    let obj = {
      type: data.subtype,
      building_id: {
        key: data.building_id,
        title: data.building_name,
      },
      attachments: data.attachments,
      title: data.title,
      subtitle: data.subtitle,
      financial_details: data.financial_details,
      technical_details: data.technical_details,
      vendor: data.vendor,
      other: data.other,
      description: data.overview,
      est_roi: data.roi,
      est_annual_savings: data.est_annual_savings,
      est_capital_cost: data.est_capital_cost,
      est_payback_period_years: data.est_payback_period_years,
      offer_id: newVersion ? undefined : data.id,
      parent_offer_id: newVersion ? data.id : undefined
    };

    if (data.type === 'offer') {
      obj = {
        ...obj,
        energy_use_intensity: data.energy_use_intensity,
        ghg_intensity: data.ghg_emission_intensity,
        eui_savings: data.eui_savings && {
          min: data.eui_savings.min,
          max: data.eui_savings.max
        },
        ghg_savings: data.ghg_savings && {
          min: data.ghg_savings.min,
          max: data.ghg_savings.max
        },
        energy_star: data.energy_star,
        projected_energy_star: data.projected_energy_star && {
          min: data.projected_energy_star.min,
          max: data.projected_energy_star.max
        }
      };
    }

    this.setState({
      editMe: obj
    })
  }

  renderTab(title, key, percentWidth) {
    return (
      <div className={'insight-tab ' + ((this.state.selectedTab == key) ? ' tab-selected' : '')} style={{width: percentWidth + '%'}} onClick={() => {this.setTab(key)}}>
        {title}
      </div>
    );
  }

  render() {
    const { type, data } = this.props;

    const fmtGJ = Format.GJ,
          fmtKG = Format.KG,
          fmtPercent = Format.Percent,
          fmtDollars = Format.Dollars,
          fmtDollarsShort = Format.DollarsShort;

    const mailTo = (emailAddress) => {
      return () => {
        window.location.href = `mailto:${emailAddress}?cc=energy.navigator@simptekinc.com`;
      };
    };

    let role = this.props.currentUser && this.props.currentUser.role && this.props.currentUser.role[0];
    let isSP = role === 'serviceprovider';

    let attKeyMap = { 'overview': 'description', 'technical': 'technical_details', 'financial': 'financial_details', 'vendor': 'vendor', 'other': 'other' };
    let attachments = data.attachments[attKeyMap[this.state.selectedTab]] || [];

    return (
      <div className='insight-card'>
        {this.state.editMe && <CreateProjectModal
          edit={this.state.editMe}
          onClose={() => { this.setState({editMe: null}) }}
        />}
        <InsightTypeBlock type={data.subtype}/>
        {role === 'serviceprovider' && <div className={'insight-status ' + (data.status === 'rejected' ? 'rejected' : (data.active ? 'published' : 'draft'))}>
          {data.status === 'rejected' ? 'Rejected' : (data.active === 1 ? 'Published' : 'Draft')}
        </div>}
        <div className='insight-title-cont'>
          <div className='insight-title' title={data.title}>{data.title}</div>
          <div className='insight-building-name' title={data.building_name}>{data.building_name}</div>
        </div>
        {data.subtype === 'automated-insights' && <StatLine title="Energy Use Intensity" subtitle={data.energy_use_intensity && `${fmtGJ(data.energy_use_intensity.gj)} kWh/ft², `}>{data.energy_use_intensity && fmtPercent(data.energy_use_intensity.delta_percent)}</StatLine>}
        {data.subtype === 'automated-insights' && <StatLine title="GHG Emission Intensity" subtitle={data.ghg_emission_intensity && `${fmtKG(data.ghg_emission_intensity.kgCO2)} kgCO2/m², `}>{data.ghg_emission_intensity && fmtPercent(data.ghg_emission_intensity.delta_percent)}</StatLine>}
        {data.subtype !== 'automated-insights' && <StatLine title="Return on Investment" subtitle={data.roi && `${Math.floor(data.roi.min)}-${Math.floor(data.roi.max)}%`} icon="/images/roi-icon.svg"></StatLine>}
        {data.subtype !== 'automated-insights' && <StatLine title="Estimated Annual Savings" subtitle={data.est_annual_savings && `${fmtDollars(data.est_annual_savings.min)} - ${fmtDollars(data.est_annual_savings.max)}`} icon="/images/est-savings-icon.svg"></StatLine>}
        <div className='insight-view-more' onClick={() => {this.showPullout(true);}}>View {this.props.type === 'insight' ? 'Insight' : 'Offer'}</div>
        {isSP && <div className='insight-view-more' onClick={() => {this.editInsight();}}>Edit {this.props.type === 'insight' ? 'Insight' : 'Offer'}</div>}
        {this.state.showPullout && <div className="insight-pullout-cont" onClick={(e) => {if ((e||window.event).target.className==='insight-pullout-cont') this.showPullout(false);}}>
          <div className={"insight-pullout " + (data.subtype !== 'automated-insights' && data.subtype !== 'expert-insights' ? ' offer-pullout' : '')}>
            <InsightTypeBlock type={data.subtype}/>
            <div className='insight-title'>{data.title}</div>
            <div className='insight-subtitle'>{data.subtitle}</div>
            <div className='insight-name-addr'>{data.building_name}{/* | {data.building_address}*/}</div>
            {(data.subtype === 'automated-insights' || data.subtype === 'expert-insights') ? (<div>
              {data.advisor && <div className='insight-header-item left'>Energy Advisor: <span>{data.advisor.name}, {data.advisor.company}</span></div>}
              {data.roi && <div className='insight-header-item right'>Estimated ROI: <span>{Math.floor(data.roi.min)}-{Math.floor(data.roi.max)}%</span></div>}
              {data.est_capital_cost && <div className='insight-header-item left'>Estimated Capital Cost: <span>{fmtDollars(data.est_capital_cost.min)} - {fmtDollars(data.est_capital_cost.max)}</span></div>}
              {data.est_payback_period_years && <div className='insight-header-item right'>Estimated Payback Period: <span>{Math.floor(data.est_payback_period_years.min)}{data.est_payback_period_years.max === data.est_payback_period_years.min ? '' : ('-' + Math.floor(data.est_payback_period_years.max))} years</span></div>}
              {data.est_annual_savings && <div className='insight-header-item left'>Estimated Annual Savings: <span>{fmtDollarsShort(data.est_annual_savings.min)} - {fmtDollarsShort(data.est_annual_savings.max)}</span></div>}
              {!data.advisor && <div className='insight-header-item left'></div>}
              {!data.roi && <div className='insight-header-item left'></div>}
              {!data.est_capital_cost && <div className='insight-header-item left'></div>}
              {!data.est_payback_period_years && <div className='insight-header-item left'></div>}
              {!data.est_annual_savings && <div className='insight-header-item left'></div>}
              <div className='insight-header-item full'></div>
            </div>)
            :(<div>
              {data.advisor && <div className='insight-header-item left'>Energy Advisor: <span>{data.advisor.name}, {data.advisor.company}</span></div>}
              {data.roi && <div className='insight-header-item right'>Estimated ROI: <span>{Math.floor(data.roi.min)}-{Math.floor(data.roi.max)}%</span></div>}
              {data.est_capital_cost && <div className='insight-header-item left'>Estimated Capital Cost: <span>{fmtDollars(data.est_capital_cost.min)} - {fmtDollars(data.est_capital_cost.max)}</span></div>}
              {data.est_payback_period_years && <div className='insight-header-item right'>Estimated Payback Period: <span>{Math.floor(data.est_payback_period_years.min)}{data.est_payback_period_years.max === data.est_payback_period_years.min ? '' : ('-' + Math.floor(data.est_payback_period_years.max))} years</span></div>}
              {data.est_annual_savings && <div className='insight-header-item left'>Estimated Annual Savings: <span>{fmtDollarsShort(data.est_annual_savings.min)} - {fmtDollarsShort(data.est_annual_savings.max)}</span></div>}
              {!data.advisor && <div className='insight-header-item left'></div>}
              {!data.roi && <div className='insight-header-item left'></div>}
              {!data.est_capital_cost && <div className='insight-header-item left'></div>}
              {!data.est_payback_period_years && <div className='insight-header-item left'></div>}
              {!data.est_annual_savings && <div className='insight-header-item left'></div>}
              {<div className='sep-offer'/>}
              <div className='offer-fields'>
                {data.energy_use_intensity && <div className='insight-header-item full'>Energy Use Intensity: <span>{fmtGJ(data.energy_use_intensity)} kWh/ft²</span></div>}
                {data.ghg_emission_intensity && <div className='insight-header-item full'>GHG Emission Intensity: <span>{fmtGJ(data.ghg_emission_intensity)} kgCO2/m²</span></div>}
                {data.energy_star && <div className='insight-header-item full'>Energy Star Score: <span>{Math.floor(data.energy_star)}</span></div>}
                {data.eui_savings && <div className='insight-header-item full'>Projected Energy Use Intensity Reduction: <span>{fmtGJ(data.eui_savings.min)}-{fmtGJ(data.eui_savings.max)} kWh/ft²</span></div>}
                {data.ghg_savings && <div className='insight-header-item full'>Projected GHG Emission Intensity Reduction: <span>{fmtGJ(data.ghg_savings.min)}-{fmtGJ(data.ghg_savings.max)} kgCO2/m²</span></div>}
                {data.projected_energy_star && <div className='insight-header-item full'>Projected Energy Star Score Improvement: <span>{Math.floor(data.projected_energy_star.min)}-{Math.floor(data.projected_energy_star.max)}</span></div>}
              </div>
            </div>)}
            <div className='insight-tab-cont'>
              {data.overview && this.renderTab('Overview', 'overview', 20)}
              {data.technical_details && this.renderTab('Technical Details', 'technical', 25)}
              {data.financial_details && this.renderTab('Financial Details', 'financial', 25)}
              {data.vendor && this.renderTab('Vendor', 'vendor', 15)}
              {data.other && this.renderTab('Other', 'other', 15)}
            </div>
            <div className='insight-tab-content'>
              {this.state.selectedTab === 'overview' && (<RichTextViewer value={data.overview} width="545px"/>)}
              {this.state.selectedTab === 'financial' && (<RichTextViewer value={data.financial_details} width="545px"/>)}
              {this.state.selectedTab === 'technical' && (<RichTextViewer value={data.technical_details} width="545px"/>)}
              {this.state.selectedTab === 'vendor' && (<RichTextViewer value={data.vendor} width="545px"/>)}
              {this.state.selectedTab === 'other' && (<RichTextViewer value={data.other} width="545px"/>)}
              {(attachments.length > 0) && <div className='insight-tab-att-title'>Attachments</div>}
              {attachments.map((att, index) => (
                <div className='insight-tab-att' key={'insight-card-att-' + att.id}>
                  <div className='itatt-filename'>{att.file_name}</div>
                  <img src='/images/download-file.svg' onClick={() => {window.location.href = att.link;}} />
                </div>
              ))}
            </div>
            {!isSP && data.subtype === 'automated-insights' && <div className='insight-tab-ctas'>
              {/*<div className='ask-for-expert'>Ask for Expert Insights</div>*/}
            </div>}
            {!isSP && data.subtype === 'estimated-offer' && <div className='insight-tab-ctas'>
              {/*<div className='ask-for-quote'>Ask for Quote</div>*/}
              <div className='contact-vendor' onClick={mailTo(data.advisor.email)}>Contact Vendor</div>
            </div>}
            {!isSP && data.subtype === 'expert-insights' && <div className='insight-tab-ctas'>
              {/*<div className='ask-for-est'>Ask for Estimates</div>*/}
              <div className='contact-vendor' onClick={mailTo(data.advisor.email)}>Contact Vendor</div>
            </div>}
            {!isSP && data.subtype === 'quoted-offer' && <div className='insight-tab-ctas'>
              {/*<div className='start-project'>Start Project</div>*/}
              <div className='contact-vendor' onClick={mailTo(data.advisor.email)}>Contact Vendor</div>
            </div>}
            {isSP && <div className='insight-tab-ctas'>
              {data.building_owner_email && <div className='contact-building-owner' onClick={mailTo(data.building_owner_email)}>Contact Building Owner</div>}
              {data.status !== 'rejected' && <div className='insight-view-more' onClick={() => {this.editInsight();}}>Edit {this.props.type === 'insight' ? 'Insight' : 'Offer'}</div>}
              {data.status === 'rejected' && <div className='insight-view-more' onClick={() => {this.makeNewVersion();}}>Make New Version</div>}
              {false && data.type === 'offer' && <ConfirmButton title="Revoke" className="en-button-secondary-red" wrapperClassName="revoke-button-wrapper" msg="Why do you want to revoke this? Note that your response will be shared with the Building Owner." requireReason={true} onConfirm={(reason) => {console.log("revoke reason: " + reason)}} />}
            </div>}
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(InsightCard);
