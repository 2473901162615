import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BuildingDetailsCard from './components/BuildingDetailsCard';
import BuildingAttachments from './components/BuildingAttachments';
import { getBuildingRequest } from '../../actions/buildings'
import { push } from 'connected-react-router';
import InsightsOffers from '../enInsightsOffers/ENInsightsOffers';
import { RandomKey } from '../../components/Common';

import './ENBuildingDetail.scss';

class EnergyBuildingsDetails extends Component {
  static propTypes = {
    customer: PropTypes.object,
  };

  static defaultProps = {
    customer: {
      buildingId: '',
      name: "",
      building_address: '',
      projects_new: 1,
      projects_ongoing: 1,
      projects_completed: 1,
      image_uri: ""
    }
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  componentDidMount() {
    const buildingId = this.props.match.params.buildingId;
    if (buildingId) {
      this.props.dispatch(getBuildingRequest(buildingId));
    }
    setTimeout(() => {
      this.showPromoAlert();
    }, 100);
  }

  showPromoAlert() {
    //this.setState({ promoAlert: true });
  }

  render() {
    const buildingId = this.props.match.params.buildingId;
    let tok = window.location.href.split("?cid=");
    let customerId = tok[1] || null;
    return (
      <div className='building-details-page-container'>
        <div className='back-button' onClick={customerId ? (() => this.props.dispatch(push('/app/main/customer-details/' + customerId))) : (() => this.props.dispatch(push('/app/main/en-buildings')))}>
          <img src="/images/back-arrow.svg" /> Back
        </div>
        <div className='building-details-container'>
          <BuildingDetailsCard data={this.props.customer} />
        </div>
        <div className='building-details-right-cont'>
          <InsightsOffers buildingId={buildingId}></InsightsOffers>

          {this.props.customer && this.props.customer.propertyAttachment && this.props.customer.buildingId === buildingId && <BuildingAttachments buildingId={buildingId} data={this.props.customer.propertyAttachment} />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.buildings.curBuilding.building,
  };
}

export default connect(mapStateToProps)(EnergyBuildingsDetails);
