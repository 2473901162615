import React, { memo, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import LZUTF8 from 'lzutf8';
import NodeWrapper from './NodeWrapper';
import ColumnSelect from './ColumnSelect';
import Checkbox from './Checkbox';
import { Input, Dropdown } from '../../../components/Common';
import { toTableFormat } from './helpers';

const loadSubFlow = async (id) => {
  let res = await axios.get('/flows/sub-programs/' + id);
  let decompressedStr = LZUTF8.decompress(res.data.flow_json, {inputEncoding:"Base64"});
  const flow = JSON.parse(decompressedStr);

  for (let e of flow.elements) {
    if (e && e.data && e.data.value && e.data.value._separate_save_json) {
      let resp = await axios.post(
        '/flows/api-proxy',
        {
          method: 'GET',
          uri: e.data.value._separate_save_json.link
        }
      );
      e.data.value._separate_save_json = resp.data;
    }
  }

  return flow;
};

export default memo(({ data }) => {

  const getColumns = (flow) => {
    let columns = model.columns || {};
    let N = null;
    if (flow && flow.elements) {
      for (let E of flow.elements) {
        if (E.type === 'subEnter') {
          N = E;
          break;
        }
      }
    }
    let inputSpec = N && N.data.value.mockInputLive;
    try {
      inputSpec = JSON.parse(inputSpec);
    }
    catch (err) {
      inputSpec = [];
    }
    if (!(inputSpec instanceof Array)) {
      inputSpec = [ inputSpec ];
    }
    let table = toTableFormat(inputSpec, false, true, true) || { header: [] };
    for (let H of table.header) {
      if (!columns[H]) {
        columns[H] = null;
      }
    }
    return columns;
  }

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [flowList, setFlowList] = useState(null);
  const [selectId, setSelectId] = useState("select-sub-" + Math.round(Math.random() * 1e9));
  const loadFlow = (id) => {
    loadSubFlow(id).then((flow) => {
      let columns = getColumns(flow);
      saveModel({ ...model, flow, flow_id: id, columns });
    });
  };
  const onChangeCol = (col, val) => {
    const m = { ...model, columns: { ...(model.columns||{}) } };
    m.columns[col] = val;
    saveModel(m);
  };
  const loadFlows = (value, setValue) => {
    axios.get('/flows/sub-programs/').then((res) => {
      res.data.sort((a, b) => {
        return -a.updatedAt.localeCompare(b.updatedAt);
      });
      let FL = [];
      for (let F of res.data) {
        FL.push({ key: F.id, title: F.name });
      }
      if (value && (data.value.flow_id != value)) {
        loadFlow(value);
      }
      setFlowList(FL);
    });
  };

  useEffect(() => {
    if (!flowList) {
      loadFlows(model.flow_id, true);
    }
    if (model.flow) {
      let columns = getColumns(model.flow);
      if (JSON.stringify(columns) !== JSON.stringify(model.columns)) {
        saveModel({ ...model, columns });
      }
    }
  });

  return (
    <NodeWrapper
      title='Call Function'
      onSave={() => data.onChange(model)}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      data={data}
      width="415px"
    >
      <div className='fcs-title'>Select Function from Library</div>
      {flowList && <Dropdown defaultValue={flowList.filter((e) => (`${e.key}` === `${model.flow_id}`))[0]} options={flowList} onChange={(val) => {model.columns = {}; saveModel({...model, columns: {}}); loadFlow(val.key)}} hasArrow={true} />}
      <br/>
      {flowList && <div className='fcs-title' style={{marginBottom: '15px'}}>Map Function Parameters</div>}
      {flowList && <div className='fcs-col-scroll'>
        {Object.keys(model.columns||{}).map((col, idx) => (<div key={'fcscs-'+idx} className='fcs-half'>
          <ColumnSelect title={col} value={model.columns[col]} onChange={(val) => onChangeCol(col, val)} />
        </div>))}
      </div>}
      <Checkbox title='Iterate over input in chunks' value={!!model.iterate} onChange={(val) => saveModel({ ...model, iterate: !!val })} />
      <br/>
      <div className='fcs-title'>Max Chunk Size</div>
      <Input type="number" defaultValue={model.chunkSize || 1} onChange={(val) => saveModel({ ...model, chunkSize: Math.max(parseInt(val)||0, 1)})} />
    </NodeWrapper>
  );
});