import {
  RECEIVED_AUDITS,
  RECEIVED_AUDITS_YBD,
} from '../actions/audits';

const defaultState = {
    data: [],
    images: [],
    isReceiving: false,

};

export default function auditsReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_AUDITS:
            return Object.assign({}, state, {
                data: action.payload,
                isReceiving: false,
            });
        case RECEIVED_AUDITS_YBD:
            return Object.assign({}, state, {
                data: action.payload,
                isReceiving: false,
            });
        default:
            return state;
    }
}
