/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import config from './config';

import './SimpHLChart.scss';

class SimpHLChart extends React.Component {

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    walls_hl: PropTypes.number,
    ceiling_hl: PropTypes.number,
    foundation_hl: PropTypes.number,
    windows_hl: PropTypes.number,
    comp_walls_hl: PropTypes.number,
    comp_ceiling_hl: PropTypes.number,
    comp_foundation_hl: PropTypes.number,
    comp_windows_hl: PropTypes.number,
    no_found: PropTypes.bool
  };

  static defaultProps = {
    width: 268,
    height: 268,
    walls_hl: 0,
    ceiling_hl: 0,
    foundation_hl: 0,
    windows_hl: 0,
    comp_walls_hl: 0,
    comp_ceiling_hl: 0,
    comp_foundation_hl: 0,
    comp_windows_hl: 0,
    no_found: false
  };

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderHLChart();
  }

  componentDidUpdate(prevProps) {
    if (this.canvas !== this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.ctx = this.canvas.getContext('2d');
    }

    this.renderHLChart();
  }

  renderHLChart() {
    const width = this.props.width, height = this.props.height;

    // size canvas
    this.canvas.width = width;
    this.canvas.height = height;

    let ctx = this.ctx;

    ctx.save();

    // clear canvas
    ctx.clearRect(0, 0, width, height);

    let cx = width * 0.5, cy = height * 0.5;
    let cr = Math.min(width * 0.5, height * 0.5) * 0.9;

    let data = [
      this.props.walls_hl, this.props.ceiling_hl, this.props.foundation_hl, this.props.windows_hl
    ];

    let data2 = [
      this.props.comp_walls_hl, this.props.comp_ceiling_hl, this.props.comp_foundation_hl, this.props.comp_windows_hl
    ];

    if (this.props.no_found) {
      data[2] = data2[2] = 0.;
    }

    let max = Math.max(...data);
    let max2 = Math.max(...data2);

    if (!(max > 0)) {
      ctx.restore();
      return;
    }

    max = Math.max(max, max2);

    let gridSteps = 4;
    for (let i=1; i<=gridSteps; i++) {
      ctx.strokeStyle = 'rgba(50, 66, 93, 1.0)';
      ctx.beginPath();
      let r1 = cr*Math.pow((i-1.0)/gridSteps, 0.5);
      let r2 = cr*Math.pow((i-0.5)/gridSteps, 0.5);
      ctx.lineWidth = (r2 - r1);
      ctx.arc(cx, cy, (r1+r2)*0.5, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.beginPath();
      r1 = cr*Math.pow((i-0.5)/gridSteps, 0.5);
      r2 = cr*Math.pow((i)/gridSteps, 0.5);
      ctx.lineWidth = (r2 - r1);
      ctx.arc(cx, cy, (r1+r2)*0.5, 0, 2 * Math.PI);
      ctx.stroke();
    }
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'rgba(50, 66, 93, 1.0)';
    let a0 = Math.PI * 0.25;
    for (let i=0; i<data.length; i++) {
      let r = cr * 1.35;
      ctx.beginPath();
      ctx.moveTo(cx, cy);
      ctx.lineTo(cx + Math.cos(a0) * r, cy + Math.sin(a0) * r);
      ctx.strokeStyle = 'rgba(50, 66, 93, 1.0)';
      ctx.stroke();
      a0 += Math.PI * 0.5;
    }

    a0 = 0;
    let points = [];

    const STEPS = 20;
    const POW = 0.2;

    for (let i=0; i<data.length; i++) {
      let r = cr * Math.pow((data[i] / max), 0.5);
      let lr = cr * Math.pow((data[(i+data.length-1)%data.length] / max), 0.5);
      let rr = cr * Math.pow((data[(i+1)%data.length] / max), 0.5);
      lr = (lr + r) * 0.5;
      rr = (rr + r) * 0.5;
      for (let k=0; k<STEPS; k++) {
        let t = k / STEPS;
        let a = a0 + Math.PI * 0.25 * t;
        let tr = (1 - Math.pow(t, POW)) * lr + Math.pow(t, POW) * r;
        points.push({ x: cx + Math.cos(a) * tr, y: cy + Math.sin(a) * tr });
      }
      for (let k=0; k<STEPS; k++) {
        let t = k / STEPS;
        let a = a0 + Math.PI * 0.25 + Math.PI * 0.25 * t;
        let tr = Math.pow(t, 1/POW) * rr + (1 - Math.pow(t, 1/POW)) * r;
        points.push({ x: cx + Math.cos(a) * tr, y: cy + Math.sin(a) * tr });
      }
      a0 += Math.PI * 0.5;
    }

    let points2 = null;
    if (max2 > 0) {
      points2 = [];
      a0 = 0;
      for (let i=0; i<data2.length; i++) {
        let r = cr * Math.pow((data2[i] / max), 0.5);
        let lr = cr * Math.pow((data2[(i+data2.length-1)%data2.length] / max), 0.5);
        let rr = cr * Math.pow((data2[(i+1)%data2.length] / max), 0.5);
        lr = (lr + r) * 0.5;
        rr = (rr + r) * 0.5;
        for (let k=0; k<STEPS; k++) {
          let t = k / STEPS;
          let a = a0 + Math.PI * 0.25 * t;
          let tr = (1 - Math.pow(t, POW)) * lr + Math.pow(t, POW) * r;
          points2.push({ x: cx + Math.cos(a) * tr, y: cy + Math.sin(a) * tr });
        }
        for (let k=0; k<STEPS; k++) {
          let t = k / STEPS;
          let a = a0 + Math.PI * 0.25 + Math.PI * 0.25 * t;
          let tr = Math.pow(t, 1/POW) * rr + (1 - Math.pow(t, 1/POW)) * r;
          points2.push({ x: cx + Math.cos(a) * tr, y: cy + Math.sin(a) * tr });
        }
        a0 += Math.PI * 0.5;
      }
    }

    if (points2) {

      ctx.fillStyle = 'rgba(88,88,88,0.7)';
      ctx.strokeStyle = 'rgba(132,132,132,0.8)';
      ctx.lineWidth = 2.5;
      ctx.beginPath();
      ctx.moveTo(points2[0].x, points2[0].y)
      for (let i=1; i<points2.length; i++) {
        ctx.lineTo(points2[i].x, points2[i].y);
      }
      ctx.lineTo(points2[0].x, points2[0].y);
      ctx.fill();
      ctx.stroke();

    }

    ctx.fillStyle = 'rgba(199,52,26,0.8)';
    ctx.strokeStyle = 'rgba(255,88,35,0.9)';
    ctx.lineWidth = 2.5;
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y)
    for (let i=1; i<points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.lineTo(points[0].x, points[0].y);
    ctx.fill();
    ctx.stroke();

    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.9)';
    ctx.beginPath();
    ctx.moveTo(cx, cy - cr * 1.1);
    ctx.lineTo(cx, cx);
    ctx.moveTo(cx + cr * 1.1, cy);
    ctx.lineTo(cx, cx);
    ctx.moveTo(cx, cy + cr * 1.1);
    ctx.lineTo(cx, cx);
    ctx.moveTo(cx - cr * 1.1, cy);
    ctx.lineTo(cx, cx);
    ctx.stroke();

    ctx.lineWidth = 1.5;

    ctx.restore();

  }
  
  render() {

    const data = this.props.data;

    return (
      <div className='simp-hl-cont'>
        <div className='shl-canvas-cont' style={{"width": this.props.width + 'px', "height": this.props.height + 'px'}}>
          <canvas ref={this.canvasRef}/>
          <div className='shl-label-walls'>
            <div className='shl-label-top'>Walls</div><br/>
            <div className='shl-label-bot'>{Math.round(this.props.walls_hl * 10) / 10} GJ / year</div>
          </div>
          <div className='shl-label-ceiling'>
            <div className='shl-label-top'>Ceiling</div><br/>
            <div className='shl-label-bot'>{Math.round(this.props.ceiling_hl * 10) / 10} GJ / year</div>
          </div>
          <div className='shl-label-foundation'>
            <div className='shl-label-top'>Foundation</div><br/>
            <div className='shl-label-bot'>{this.props.no_found ? 0 : Math.round(this.props.foundation_hl * 10) / 10} GJ / year</div>
          </div>
          <div className='shl-label-windows'>
            <div className='shl-label-top'>Windows & Doors</div><br/>
            <div className='shl-label-bot'>{Math.round(this.props.windows_hl * 10) / 10} GJ / year</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let building = (state.buildings.curModel && state.buildings.curModel.building) || {};
  return { building };
}
export default withRouter(connect(mapStateToProps)(SimpHLChart));
