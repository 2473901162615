import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RandomKey from './RandomKey';
import Input from './Input';
import '../Common.scss';

class ConfirmButton extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();
    this.state = {
      open: false,
      requireReason: '',
      reasonError: null
    };

    this.onClick = (onConfirm) => {
      if (onConfirm && (!this.props.requireReason || `${(this.state.requireReason||'')}`.trim().length)) {
        this.setState({ reasonError: null });
        this.setState({open: !this.state.open});
        onConfirm(this.state.requireReason);
      }
      else if (onConfirm && this.props.requireReason) {
        this.setState({ reasonError: "Please enter a reason" });
      }
      else if (!onConfirm) {
        this.setState({open: !this.state.open});
      }
    };
  }

  render() {
    const {title, className, onConfirm, msg, msgBottom, icon, requireReason, wrapperClassName} = this.props;

    return (
      <div key={this.key} className={'confirm-button-wrapper ' + (this.state.open ? 'open' : '') + ' ' + (wrapperClassName||'')}>
        {this.state.open && <div className={'confirm-msg ' + (msgBottom ? 'bottom' : '')}>{msg}{requireReason &&
          <Input type="textarea" width="200px" rows={5} value={this.state.requireReason} error={this.state.reasonError} onChange={(val) => {this.setState({requireReason: val, reasonError: false})}}/>
        }</div>}
        <div className={(className || 'en-button-secondary-red') + ' confirm-button'} onClick={!this.state.open ? (() => {this.onClick();}) : undefined}>{icon ? (<img src={icon} />) : (title || 'Confirm')}
          {this.state.open && <div className="confirm-buttons">
            <div className={(className || 'en-button-secondary-red') + ' yes-button'} onClick={() => {this.onClick(onConfirm);}}>Yes</div>
            <div className={(className || 'en-button-secondary-red') + ' no-button'} onClick={() => {this.onClick();}}>No</div>
          </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(ConfirmButton));
