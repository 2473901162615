import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Alert, Button, FormGroup, Label, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import config from '../../../config';
import Widget from '../../../components/Widget';
import { loginUser, receiveToken, doInit } from '../../../actions/auth';
import jwt from "jsonwebtoken";
// import microsoft from '../../../images/microsoft.png';

class ENLogin extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    static isAuthenticated() {
        const token = localStorage.getItem('token');
        if (!config.isBackend && token) return true;
        if (!token) return;
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(token);
        if (!data) return;
        return date < data.exp;
    }

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            selectedOption: '',
        };

        localStorage.setItem('app', 'en');

        this.doLogin = this.doLogin.bind(this);
        this.googleLogin = this.googleLogin.bind(this);
        this.microsoftLogin = this.microsoftLogin.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.signUp = this.signUp.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    changeEmail(event) {
        this.setState({ email: event.target.value });
    }

    changePassword(event) {
        this.setState({ password: event.target.value });
    }

    doLogin(e) {
        e.preventDefault();
        this.props.dispatch(loginUser({ email: this.state.email, password: this.state.password}, 'en'));
    }

    googleLogin() {
        this.props.dispatch(loginUser({ social: "google" }));
    }

    microsoftLogin() {
        this.props.dispatch(loginUser({ social: "microsoft" }));
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        if (token) {
            this.props.dispatch(receiveToken(token));
            this.props.dispatch(doInit());
        }
    }

    signUp() {
        this.props.dispatch(push('/register'));
    }
    onValueChange(event) {
        this.setState({
          selectedOption: event.target.value
        });
      }
    render() {
        document.body.style.background = `#1f2631`;

        return (
            <div className="auth-page">
                <Container>
                    <center>
                        <a href="https://www.climative.ai/"><img alt="Climative" src="images/simptek-green.png" /></a><br />
                        <br />
                        <span style={{ "font-size": "18px", "color": "#cbe8f6" }}>Energy Advisor Application</span>
                    </center>
                    <br />
                    <br />
                    <br />
                    <Widget className="widget-auth mx-auto" title={<h3 className="mt-0">Login</h3>}>

                        <form onSubmit={this.doLogin}>
                            {
                                this.props.errorMessage && (
                                    <Alert className="alert-sm widget-middle-overflow rounded-0" color="danger">
                                        {this.props.errorMessage}
                                    </Alert>
                                )
                            }
                            <FormGroup className="mt">
                                <Label for="email">Email</Label>
                                <InputGroup className="input-group-no-border">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="la la-user text-white" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input id="email" className="input-transparent pl-3" value={this.state.email} onChange={this.changeEmail} type="email"
                                        required name="email" placeholder="Email" />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <InputGroup className="input-group-no-border">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="la la-lock text-white" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input id="password" className="input-transparent pl-3" value={this.state.password}
                                        onChange={this.changePassword} type="password"
                                        required name="password" placeholder="Password" />
                                </InputGroup>
                            </FormGroup>
                            {/*<FormGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            name="app"
                                            type="radio"
                                            value="ra"
                                            checked={this.state.selectedOption === "ra"}
                                            onChange={this.onValueChange}
                                        />
                                Remote Auditor
                            </Label>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            name="app"
                                            type="radio"
                                            value="en"
                                            checked={this.state.selectedOption === "en"}
                                            onChange={this.onValueChange}
                                        />
                                Energy Advisor
                            </Label>
                                </InputGroup>
                            </FormGroup>*/}
                            <div className="bg-widget auth-widget-footer">
                                <br />
                                <Button type="submit" color="danger" className="auth-btn" style={{ "background-color": "#47bcf6", "font-weight": "bold", "border": "1px solid rgba(0, 0, 0, 0.25)", "padding": "12px" }}
                                    size="sm">
                                    {this.props.isFetching ? 'Loading...' : 'Login'}
                                </Button>
                                <Link className="d-block text-center mt-2 fs-sm" to="forgot">Forgot password?</Link>
                                <p className="widget-auth-info mt-4">
                                    Don't have an account? Sign up now!
                                </p>
                                <Link className="d-block text-center mb-4" to="register">Create an Account</Link>
                                <div className="social-buttons">
                                    {/*<Button onClick={this.googleLogin} color="primary" className="social-button">
                                        <i className="social-icon social-google"/>
                                        <p className="social-text">GOOGLE</p>
                                    </Button>*/}
                                    {/* <Button onClick={this.microsoftLogin} color="success" className="social-button">
                                        <i className="social-icon social-microsoft"
                                           style={{backgroundImage: `url(${microsoft})`}}/>
                                        <p className="social-text">MICROSOFT</p>
                                    </Button> */}
                                </div>
                                <br />
                            </div>
                        </form>
                    </Widget>
                </Container>
                <footer className="auth-footer">
                    Energy Advisor Application by <a href="https://www.climative.ai/" >Climative</a>
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(ENLogin));
