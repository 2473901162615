import React, { useState, useRef, useEffect } from 'react';
import ReactFlow, {
  removeElements,
  addEdge,
  updateEdge,
  MiniMap,
  Controls,
  Background,
  useZoomPanHelper
} from 'react-flow-renderer';
import { Dropdown, Input } from "../../components/Common";
import { withRouter } from 'react-router';
import { JSONTree } from 'react-json-tree'
import axios from 'axios';
import moment from 'moment';

import LZUTF8 from 'lzutf8';

import JSNode from './NodeTypes/JSNode';
import FileNode from './NodeTypes/FileNode';
import EnumNode from './NodeTypes/EnumNode';
import HTTPAPINode from './NodeTypes/HTTPAPINode';
import TableOutputNode from './NodeTypes/TableOutputNode';
import MapOutputNode from './NodeTypes/MapOutputNode';
import JSONOutputNode from './NodeTypes/JSONOutputNode';
import SortNode from './NodeTypes/SortNode';
import ArraySelectNode from './NodeTypes/ArraySelectNode';
import JoinNode from './NodeTypes/JoinNode';
import GradientNode from './NodeTypes/GradientNode';
import RemapperNode from './NodeTypes/RemapperNode';
import SubEnterNode from './NodeTypes/SubEnterNode';
import SubReturnNode from './NodeTypes/SubReturnNode';
import SubCallNode from './NodeTypes/SubCallNode';
import APIProcessNode from './NodeTypes/APIProcessNode';
import PrebuiltAPIProcessNode from './NodeTypes/PrebuiltAPIProcessNode';
import TableRemapperNode from './NodeTypes/TableRemapperNode';
import GeoJSONNode from './NodeTypes/GeoJSONNode';
import AddressToolsNode from './NodeTypes/AddressToolsNode';
import InsightsMapNode from './NodeTypes/InsightsMapNode';
import SAEditorNode from './NodeTypes/SAEditorNode';

import NodeDefaults from './NodeTypes/DefaultValues';

import Map from './Controls/Map';
import ViewJSON from './Controls/ViewJSON';
import ExportCSVForm from './Controls/ExportCSVForm';
import DataTable from './Controls/DataTable';
import exportFunctions from './ExportFunctions';

import { spawn, Thread, Worker } from "threads";

import config from '../../config';

import './Flow.scss';

// create ids
const getId = () => `stnode_${1e9 + Math.floor(Math.random()*1e9)}`;

// For displaying dragable buttons on control bar
const controls = [
  { title: 'File Input', nodeType: 'fileInput' },
  //{ title: 'HTTP Input', nodeType: 'httpInput' },
  { title: 'JavaScript', nodeType: 'javascript' },
  { title: 'Sort Rows', nodeType: 'sort' },
  { title: 'Array Select', nodeType: 'arraySelect' },
  { title: 'Left Join', nodeType: 'joinNode' },
  { title: 'Gradient', nodeType: 'gradientNode' },
  { title: 'Remapper', nodeType: 'remapperNode' },
  { title: 'Enum Fixer', nodeType: 'enumNode' },
  { title: 'Call Sub-Flow', nodeType: 'subCall' },
  { title: 'API Process', nodeType: 'apiProcess' },
  { title: 'Table Remapper', nodeType: 'tableRemapperNode' },
  { title: 'Pre-Built API Process', nodeType: 'pbApiProcess' },
  { title: 'Add Buildings to Insights Map', nodeType: 'insightsMap' },
  { title: 'Address Tools', nodeType: 'addressTools' },
  { title: 'GEOJSON', nodeType: 'geoJson' },
  { title: 'JSON Output', nodeType: 'jsonOutput' },
  { title: 'Service Area Editor', nodeType: 'saEditor' },
  //{ title: 'To Table', nodeType: 'tableOutput' },
  { title: 'GEOJSON to MapBox', nodeType: 'mapOutput' }
];

// Sub-program Controls
const controlsSp = [
  { title: 'Sub-Flow Entry', nodeType: 'subEnter' },
  { title: 'Sub-Flow Return', nodeType: 'subReturn' },
  { title: 'File Input', nodeType: 'fileInput' },
  //{ title: 'HTTP Input Node', nodeType: 'httpInput' },
  { title: 'JavaScript', nodeType: 'javascript' },
  { title: 'Sort Rows', nodeType: 'sort' },
  { title: 'Array Select', nodeType: 'arraySelect' },
  { title: 'Left Join', nodeType: 'joinNode' },
  { title: 'Gradient', nodeType: 'gradientNode' },
  { title: 'Remapper', nodeType: 'remapperNode' },
  { title: 'Enum Fixer', nodeType: 'enumNode' },
  { title: 'Call Sub-Flow', nodeType: 'subCall' },
  { title: 'Table Remapper', nodeType: 'tableRemapperNode' },
  { title: 'API Process', nodeType: 'apiProcess' },
  { title: 'Pre-Built API Process', nodeType: 'pbApiProcess' },
  { title: 'Add Buildings to Insights Map', nodeType: 'insightsMap' },
  { title: 'GEOJSON', nodeType: 'geoJson' },
  { title: 'Address Tools', nodeType: 'addressTools' },
  { title: 'JSON Output', nodeType: 'jsonOutput' },
  { title: 'GEOJSON to MapBox', nodeType: 'mapOutput' }
];

const controlsDisplayInfo = {
  'fileInput': {
    'type': 'input',
    'title': 'File Input',
    'description': 'Load CSV, XLSX, JSON, GEOJSON files from file system.',
    'input': 'File',
    'output': 'Table, JSON, GEOJSON'
  },
  'javascript': {
    'type': 'transform',
    'title': 'JavaScript',
    'description': 'Process a table\'s rows with a custom JavaScript filter function.',
    'input': 'Table',
    'output': 'Table'
  },
  'sort': {
    'type': 'transform',
    'title': 'Sort',
    'description': 'Sort a table by a column.',
    'input': 'Table',
    'output': 'Table'
  },
  'arraySelect': {
    'type': 'input',
    'title': 'Array Select',
    'description': 'Output table from array contained within the input dataset.',
    'input': 'Table, JSON',
    'output': 'Table'
  },
  'joinNode': {
    'type': 'transform',
    'title': 'Left Join',
    'description': 'Preform a left join on two input tables.',
    'input': 'Table',
    'output': 'Table'
  },
  'gradientNode': {
    'type': 'transform',
    'title': 'Color Gradient',
    'description': 'Populate a column with a color field based on a gradient.',
    'input': 'Table',
    'output': 'Table'
  },
  'remapperNode': {
    'type': 'transform',
    'title': 'Value Remapper',
    'description': 'Normalize, scale, and then offset a column.',
    'input': 'Table',
    'output': 'Table'
  },
  'enumNode': {
    'type': 'transform',
    'title': 'ENUM Remapper',
    'description': 'Remap the values represented in an ENUM column into a new set of values.',
    'input': 'Table',
    'output': 'Table'
  },
  'subCall': {
    'type': 'transform',
    'title': 'Call Function',
    'description': 'Call a pre-built function for the Function Library',
    'input': 'Table, JSON, GEOJSON',
    'output': 'Table, JSON, GEOJSON'
  },
  'tableRemapperNode': {
    'type': 'output',
    'title': 'Table Output/Remapper',
    'description': 'Display a Table and/or hide columns and change column names.',
    'input': 'Table',
    'output': 'Table'
  },
  'addressTools': {
    'type': 'transform',
    'title': 'Address Tools',
    'description': 'Combine address components or extract FSAs from postal codes.',
    'input': 'Table',
    'output': 'Table'
  },
  'apiProcess': {
    'type': 'insights',
    'title': 'Call API',
    'description': 'Call an API endpoint.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'Table, JSON, GEOJSON'
  },
  'saEditor': {
    'type': 'transform',
    'title': 'Service Area Editor',
    'description': 'UI for Endpoints to batch editor service areas',
    'input': '-',
    'output': '-'
  },
  'pbApiProcess': {
    'type': 'insights',
    'title': 'Call Pre-Built Process',
    'description': 'Geocode, get a Building Polygon, or generate Insights.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'Table, JSON, GEOJSON'
  },
  'insightsMap': {
    'type': 'insights',
    'title': 'Add Insights to Map',
    'description': 'Queue insights to be generated in background and added to the insights map.',
    'input': 'Table',
    'output': 'Insights Map'
  },
  'geoJson': {
    'type': 'transform',
    'title': 'Generate GEOJSON',
    'description': 'Generate GEOJSON (Points or Polygons) or merge two GEOJSON objects.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'GEOJSON'
  },
  'jsonOutput': {
    'type': 'output',
    'title': 'JSON Output/Tree View',
    'description': 'Generate JSON and/or display as JSON Tree.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'JSON'
  },
  'mapOutput': {
    'type': 'output',
    'title': 'Map Display',
    'description': 'Display GEOJSON on a MapBox GL map.',
    'input': 'GEOJSON',
    'output': '-'
  },
  'subEnter': {
    'type': 'input',
    'title': 'Function Entry Point',
    'description': 'Must be the first node of every Function, also describes input format.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'Table, JSON, GEOJSON'
  },
  'subReturn': {
    'type': 'output',
    'title': 'Function Return',
    'description': 'Must be the last node of every Function, input stream is piped to output of Function Call node.',
    'input': 'Table, JSON, GEOJSON',
    'output': 'Table, JSON, GEOJSON'
  }
};

// Maps node type strings to React Node Components
const nodeTypes = {
  fileInput: FileNode,
  httpInput: HTTPAPINode,
  javascript: JSNode,
  sort: SortNode,
  arraySelect: ArraySelectNode,
  joinNode: JoinNode,
  gradientNode: GradientNode,
  remapperNode: RemapperNode,
  enumNode: EnumNode,
  tableOutput: TableOutputNode,
  mapOutput: MapOutputNode,
  jsonOutput: JSONOutputNode,
  subEnter: SubEnterNode,
  subReturn: SubReturnNode,
  subCall: SubCallNode,
  apiProcess: APIProcessNode,
  tableRemapperNode: TableRemapperNode,
  pbApiProcess: PrebuiltAPIProcessNode,
  geoJson: GeoJSONNode,
  addressTools: AddressToolsNode,
  insightsMap: InsightsMapNode,
  saEditor: SAEditorNode
};

const GE360Flow = (props) => {

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const [uiBlocked, setUiBlocked] = useState(false);
  const [etlType, setETLType] = useState('flows');
  const [flowInfo, setFlowInfo] = useState({
    title: (etlType === 'sub-flows') ? "Untitled Function" : "Untitled Flow",
    id: null
  });
  const [flowList, setFlowList] = useState([]);
  const [viewData, setViewData] = useState(0);
  const [percentComplete, setPercentComplete] = useState(-1);
  const [modalType, setModalType] = useState('');
  const [modalDragging, setModalDragging] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [outputDrag, setOutputDrag] = useState(false);
  const [outputHeight, setOutputHeight] = useState(253);
  const [exportFileName, setExportFilename] = useState('');

  const [viewDataList, setViewDataList] = useState([]);

  const reactFlowWrapper = useRef(null);

  const updateViewData = (elements) => {
    const ret = [];
    for (let E of elements) {
      if (E && E.data && E.data._viewData) {
        ret.push(E.data._viewData);
      }
    }
    setViewDataList(ret);
    if (viewData) {
      let vdset = null;
      for (let vd of ret) {
        if (vd.id === viewData.id) {
          vdset = vd;
          break;
        }
      }
      setViewData(vdset ? vdset : 0);
    }
    else {
      setViewData(ret.length ? ret[0] : 0);
    }
  };

  const countType = (type) => {
    let count = 0;
    for (let e of elements) {
      if (e.type == type) {
        count += 1;
      }
    }
    return count
  };

  const loadFlows = (loadAnyway) => {
    if (flowList.length) {
      setFlowList([]);
      if (!loadAnyway) {
        return;
      }
    }
    axios.get(((etlType === 'sub-flows') ? '/flows/sub-programs/' : '/flows/')).then((res) => {
      res.data.sort((a, b) => {
          return -a.updatedAt.localeCompare(b.updatedAt);
      })
      setFlowList(res.data);
    });
  };

  const getArr = (str) => {
    let nIndex = 0,
      nLen = str.length,
      arr = [];
    for (; nIndex < nLen; nIndex++) {
      arr.push(str.charCodeAt(nIndex));
    }
    return arr;
  }

  const loadFlow = (id) => {
    setUiBlocked(true);
    axios.get(((etlType === 'sub-flows') ? '/flows/sub-programs/' : '/flows/') + id).then(async (res) => {
      setFlowInfo({title: res.data.name, id});

      let decompressedStr = LZUTF8.decompress(res.data.flow_json, {inputEncoding:"Base64"});

      const flow = JSON.parse(decompressedStr);

      for (let i=0; i<flow.elements.length; i++) {
        const e = flow.elements[i];
        if (e.type === 'apiOutput') {
          e.type = 'apiProcess';
          let value = JSON.parse(JSON.stringify(NodeDefaults[e.type] || {}));
          if (value && value.viewData === -1) {
            value.viewData = new Date().getTime();
          }
          e.data.value = value;
        }
        if (e.type === 'tableOutput') {
          e.type = 'tableRemapperNode';
        }
        if (e.type !== 'default' && !nodeTypes[e.type]) {
          flow.elements.splice(i, 1);
          i--;
          continue;
        }
      }

      for (let e of flow.elements) {
        if (e && e.data && e.data.value && e.data.value._separate_save_json) {
          let resp = await axios.post(
            '/flows/api-proxy',
            {
              method: 'GET',
              uri: e.data.value._separate_save_json.link
            }
          );
          e.data.value._separate_save_json = resp.data;
        }
        if (e && e.data && e.data._viewData && e.data._viewData.link) {
          let resp = await axios.post(
            '/flows/api-proxy',
            {
              method: 'GET',
              uri: e.data._viewData.link
            }
          );
          e.data._viewData = resp.data;
        }
      }

      setElements(flow.elements || []);
      setElements((els) =>
        els.map((e) => {
          return {
            ...e,
            data: {
              ...e.data,
              onChange: mkOnChange(e.type, e.id)
            },
          };
        })
      );
      calcData();
      updateViewData(flow.elements || []);
      setUiBlocked(false);
    });
  };

  const saveFlow = async (asNew) => {
    setUiBlocked(true);
    setFlowList([]);

    const flow = reactFlowInstance.toObject();
    let odata = [];
    for (let e of flow.elements) {
      if (e && e.data && e.data.value) {
        e.data.value._colInfo = undefined;
      }
      if (e && e.data && e.data.value && e.data.value._separate_save_json) {
        odata.push(e.data.value._separate_save_json);
        let file = new File([new Blob([JSON.stringify(e.data.value._separate_save_json)], {type: 'text/plain'})], e.data.value.file_name || "data.json", {
          type: "text/plain",
        });
        const formData = new FormData();
        formData.append("file", file);
        let res = await axios.post('/flows/upload-files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        e.data.value._separate_save_json = res.data[0];
      }
      if (e && e.data && e.data._viewData) {
        odata.push(e.data._viewData);
        let file = new File([new Blob([JSON.stringify(e.data._viewData)], {type: 'text/plain'})], "view-data" + e.id + ".json", {
          type: "text/plain",
        });
        const formData = new FormData();
        formData.append("file", file);
        let res = await axios.post('/flows/upload-files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        e.data._viewData = res.data[0];
      }
      if (e && e.data && e.data.value) {
        for (let key in e.data.value) {
          let T = e.data.value[key];
          if ((typeof T === 'object') && T && T.rows && T.header) {
            T.rows = null;
          }
        }
      }
      if (e && e.data) {
        e.data.lastValueStr = null;
        for (let key in e.data) {
          let T = e.data[key];
          if ((typeof T === 'object') && T && T.rows && T.header) {
            T.rows = null;
          }
        }
      }
    }

    let finish = () => {
      let i = 0;
      for (let e of flow.elements) {
        if (e && e.data && e.data.value && e.data.value._separate_save_json) {
          e.data.value._separate_save_json = odata[i];
          i += 1;
        }
        if (e && e.data && e.data._viewData) {
          e.data._viewData = odata[i];
          i += 1;
        }
      }
    };

    window.LZUTF8 = LZUTF8;
    let compressedStr = LZUTF8.compress(JSON.stringify(flow), {outputEncoding:"Base64"});
    const data = {
      name: flowInfo.title,
      flow_json: compressedStr
    };

    if (!flowInfo.id || asNew) {
      axios.post(((etlType === 'sub-flows') ? '/flows/sub-programs/' : '/flows/'), data).then((res) => {
        finish();
        if (res.data && res.data.success) {
          setFlowInfo({...flowInfo, id: res.data.id});
        }
        setElements(flow.elements);
        setTimeout(() => {
          calcData();
          setUiBlocked(false);
        }, 10);
      });
    }
    else {
      axios.put(((etlType === 'sub-flows') ? '/flows/sub-programs/' : '/flows/') + flowInfo.id, data).then((res) => {
        finish();
        if (res.data && res.data.success) {

        }
        setElements(flow.elements);
        setTimeout(() => {
          calcData();
          setUiBlocked(false);
        }, 10);
      });
    }
  };

  const cancelRun = () => {
    window._CANCEL_RUN = true;
  }

  // Tail recursion to flow data and execute node flow processors (inputs are satisfied first)
  let runTimeoutId = -1;
  const runFlow = () => {
    if (runTimeoutId >= 0) {
      clearTimeout(runTimeoutId);
      runTimeoutId = -1;
    }
    runTimeoutId = setTimeout(()=>{
      (async () => {
        if (window._CANCEL_RUN) {
          window._CANCEL_RUN = false;
          return;
        }
        runTimeoutId = -1;
        setUiBlocked(true);
        const processor = await spawn(new Worker("./NodeTypes/ProcessorWorker"));
        const flow = reactFlowInstance.toObject();
        let onChanges = [];
        for (let i=0; i<flow.elements.length; i++) {
          onChanges[i] = null;
          if (flow.elements[i].data) {
            onChanges[i] = flow.elements[i].data.onChange;
            flow.elements[i].data.onChange = null;
            flow.elements[i].data.changed = true;
          }
        }
        let token = localStorage.getItem('token');
        setPercentComplete(0);
        processor(flow, true, token, {}, config.baseURLApi).subscribe(async (ret) => {
          if (window._CANCEL_RUN) {
            window._CANCEL_RUN = false;
            for (let i=0; i<flow.elements.length; i++) {
              if (flow.elements[i].data) {
                flow.elements[i].data.onChange = onChanges[i];
              }
            }
            setElements((els) => flow.elements);
            setTimeout(() => {
              setUiBlocked(false);
            }, 50);
            setPercentComplete(-1);
            await Thread.terminate(processor);
            return;
          }
          if (ret.complete) {
            await Thread.terminate(processor);
            for (let i=0; i<ret.flow.elements.length; i++) {
              if (ret.flow.elements[i].data) {
                ret.flow.elements[i].data.onChange = onChanges[i];
              }
            }
            setElements((els) => ret.flow.elements);
            updateViewData(ret.flow.elements);
            setTimeout(() => {
              setUiBlocked(false);
            }, 50);
            setPercentComplete(-1);
          }
          else {
            setPercentComplete(ret.status || 0);
          }
        });
        for (let i=0; i<flow.elements.length; i++) {
          if (flow.elements[i].data) {
            flow.elements[i].data.onChange = onChanges[i];
          }
        }             
      })();
    }, 50);
  };

  let calcTimeoutId = -1;
  const calcData = () => {
    if (calcTimeoutId >= 0) {
      clearTimeout(calcTimeoutId);
      calcTimeoutId = -1;
    }
    calcTimeoutId = setTimeout(async () => {
      setUiBlocked(true);
      calcTimeoutId = -1;
      const processor = await spawn(new Worker("./NodeTypes/ColumnCalcWorker"));
      const flow = reactFlowInstance.toObject();
      let onChanges = [];
      for (let i=0; i<flow.elements.length; i++) {
        onChanges[i] = null;
        if (flow.elements[i].data) {
          onChanges[i] = flow.elements[i].data.onChange;
          flow.elements[i].data.onChange = null;
        }
      }
      let ret = await processor(flow);
      await Thread.terminate(processor);
      for (let i=0; i<ret.elements.length; i++) {
        if (ret.elements[i].data) {
          ret.elements[i].data.onChange = onChanges[i];
        }
      }
      setElements((els) => ret.elements);
      setUiBlocked(false);
    }, 1);
  };


  //const runFlow = () => { calcData(true); };

  // Creates Node data onChange handler
  const mkOnChange = (type, id) => {
    return (value) => {
      if (typeof value === 'object' && value.deleteMe) {
        const flow = reactFlowInstance.toObject();
        let remove = [];
        for (let e of flow.elements) {
          if (e.id === id) {
            remove.push(e);
          }
        }
        onElementsRemove(remove, true);
        return;
      }
      setElements((els) =>
        els.map((e) => {
          if (e.id !== id) {
            return e;
          }
          return {
            ...e,
            data: {
              ...e.data,
              value
            },
          };
        })
      );
      calcData();
    }
  };

  const edgeStyle = {
    stroke: '#44D9E6', strokeWidth: 4
  };

  const onConnect = (params) => {
    params.style = edgeStyle;
    setElements((els) => addEdge(params, els));
    calcData();
  };

  const onEdgeUpdate = (oldEdge, newConnection) => {
    setElements((els) => updateEdge(oldEdge, newConnection, els));
    calcData();
  };

  const onElementsRemove = (elementsToRemove, onlyNode) => {
    if (!onlyNode && elementsToRemove[0].type !== 'default') {
      return;
    }
    setElements((els) => removeElements(elementsToRemove, els));
    calcData();
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    setModalType(null); setModalDragging(false);
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    let tid = getId();
    let value = JSON.parse(JSON.stringify(NodeDefaults[data.nodeType] || {}));
    if (value && value.viewData === -1) {
      value.viewData = new Date().getTime();
    }
    const newNode = {
      id: tid,
      type: data.nodeType,
      position,
      data: { label: `${data.title}`, onChange: mkOnChange(data.nodeType, tid), value },
    };

    setElements((es) => es.concat(newNode));
  };

  const onDragStart = (event, data) => {
    event.dataTransfer.setData('application/reactflow', data);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const startOutputDrag = (e) => {
    e = e || window.event;
    if (e.buttons === 1) {
      setOutputDrag(true);
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  useEffect(() => {
    function mouseUp(e) {
      e = e || window.event;
      if (outputDrag) {
        setOutputDrag(false);
        e.stopPropagation();
        e.preventDefault();
      }
      return false;
    }
    let lastOutputHeight = outputHeight;
    let lastWindowSize = [window.innerWidth, window.innerHeight];
    const intervalId = window.setInterval(() => {
      let size = [window.innerWidth, window.innerHeight];
      if (size[0] !== lastWindowSize[0] || size[1] !== lastWindowSize[1]) {
        setOutputHeight(outputHeight + Math.random() * 0.000000001);
        lastWindowSize = size;
      }
    }, Math.ceil(1000/30));
    function mouseMove(e) {
      e = e || window.event;
      let y = document.getElementById('flow-output-resize-handle');
      if (y && outputDrag) {
        y = y.getBoundingClientRect().top;
        let delta = y - e.pageY;
        lastOutputHeight = Math.min(Math.max(lastOutputHeight + delta, 150), window.innerHeight - 300);
        setOutputHeight(lastOutputHeight);
      }
      if (outputDrag) {
        e.stopPropagation();
        e.preventDefault();  
      }
      if (!(e.buttons & 1)) {
        setOutputDrag(false);
      }
      return false;
    }
    document.body.addEventListener('mousemove', mouseMove);
    document.body.addEventListener('mouseup', mouseUp);
    return function cleanup() {
      document.body.removeEventListener('mousemove', mouseMove);
      document.body.removeEventListener('mouseup', mouseUp);
      window.clearInterval(intervalId);
    };
  });

  let _controlsSp = etlType === 'sub-flows' ? [...controlsSp] : [...controls];

  if ((etlType === 'sub-flows')) {
    let countStart = countType('subEnter');
    let countEnd = countType('subReturn');
    for (let i=0; i<_controlsSp.length; i++) {
      const C = _controlsSp[i];
      if (C.nodeType == 'subEnter' && countStart > 0) {
        _controlsSp.splice(i, 1); i--; continue;
      }
      else if (C.nodeType == 'subReturn' && countEnd > 0) {
        _controlsSp.splice(i, 1); i--; continue;
      }
      else if (C.nodeType != 'subEnter' && C.nodeType != 'subReturn' && (countStart < 1 || countEnd < 1)) {
        _controlsSp.splice(i, 1); i--; continue;
      }
    }
  }

  for (let i=0; i<_controlsSp.length; i++) {
    const C = _controlsSp[i];
    _controlsSp[i] = controlsDisplayInfo[_controlsSp[i].nodeType];
    _controlsSp[i].C = C;
  }

  const inputControls = _controlsSp.filter((C)=>(C.type === 'input' && (!searchText.trim() || C.title.toLowerCase().includes(searchText.toLowerCase()) || C.description.toLowerCase().includes(searchText.toLowerCase()))));
  const transformControls = _controlsSp.filter((C)=>(C.type === 'transform' && (!searchText.trim() || C.title.toLowerCase().includes(searchText.toLowerCase()) || C.description.toLowerCase().includes(searchText.toLowerCase()))));
  const insightsControls = _controlsSp.filter((C)=>(C.type === 'insights' && (!searchText.trim() || C.title.toLowerCase().includes(searchText.toLowerCase()) || C.description.toLowerCase().includes(searchText.toLowerCase()))));
  const outputControls = _controlsSp.filter((C)=>(C.type === 'output' && (!searchText.trim() || C.title.toLowerCase().includes(searchText.toLowerCase()) || C.description.toLowerCase().includes(searchText.toLowerCase()))));
  
  return (
    <div className={'flow-wrapper ' + (viewData ? 'view-data' : '') + (uiBlocked ? ' loading' : '')}>
      {modalType && <div className={'flow-modal-cont ' + (modalDragging ? 'fm-dragging' : '')} onClick={() => setModalType(null)}>
        {modalType === 'add-block' && <div className={'flow-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
          <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
          <div className='fm-pane-left'>
            <div className='fm-pane-title'>Block Library</div>
            <Input type="text" label='Search' value={searchText} onChange={(val) => {setSearchText(val)}} className='flow-block-search' /><br/>
          </div>
          <div className='fm-pane-right'>
            {inputControls.length > 0 && <div className='fm-control-type-title'>INPUT</div>}
            {inputControls.map((C, idx) => (
              <div className="fm-draggable-control" key={"control-input-" + C.title} onDragStart={(event) => {setModalDragging(true); onDragStart(event, JSON.stringify(C.C))}} onDragEnd={() => {setModalType(null); setModalDragging(false);}} draggable>
                <div className="fm-control-title">{C.title}</div>
                <div className="fm-control-description">{C.description}</div>
                <div className="fm-control-inputs">Input: {C.input}</div>
                <div className="fm-control-outputs">Output: {C.output}</div>
              </div>
            ))}
            {transformControls.length > 0 && <div className='fm-control-type-title'>TRANSFORM</div>}
            {transformControls.map((C, idx) => (
              <div className="fm-draggable-control" key={"control-transform-" + C.title} onDragStart={(event) => {setModalDragging(true); onDragStart(event, JSON.stringify(C.C))}} onDragEnd={() => {setModalType(null); setModalDragging(false);}}  draggable >
                <div className="fm-control-title">{C.title}</div>
                <div className="fm-control-description">{C.description}</div>
                <div className="fm-control-inputs">Input: {C.input}</div>
                <div className="fm-control-outputs">Output: {C.output}</div>
              </div>
            ))}
            {insightsControls.length > 0 && <div className='fm-control-type-title'>INSIGHTS</div>}
            {insightsControls.map((C, idx) => (
              <div className="fm-draggable-control" key={"control-insights-" + C.title} onDragStart={(event) => {setModalDragging(true); onDragStart(event, JSON.stringify(C.C))}} onDragEnd={() => {setModalType(null); setModalDragging(false);}}  draggable >
                <div className="fm-control-title">{C.title}</div>
                <div className="fm-control-description">{C.description}</div>
                <div className="fm-control-inputs">Input: {C.input}</div>
                <div className="fm-control-outputs">Output: {C.output}</div>
              </div>
            ))}
            {outputControls.length > 0 && <div className='fm-control-type-title'>VISUALIZATION</div>}
            {outputControls.map((C, idx) => (
              <div className="fm-draggable-control" key={"control-output-" + C.title} onDragStart={(event) => {setModalDragging(true); onDragStart(event, JSON.stringify(C.C))}} onDragEnd={() => {setModalType(null); setModalDragging(false);}}  draggable >
                <div className="fm-control-title">{C.title}</div>
                <div className="fm-control-description">{C.description}</div>
                <div className="fm-control-inputs">Input: {C.input}</div>
                <div className="fm-control-outputs">Output: {C.output}</div>
              </div>
            ))}
          </div>
        </div>}
        {modalType === 'load-flow' && <div className={'flow-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
          <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
          <div className='fm-title-large'>{etlType === 'flows' ? 'Marketing Insights Flowcharts' : 'Function Library'}</div>
          <div className='fm-title-2'>Saved {etlType === 'flows' ? 'Projects' : 'Functions'}</div>
          <div className='fm-function-library'>
            {flowList.map((F, idx) => (<div className='fm-flow' onClick={() => {setFlowList([]); loadFlow(F.id); setModalType(null);}} key={'load-flow-' + F.id}>
              <img src='/images/flow-file.svg'/>
              <div className='fm-flow-right'>
                <div className='fm-flow-title'>{F.name || 'Untitled Flow'}</div>
                <div className='fm-flow-meta'>Created: {moment(F.createdAt).format('MMM. DD, YYYY')} &nbsp;| Modified: {moment(F.updatedAt).format('MMM. DD, YYYY')}</div>
              </div>
            </div>))}
          </div>
        </div>}
        {modalType === 'export' && <div className={'flow-modal export-modal'} onClick={(e) => {(e||window.event).stopPropagation(); (e||window.event).preventDefault(); return false;}}>
          <img src='/images/x-icon.svg' className='fm-close-button' onClick={() => setModalType(null)}/>
          <div className='fm-title-large'>Export {{'map': 'Map', 'table': 'Table', 'json': 'JSON'}[viewData.type]} Display to filesystem.</div>
          <div className='fm-title-2'>Filename ({{'map': '.json', 'table': '.csv', 'json': '.json'}[viewData.type]} assumed)</div>
          <br/>
          <Input type='text' defaultValue={exportFileName} onChange={(val) => setExportFilename(val)} />
          <br/>
          <div className='flow-button' onClick={() => {exportFunctions[viewData.type](exportFileName, viewData); setModalType(null);}}><img src='/images/save-flow-blue.svg'/> Save File</div>
        </div>}
      </div>}
      <div className={'flow-switcher-wrapper'}>
        <Dropdown
          options={[{title: 'ETL Flows', key: 'flows'}, {title: 'ETL Functions', key: 'sub-flows'}]}
          defaultValue={etlType === 'flows' ? {title: 'ETL Flows', key: 'flows'} : {title: 'ETL Functions', key: 'sub-flows'}}
          etlType={true}
          onChange={(val) => {
            if (etlType !== val.key) {
              setElements((es) => []);
              setFlowInfo({
                title: (val.key === 'sub-flows') ? "Untitled Function" : "Untitled Flow",
                id: null
              });
              setViewData(null);
            }
            setETLType(val.key)
          }}
        />
      </div>
      <div className={'flow-header-right'}>
        <div className='flow-pname-label'>Project Name: </div>
        <Input type="text" value={flowInfo.title} onChange={(val) => {setFlowInfo({...flowInfo, title: val})}} className='flow-title' />
        {percentComplete < 0 && <div className='flow-button flow-run-button' onClick={() => runFlow()}><img src='/images/run-flow.png' /> Run Flow</div>}
        {percentComplete >= 0 && (<div className='flow-button flow-run-button cancel-flow-button' onClick={() => cancelRun()}><span className='pc-percent'>{Math.round(percentComplete)}% - Cancel</span><div className='pc-bar' style={{'width': percentComplete + '%'}}></div></div>)}
        <div className='flow-button save-project' onClick={() => saveFlow()}><img src='/images/save-flow-blue.svg'/> Save {etlType === 'flows' ? 'Project' : 'Function'}</div>
        <div className='flow-button save-project' onClick={() => saveFlow(true)}><img src='/images/flow-duplicate.svg'/> Save As New {etlType === 'flows' ? 'Project' : 'Function'}</div>
        <div className='flow-button open-saved-project' onClick={() => { loadFlows(true); setModalType('load-flow'); }}><img src='/images/open-flow.svg' /> Open Saved {etlType === 'flows' ? 'Project' : 'Function'}</div> 
        <div className='flow-button new-project' onClick={() => {
          setElements((es) => []);
          setFlowInfo({
            title: (etlType === 'flows') ? "Untitled Flow" : "Untitled Function",
            id: null
          });
          setViewData(null);
        }}><img src='/images/add-block-blue.svg' /> New {etlType === 'flows' ? 'Project' : 'Function'}</div> 
      </div>
      <div ref={reactFlowWrapper} className='flow-grid-wrapper' style={{height: (window.innerHeight - outputHeight - 200) + 'px'}}>
        <div className='flow-button-og add-block' onClick={() => {setModalDragging(false); setModalType('add-block');}}><img src='/images/add-block.svg' /> Add Block</div>
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          onElementsRemove={onElementsRemove}
          onLoad={onLoad}
          snapToGrid={true}
          snapGrid={[15, 15]}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onLoad={onLoad}
          nodeTypes={nodeTypes}
          deleteKeyCode={46}
        >
          <MiniMap
            nodeStrokeColor={(n) => {
              return '#44D9E6';
            }}

            nodeColor={(n) => {
              return 'rgba(0,0,0,0.2)';
            }}

            nodeBorderRadius={2}
          />

          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
      <div className='flow-output-cont' style={{height: outputHeight + 'px'}}>
        <div className='flow-output-resize-handle' onMouseDown={(e) => startOutputDrag(e)} id='flow-output-resize-handle'></div>
        <div className='flow-output-header'>
          <div className='flow-output-title'>OUTPUT</div>
          {viewDataList.map((V, idx) => (<div className={'flow-output-vd-title ' + (viewData && viewData.id === V.id ? 'flow-output-selected' : '')} onClick={() => setViewData(V)} key={'fovdt-' + idx}>{V.name || ({'map': 'Map', 'table': 'Table', 'json': 'JSON'}[V.type] + ' Display')}</div>))}
          {!!viewData && <div className='flow-output-export-button' onClick={() => {setExportFilename('untitled-export'); setModalType('export');}}><img src='/images/flow-export.svg'/> Export Output</div>}
        </div>
        <div className='flow-output-body'>
          {(!!viewData && (viewData.type === 'table')) && <div className='view-data-table'>
            <DataTable data={viewData} />
          </div>}
          {(!!viewData && (viewData.type === 'map')) && <Map data={viewData} />}
          {(!!viewData && (viewData.type === 'json')) && <div className='view-data-json'>
            <JSONTree data={viewData.data} theme={{ extend: {
              base00: 'transparent',
              base01: '#383830',
              base02: '#49483e',
              base03: '#75715e',
              base04: '#a59f85',
              base05: '#f8f8f2',
              base06: '#f5f4f1',
              base07: '#f9f8f5',
              base08: '#f92672',
              base09: '#fd971f',
              base0A: '#f4bf75',
              base0B: '#a6e22e',
              base0C: '#a1efe4',
              base0D: '#66d9ef',
              base0E: '#ae81ff',
              base0F: '#cc6633'
            }}} invertTheme={false} />
          </div>}
        </div>
      </div>
    </div>
  );
}

export default withRouter(GE360Flow);
