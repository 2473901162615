import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Format, InsightTypeBlock, StatLine, BuildingImage, RandomKey } from '../../../components/Common'
import './BuildingCard.scss';

class BuildingCard extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    const fmtGJ = Format.GJ,
          fmtKG = Format.KG,
          fmtPercent = Format.Percent,
          fmtDollars = Format.Dollars,
          fmtDollarsShort = Format.DollarsShort,
          fmtSQFT = Format.SQFT;

    return (
      <div key={this.key} className='building-card'>
        <BuildingImage
          address={data.address + ',' + data.city + ',' + data.iso3166_2_code}
          width={257} height={131}
          onClick={() => this.props.dispatch(push('/app/main/building-details/' + data.buildingId + (this.props.customerId ? ("?cid="+this.props.customerId) : "")))}
        />
        <div className='building-name'>{data.address}</div>
        <StatLine
          title="Energy Use Intensity"
          subtitle={data.building_comparison && data.building_comparison.building_eui ? `${fmtGJ(data.building_comparison.building_eui * 277.78 / 10.764)} kWh/ft², ` : `-- kWh/ft²`}
        >
          {data.building_comparison && data.building_comparison.building_eui && fmtPercent(data.building_comparison.eui_delta_percent)}
        </StatLine>

        <StatLine
          title="GHG Emission Intensity"
          subtitle={data.building_comparison && data.building_comparison.ghg_delta_percent ? `${fmtKG(data.building_comparison.building_ghg)} kgCO2/m², ` : `-- kgCO2/m²`}
        >
          {data.building_comparison && data.building_comparison.ghg_delta_percent && fmtPercent(data.building_comparison.ghg_delta_percent)}
        </StatLine>
        <div className='bc-sep'></div>
        <div>
          <div className="building-offer-count">
            <div>Insights: {data.offersNewCount || 0}&nbsp;&nbsp;|&nbsp;&nbsp;Offers: {data.offersOngoingCount  || 0 }</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(BuildingCard);
