import React, { memo, useRef, useState } from 'react';
import NodeWrapper from './NodeWrapper';
import { Input, Dropdown } from '../../../components/Common';
import ColumnSelect from './ColumnSelect';

export default memo(({ data }) => {

  const [ model, saveModel ] = useState({ ...((data && data.value) || {}) });
  const [ k2, setK2 ] = useState(["k2-" + Math.random(), "k2-" + Math.random(), "k2-" + Math.random()]);

  const processTypeOptions = [
    {
      key: "generate-points",
      title: "Generate Points"
    },
    {
      key: "generate-polygons",
      title: "Generate Polygons"
    },
    {
      key: "merge",
      title: "Merge"
    }
  ];

  return (
    <NodeWrapper
      title='GEOJSON Generation'
      onSave={() => {let newModel = {...model, properties: model.properties.filter((e) => e !== -1)}; data.onChange(newModel); saveModel(newModel)}}
      onEdit={() => {}}
      onCancelEdit={() => saveModel({ ...((data && data.value) || {}) })}
      onChangeName={(name) => saveModel({...model, name})}
      onDelete={() => data.onChange({ deleteMe: true })}
      inputs={1}
      outputs={1}
      model={model}
      width={"450px"}
      data={data}
    >
      <div className='fcs-title'>Method</div>
      <Dropdown
        options={processTypeOptions}
        defaultValue={processTypeOptions.filter((e) => e.key === model.process)[0]}
        onChange={(val) => saveModel({...model, process: val.key})}
        hasArrow={true}
      />
      {model.process === 'generate-points' && <div key={k2[0]}>
        <div className='fcs-io-title'>INPUTS</div>
        <div className='fcs-half'><ColumnSelect title='Longitude' value={model.longitude} onChange={(val) => saveModel({...model, longitude: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Latitude' value={model.latitude} onChange={(val) => saveModel({...model, latitude: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Color' value={model.color} onChange={(val) => saveModel({...model, color: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Radius' value={model.radius} onChange={(val) => saveModel({...model, radius: val})} /></div>
      </div>}
      {model.process === 'generate-polygons' && <div key={k2[1]}>
        <div className='fcs-io-title'>INPUTS</div>
        <div className='fcs-half'><ColumnSelect title='Polygons' value={model.polygons} onChange={(val) => saveModel({...model, polygons: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Color' value={model.color} onChange={(val) => saveModel({...model, color: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Extrude (Storeys)' value={model.extrude_storeys} onChange={(val) => saveModel({...model, extrude_storeys: val})} /></div>
        <div className='fcs-half'><ColumnSelect title='Extrude (Meters)' value={model.extrude} onChange={(val) => saveModel({...model, extrude: val})} /></div>
      </div>}
      {(model.process.indexOf('generate') >= 0) && <div key={k2[2]} onMouseDown={(e) => { e = e || window.event; /*e.preventDefault();*/ e.stopPropagation(); return false; }}>
        <div className='fcs-add-title'>TOOLTIP PROPERTIES<div className='fcs-add-button' onClick={() => saveModel({...model, properties: [...(model.properties || []), '']})}><img src='/images/flow-add-blue.svg'/> Add Property</div></div>
        <div className='fcs-tooltip-properties'>
          {(model.properties||[]).map((pval, idx) => (pval !== -1 && <div className='fcs-tooltip-property' key={"fcstp-" + idx}>
            <ColumnSelect title={'Property ' + (idx+1) + ' Column'} value={pval} onChange={(val) => {model.properties[idx] = val; saveModel({...model});}} />
            <img className='fcs-delete-property' src='/images/flow-node-delete.svg' onClick={() => {model.properties[idx] = -1; saveModel({...model});}} />
          </div>))}
        </div>
      </div>}
      {model.process === 'merge' && <div>
      </div>}
    </NodeWrapper>
  );
  
});