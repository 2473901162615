import React from 'react';
import ReactDOM from 'react-dom';
import {routerMiddleware} from 'connected-react-router';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux'
import ReduxThunk from 'redux-thunk'
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import App from './components/App';
import config from './config';
import createRootReducer from './reducers';
import {doInit} from './actions/auth';
import {createHashHistory} from 'history';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const history = createHashHistory();

export function getHistory() {
    return history;
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), ReduxThunk),
)

export const store = createStore(
    createRootReducer(history),
    enhancer
);

store.dispatch(doInit());

window.MISSING_KEYS = {};

i18next.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'sessionStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',
        
        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
        
        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: { path: '/', sameSite: 'strict' }
    },
    resources: {
        "en": {
            "translation": {
                "Climative Low Carbon Communities": "Climative Low Carbon Communities",
                "Loading Animation": "Loading Animation",
                "Loading": "Loading",
                "Home Energy Report": "Home Energy Report",
                "Climative | Low Carbon Communities": "Climative | Low Carbon Communities",
                "Low Carbon Communities": "Low Carbon Communities",
                "Search": "Search",
                "Enter your home address": "Enter your home address",
                "Intro Model": "Intro Model",
                "People discussing energy charts on a computer": "People discussing energy charts on a computer",
                "Next": "Next",
                "FAQ Modal": "FAQ Modal",
                "Close FAQ": "Close FAQ",
                "Expand FAQ Item": "Expand FAQ Item",
                "Energy Use Icon": "Energy Use Icon",
                "Environment Impact Icon": "Environment Impact Icon",
                "Icon portraying various types of energy": "Icon portraying various types of energy",
                "Charts on a computer screen": "Charts on a computer screen",
                "Grey Home Icon": "Grey Home Icon",
                "Close Result": "Close Result",
                "Emissions": "Emissions",
                "Energy": "Energy",
                "Find your home": "Find your home",
                "hmp-intro-step-no-1": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-skip": "Skip",
                "hmp-how-title": "Frequently Asked Questions",
                "hmp-faq-title-0": "How Was My Home's Energy Use Estimated?",
                "hmp-faq-content-0": "<0>We used <1>publicly-available building info</1> about your home, and <3>compared it against 1000s of similar homes</3> to estimate your home's energy use.</0>",
                "hmp-faq-title-1": "How Was My Home's Emissions Rating Estimated?",
                "hmp-faq-content-1": "<0>Your home's Emissions Rating depends on its <1>estimated Energy Use</1> (see question above) and the <3>fuel types</3> used in your home.</0>",
                "hmp-faq-title-2": "Why is My Energy Use Good but Emissions Rating Bad? (Or Vice-Versa)",
                "hmp-faq-content-2": "<0>Your home's Emissions Rating is significantly impacted by the <1>emissions of the fuel types</1> your home uses. If your home uses a relatively small amount of energy but that energy comes from a fuel source with high emissions, your emissions rating can still be bad.</0>",
                "hmp-faq-title-3": "I Think My Home's Rating is Wrong. Can I Fix It?",
                "hmp-faq-content-3": "<0>Yes! The info you're seeing so far is based on what's <1>normal for a home like yours</1>. If your home has been upgraded, or if it uses a different fuel source than we estimated, that can make a big impact on your home's rating.<br/><br/><5>You can increase the accuracy of your home's rating, get energy savings recommendations and more by <1>Signing Up</1>, all for free.</5></0>",
                "hmp-faq-title-4": "Can I Get More Accurate and Detailed Info Easily?",
                "hmp-faq-content-4": "<0>Yes! The info you're seeing so far is based on what's <1>normal for a home like yours</1>. If your home has been upgraded, or if it uses a different fuel source than we estimated, that can make a big impact on your home's rating.<br/><br/>You can increase the accuracy of your home's rating, get energy savings recommendations and more by <6>Signing Up</6>, all for free.</0>",
                "hmp-how-footer": "Sign Up to see your detailed free assessment in 60 seconds, including recommendations and savings estimates.",
                "Address Not Found": "Address Not Found",
                "Please check address and try again": "Please check address and try again",
                "People reviewing a home assessment": "People reviewing a home assessment",
                "Done": "Done",
                "hmp-intro-step-no-2": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-back": "Back",
                "Search Result": "Search Result",
                "Home Icon": "Home Icon",
                "Question Mark Icon": "Question Mark Icon",
                "Environmental Impact Icon": "Emissions Icon",
                "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)": "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)",
                "Very Poor": "Very Poor",
                "Trees Icon": "Trees Icon",
                "Check Icon 1": "Check Icon 1",
                "Check Icon 2": "Check Icon 2",
                "Check Icon 3": "Check Icon 3",
                "Check Icon 4": "Check Icon 4",
                "hmp-home-rating": "Home Rating",
                "FAQ": "FAQ",
                "rrt-environmental-impact": "Emissions",
                "rrt-energy-use": "Energy Use",
                "rrti-main-co2-per-year": "{{activeEnvImpact}} tonnes of CO2e/year",
                "rrti-sub-co2-per-year": "Carbon Emissions",
                "rrti-trees": "<0></0> It takes {{numTrees}} trees to clean that much carbon from the air",
                "hmp-cta-title": "Your Energy Saving Journey Starts Here",
                "hmp-cta-button-title": "Sign in to Get My Personalized Energy Recommendations",
                "hmp-cta-footer": "Free Energy Savings Insights in 60 seconds",
                "hmp-cta-check-1": "Retrofit Savings Estimates",
                "hmp-cta-check-2": "Estimated Retrofit Costs",
                "hmp-cta-check-3": "Energy Use Breakdown",
                "hmp-cta-check-4": "Increase Accuracy by Updating Building Info",
                "Better than Average": "Better than Average",
                "rrti-main-gj-per-year": "{{activeEnergyUse}} GJ/year",
                "rrti-sub-gj-per-year": "Annual Energy Use",
                "Collapse FAQ Item": "Collapse FAQ Item",
                "Unknown": "Unknown",
                "Good": "Good",
                "Worse than Average": "Worse than Average",
                "Poor": "Poor",
                "Average": "Average",
                "Excellent": "Excellent",
                "Header": "Header",
                "Search Result (None)": "Search Result (None)",
                "hmp-desktop-banner": "hmp-title-desktop.png",
                "hmp-mobile-banner": "hmp-title-mobile.png",
                "hmp-lang-code": "EN",
                "Language Icon": "Language Icon - English",
                "Language Switcher": "Language Switcher",
                "Dial Pointer": "Dial Pointer",
                "Map Switcher": "Map Switcher",
                "of": "of",
                "Home Details": "Home Details",
                "Insulation": "Insulation",
                "What type of house do you have?": "What type of house do you have?",
                "Enter year": "Enter year",
                "Approximately what year was your house built?": "Approximately what year was your house built?",
                "How drafty is your home?": "How drafty is your home?",
                "How many heated floors/storeys does your house have?": "How many heated floors/storeys does your house have?",
                "Area": "Area",
                "What is the total floor area of your home? Only include areas you heat.": "What is the total floor area of your home? Only include areas you heat.",
                "What is the area of your first floor? This helps us know the size of your building's footprint.": "What is the area of your first floor? This helps us know the size of your building's footprint.",
                "What type of foundation do you have?": "What type of foundation do you have?",
                "CONTINUE": "CONTINUE",
                "Back to Previous Step": "Back to Previous Step",
                "cwp-info-text": "Thank you for providing information about your home.<br/><2>Note: All inputs are completely optional. You can change/ provide any of this information in your Assessment.</2>",
                "cwp-sub-title-year_built": "If you are unsure, you can select from the following estimated year ranges below",
                "cwp-sub-title-total_floor_area_sqft": "If you are unsure, you can select from the following estimated area ranges below",
                "Good Morning": "Good Morning",
                "Good Afternoon": "Good Afternoon",
                "Good Evening": "Good Evening",
                "View Report": "View Report",
                "Energy Savings Potential Report": "Energy Savings Potential Report",
                "Your Efficiency Roadmap": "Your Efficiency Roadmap",
                "Book Home Energy Assessment": "Book Home Energy Assessment",
                "STEP": "STEP",
                "PENDING": "PENDING",
                "Home Information Survey": "Building Questionnaire",
                "OPTIONAL STEP": "OPTIONAL STEP",
                "OPTIONAL": "OPTIONAL",
                "Enter Home Information": "Enter Home Information",
                "Perform Remote Energy Assessment": "Perform Remote Energy Assessment",
                "View Recommendations in your Energy Advice Report": "View Recommendations in your Energy Advice Report",
                "report-updated-on": "Updated: {{lastPublishedDate}}",
                "cdb-step-no": "STEP {{number}}",
                "cdb-step1-sub-step-after-va-auto-insights": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-before-pre-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><2>Your Energy Advisor will reach out to you to book your appointment.</2>",
                "cdb-sc-desc-booked": "Your energy advisor will perform an energy assessment of your home while on a video call with you.",
                "cdb-sc-desc-post-survey": "Thank you for taking the time to provide more information about your home.<br/><br/><3>Your Energy Savings Potential Report has been updated. You can access it at the top of this page, or by clicking here.</3><br/><br/>Still want to provide more information?<br/>",
                "Heating and Cooling": "Heating and Cooling",
                "Insulation R-Value": "Insulation R-Value",
                "How much insulation is in your ceiling/attic?": "How much insulation is in your ceiling/attic?",
                "How much insulation is in your walls?": "How much insulation is in your walls?",
                "Doors": "Doors",
                "How many exterior doors do you have?": "How many exterior doors do you have?",
                "Windows": "Windows",
                "How many windows do you have?": "How many windows do you have?",
                "How many of your windows are ENERGY STAR (or energy efficient)?": "How many of your windows are ENERGY STAR (or energy efficient)?",
                "How many of your doors are ENERGY STAR (or energy efficient)?": "How many of your doors are ENERGY STAR (or energy efficient)?",
                "Finish": "Finish",
                "What kind of primary heating system do you have?": "What kind of primary heating system do you have?",
                "Do you have a secondary heating system?": "Do you have a secondary heating system?",
                "What type of cooling / air conditioner do you have?": "What type of cooling / air conditioner do you have?",
                "What type of water heating system do you have?": "What type of water heating system do you have?",
                "What fuel type does your water heating system use?": "What fuel type does your water heating system use?",
                "FINISH": "FINISH",
                "cwp-finished-title": "Thank you for your helpful submission.",
                "cwp-finished-body": "This information will be shared with your energy advisor.<br/><br/>Also, your Energy Savings Potential Report has been updated. You can access it at the top of the dashboard page, or by clicking below.",
                "Total Energy Savings": "Retrofit Scenario Savings",
                "How we came up with this": "How we came up with this",
                "Logout Button": "Logout Button",
                "Dashboard": "Dashboard",
                "Your Energy Savings Potential Report": "Your Energy Savings Potential",
                "Upgrade Package Savings": "Retrofit Scenario Savings",
                "Individual Upgrades": "Individual Retrofits",
                "Footer": "Footer",
                "crp-how-we": "How we came up with this",
                "crp-read-more": "Read More <1></1>",
                "Success!<br/>You have requested a Video Home Energy Assessment.": "Success!<br/>You have requested a Video Home Energy Assessment.",
                "Your energy advisor will reach out to you shortly to book your appointment.": "Your energy advisor will reach out to you shortly to book your appointment.",
                "Go to Home Page": "Go to Updated Report",
                "What fuel type does your primary heating system use?": "What fuel type does your primary heating system use?",
                "What Type of Primary Heat Pump do you have?": "What Type of Primary Heat Pump do you have?",
                "What kind of secondary heating system do you have?": "What kind of secondary heating system do you have?",
                "Your request has been submitted. A service provider will send you an offer soon as possible.": "Your request has been submitted. A service provider will send you an offer soon as possible.",
                "You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.": "You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.",
                "Select a unit": "Select a unit",
                "Home Information Wizard": "Building Questionnaire",
                "Home Details": "Home Details",
                "Insulation": "Insulation",
                "Heating and Cooling": "Heating and Cooling",
                "Space Heating": "Space Heating",
                "Space Cooling": "Space Cooling",
                "Water Heating": "Water Heating",
                "Lights, Appliances & Other": "Appliances, Lights & Other",
                "Emissions Rating": "Emissions\nRating",
                "Energy Rating": "Energy\nRating",
                "Energy Use Breakdown": "Energy Use\nBreakdown",
                "Low Energy Use": "Low Energy Use",
                "High Energy Use": "High Energy Use",
                "Your Home": "Your Home",
                "Other Homes": "Other Homes",
                "Estimated Costs": "Estimated Costs",
                "Efficiency NS - Average Rebates": "Efficiency NS - Average Rebates",
                "Greener Homes - Average Rebates": "Greener Homes - Average Rebates",
                "Estimated Annual Savings": "Estimated Annual Savings",
                "20-Year Savings": "20-Year Savings",
                "15-Year Savings": "15-Year Savings",
                "10-Year Savings": "10-Year Savings",
                "Recommendations & Estimated Savings": "Recommendations & Estimated Savings",
                "We analyzed your home’s energy usage and basic building information to evaluate which building retrofits could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "We analyzed your home’s energy usage and basic building information to evaluate which building retrofits could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that was automatically recommended.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that was automatically recommended.",
                "The average rebate value shown is the average rebate homeowners received for performing this upgrade.": "The average rebate value shown is the average rebate homeowners received for performing this upgrade.",
                "Unknown": "Unknown",
                "Payback Period": "Payback Period",
                "Unavailable": "Unavailable",
                "Energy Advisor's Comments": "Energy Advisor's Comments",
                "Your Certified Energy Advisor modeled the building upgrades you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Your Certified Energy Advisor modeled the building retrofits you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "Your Certified Energy Advisor analyzed your energy use and building information you provided to model the best building upgrades to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Your Certified Energy Advisor analyzed your energy use and building information you provided to model the best building retrofits to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade you implemented.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that upgrade you implemented.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade your Energy Advisor recommended.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that upgrade your Energy Advisor recommended.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.": "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Advisor recommended.": "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Advisor recommended.",
                "book-remote-audit-cta": "For even more accurate results and actionable advice, we recommend you <1>book a Remote Home Energy Audit today.</1>",
                "COMPLETE": "COMPLETE",
                "Update Home Information": "Update Home Information",
                "Please wait ...": "Please wait ...",
                "Energy Auditor Book HEA": "Energy Auditor Book HEA",
                "cdb-step1-booking-disabled": "<0>We are no longer able to accept requests for video audit at this time as the pilot period has ended.</0><1>We thank you for your interest and apologize for the inconvenience.</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-1": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-2": "<0>Request Home Energy Assessment</0>",
                "cdb-step1-sub-step-after-upg-upgrades-completed": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-before-post-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><2>Your Energy Advisor will reach out to you to book your appointment.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><br/><3></3>",
                "cdb-step1-sub-step-eq-pre-auto-insights-2": "<0>Request Home Energy Assessment!</0>",
                "cdb-sc-desc-call-booked": "Your energy advisor will perform an energy assessment of your home to validate your retrofits while on a video call with you.",               
                "cdb-sc-desc-val-report-ready": "<0>You now have an Upgrade Validation Report. You can access it at the top of this page, or by clicking here.</0><br/><br/>",
                "cdb-sc-desc-report-ready-with-fsp": "<0>You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</0><br/><br/><3>Ready to start saving energy?</3><4>Find a Service Provider</4>",
                "cdb-sc-desc-report-ready-no-fsp": "<0>You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</0><br/>",
                "cdb-sc-desc-pre-survey": "Please tell us as much about your home as you can in preparation for your Energy Assessment.<br/><br/>This info will be shared with your energy advisor, and this will help determine which retrofits are the best fit for your home in your updated Energy Savings Potential Report.",
                "1940 or before": "1940 or before",
                "1941 to 1960": "1941 to 1960",
                "1961 to 1980": "1961 to 1980",
                "1981 to 2000": "1981 to 2000",
                "2000-3000 ft²": "2000-3000 ft²",
                "2001 to 2010": "2001 to 2010",
                "2011 to present": "2011 to present",
                "3000-4000 ft²": "3000-4000 ft²",
                "4000-5000 ft²": "4000-5000 ft²",
                "- Unspecified -": "- Unspecified -",
                "A little drafty": "A little drafty",
                "Above average - R16+": "Above average - R16+",
                "Above average - R24+": "Above average - R24+",
                "Above average - R60+": "Above average - R60+",
                "Air-to-Water": "Air-to-Water",
                "All": "All",
                "Average - Approx. R10": "Average - Approx. R10",
                "Average - Approx. R18": "Average - Approx. R18",
                "Average - Approx. R30": "Average - Approx. R30",
                "Basement": "Basement",
                "Below average - Approx. R5": "Below average - Approx. R5",
                "Below average - Approx. R12": "Below average - Approx. R12",
                "Below average - Approx. R15": "Below average - Approx. R15",
                "Central": "Central",
                "Central Split System (Ducted)": "Central Split System (Ducted)",
                "Central single package system": "Central single package system",
                "Combination boiler": "Combination boiler",
                "Condensing water heater": "Condensing water heater",
                "Conventional A/C": "Conventional A/C",
                "Conventional/Tank Water Heater": "Conventional/Tank Water Heater",
                "Crawlspace": "Crawlspace",
                "Electric Baseboard": "Electric Baseboard",
                "Electric Baseboard / Space Heater": "Electric Baseboard / Space Heater",
                "Electricity": "Electricity",
                "Enter R-Value": "Enter R-Value",
                "Enter area": "Enter area",
                "Enter number of exterior doors": "Enter number of exterior doors",
                "Enter number of windows": "Enter number of windows",
                "Fireplace": "Fireplace",
                "Furnace (Forced Air)": "Furnace (Forced Air)",
                "Geothermal": "Geothermal",
                "Heat Pump": "Heat Pump",
                "Heat Pump (hybrid) water heater": "Heat Pump (hybrid) water heater",
                "Hot Water Radiators and Boiler": "Hot Water Radiators and Boiler",
                "How much insulation is on your foundation walls?": "How much insulation is on your foundation walls?",
                "In-Floor Radiant & Boiler": "In-Floor Radiant & Boiler",
                "Is your basement heated?": "Is your basement heated?",
                "Is your crawlspace heated?": "Is your crawlspace heated?",
                "Mini-Split": "Mini-Split",
                "Mini-split Heat Pump (ductless)": "Mini-split Heat Pump (ductless)",
                "N/A": "N/A",
                "Natural Gas": "Natural Gas",
                "No": "No",
                "None": "None",
                "Not Installed": "Not Installed",
                "Not drafty": "Not drafty",
                "Oil": "Oil",
                "Other": "Other",
                "Propane": "Propane",
                "Slab": "Slab",
                "Solar": "Solar",
                "Solar water heater": "Solar water heater",
                "Tankless/Instantaneous Water Heater": "Tankless/Instantaneous Water Heater",
                "Unsure": "Unsure",
                "Very drafty": "Very drafty",
                "Window A/C": "Window A/C",
                "Wood": "Wood",
                "Wood / Pellet Stove": "Wood / Pellet Stove",
                "Wood Stove": "Wood Stove",
                "YYYY": "YYYY",
                "Yes": "Yes",
                "ft²": "ft²",
                "less than 100 m²": "less than 100 m²",
                "less than 1000 ft²": "less than 1000 ft²",
                "m²": "m²",
                "type_of_house__mobile_home": "<0><0></0><br/>Mobile<br/>Home</0>",
                "type_of_house__row_house_end_unit": "<0><0></0><br/>Row house -<br/>End unit</0>",
                "type_of_house__row_house_middle_unit": "<0><0></0><br/>Row house -<br/>Middle unit</0>",
                "type_of_house__semi_detached": "<0><0></0><1></1><br/>Double or Semi-<br/>Detached</0>",
                "type_of_house__single_detached": "<0><0></0><br/>Single<br/>Detached</0>",
                "Next": "Next",
                "Sign In To Energy Advisor": "Sign In To Energy Advisor",
                "Phone Number": "Phone Number",
                "Pin Number": "Pin Number",
                "Login": "Login",
                "simptek-login-footer": "<1>© Climative 2023</1>",
                "Loading...": "Loading...",
                "Could not find a customer matching this phone number & pin number.": "Could not find a customer matching this phone number & pin number.",
                "Please fill out this field.": "Please fill out this field.",
                "Estimated Rebates": "Estimated Rebates",
                "Average Rebates": "Average Rebates",
                "Want a more accurate rating?": "Want a more accurate rating?",
                "Ready to start saving energy?": "Ready to start saving energy?",
                "Upgrade Packages": "Retrofit Scenarios",
                "Take Building Info Survey": "Take Building Questionnaire!",
                "FAQ - Energy & Emissions Ratings": "FAQ - Energy & Emissions Ratings",
                "FAQ – Upgrades": "FAQ – Retrofits",
                "crp-faq-rating-title-0": "How Was My Home’s Energy Rating Estimated?",
                "crp-faq-rating-content-0": "<0><0>We use all of the data sources below when available to compare your home against thousands of similar homes to estimate your energy use.</0><1><0>•</0><1>Widely-Available Data (property assessment, weather data & others)</1></1><2><0>•</0><1>Your Questionnaire Submission</1></2><3><0>•</0><1>Assessment by professional Energy Advisor (either remote energy audit or on-site EnerGuide assessment)</1></3><4><0>•</0><1>Energy Consumption Data</1></4><5>When more data becomes available (we only obtain more data with user consent), we rerun the analysis.</5><6>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</6></0>",
                "crp-faq-rating-title-1": "How Was My Home’s Emissions Rating Estimated?",
                "crp-faq-rating-content-1": "<0><0>Your home’s Emissions Rating depends on its estimated Energy Rating (see question above) and the fuel types used in your home.</0><1>Which fuel type(s), and which utility provider(s) your energy comes from affects your emissions rating a lot. Electricity varies the most, where some electric utilities mostly use clean sources like hydro, but others use high-emissions sources like coal to generate electricity.</1></0>",
                "crp-faq-rating-title-2": "Why is My Energy Rating Good but Emissions Rating Bad? (Or Vice-Versa)",
                "crp-faq-rating-content-2": "<0><0>Yes! Since we start by assessing every home with widely-available data, the ratings start as being what’s normal for a home like yours – this often won’t take existing retrofits into account. You can increase the accuracy of your energy and emissions ratings by providing more data through the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</1></0>",
                "crp-faq-rating-title-3": "What is CO2e?",
                "crp-faq-rating-content-3": "<0><0>There are various different greenhouse gases (emissions) which are produced as a result of energy use. The main one is carbon dioxide (CO2), but there are others. CO2e accounts for the other gases as well, to give a full picture of your home’s emissions impact on climate change.</0></0>",
                "crp-faq-upgrades-title-0": "How was/were my Retrofit Scenario(s) Determined",
                "crp-faq-upgrades-content-0": "<0><0>We compared the data available for your home against thousands of other similar homes that completed retrofits and estimated the best combination of building retrofits based on your energy priorities. You can set your energy priorities in the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page</1></0>",
                "crp-faq-upgrades-title-1": "How Was the Energy Saving of Each Upgrade Estimated?",
                "crp-faq-upgrades-content-1": "<0><0>Climative uses a combination of artificial intelligence (AI) and building science to estimate the energy saved from each upgrade.</0></0>",
                "crp-faq-upgrades-title-2": "How Was the Emissions Reduction from Retrofits Estimated",
                "crp-faq-upgrades-content-2": "<0><0>The amount of emissions reduction for each upgrade depends on the energy savings (see section above) for that upgrade and the fuel types used in your home.</0></0>",
                "crp-faq-upgrades-title-3": "How Were the Costs of Retrofits Estimated?",
                "crp-faq-upgrades-content-3": "<0><0>We used data from NRCan and contractors to estimate the cost of retrofits (equipment + installation). Note that the cost of retrofits can vary significantly based on the specific details of a home.</0></0>",
                "crp-faq-upgrades-title-4": "How Were the Financial Projections Estimated?",
                "crp-faq-upgrades-content-4": "<0><0>The financial projections for your Retrofit Scenarios were based on: estimated retrofit costs after rebates, projected carbon tax rates and projected energy costs</0></0>",
                "crp-faq-upgrades-title-5": "Can I Get More Accurate Recommendations?",
                "crp-faq-upgrades-content-5": "<0><0>Yes! Since we start by assessing every home with widely-available data, the ratings start as being what’s normal for a home like yours – this often won’t take existing retrofits into account. You can increase the accuracy of your upgrade recommendations by providing more data through the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</1></0>",
                "cdb-step1-sub-step-eq-pre-auto-insights-1": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "Data Sources": "Data Sources",
                "Publicly-Available Data": "Publicly-Available Data",
                "Utility Consumption Data": "Utility Consumption Data",
                "crp-data-updated-on": "Updated: {{lastUpdatedDate}}",
                "EnerGuide Audit": "EnerGuide Audit",
                "Your Energy Rating and Upgrade Packages are based on:": "Your Energy Rating and Retrofit Scenarios are based on:",
                "Best": "Best",
                "Better": "Better",
                "Current": "Current",
                "Home Rating": "Home Rating",
                "Your Survey Submission": "Your Questionnaire Submission",
                "cwp-sub-title-r-select": "If you are unsure, you can select from the following estimated R-Value ranges below",
                "Share Utility Data": "Share Utility Data",
                "Current state: ": "Current state: ",
                "Unchecked": "Unchecked",
                "Cancel": "Cancel",
                "Share EnerGuide Report": "Share EnerGuide Report",
                "Get Your Upgrades Validated": "Get Your Retrofits Validated",
                "Close": "Close",
                "View Energy Savings Potential Report": "View Energy Savings Potential Report",
                "Increase Accuracy of Energy Rating and Retrofit Guidance": "Increase Accuracy of Energy Rating and Retrofit Guidance",
                "ONGOING": "ONGOING",
                "Here are the things you can do to increase accuracy. Do one or more.": "Here are the things you can do to increase accuracy. Do one or more.",
                "Survey": "Questionnaire",
                "Share": "Share",
                "Utility Data": "Utility Data",
                "EnerGuide Audit": "EnerGuide Audit",
                "Make Actionable Retrofit Plan": "Make Actionable Retrofit Plan",
                "NOT STARTED": "NOT STARTED",
                "Building Upgrades and Update Energy Rating": "Building Retrofits and Update Energy Rating",
                "DONE": "DONE",
                "Expand": "Expand",
                "Upgrades completed? Update your Energy Rating and Retrofit Guidance.": "Retrofits completed? Update your Energy Rating and Retrofit Guidance.",
                "Get Upgrades Vaildated \nby a Pro": "Get Retrofits Vaildated \nby a Pro",
                "More info": "More info",
                "Update": "Update",
                "report-updated-on-generic": "Last updated {{lastUpdatedDate}}",
                "Collapse": "Collapse",
                "Pending": "Pending",
                "Done with this step?": "Done with this step?",
                "Current: Yes": "Current: Yes",
                "cdb-vendor-make-plan": "Make a plan with a pro. Take advantage of {{vendorNamePossesive}} great incentives.",
                "cdb-vendor-link": "Open {{vendorNamePossesive}} website",
                "Climative": "Climative",
                "Compared Against Similar Homes in my": "Compared Against Similar Homes in my",
                "Municipality": "Municipality",
                "Neighborhood": "Neighborhood",
                "Province": "Province",
                "Select Comparison Area": "Select Comparison Area",
                "Good Upgrade Package": "Light Retrofit Scenario",
                "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.": "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.",
                "Better Upgrade Package": "Medium Retrofit Scenario",
                "Best Upgrade Package": "Deep Retrofit Scenario",
                "Helpful Links": "Helpful Links",
                "Better Homes Ottawa Services": "Better Homes Ottawa Services",
                "Get help from professionals who will help you what to do next.": "Get help from professionals who will help you what to do next.",
                "Better Homes Ottawa Loan Program": "Better Homes Ottawa Loan Program",
                "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!": "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!",
                "Rebate & Incentive Programs": "Rebate & Incentive Programs",
                "There are lots of programs available to help make your deep retrofit journey more affordable.": "There are lots of programs available to help make your deep retrofit journey more affordable.",
                "Select Package": "Select Scenario",
                "Select Upgrade Package": "Select Upgrade Scenario",
                "Update Preferences": "Update Preferences",
                "Go to ": "Go to ",
                "cdb-accept-bills-para": "Your Home Energy Report and public Home Rating (displayed on the Low Carbon Communities map) can be updated with your home’s energy consumption data from utilities.<br/><br/><3>Please set your preferences below:</3>",
                "cdb-accept-eng-checkbox-bc-on": "Retrieve available energy use data from Hydro One",
                "cdb-accept-eng-checkbox-2-on": "Retrieve available energy use data from Enbridge",
                "cdb-accept-eng-checkbox-3": "Continue retrieving energy use data from my utilities in the future",
                "cdb-accept-eng-checkbox-4": "Update public Home Rating based on retrieved utility data",
                "cdb-accept-eng-para": "Your Home Energy Report and public Home Rating (displayed on the Low Carbon Communities map) can be updated with EnerGuide reports.<br/><br/><3>Please set your preferences below:</3>",
                "cdb-accept-bills-checkbox-on": "Retrieve any existing EnerGuide reports for your home from Better Homes Ottawa",
                "cdb-accept-bills-checkbox-2-on": "Retrieve any future EnerGuide reports for your home from Better Homes Ottawa",
                "cdb-accept-bills-checkbox-3": "Update public Home Rating based on retrieved EnerGuide reports",
                "Your Priorities": "Your Priorities",
                "When making decisions about energy efficiency upgrades, what is most important to you?": "When making decisions about energy efficiency retrofits, what is most important to you?",
                "Most important to me": "Most important to me",
                "Move Down": "Move Down",
                "Saving Money": "Saving Money",
                "Move Up": "Move Up",
                "Important to me": "Important to me",
                "Reducing Carbon Emissions": "Reducing Carbon Emissions",
                "Improving Comfort": "Improving Comfort",
                "Somewhat important to me": "Somewhat important to me",
                "Improving my EnerGuide Rating": "Improving my EnerGuide Rating",
                "Does not affect my decisions": "Does not affect my decisions",
                "This Section is Empty": "This Section is Empty",
                "Save Priorities": "Save Priorities",
                "Personalize Your Recommendations": "Personalize Your Recommendations",
                "Everyone has different priorities when making energy efficiency upgrades.": "Everyone has different priorities when making energy efficiency retrofits.",
                "You can get upgrade packages that are customized based on what you care about by sharing a little info.": "You can get upgrade retrofit scenarios that are customized based on what you care about by sharing a little info.",
                "This is optional and can be updated at any time.": "This is optional and can be updated at any time.",
                "cdb-accept-eng-checkbox-bc": "Retrieve available energy use data from BC Hydro",
                "cdb-accept-eng-checkbox-2-bc": "Retrieve available energy use data from Fortis BC",
                "cdb-accept-bills-checkbox-bc": "Retrieve any existing EnerGuide reports for your home from CleanBC",
                "cdb-accept-bills-checkbox-2-bc": "Retrieve any future EnerGuide reports for your home from CleanBC",
                "Rating After Upgrades (vs. ": "Rating After Retrofits (vs. ",
                "Personalize my Recommendations": "Personalize my Recommendations",
                "Free Energy Coaching": "Free Energy Coaching",
                "CleanBC Better Homes provides free energy coaching services for homeowners.": "CleanBC Better Homes provides free energy coaching services for homeowners.",
                "Find a Contractor": "Find a Contractor",
                "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.": "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.",
                "CleanBC Rebates": "CleanBC Rebates",
                "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.": "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.",
                "Welcome to Climative": "Welcome to Climative",
                "Welcome": "Welcome",
                "Survey for Increased Accuracy": "Questionnaire for Increased Accuracy",
                "Personalized Recommendations": "Personalized Recommendations",
                "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.": "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ": "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ",
                "We also modeled different potential upgrade packages for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.": "We also modeled different potential upgrade retrofit scenarios for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.",
                "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.": "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.",
                "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the survey.": "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the building questionnaire.",
                "Everyone has different priorities when making energy efficiency retrofits.\n\nWith the Personalize My Recommendations feature, you can get upgrade packages customized based on your priorities.": "Everyone has different priorities when making energy efficiency retrofits.\n\nWith the Personalize My Recommendations feature, you can get upgrade retrofit scenarios customized based on your priorities.",
                "Back": "Back",
                "Estimated door age": "Estimated door age",
                "Enter age in years": "Enter age in years",
                "0-10 years": "0-10 years",
                "10-20 years": "10-20 years",
                "20-30 years": "20-30 years",
                "30-50 years": "30-50 years",
                "50+ years": "50+ years",
                "Approximately how old are your exterior doors?": "Approximately how old are your exterior doors?",
                "Estimated window age": "Estimated window age",
                "Approximately how old are your windows?": "Approximately how old are your windows?",
                "cwp-sub-title-exterior_doors_age": "If you are unsure, you can select from the following estimated ranges below",
                "cwp-sub-title-windows_age": "If you are unsure, you can select from the following estimated ranges below",
                "Primary heating system age": "Primary heating system age",
                "0-5 years": "0-5 years",
                "5-10 years": "5-10 years",
                "10-15 years": "10-15 years",
                "15-20 years": "15-20 years",
                "30+ years": "30+ years",
                "Approximately how old is your primary heating system?": "Approximately how old is your primary heating system?",
                "Water heating system age": "Water heating system age",
                "Approximately how old is your water heating system?": "Approximately how old is your water heating system?",
                "cwp-sub-title-primary_heating_system_age": "If you are unsure, you can select from the following estimated ranges below",
                "cwp-sub-title-water_heating_system_age": "If you are unsure, you can select from the following estimated ranges below",
                "Home Energy Report updated.": "Home Energy Report updated.",
                "Your home’s energy modelling has been updated based on the updated building information you submitted.": "Your home’s energy modelling has been updated based on the updated building information you submitted.",
                "Map": "Map",
                "Report": "Report",
                "Sign In": "Sign In",
                "Sign Out": "Sign Out",
                "Layers": "Layers",
                "cdb-accept-eng-checkbox-2": "Retrieve available energy use data",
                "rrt-emissions": "Emissions",
                "rrt-energy": "Energy",
                "Energy Use Breakdown (one line)": "Energy Use Breakdown",
                "Data Privacy": "Data Privacy",
                "Share Nothing": "Share Nothing",
                "Share Some": "Share Some",
                "Share More": "Share More",
                "Building Colour on Map": "Building Colour on Map",
                "Energy and Emissions Rating": "Energy and Emissions Rating",
                "Retrofit Scenarios": "Retrofit Scenarios",
                "We weren’t able to find a building with that address.": "We weren’t able to find a building with that address.",
                "We couldn’t place this building on the map, but all other features should work normally.": "We couldn’t place this building on the map, but all other features should work normally.",
                "add-my-home-cta": "Go to <1>Climative FAQ: Find My Home</1> for more info.",
                "Energy Advisor": "Energy Advisor"
            }
        },
        "fr": {
            "translation": {
                "Climative Low Carbon Communities": "Climative Communautés à Faible Émissions",
                "Loading Animation": "Animation de chargement",
                "Climative | Low Carbon Communities": "Climative | Communautés à Faible Émissions",
                "Home Energy Report": "Rapport sur l'énergie domestique",
                "Low Carbon Communities": "Communautés à Faible Émissions",
                "Search": "Rechercher",
                "Enter your home address": "Entrez votre addresse",
                "Intro Model": "Modèle d'introduction",
                "People discussing energy charts on a computer": "Les gens discutent de graphiques d'énergie sur un ordinateur",
                "Next": "Suivant",
                "FAQ Modal": "Modal FAQ",
                "Close FAQ": "Fermer FAQ",
                "Expand FAQ Item": "Développer l'élément FAQ",
                "Energy Use Icon": "Icône d'utilisation de l'énergie",
                "Environment Impact Icon": "Icône d'impact environnemental",
                "Icon portraying various types of energy": "Icône représentant divers types d'énergie",
                "Charts on a computer screen": "Graphiques sur l'écran d'un ordinateur",
                "Grey Home Icon": "Icône de maison grise",
                "Close Result": "Fermer résultat",
                "Emissions": "Émissions",
                "Energy": "Énergie",
                "Find your home": "Trouvez votre maison",
                "hmp-intro-step-no-1": "{{_step}} de {{NUM_STEPS}}",
                "hmp-intro-skip": "Sauter",
                "hmp-how-title": "Questions fréquentes",
                "hmp-faq-title-0": "Comment a été estimé l'utilisation d'énergie de ma maison ?",
                "hmp-faq-content-0": "<0>Nous avons utilisé <1> des informations sur les bâtiments disponibles publiquement</1> sur votre maison, et <3> les avons comparées à des milliers de maisons similaires</3> pour estimer l'utilisation d'énergie de votre maison.</0>",
                "hmp-faq-title-1": "Comment a été estimé la cote d'émissions de ma maison?",
                "hmp-faq-content-1": "<0>La cote d'émissions de votre maison dépend de son <1>utilisation estimée d'énergie</1> (voir la question ci-dessus) et des <3>types d'énergie</3> utilisés dans votre maison.</0>",
                "hmp-faq-title-2": "Pourquoi mon utilisation d'énergie est bonne mais mon ma cote d'émission est mauvaise ? (ou vice versa)",
                "hmp-faq-content-2": "<0>La cote d'émissions de votre maison est considérablement affectée par les <1>émissions des types d'énergie</1> utilisés par votre maison. Si votre maison utilise une quantité relativement faible d'énergie mais que ce type d'énergie provient d'une source d'énergie à haute émissions, votre cote émissions peut encore être mauvais.",
                "hmp-faq-title-3": "Je pense que le classement de ma maison est faux. Puis-je le corriger?",
                "hmp-faq-content-3": "<0>Oui ! Les informations que vous voyez jusqu'à présent sont basées sur ce qui est <1>normal pour une maison comme la vôtre</1>. Si votre maison a été modernisée ou si elle utilise une source de combustible différente de celle que nous avons estimée, cela peut avoir un grand impact sur le classement de votre maison.<br/><br/><5>Vous pouvez augmenter la précision du classement de votre maison, obtenir des recommandations d'économies d'énergie et plus encore en <1>s'inscrivant</1>, tout cela gratuitement.</5></0>",
                "hmp-faq-title-4": "Puis-je obtenir facilement des informations plus précises et détaillées ?",
                "hmp-faq-content-4": "<0>Oui ! Les informations que vous voyez jusqu'à présent sont basées sur ce qui est <1>normal pour une maison comme la vôtre</1>. Si votre maison a été modernisée ou si elle utilise une source de combustible différente de celle que nous avons estimée, cela peut avoir un grand impact sur le classement de votre maison.<br/><br/>Vous pouvez augmenter la précision du classement de votre maison, obtenir des recommandations d'économies d'énergie et plus encore en <6>s'inscrivant</6>, tout cela gratuitement.</0>",
                "hmp-how-footer": "Inscrivez-vous pour voir votre évaluation détaillée gratuite en 60 secondes, y compris des recommandations et des estimations d'économies.",
                "Address Not Found": "Adresse non trouvée",
                "Please check address and try again": "Veuillez vérifier l'adresse et réessayer",
                "People reviewing a home assessment": "Les gens examinent une évaluation de maison",
                "Done": "Terminé",
                "hmp-intro-step-no-2": "{{_step}} de {{NUM_STEPS}}",
                "hmp-intro-back": "Retour",
                "Search Result": "Résultat de recherche",
                "Home Icon": "Icône de maison",
                "Question Mark Icon": "Icône de point d'interrogation",
                "Environmental Impact Icon": "Icône d'Émissions",
                "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)": "Jauge allant du vert (excellent) au jaune (moyen) au rouge (très mauvais)",
                "Very Poor": "Très mauvais",
                "Trees Icon": "Icône d'arbres",
                "Check Icon 1": "Icône de vérification 1",
                "Check Icon 2": "Icône de vérification 2",
                "Check Icon 3": "Icône de vérification 3",
                "Check Icon 4": "Icône de vérification 4",
                "hmp-home-rating": "Classement de maison",
                "FAQ": "FAQ",
                "rrt-environmental-impact": "Émissions",
                "rrt-energy-use": "Utilisation d'énergie",
                "rrti-main-co2-per-year": "{{activeEnvImpact}} tonnes de CO2e/an",
                "rrti-sub-co2-per-year": "Émissions de carbone",
                "rrti-trees": "<0></0> Il faut {{numTrees}} arbres pour éliminer autant de carbone de l'air",
                "hmp-cta-title": "Votre voyage d'économie d'énergie commence ici",
                "hmp-cta-button-title": "Obtenez mes recommandations énergétiques personnalisées",
                "hmp-cta-footer": "Des connaissances gratuits d'économies d'énergie en 60 secondes",
                "hmp-cta-check-1": "Estimations d'économies de rénovation",
                "hmp-cta-check-2": "Coûts estimés de rénovation",
                "hmp-cta-check-3": "Décomposition de l'utilisation d'énergie",
                "hmp-cta-check-4": "Augmentez la précision en mettant à jour les informations du bâtiment",
                "Better than Average": "Mieux que la moyenne",
                "rrti-main-gj-per-year": "{{activeEnergyUse}} GJ/an",
                "rrti-sub-gj-per-year": "Consommation d'énergie annuelle",
                "Collapse FAQ Item": "Réduire l'élément FAQ",
                "Unknown": "Inconnu",
                "Good": "Bon",
                "Worse than Average": "Pire que la moyenne",
                "Poor": "Mauvais",
                "Average": "Moyen",
                "Excellent": "Excellent",
                "Header": "En-tête",
                "Search Result (None)": "Résultat de recherche (aucun)",
                "hmp-desktop-banner": "hmp-title-desktop-fr.png",
                "hmp-mobile-banner": "hmp-title-mobile-fr.png",
                "hmp-lang-code": "FR",
                "Language Icon": "Icône de la langue - Français",
                "Language Switcher": "Sélecteur de langue",
                "hmp-lang-radio-fr": "bouton radio français",
                "hmp-lang-radio-en": "bouton radio anglais",
                "hmp-lang-radio-fr-sel": "bouton radio français (choisie)",
                "hmp-lang-radio-en-sel": "bouton radio anglais (choisie)",
                "Dial Pointer": "Pointeur du cadran",
                "Map Switcher": "Changeur de carte",
                "of": "de",
                "Home Details": "Détails de la maison",
                "Insulation": "Isolation",
                "What type of house do you have?": "Quel type de maison avez-vous ?",
                "Enter year": "Entrez l'année",
                "Approximately what year was your house built?": "Vers quelle année votre maison a-t-elle été construite ?",
                "How drafty is your home?": "À quel point votre maison est-elle sujette aux courants d'air ?",
                "How many heated floors/storeys does your house have?": "Combien d'étages chauffés possède votre maison ?",
                "Area": "Surface",
                "What is the total floor area of your home? Only include areas you heat.": "Quelle est la surface totale de votre maison ? Ne comptez que les zones que vous chauffez.",
                "What is the area of your first floor? This helps us know the size of your building's footprint.": "Quelle est la surface de votre premier étage ? Cela nous aide à connaître la taille de l'empreinte de votre bâtiment.",
                "What type of foundation do you have?": "Quel type de fondation avez-vous ?",
                "CONTINUE": "CONTINUER",
                "Back to Previous Step": "Retournez",
                "cwp-info-text": "Merci d'avoir fourni des informations sur votre maison.<br/><2>Remarque : toutes les entrées sont complètement optionnelles. Vous pouvez changer/fournir n'importe quelle de ces informations dans votre évaluation.</2>",
                "cwp-sub-title-year_built": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages d'années estimées suivantes ci-dessous",
                "cwp-sub-title-total_floor_area_sqft": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages de superficies estimées suivantes ci-dessous",
                "Good Morning": "Bonjour",
                "Good Afternoon": "Bon après-midi",
                "Good Evening": "Bonsoir",
                "View Report": "Voir le rapport",
                "Energy Savings Potential Report": "Rapport sur le potentiel d'économies d'énergie",
                "Your Efficiency Roadmap": "Votre feuille de route d'efficacité",
                "Book Home Energy Assessment": "Réserver une évaluation énergétique à domicile",
                "STEP": "ÉTAPE",
                "PENDING": "EN ATTENTE",
                "Pending": "En Attente",
                "Home Information Survey": "Questionnaire sur le bâtiment",
                "OPTIONAL STEP": "ÉTAPE FACULTATIVE",
                "OPTIONAL": "FACULTATIF",
                "Enter Home Information": "Entrez les informations de la maison",
                "Perform Remote Energy Assessment": "Effectuer une évaluation énergétique à distance",
                "View Recommendations in your Energy Advice Report": "Voir les recommandations dans votre rapport de conseils énergétiques",
                "report-updated-on": "Mis à jour : {{lastPublishedDate}}",
                "cdb-step-no": "ÉTAPE {{number}}",
                "cdb-step1-sub-step-after-va-auto-insights": "<0></0><1>Demande de réservation pour une évaluation énergétique à domicile</1>",
                "cdb-step1-sub-step-before-pre-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2>Votre conseiller en énergie vous contactera pour prendre rendez-vous.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2><br/></3>",
                "cdb-step1-sub-step-pre-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><br/><3></3>",
                "cdb-sc-desc-booked": "Votre conseiller en énergie effectuera une évaluation énergétique de votre maison lors d'un appel vidéo avec vous.",
                "cdb-sc-desc-post-survey": "Merci d'avoir pris le temps de fournir plus d'informations sur votre maison.<br/><br/><3>Votre rapport sur le potentiel d'économies d'énergie a été mis à jour. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</3><br/><br/>Vous voulez toujours fournir plus d'informations ?<br/>",
                "Heating and Cooling": "Chauffage et climatisation",
                "Insulation R-Value": "Valeur R de l'isolation",
                "How much insulation is in your ceiling/attic?": "Combien d'isolant y a-t-il dans votre plafond/grenier ?",
                "How much insulation is in your walls?": "Combien d'isolant y a-t-il dans vos murs ?",
                "Doors": "Portes",
                "How many exterior doors do you have?": "Combien de portes extérieures avez-vous ?",
                "Windows": "Fenêtres",
                "How many windows do you have?": "Combien de fenêtres avez-vous ?",
                "How many of your windows are ENERGY STAR (or energy efficient)?": "Combien de vos fenêtres sont ENERGY STAR (ou économes en énergie) ?",
                "How many of your doors are ENERGY STAR (or energy efficient)?": "Combien de vos portes sont ENERGY STAR (ou économes en énergie) ?",
                "Finish": "Fin",
                "What kind of primary heating system do you have?": "Quel type de système de chauffage principal avez-vous ?",
                "Do you have a secondary heating system?": "Avez-vous un système de chauffage secondaire ?",
                "What type of cooling / air conditioner do you have?": "Quel type de climatisation / climatiseur avez-vous ?",
                "What type of water heating system do you have?": "Quel type de système de chauffage de l'eau avez-vous ?",
                "What fuel type does your water heating system use?": "Quel type de combustible utilise votre système de chauffage de l'eau ?",
                "FINISH": "FIN",
                "cwp-finished-title": "Merci pour votre soumission utile.",
                "cwp-finished-body": "Ces informations seront partagées avec votre conseiller en énergie.<br/><br/>De plus, votre Rapport de potentiel d'économies d'énergie a été mis à jour. Vous pouvez y accéder en haut de la page du tableau de bord ou en cliquant ci-dessous.",
                "Total Energy Savings": "Économies de forfait de mise à niveau",
                "How we came up with this": "Comment nous en sommes arrivés là",
                "Logout Button": "Bouton de déconnexion",
                "Dashboard": "Tableau de bord",
                "Your Energy Savings Potential": "Votre potentiel d'économies d'énergie",
                "Upgrade Package Savings": "Économies de forfait de mise à niveau",
                "Individual Upgrades": "Mises à niveau individuelles",
                "Footer": "Bas de page",
                "crp-how-we": "Comment nous en sommes arrivés là",
                "crp-read-more": "En savoir plus <1></1>",
                "Success!<br/>You have requested a Video Home Energy Assessment.": "Succès !<br/>Vous avez demandé une évaluation vidéo de l'efficacité énergétique de votre maison.",
                "Your energy advisor will reach out to you shortly to book your appointment.": "Votre conseiller en énergie vous contactera sous peu pour prendre rendez-vous.",
                "Go to Home Page": "Aller au rapport",
                "What fuel type does your primary heating system use?": "Quel type de combustible utilise votre système de chauffage principal ?",
                "What Type of Primary Heat Pump do you have?": "Quel type de pompe à chaleur avez-vous comme chauffage principal ?",
                "What kind of secondary heating system do you have?": "Quel type de système de chauffage secondaire avez-vous ?",
                "Your request has been submitted. A service provider will send you an offer soon as possible.": "Votre demande a été soumise. Un fournisseur de services vous enverra une offre dès que possible.",
                "You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.": "Vous serez notifié par email lorsque vous recevrez de nouvelles offres. Vous pourrez voir toutes les offres sur le conseiller en énergie.",
                "Select a unit": "Sélectionnez une unité",
                "Home Information Wizard": "Questionnaire sur le bâtiment",
                "Home Details": "Détails de la maison",
                "Insulation": "Isolation",
                "Heating and Cooling": "Chauffage et refroidissement",
                "Space Heating": "Chauffage d'espace",
                "Space Cooling": "Refroidissement de l'espace",
                "Water Heating": "Chauffage à l'eau",
                "Lights, Appliances & Other": "Appareils, Lumières et Autres",
                "Emissions Rating": "Cote\nd'émissions",
                "Energy Rating": "Cote\nénergétique",
                "Energy Use Breakdown": "Consommateurs\nd’énergie",
                "Energy Use Breakdown (one line)": "Consommateurs d’énergie",
                "Low Energy Use": "Faible Utilisation",
                "High Energy Use": "Utilisation Élevée",
                "Your Home": "Votre domicile",
                "Other Homes": "Autres maisons",
                "Estimated Costs": "Coûts estimés",
                "Efficiency NS - Average Rebates": "Efficiency NS - Remboursements Moyens",
                "Greener Homes - Average Rebates": "Greener Homes - Remboursements Moyens",
                "Estimated Annual Savings": "Économies annuelles estimatives",
                "20-Year Savings": "Économies sur 20 ans",
                "15-Year Savings": "Économies sur 15 ans",
                "10-Year Savings": "Économies sur 10 ans",
                "Recommendations & Estimated Savings": "Recommandations et économies estimées",
                "We analyzed your home’s energy usage and basic building information to evaluate which building upgrades could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Nous avons analysé la consommation d'énergie de votre maison et les informations de base sur le bâtiment pour évaluer quelles améliorations du bâtiment pourraient vous faire économiser le plus d'énergie. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that was automatically recommended.": "Les estimations des coûts estimés étaient basées sur un large échantillon de rénovations de bâtiments effectuées sur des maisons canadiennes et le montant de celles-ci était automatiquement recommandé.",
                "The average rebate value shown is the average rebate homeowners received for performing this upgrade.": "La valeur de remise moyenne indiquée est la remise moyenne que les propriétaires ont reçue pour avoir effectué cette mise à niveau.",
                "Unknown": "Inconnu",
                "Payback Period": "Période de récupération",
                "Unavailable": "Indisponible",
                "Energy Advisor's Comments": "Commentaires du conseiller en énergie",
                "Your Certified Energy Advisor modeled the building upgrades you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Votre conseiller en énergie certifié a modélisé les mises à niveau du bâtiment que vous avez effectuées. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "Your Certified Energy Advisor analyzed your energy use and building information you provided to model the best building upgrades to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Votre conseiller en énergie certifié a analysé votre consommation d'énergie et les informations sur le bâtiment que vous avez fournies pour modéliser les meilleures améliorations de bâtiment pour vous faire économiser de l'énergie. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade you implemented.": "Les estimations des coûts estimés étaient basées sur un large échantillon de rénovations de bâtiments effectuées sur des maisons canadiennes et sur le montant de cette rénovation que vous avez mise en œuvre.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade your Energy Advisor recommended.": "Les estimations des coûts estimés étaient basées sur un large échantillon de mises à niveau de bâtiments effectuées sur des maisons canadiennes et sur le montant de cette mise à niveau recommandée par votre conseiller en énergie.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.": "Les remises estimées fournies étaient basées sur les montants de remise standard pour ce type de mise à niveau et le montant de cette mise à niveau que vous avez mise en œuvre.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Advisor recommended.": "Les remises estimées fournies étaient basées sur les montants de remise standard pour ce type de mise à niveau et sur le montant de cette mise à niveau recommandé par votre conseiller en énergie.",
                "book-remote-audit-cta": "Pour des résultats encore plus précis et des conseils pratiques, nous vous recommandons de <1>réserver un audit énergétique domestique à distance dès aujourd'hui.</1>",
                "COMPLETE": "COMPLÈTE",
                "Update Home Information": "Mettre à jour les informations sur la maison",
                "Please wait ...": "S'il vous plaît, attendez ...",
                "Energy Auditor Book HEA": "Évaluateur énergétique Réserver l'ÉED",
                "cdb-step1-booking-disabled": "<0>Nous ne sommes plus en mesure d'accepter les demandes d'audit vidéo pour le moment car la période pilote est terminée.</0><1>Nous vous remercions de votre intérêt et nous nous excusons pour la gêne occasionnée.</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-1": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-2": "<0>Demander une évaluation énergétique de la maison</0>",
                "cdb-step1-sub-step-after-upg-upgrades-completed": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "cdb-step1-sub-step-before-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2>Votre conseiller en énergie vous contactera pour prendre rendez-vous.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><br/><3></3>",
                "cdb-step1-sub-step-eq-pre-auto-insights-2": "<0>Demandez une évaluation énergétique de votre maison !</0>",
                "cdb-sc-desc-call-booked": "Votre conseiller en énergie effectuera une évaluation énergétique de votre maison pour valider vos mises à niveau lors d'un appel vidéo avec vous.",               
                "cdb-sc-desc-val-report-ready": "<0>Vous avez maintenant un rapport de validation de mise à niveau. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</0><br/><br/>",
                "cdb-sc-desc-report-ready-with-fsp": "<0>Vous disposez maintenant d'un rapport de conseils énergétiques. Vous pouvez y accéder en haut de cette page ou en cliquant ici.</0><br/><br/><3>Prêt à commencer à économiser de l'énergie ?</3><4>Trouver un fournisseur de services</4>",
                "cdb-sc-desc-report-ready-no-fsp": "<0>Vous disposez maintenant d'un rapport de conseils énergétiques. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</0><br/>",
                "cdb-sc-desc-pre-survey": "Veuillez nous en dire le plus possible sur votre maison en préparation de votre évaluation énergétique.<br/><br/>Cette information sera partagée avec votre conseiller en énergie, et cela aidera à déterminer quelles améliorations conviennent le mieux à votre maison dans votre rapport sur le potentiel d'économies d'énergie mis à jour.",
                "1940 or before": "1940 ou avant",
                "1941 to 1960": "1941 à 1960",
                "1961 to 1980": "1961 à 1980",
                "1981 to 2000": "1981 à 2000",
                "2000-3000 ft²": "2000-3000 pi²",
                "2001 to 2010": "2001 à 2010",
                "2011 to present": "2011 à aujourd'hui",
                "3000-4000 ft²": "3000-4000 pi²",
                "4000-5000 ft²": "4000-5000 pi²",
                "- Unspecified -": "- Non spécifié -",
                "A little drafty": "Un peu de courants d'air",
                "Above average - R16+": "Au dessus de la moyenne - R16+",
                "Above average - R24+": "Au dessus de la moyenne - R24+",
                "Above average - R60+": "Au dessus de la moyenne - R60+",
                "Air-to-Water": "Air-Eau",
                "All": "Tout",
                "Average - Approx. R10": "Moyen - Env. R10",
                "Average - Approx. R18": "Moyen - Env. R18",
                "Average - Approx. R30": "Moyen - Env. R30",
                "Basement": "Sous-sol",
                "Below average - Approx. R5": "Sous la moyenne - env. R5",
                "Below average - Approx. R12": "Sous la moyenne - env. R12",
                "Below average - Approx. R15": "Sous la moyenne - env. R15",
                "Central": "Central",
                "Central Split System (Ducted)": "Système de séparation central (canalisé)",
                "Central single package system": "Système de paquet unique central",
                "Combination boiler": "Chaudière mixte",
                "Condensing water heater": "Chauffe-eau à condensation",
                "Conventional A/C": "Climatisation conventionnelle",
                "Conventional/Tank Water Heater": "Chauffe-eau conventionnel/réservoir",
                "Crawlspace": "Vide sanitaire",
                "Electric Baseboard": "Plinthe électrique",
                "Electric Baseboard / Space Heater": "Plinthe électrique / Chaufferette",
                "Electricity": "Électricité",
                "Enter R-Value": "Entrez la valeur R",
                "Enter area": "Entrez zone",
                "Enter number of exterior doors": "Entrez le nombre de portes extérieures",
                "Enter number of windows": "Entrez le nombre de fenêtres",
                "Fireplace": "Cheminée",
                "Furnace (Forced Air)": "Fournaise (air pulsé)",
                "Geothermal": "Géothermie",
                "Heat Pump": "Pompe à chaleur",
                "Heat Pump (hybrid) water heater": "Chauffe-eau à pompe à chaleur (hybride)",
                "Hot Water Radiators and Boiler": "Radiateurs à eau chaude et chaudière",
                "How much insulation is on your foundation walls?": "Quel est le degré d'isolation de vos murs de fondation?",
                "In-Floor Radiant & Boiler": "Radiant et chaudière au sol",
                "Is your basement heated?": "Votre sous-sol est-il chauffé ?",
                "Is your crawlspace heated?": "Votre vide sanitaire est-il chauffé ?",
                "Mini-Split": "Mini-Split",
                "Mini-split Heat Pump (ductless)": "Thermopompe mini-split (sans conduit)",
                "N/A": "N/A",
                "Natural Gas": "Gaz naturel",
                "No": "Non",
                "None": "Aucune",
                "Not Installed": "Pas installé",
                "Not drafty": "Pas de courants d'air",
                "Oil": "Huile",
                "Other": "Autre",
                "Propane": "Propane",
                "Slab": "Dalle",
                "Solar": "Solaire",
                "Solar water heater": "Chauffe-eau solaire",
                "Tankless/Instantaneous Water Heater": "Chauffe-eau sans réservoir/instantané",
                "Unsure": "Incertaine",
                "Very drafty": "Très courant d'air",
                "Window A/C": "Climatisation de fenêtre",
                "Wood": "Bois",
                "Wood / Pellet Stove": "Poêle à Bois / Pellets",
                "Wood Stove": "Poêle à bois",
                "YYYY": "AAAA",
                "Yes": "Oui",
                "ft²": "pi²",
                "less than 100 m²": "moins que 100 m²",
                "less than 1000 ft²": "moins que 1000 pi²",
                "m²": "m²",
                "type_of_house__mobile_home": "<0><0></0><br/>Mobil-<br/>Home</0>",
                "type_of_house__row_house_end_unit": "<0><0></0><br/>Maison en rangée -<br/>Unité de bout</0>",
                "type_of_house__row_house_middle_unit": "<0><0></0><br/>Maison en rangée -<br/>Unité du milieu</0>",
                "type_of_house__semi_detached": "<0><0></0><1></1><br/>Double ou Semi-<br/>Détaché</0>",
                "type_of_house__single_detached": "<0><0></0><br/>Individuelle<br/>Isolée</0>",
                "1500-2000 ft²": "1500-2000 pi²",
                "1000-1500 ft²": "1000-1500 pi²",
                "Next": "Suivant",
                "Sign In To Energy Advisor": "Connectez-vous au Navigateur D'Énergie",
                "Phone Number": "Numéro de téléphone",
                "Pin Number": "Code PIN",
                "Login": "Connexion",
                "simptek-login-footer": "<1>© Climative 2023</1>",
                "Loading...": "Chargement...",
                "Could not find a customer matching this phone number & pin number.": "Impossible de trouver un client correspondant à ce numéro de téléphone et à ce code PIN.",
                "Please fill out this field.": "Veuillez remplir ce champ.",
                "Estimated Rebates": "Rabais estimés",
                "Average Rebates": "Remises moyennes",
                "Want a more accurate rating?": "Voulez-vous une évaluation plus précise ?",
                "Ready to start saving energy?": "Prêt à commencer à économiser de l'énergie ?",
                "Upgrade Packages": "Scénarios de rénovation",
                "Your Energy Savings Potential Report": "Votre Potentiel d'Économies d'Énergie",
                "Take Building Info Survey": "Remplir le Questionnaire !",
                "FAQ - Energy & Emissions Ratings": "FAQ - Cotes d'énergie et d'émissions",
                "FAQ – Upgrades": "FAQ – Mises à jour",
                "crp-faq-rating-title-0": "Comment la cote énergétique de ma maison a-t-elle été estimée ?",
                "crp-faq-rating-content-0": "<0><0>Nous utilisons toutes les sources de données ci-dessous lorsqu'elles sont disponibles pour comparer votre maison à des milliers de maisons similaires afin d'estimer votre consommation d'énergie.</0><1><0>•</0><1>Largement -Données disponibles (évaluation de la propriété, données météorologiques et autres)</1></1><2><0>•</0><1>Votre soumission d'enquête</1></2><3><0 >•</0><1>Évaluation par un conseiller en énergie professionnel (audit énergétique à distance ou évaluation ÉnerGuide sur place)</1></3><4><0>•</0><1>Consommation d'énergie Données</1></4><5>Lorsque davantage de données deviennent disponibles (nous n'obtenons plus de données qu'avec le consentement de l'utilisateur), nous réexécutons l'analyse.</5><6>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</6></0>",
                "crp-faq-rating-title-1": "Comment la cote d'émissions de ma maison a-t-elle été estimée ?",
                "crp-faq-rating-content-1": "<0><0>La cote d'émissions de votre maison dépend de sa cote énergétique estimée (voir la question ci-dessus) et des types de combustibles utilisés dans votre maison.</0><1>Quel(s) type(s) de combustible et quel(s) fournisseur(s) de services publics ) d'où provient votre énergie affecte beaucoup votre taux d'émissions. L'électricité varie le plus, certains services publics d'électricité utilisant principalement des sources propres comme l'hydroélectricité, tandis que d'autres utilisent des sources à fortes émissions comme le charbon pour produire de l'électricité.</1></0>",
                "crp-faq-rating-title-2": "Pourquoi ma cote énergétique est-elle bonne mais ma cote d'émissions est-elle mauvaise ? (Ou vice versa)",
                "crp-faq-rating-content-2": "<0><0>Oui ! Étant donné que nous commençons par évaluer chaque maison avec des données largement disponibles, les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre - cela ne prendra souvent pas en compte les mises à niveau existantes. Vous pouvez augmenter la précision de vos cotes d'énergie et d'émissions en fournissant plus de données via l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</ 1></0>",
                "crp-faq-rating-title-3": "Qu'est-ce que le CO2e ?",
                "crp-faq-rating-content-3": "<0><0>Il existe différents gaz à effet de serre (émissions) qui sont produits à la suite de la consommation d'énergie. Le principal est le dioxyde de carbone (CO2), mais il y en a d'autres. Le CO2e tient également compte des autres gaz, pour donner une image complète de l'impact des émissions de votre maison sur le changement climatique.</0></0>",
                "crp-faq-upgrades-title-0": "Comment mon ou mes forfaits de mise à niveau ont-ils été déterminés ?",
                "crp-faq-upgrades-content-0": "<0><0>Nous avons comparé les données disponibles pour votre maison à des milliers d'autres maisons similaires qui ont effectué des rénovations et avons estimé la meilleure combinaison de rénovations de bâtiments en fonction de vos priorités énergétiques. Vous pouvez définir vos priorités énergétiques dans l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie</1></0>",
                "crp-faq-upgrades-title-1": "Comment l'économie d'énergie de chaque mise à niveau a-t-elle été estimée ?",
                "crp-faq-upgrades-content-1": "<0><0>Climative utilise une combinaison d'intelligence artificielle (IA) et de science du bâtiment pour estimer l'énergie économisée grâce à chaque mise à niveau.</0></0>",
                "crp-faq-upgrades-title-2": "Comment la réduction des émissions due aux mises à niveau a-t-elle été estimée",
                "crp-faq-upgrades-content-2": "<0><0>Le montant de la réduction des émissions pour chaque mise à niveau dépend des économies d'énergie (voir la section ci-dessus) pour cette mise à niveau et des types de combustibles utilisés dans votre maison.</0></0>",
                "crp-faq-upgrades-title-3": "Comment les coûts des mises à niveau ont-ils été estimés ?",
                "crp-faq-upgrades-content-3": "<0><0>Nous avons utilisé les données de RNCan et des entrepreneurs pour estimer le coût des mises à niveau (équipement + installation). Notez que le coût des mises à niveau peut varier considérablement en fonction des détails spécifiques d'une maison.</0></0>",
                "crp-faq-upgrades-title-4": "Comment les projections financières ont-elles été estimées ?",
                "crp-faq-upgrades-content-4": "<0><0>Les projections financières de vos scénarios de rénovation étaient basées sur : les coûts de rénovation estimés après remises, les taux de taxe sur le carbone projetés et les coûts énergétiques projetés.</0></0>",
                "crp-faq-upgrades-title-5": "Puis-je obtenir des recommandations plus précises ?",
                "crp-faq-upgrades-content-5": "<0><0>Oui ! Étant donné que nous commençons par évaluer chaque maison avec des données largement disponibles, les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre - cela ne prendra souvent pas en compte les mises à niveau existantes. Vous pouvez augmenter la précision de vos recommandations de mise à niveau en fournissant plus de données via l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</1> </0>",
                "cdb-step1-sub-step-eq-pre-auto-insights-1": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "Data Sources": "Les sources de données",
                "Publicly-Available Data": "Données accessibles au public",
                "Utility Consumption Data": "Données de consommation des services publics",
                "crp-data-updated-on": "Mis à jour : {{lastUpdatedDate}}",
                "EnerGuide Audit": "Vérification ÉnerGuide",
                "Your Energy Rating and Upgrade Packages are based on:": "Votre évaluation énergétique et vos forfaits de mise à niveau sont basés sur :",
                "Best": "Meilleur",
                "Better": "Mieux",
                "Current": "Présent",
                "Home Rating": "Évaluation de la maison",
                "Your Survey Submission": "Votre soumission d'enquête",
                "cwp-sub-title-r-select": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages de valeurs R estimées suivantes ci-dessous",
                "Climative": "Climative",
                "Compared Against Similar Homes in my": "Comparé à des maisons similaires dans ma",
                "Municipality": "Municipalité",
                "Neighborhood": "Quartier",
                "Province": "Province",
                "Select Comparison Area": "Sélectionnez la zone de comparaison",
                "Good Upgrade Package": "Scénario de rénovation légère",
                "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.": "Better Homes Ottawa offre des conseils professionnels, des entrepreneurs qualifiés et des incitatifs pour économiser de l'argent.",
                "Better Upgrade Package": "Scénario de rénovation moyenne",
                "Best Upgrade Package": "Scénario de rénovation approfondie",
                "Helpful Links": "Liens utiles",
                "Better Homes Ottawa Services": "Better Homes Services d'Ottawa",
                "Get help from professionals who will help you what to do next.": "Obtenez l'aide de professionnels qui vous aideront à savoir quoi faire ensuite.",
                "Better Homes Ottawa Loan Program": "Programme de prêts Better Homes Ottawa",
                "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!": "La Ville d'Ottawa met à l'essai un programme de prêt sans intérêt de trois ans pour les rénovations éconergétiques domiciliaires!",
                "Rebate & Incentive Programs": "Programmes de rabais et d'incitation",
                "There are lots of programs available to help make your deep retrofit journey more affordable.": "De nombreux programmes sont disponibles pour vous aider à rendre votre parcours de rénovation en profondeur plus abordable.",
                "Select Package": "Choisir un scénario",
                "Select Upgrade Package": "Sélectionnez le scénario de mise à niveau",
                "Update Preferences": "Préférences de mise à jour",
                "Go to ": "Aller à ",
                "cdb-accept-bills-para": "Votre rapport énergétique domestique et votre évaluation publique (affichés sur la carte des communautés à faible émission de carbone) peuvent être mis à jour avec les données de consommation d'énergie de votre maison fournies par les services publics.<br/><br/><3>Veuillez définir vos préférences ci-dessous :</3>",
                "cdb-accept-eng-checkbox-bc-on": "Récupérer les données disponibles sur la consommation d'énergie d'Hydro One",
                "cdb-accept-eng-checkbox-2-on": "Récupérer les données disponibles sur la consommation d'énergie d'Enbridge",
                "cdb-accept-eng-checkbox-3": "Continuer à récupérer les données de consommation d'énergie de mes services publics à l'avenir",
                "cdb-accept-eng-checkbox-4": "Mettre à jour l'évaluation de la maison publique en fonction des données de service récupérées",
                "cdb-accept-eng-para": "Votre rapport sur l'énergie domestique et la cote publique de votre maison (affichés sur la carte des communautés à faibles émissions de carbone) peuvent être mis à jour avec les rapports ÉnerGuide.<br/><br/><3>Veuillez définir vos préférences ci-dessous :</3>",
                "cdb-accept-bills-checkbox-on": "Récupérez tous les rapports ÉnerGuide existants pour votre maison auprès de Better Homes Ottawa",
                "cdb-accept-bills-checkbox-2-on": "Récupérez tous les futurs rapports ÉnerGuide pour votre maison auprès de Better Homes Ottawa",
                "cdb-accept-bills-checkbox-3": "Mettre à jour la cote publique des maisons en fonction des rapports ÉnerGuide récupérés",
                "Your Priorities": "Vos priorités",
                "When making decisions about energy efficiency upgrades, what is most important to you?": "Lorsque vous prenez des décisions concernant des mises à niveau d'efficacité énergétique, qu'est-ce qui est le plus important pour vous ?",
                "Most important to me": "Le plus important pour moi",
                "Move Down": "Descendre",
                "Saving Money": "Économiser de l'argent",
                "Move Up": "Déplacer vers le haut",
                "Important to me": "Important pour moi",
                "Reducing Carbon Emissions": "Réduction des émissions de carbone",
                "Improving Comfort": "Améliorer le confort",
                "Somewhat important to me": "Un peu important pour moi",
                "Improving my EnerGuide Rating": "Améliorer ma cote ÉnerGuide",
                "Does not affect my decisions": "N'affecte pas mes décisions",
                "This Section is Empty": "Cette section est vide",
                "Save Priorities": "Enregistrer",
                "Personalize Your Recommendations": "Personnalisez vos recommandations",
                "Everyone has different priorities when making energy efficiency upgrades.": "Tout le monde a des priorités différentes lorsqu'il s'agit d'améliorer l'efficacité énergétique.",
                "You can get upgrade packages that are customized based on what you care about by sharing a little info.": "Vous pouvez obtenir des packages de mise à niveau personnalisés en fonction de ce qui vous intéresse en partageant quelques informations.",
                "This is optional and can be updated at any time.": "Ceci est facultatif et peut être mis à jour à tout moment.",
                "Cancel": "Annuler",
                "Share Utility Data": "Partager les données de l'utilitaire",
                "Current state: ": "État actuel: ",
                "Unchecked": "Décoché",
                "Share EnerGuide Report": "Partager le rapport ÉnerGuide",
                "Survey": "Enquête",
                "Share": "Partager",
                "Utility Data": "Données utilitaires",
                "cdb-accept-eng-checkbox-bc": "Récupérer les données disponibles sur la consommation d'énergie de BC Hydro",
                "cdb-accept-eng-checkbox-2-bc": "Récupérer les données disponibles sur la consommation d'énergie de Fortis BC",
                "cdb-accept-bills-checkbox-bc": "Récupérez tous les rapports ÉnerGuide existants pour votre maison auprès de CleanBC",
                "cdb-accept-bills-checkbox-2-bc": "Récupérez tous les futurs rapports ÉnerGuide pour votre maison auprès de CleanBC",
                "Update": "Mise à jour",
                "Rating After Upgrades (vs. ": "Classement après rénos (vs. ",
                "Personalize my Recommendations": "Personnaliser mes recommandations",
                "Free Energy Coaching": "Coaching énergétique gratuit",
                "CleanBC Better Homes provides free energy coaching services for homeowners.": "CleanBC Better Homes offre des services de coaching énergétique gratuits aux propriétaires.",
                "Find a Contractor": "Trouver un entrepreneur",
                "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.": "CleanBC Better Homes dispose d'un outil de recherche pour vous aider à trouver un entrepreneur enregistré pour vos besoins de rénovation domiciliaire.",
                "CleanBC Rebates": "Rabais CleanBC",
                "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.": "Le programme Better Homes de CleanBC offre des conseils professionnels, des entrepreneurs qualifiés et des incitatifs pour économiser de l'argent.",
                "Welcome to Climative": "Bienvenue chez Climative",
                "Welcome": "Bienvenu",
                "Survey for Increased Accuracy": "Enquête pour une précision accrue",
                "Personalized Recommendations": "Recommandations personnalisées",
                "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.": "Bienvenue dans votre bilan énergétique climatique de la maison ! C'est la première étape vers des économies d'argent et la réduction des émissions de votre maison.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ": "Nous avons utilisé une combinaison d'intelligence artificielle (IA) et de science du bâtiment pour estimer les émissions, la cote énergétique et la répartition des principaux consommateurs d'énergie de votre maison.",
                "We also modeled different potential upgrade packages for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.": "Nous avons également modélisé différents packages de mise à niveau potentiels à prendre en compte. Nous avons estimé la modification de vos cotes d'énergie et d'émissions, ainsi que l'impact financier.",
                "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.": "La section Sources de données au bas du rapport sur l'énergie domestique 1) vous indique quelles données sont actuellement utilisées dans votre rapport sur l'énergie, et 2) vous permet d'ajouter plus d'informations afin d'obtenir les meilleurs résultats.",
                "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the survey.": "Nous commençons par évaluer les maisons avec des données largement disponibles, de sorte que les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre. Vous pouvez augmenter la précision de votre rapport sur l'énergie domestique en fournissant plus de données via l'enquête.",
                "Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get upgrade packages customized based on your priorities.": "Tout le monde a des priorités différentes lorsqu'il s'agit d'améliorer l'efficacité énergétique.\n\nGrâce à la fonction Personnaliser mes recommandations, vous pouvez obtenir des forfaits d'amélioration personnalisés en fonction de vos priorités.",
                "Back": "Dos",
                "cdb-dont-show-again": "Ne plus afficher ceci",
                "Checked": "Vérifié",
                "1500-2000 ft²": "1500-2000 ft²",
                "1000-1500 ft²": "1000-1500 ft²",
                "Estimated door age": "Âge estimé de la porte",
                "Enter age in years": "Entrez l'âge en années",
                "0-10 years": "0-10 ans",
                "10-20 years": "10-20 ans",
                "20-30 years": "20-30 ans",
                "30-50 years": "30-50 ans",
                "50+ years": "50+ ans",
                "Approximately how old are your exterior doors?": "Quelle est l'âge approximatif de vos portes extérieures ?",
                "Estimated window age": "Âge estimé de la fenêtre",
                "Approximately how old are your windows?": "Quel âge ont vos fenêtres environ ?",
                "cwp-sub-title-exterior_doors_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "cwp-sub-title-windows_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "Primary heating system age": "Âge du système de chauffage primaire",
                "0-5 years": "0-5 ans",
                "5-10 years": "5-10 ans",
                "10-15 years": "10-15 ans",
                "15-20 years": "15-20 ans",
                "30+ years": "30+ ans",
                "Approximately how old is your primary heating system?": "Quel est l'âge approximatif de votre système de chauffage principal ?",
                "Water heating system age": "Âge du système de chauffage de l'eau",
                "Approximately how old is your water heating system?": "Quel âge a environ votre système de chauffage de l'eau ?",
                "cwp-sub-title-primary_heating_system_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "cwp-sub-title-water_heating_system_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "Home Energy Report updated.": "Rapport sur l'énergie domestique mis à jour.",
                "Your home’s energy modelling has been updated based on the updated building information you submitted.": "La modélisation énergétique de votre maison a été mise à jour en fonction des informations mises à jour sur le bâtiment que vous avez soumises.",
                "Map": "Carte",
                "Report": "Rapport",
                "Sign In": "Connexion",
                "cdb-accept-eng-checkbox-2": "Récupérer les données disponibles sur la consommation d'énergie",
                "rrt-emissions": "Émissions",
                "rrt-energy": "Énergie",
                "Data Privacy": "Confidentialité des données",
                "Share Nothing": "Partager rien",
                "Share Some": "Partagez-en",
                "Share More": "Partager plus",
                "Building Colour on Map": "Couleur du bâtiment sur la carte",
                "Energy and Emissions Rating": "Évaluation de l'énergie et des émissions",
                "Retrofit Scenarios": "Scénarios de rénovation",
                "We weren’t able to find a building with that address.": "Nous n’avons pas pu trouver de bâtiment avec cette adresse.",
                "We couldn’t place this building on the map, but all other features should work normally.": "Nous n’avons pas pu placer ce bâtiment sur la carte, mais toutes les autres fonctionnalités devraient fonctionner normalement.",
                "add-my-home-cta": "Accédez à la <1>FAQ climatique : Trouver ma maison</1> pour plus d'informations.",
                "Energy Navigator": "Navigateur Énergétique",
                "Sign Out": "Déconnexion",
                "Layers": "Couches",
            }
        }
    },
    saveMissing: true,
    saveMissingPlurals: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        window.MISSING_KEYS[key] = fallbackValue || key;
    }
}, (err, t) => {
    
});

document.body.style.background = 'white';

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();