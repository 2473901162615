import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Format, RandomKey } from '../Common';
import './BuildingSelRow.scss';

class BuildingSelRow extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object,
    customer: PropTypes.object
  };

  static defaultProps = {
    data: {}
  };

  constructor(props) {
    super(props);
    this.key = RandomKey();
  }

  render() {
    const { data } = this.props;

    if (this.props.header) {
      return (
        <div key={this.key} style={{display: 'none'}}>
        </div>
      );
    }
    
    return (
      <div key={this.key} className={'building-row ' + (data.selected ? 'selected' : '')} onClick={() => {this.props.onMessage('toggle-building', data);}}>
        <div className='building-cell'>{data.name} <span className='org-name'>{data.org_name}</span></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(BuildingSelRow);
