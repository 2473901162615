import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RandomKey from './RandomKey';
import InsightTypeBlock from './InsightTypeBlock';

import '../Common.scss';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {
      value: props.defaultValue || props.value || (props.defaultValue === 0 ? 0 : null),
      focused: false,
      type: props.type || 'text',
      required: props.required || false
    };
    if (this.state.value === null) {
      this.state.value = undefined;
    }
  }

  componentDidUpdate(prevState, prevProps) {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) && this.lastVal !== JSON.stringify(this.props.value)) {
      this.setState({ value: this.props.value === null ? undefined : this.props.value });
      this.lastVal = JSON.stringify(this.props.value);
    }
  }

  render() {

    const inputProps = {
      className: 'en-input',
      key: this.key + '-input',
      type: this.state.type === 'textarea' ? undefined : this.state.type,
      onFocus: () => {this.setState({focused: true})},
      onBlur: () => {this.setState({focused: false})},
      value: this.state.value,
      maxLength: this.props.maxLength ? `${this.props.maxLength}` : undefined,
      rows: this.props.textareaRows,
      cols: this.props.textareaCols,
      readOnly: this.props.readOnly ? true : undefined,
      placeholder: this.props.placeholder,
      onKeyUp: (e) => {
        this.setState({ value: (e||window.event).target.value });
        if (this.props.onChange) {
          let val = (e||window.event).target.value;
          if (this.state.type === 'number' && val) {
            val = parseFloat(val);
          }
          this.props.onChange(val);
        }
      },
      onChange: (e) => {
        this.setState({ value: (e||window.event).target.value });
        if (this.props.onChange) {
          let val = (e||window.event).target.value;
          if (typeof val === 'string' && val.length > 0) {
            if (this.props.onNonEmpty) {
              this.props.onNonEmpty();
            }
          }
          if (this.state.type === 'number' && val) {
            val = parseFloat(val);
          }
          this.props.onChange(val);
        }
      }
    };

    return (
      <div className={'en-input-wrapper ' + (this.props.className || '') + ' ' + (this.props.large ? 'en-input-large' : '') + ' ' + (this.state.focused ? 'focused' : '')  + (this.props.error ? ' form-error-border' : '') + ((this.props.vlabel && this.state.value) ? ' has-value' : '')} key={this.key} style={this.props.width ? {width: this.props.width} : {}}>
        {(this.props.vlabel && (this.state.value || this.state.value === '0')) && <div className="en-input-vlabel">{this.props.vlabel}:</div>}
        {this.state.type !== 'textarea' ? <input
          {...inputProps}
        /> : <textarea {...inputProps} />}
        {(!this.state.focused && (!this.state.value && this.state.value !== '0')) && <div className='en-input-label'>
          {this.props.label}
          {this.state.required && <span className='en-input-required'>*</span>}
        </div>}
        {this.props.error && <div className='form-error-message'>{this.props.error}</div>}
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(Input));
